const common = [
  {
    path: '/downloadEshop',
    name: 'downloadEshop',
    component: () => import('@/viewsToB/download/index.vue'),
    meta: {
      title: '下载三翼鸟工作台APP',
      keepAlive: true,
      showTabbar: false,
      showHeader: false,
    }
  },
]

export default common
