const qrCodeList = [
  {
    path: '/qrCodeList',
    name: 'qrCodeList',
    component: () => import('@/viewsToB/qrCodeList/qrCodeList.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '常用二维码',
      needLogin: true,
    }
  },
  {
    path: '/createQrcode',
    name: 'createQrcode',
    component: () => import('@/viewsToB/qrCodeList/createQrcode.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '新建二维码',
      needLogin: true,
    }
  },
  {
    path: '/previewPage',
    name: 'previewPage',
    component: () => import('@/viewsToB/qrCodeList/previewPage.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '页面标题',
      needLogin: true,
    }
  }
]

export default qrCodeList