const everyDayListRouter = () => import(/* webpackChunkName: "everyDayList" */ '@/views/everyDayList/index');

const everyDayList = [
  {
    path: '/everyDayList',
    name: 'everyDayList',
    component: everyDayListRouter,
    meta: {
      keepAlive: true,
      title: '热销榜',
      showHeader: false,
      showTabbar: false,
    },
  },
]

export default everyDayList
