import Vue from 'vue'
import {
  Toast,
  Lazyload,
  Overlay,
  List,
  Cell,
  CellGroup,
  DatetimePicker,
  Loading,
  Popup,
  Tabbar,
  TabbarItem,
  NavBar,
  Swipe,
  SwipeItem,
  SwipeCell,
  Switch,
  Tabs,
  Tab,
  Grid,
  GridItem,
  Image,
  Sidebar,
  SidebarItem,
  Search,
  Sticky,
  PullRefresh,
  Skeleton,
  Dialog,
  Field,
  Stepper,
  ActionSheet,
  Icon,
  Row,
  Col,
  Button,
  RadioGroup,
  Radio,
  Uploader,
  Checkbox,
  CheckboxGroup,
  DropdownMenu,
  DropdownItem,
  Picker,
  Circle,
  TreeSelect,
  CountDown,
  Tag,
  PasswordInput,
  NumberKeyboard,
  DynamicTheme,
  Form,
} from 'vant'

import utils from '@/utils/utils'
var config = {
  // ...DynamicTheme.defaultTheme,
  themeBlue:{'--vant-sg-main-color': '#2283E2'},
  themeSyn: {
    '--vant-sg-main-color': '#be965a',
    '--vant-sg-main-color-1': '#d4bb86',
    '--vant-sg-box-shadow': 'inset 0.5px 0.5px 0 0 rgba(255, 255, 255, 1), 0 4px 8px 0 rgba(0,0,0,0.08)',
    '--vant-sg-background-color-alpha-1': '#be965a1a',
    '--vant-sg-background-color-alpha-9': '#be965ae6',
  }
};
utils.isSyBird() && document.body.classList.add('syn')
DynamicTheme.loadTheme(utils.isSyBird() ? 'themeSyn' : 0, config) // 默认使用 themeBlue
// test
// document.body.classList.add('syn')
// DynamicTheme.loadTheme('themeSyn', config)

// 有赞Vant UI框架
Vue.use(Circle)
Vue.use(Lazyload, {
  observer: true,
  // optional
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1,
  },
  lazyComponent: true,
})
Vue.use(Toast)
Vue.use(Overlay)
Vue.use(List)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(DatetimePicker)
Vue.use(Loading)
Vue.use(Popup)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(NavBar)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(SwipeCell)
Vue.use(Tabs)
Vue.use(Tab)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Image)
Vue.use(Sidebar)
Vue.use(SidebarItem)
Vue.use(Search)
Vue.use(Sticky)
Vue.use(Switch)
Vue.use(PullRefresh)
Vue.use(Skeleton)
Vue.use(Dialog)
Vue.use(Field)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Stepper)
Vue.use(ActionSheet)
Vue.use(Icon)
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Uploader)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Picker)
Vue.use(TreeSelect)
Vue.use(CountDown)
Vue.use(Tag)
Vue.use(PasswordInput)
Vue.use(NumberKeyboard)
Vue.use(Form)
