
export default {
  namespaced: true,
  actions: {
    doLogin({ dispatch, commit }, result) {
      let sessionValue = result.sessionValue
      let mid = result.mid
      let ucid = result.ucId
      let accessToken = result.accessToken
      let refreshToken = result.refreshToken || ''
      let storeCode = result.storeCode
      let token = result.token
      let sdToken = '';
      let userInfo = {}
      userInfo.username = result.userName
      userInfo.avatarImage = result.avatarImageFileId
      userInfo.loginName = result.loginName
      userInfo.birthday = result.birthday
      userInfo.phone = result.mobile
      userInfo.email = result.email
      userInfo.cartNumber = result.cartNumber
      userInfo.gender = result.gender
      userInfo.isNewUser = result.isNewUser
      userInfo.isStoreMember = result.isStoreMember
      userInfo.nickName = result.nickName
      userInfo.rankName = result.rankName
      userInfo.promotionCode = result.promotionCode
      userInfo.resultSign = result.resultSign
      userInfo.storeCode = result.storeCode

      commit('setLoginStatus', !!sessionValue, { root: true });
      commit('setmId', mid, { root: true });
      commit('setUcId', ucid, { root: true });
      commit('setAcsToken', accessToken, { root: true })
      commit('setRefreshToken', refreshToken, { root: true })
      // commit('setSessionKey', sessionKey, { root: true })
      // commit('setSessionValue', sessionValue, { root: true })
      commit('setUserInfo', userInfo, { root: true })
      commit('setToken', token, { root: true });
      commit('setStoreCode', storeCode, { root: true });

      // set locaStorage
      localStorage.setItem('isLogin', !!sessionValue)
      localStorage.setItem('ucId', ucid)
      localStorage.setItem('token', token)
      localStorage.setItem('sdToken', sdToken)
      localStorage.setItem('refreshToken', refreshToken)
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('mId', mid)
      // localStorage.setItem('sessionKey', sessionKey)
      // localStorage.setItem('sessionValue', sessionValue)
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
      localStorage.setItem('storeCode', storeCode)

      dispatch('isHasStore', { mid, sdToken }, { root: true })
      return Promise.resolve(result)
    },
    doLocation({ commit }, location) {
      commit('setLocationInfo', location, { root: true })
    }
  },
}
