import Vue from 'vue'
import VueRouter from 'vue-router'
import utils from '@/utils/utils'
import Store, { APP_USER_ADDR_KEY, defaultLocation } from '../store'
// 登录注册
import loginReg from './loginReg'

// 首页tab
import home from './home'
import category from './category'

// 订单相关
import orders from './orders'
// 充值相关
import recharge from './recharge'

import cart from './cart'

// 内购频道
import internalChannel from './internalChannel'

import mine from './mine'
import goods from './goods'
import shop from './shop'
import chat from './chat'
import smartList from './smartList'

//活动相关
import activity from './activity'

/** 拼团相关 */
import groupBooking from './groupBooking'

// 附近门店
import nearshop from './nearshop'
import everyDayList from './everyDayList'
import flashSale from './flashSale'
// 消息中心
import messageCenter from './messageCenter'
import messageDetail from './messageDetail'
// 海尔智选
import specialtyStore from './specialtyStore'

// 海尔智选
import smartChoice from './smartChoice'
// 确认邀请
import invite from './invite'

import tableau from './tableau'

// 游戏
import games from './games'
// 场景商城
import scene from './scene'

// 礼品卡提取
import gift from './gift'

// 中间页目录
import transfer from './transfer'

// 频道页
import channel from './channel'
// 积分签到
import points from './points'

// 邀请平台合伙人
import partners from './partners'
import createHelper from 'vue-router-keep-alive-helper'
// import createHelper from '../../debug/helper/index.js'
import thirdPartyLogic from '../thirdPartyLogics'
// 智家魔方
import magicCube from './magicCube'
import toB from './toB'
// 合伙人榜单
import partnerRanking from './partnerRanking'

// 购机权益相关
import goodsRight from './goodsRight'
// 三翼鸟工作台
import synWorkbench from './synWorkbench'

// 洗衣机扫码领券
import couponsCollectWithScan from './couponsCollectWithScan'

// promise形式统一加catch，防止各个方法调用后不捕获上报sentry
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function (location, onComplete, onAbort) {
  if (!onComplete && !onAbort && typeof Promise !== 'undefined') {
    return originalPush.call(this, location).catch(e => { e/*console.log(e)*/ })
  } else {
    return originalPush.call(this, location, onComplete, onAbort)
  }
}
VueRouter.prototype.replace = function (location, onComplete, onAbort) {
  if (!onComplete && !onAbort && typeof Promise !== 'undefined') {
    return originalReplace.call(this, location).catch(e => { console.log(e) })
  } else {
    return originalReplace.call(this, location, onComplete, onAbort)
  }
}

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter)
}
const routes = [
  ...toB,
  ...loginReg,
  ...activity,
  ...home,
  ...category,
  ...orders,
  ...recharge,
  ...cart,
  ...internalChannel,
  ...mine,
  ...goods,
  ...smartList,
  ...chat,
  ...shop,
  ...nearshop,
  ...everyDayList,
  ...messageCenter,
  ...messageDetail,
  ...flashSale,
  ...smartChoice,
  ...specialtyStore,
  ...invite,
  ...tableau,
  ...games,
  ...scene,
  ...gift,
  ...channel,
  ...points,
  ...partners,
  ...groupBooking,
  ...magicCube,
  ...partnerRanking,
  ...synWorkbench,
  ...transfer,
  ...goodsRight,
  ...couponsCollectWithScan,
  {
    path: '/configHelp',
    name: 'configHelp',
    component: () => import('@/views/configHelp'),
    meta: {
      showHeader: false,
    }
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import('@/views/404'),
    meta: {
      keepAlive: false,
      hideTabbar: true,
    },
  },
]
// 仅移动工作台新增部分路由（禁止其他平台访问） （目前看并没有实际作用，同时增加维护成本，暂时不启用）
// if (utils.isShopKeeper()) {
//   routes.push(toB)
// }

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // base: '/sgmobile',
  routes
})
createHelper({
  Vue,
  router,
  replaceStay: ['/', '/cart', '/mine', '/category', '/internalChannel']
}
);
let initRouter = true

const initLocationInfo = async () => {
  let infoStr = ''
  if(utils.isUplus()){
    infoStr = await utils.getAppStorageStringValue(APP_USER_ADDR_KEY)
    console.log('getAppStorageStringValue============2222222222=>', infoStr)
  }else{
    infoStr = localStorage.getItem('location')
  }
  utils.logColor('initLocationInfo', '#7b5abe', infoStr)
  utils.logColor('accessToken', '#7b5abe', localStorage.getItem('accessToken'))
  if(infoStr){
    return utils.strJSONParse(infoStr, defaultLocation)
  }else{
    return defaultLocation
  }
}


router.afterEach((to, from) => {
  console.log('+++++ afterrouter history state ++++++++', window.history.state, to)
  if (to.query.browerFirstIn === undefined && history.length === 1 && !utils.isHaierApp() && !window.history.state && !utils.isSgRn() && !utils.isIMaker() && !utils.isIMaker() && !utils.isInternalChannel()) {
    let newQuery = utils.strJSONParse(JSON.stringify(to.query), {});
    newQuery.browerFirstIn = 1;
    router.replace({ query: newQuery })
  }
  // 如果是首次进入则增加closewebview参数
  if (initRouter && utils.isHaierApp()) {
    initRouter = false
    // to.query.closewebview = 1
    let newQuery = utils.strJSONParse(JSON.stringify(to.query), {});
    newQuery.closewebview = 1;
    router.replace({ query: newQuery })
  }
})
let first = true

router.beforeEach(async (to, from, next) => {
  utils.logColor('当前页面地址', '#7b5abe', location.href)
  if (first) {
    const locationInfo = await initLocationInfo()
    Store.commit('setLocationInfo', locationInfo)

    // 仅第一次打开时调用
    first = false
    const ignoreDefaultDeliveryAddress = !!to.query.streetCode || utils.isShopKeeper() //工作台忽略默认收货
    if (utils.isHaierApp()) {
      // 添加haier App的容器进入前台逻辑
      thirdPartyLogic.injectDefaultHaierAppResumeEventListener({ ignoreDefaultDeliveryAddress })
    }

    // 判断是否需要免登行为 在爱创客和爱海尔免登 并且有access_token
    // 免登逻辑之前是在爱创客和爱海尔免登环境下，DSG-24911祥鹏于2021-5-21日取消了该限制
    /* eslint-disable camelcase */
    const {
      access_token,
      async,
      open_scene, //私域下程序打开场景
      open_source,
      source_type,
      source_code,
      crm_community_name, //crm 小区名
      crm_community_id, // crm 小区id
      opportunity_code, // crm商机编码
      opportunity_uid, // crm携带的 userid
      custom_title,
      hide_navi,
      hideHeader,
      addressInfo,
      streetCode,
      commissionDisplay,
      latitude,
      longitude,
      wxMpcommissionDisplay, //微信私域小程序佣金展示开关
      wxIdentity, // 微信私域小程序身份信息
      userCategory,
      userIdentity,
    } = to.query

    if(open_scene && !sessionStorage.getItem('sg_home_open_scene')){
      await Store.dispatch('location/doLocationInfo', { latitude: latitude || defaultLocation.latitude, longitude: longitude || defaultLocation.longitude })
      sessionStorage.setItem('sg_home_open_scene', open_scene)
    }

    if (access_token) {
      //切换账号重置定位key
      // if(utils.isUplus()){
      //   utils.setAppStorageStringValue(APP_USER_ADDR_KEY, '')
      // }
      // 默认同步 当明确异步时，URL中需要添加 async 参数
      if (async) {
        Store.dispatch('uplusAccount/getAuthorize', { sdToken: access_token, ignoreDefaultDeliveryAddress }).then(() => {
          thirdPartyLogic.launchCommissionLogic()
        })
      } else {
        try {
          await Store.dispatch('uplusAccount/getAuthorize', { sdToken: access_token, ignoreDefaultDeliveryAddress })
        } catch (error) {
        }
        await thirdPartyLogic.launchCommissionLogic()
      }
    } else {
      if ((utils.isSgRn() || utils.isWeixin()) && location.search.includes('sg_rn_app')) {
        await thirdPartyLogic.sgRNLogin()
      }

      // 工作台一定需要 等待登录 23-07-04
      // 解决切换用户第一次打开时异步接口还是以第一次用户的信息发送问题
      // 下面的if else if 之前放在if (access_token) 上面 现在放到 if (access_token) else 中防止APP中带access_token 参数重复登录
      // 新增条件  (commissionDisplay && Number(commissionDisplay) !== 0) ，防止佣金首次无法展示的问题
      if (utils.isShopKeeper() || (utils.isHaierApp() && ((location.href.indexOf('awaitlogin') === -1 && to.meta.awaitlogin) || (commissionDisplay && Number(commissionDisplay) !== 0)))) {
        try {
          utils.logColor('prepreaaaaaaa', 'blue', localStorage.getItem('accessToken'))
          await thirdPartyLogic.awaitLoginIsHaierApp(true, { ignoreDefaultDeliveryAddress })
        } catch (error) {
          console.log(error, '登录异常')
        }
      } else if (utils.isHaierApp() && location.href.indexOf('awaitlogin') === -1) {
        thirdPartyLogic.awaitLoginIsHaierApp(false, { ignoreDefaultDeliveryAddress }, (open_source === 'haier-app-zj-shared' && ['detail', 'shopDetail', 'shopFtDetail'].includes(to.name) && utils.isUplus()))
      }
      await thirdPartyLogic.launchCommissionLogic()
    }


    // 订单来源标识  只记录第一次进入的
    if (source_type && !sessionStorage.getItem('sc_source_type')) {
      sessionStorage.setItem('sc_source_type', source_type)
      sessionStorage.setItem('sc_source_code', source_code || '')
      Store.commit('setSourceTypeAndCode', { sourceType: source_type, sourceCode: source_code })
    }

    // // open_source 跳转到商城的来源标识 referer; 只记录第一次进入的
    // if (open_source && !sessionStorage.getItem('open_source')) {
    //   sessionStorage.setItem('open_source', open_source)
    // }

    if(crm_community_name && !sessionStorage.getItem('crm_community_name')){
      sessionStorage.setItem('crm_community_name', crm_community_name || '')
      sessionStorage.setItem('crm_community_id', crm_community_id || '')
    }
    if(opportunity_code && !sessionStorage.getItem('crm_opportunity_code')){
      sessionStorage.setItem('crm_opportunity_code', opportunity_code)
    }
    if(opportunity_uid && !sessionStorage.getItem('crm_opportunity_uid')){
      sessionStorage.setItem('crm_opportunity_uid', opportunity_uid)
    }
    

    // custom_title 自定义文档标题
    if (custom_title) {
      document.title = custom_title
    } else {
      document.title = to.meta.title || '三翼鸟商城'
    }
    // hide_navi = 1 或者 hideHeader = 1 在必要且页面支持的情况下隐藏导航栏
    if (hide_navi || hideHeader) {
      sessionStorage.setItem('hide_navi', hide_navi || hideHeader)
    }
    // addressInfo , streetCode 含此两者任意一个参数 则需要切换全局街道位置
    if ((addressInfo || streetCode) && !utils.isUplus()) {
      try {
        await thirdPartyLogic.launchChangePositionLogic({
          addressInfo: addressInfo,
          streetCode: streetCode,
        })
      } catch (error) {
      }
    }
    if (userCategory || userIdentity) {
      Store.commit('mymanage/setCurrentRole', {
        userCategory,
        userIdentity
      })
    }
    if (utils.isHaierApp()) {
      console.log('------------------to.meta--------------------')
      console.log(to.meta)
      await thirdPartyLogic.getAppLocalData({
        identity: !(userCategory || userIdentity),
        // address: false, //全局定位，屏蔽该方法的定位
        address: !(addressInfo || streetCode) && !to.meta.notRequireLocation  // 在路由的meta中可以设置 页面进入时是否需要获取app的地理位置
      }, true)
    }
  }

  // DSG-45604 商家平台菜单功能说明配置
  const { globTips } = to.query
  console.log('globTips====>', globTips, to.query)
  if (globTips) {
    Store.commit('setShowTips', globTips)
  } else {
    Store.commit('setShowTips', '')
  }

  //平台合伙人二期，私域小程序打开后，后期进入单品页跳原生页面
  utils.logColor('router', 'red', utils.isScMpPeWechat(), sessionStorage.getItem('sg_home_open_scene'), to.name)
  if(utils.isScMpPeWechat() && sessionStorage.getItem('sg_home_open_scene') === 'PPHP'){
    if(to.name === 'detail' && to.path === '/goodsDetail'){
      let query = utils.jsonToQueryUrl(to.query)
      console.log('123456789==>',Store.state.locationInfo.latitude, Store.state.locationInfo.longitude)
      //发送定位通知
      await Store.dispatch('goPageByType', {
        type: 22,
        params: `{"wx": "/subPages/product/detail/index?${query}&latitude=${Store.state.locationInfo.latitude}&longitude=${Store.state.locationInfo.longitude}"}`
      })
      return
    }
  }




  // iframe中打开智家页面强制跳转
  if (window.self !== window.top) {
    console.log('window.top', window.top === window.parent)
    window.top.location.href = window.self.location.href
    next()
    return;
  }

  if (to.meta.isUseCache === undefined) {
    to.meta.isUseCache = false
  }
  if (to.meta.showTabbar === undefined) {
    to.meta.showTabbar = false
  }
  if (to.meta.showHeader === undefined) {
    to.meta.showHeader = true
  }
  if (to.meta.needLogin === undefined) {
    to.meta.needLogin = false
  }
  if (utils.isHaierApp()) {
    to.meta.showTabbar = false
    to.meta.showInternalTabbar = false
  }
  if (utils.isAlipay()) {
    to.meta.showHeader = false
  }
  // 店铺大转盘嵌入到小程序中，此时需要隐藏标题栏，同时嵌入的页面需要做一下顶部标题栏的适配：目前只适配了【店铺大转盘游戏界面、我的地址界面、中奖记录界面】
  if (utils.isScMp() || utils.isSyBirdMP()) {
    to.meta.showHeader = false

  }

  //解决三翼鸟小程序内 活动页 跳转到大转盘再返回活动页再进入优惠券详情页出现的内容被标题被遮挡的问题
  if(utils.isSyBirdWeChatMP() && to.name == 'couponInfo') {
    to.meta.showHeader = true
  }

  if (to.meta.needLogin && !Store.state.isLogin && !utils.isHaierApp()) {
    Store.dispatch('feedback', to.fullPath)
    Store.dispatch('feedNeed', to.meta.needLogin)
    Store.dispatch('doLogin')
  }

  // 获取分享者id 用于佣金结束
  if (to.query.shareId && to.query.shareId !== 'undefined') {
    sessionStorage.setItem('shareUserId', to.query.shareId || '');
    Store.commit('setShareId', to.query.shareId || '')
  }

  // if (to.query.mdCode && to.query.mdCode !== 'undefined') {
  //   sessionStorage.setItem('shareMdCode', to.query.mdCode || '');
  // }

  // 从首页进入搜索页 刷新页面
  if (to.name === 'goodsSearch') {
    // 如果是首次进入则增加closewebview参数
    // if (window.history.state === null) {
    //   to.query.closewebview = 1;
    // }
    if (from.name === 'home' || from.name === 'categorySearch' || from.name === 'category' || from.name === 'shopDetail') {
      if (from.name === 'shopDetail') {
        if (to.query.isUseCache === false) {
          to.meta.isUseCache = false
        } else {
          // 店铺返回进入搜索  使用缓存
          to.meta.isUseCache = true
        }
      } else {
        to.meta.isUseCache = false
      }
    } else {
      to.meta.isUseCache = true
    }
    // app 跳转带关键字参数
    if (to.query.keyword) {
      to.meta.isUseCache = false
    }
  }
  // 从分类进入分类搜索页 刷新页面
  if (to.name === 'categorySearch') {
    if (from.name === 'category') {
      to.meta.isUseCache = false
    } else {
      to.meta.isUseCache = true
    }
  }
  // 从单品页返回场景详情增加标识
  if (to.name === 'itSoluteDetail') {
    if (from.name === 'detail' || from.name === 'login' || from.name === 'create') {
      to.meta.isUseCache = true
    } else {
      to.meta.isUseCache = false
    }
  }
  if (to.name === 'home') {
    if (from.name === 'goodsSearch') {
      if (to.name === 'home') {
        to.meta.isUseCache = true
      } else {
        to.meta.isUseCache = false
      }
    } else {
      to.meta.isUseCache = false
    }
  }
  if (to.name === 'home' && utils.isInternalChannel()) {
    // 内购环境跳转m站首页的跳转重定向为跳转内购频道首页
    next({ path: '/internalChannel' })
  } else {
    next()
  }
})


export function useRouter() {
  return router;
}

export function useRoute() {
  console.log('useRoute====>')
  return router.currentRoute
}


export default router

