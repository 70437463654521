const cida = [
  {
    path: '/cidaRedirect',
    name: 'cidaRedirect',
    component: () => import('@/viewsToB/cida/redirect.vue'),
    meta: {
      title: '中室协认证',
      keepAlive: true,
      showTabbar: false,
      showHeader: true,
    }
  },
]
  
export default cida
  