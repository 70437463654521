import url from '../url.js';
import http from '../http';
// import utils from '../../utils/utils.js';
import store from '../../store/index.js';
export const csiSave = (data) => {
  return http.request({
    url: url.csiSave,
    method: 'post',
    data
  })
}

export const getLabelList = (data) => {
  return http.request({
    url: url.getLabelList,
    method: 'post',
    data,
  })
}

export const judgeIsDisturbed = (data) => {
  return http.request({
    url: url.judgeIsDisturbed,
    method: 'post',
    data:  {
      memberId: store.getters.mId,
      ...data,
    }
  })
}

export const judgeRegisterAndLoginTime = (params) => {
  return http.request({
    url: url.judgeRegisterAndLoginTime,
    method: 'get',
    params: {
      memberId: store.getters.mId,
      ...params
    }
  })
}