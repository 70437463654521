export default {
  namespaced: true,
  state: {
    goodsDetailInfo: {},
  },
  mutations: {
    setgoodsDetailInfo(state, info) {
      state.goodsDetailInfo = info
    }
  },
}