const chat = [
  {
    path: '/guiderProfile',
    name: 'guiderProfile',
    component: () => import('@/views/chat/guiderProfile'),
    meta: {
      title: '导购名片',
      keepAlive: false,
      showHeader: false,
    }
  },
  {
    path: '/conversationList',
    name: 'conversationList',
    component: () => import('@/views/chat/conversationList'),
    meta: {
      title: '消息',
      keepAlive: true,
      showHeader: true,
      needLogin: true,
      awaitlogin: true
    }
  },
  {
    path: '/conversation',
    name: 'conversation',
    component: () => import('@/views/chat/conversation'),
    meta: {
      title: '客服消息',
      keepAlive: true,
      showHeader: false,
      needLogin: true,
      awaitlogin: true
    }
  }
]

export default chat
