const points = [{
  path: '/point-list',
  name: 'point-list',
  component: () => import('@/views/points/list.vue'),
  meta: {
    title: '我的积分',
    // showHeader: false,
    // needLogin: false,
    showTabbar: false,
  }
}, {
  path: '/point-detail',
  name: 'point-detail',
  component: () => import('@/views/points/detail.vue'),
  meta: {
    title: '积分详情',
    // needLogin: false,
    showTabbar: false,
    // showHeader: false,
  }
}, {
  path: '/point-info',
  name: 'point-info',
  component: () => import('@/views/points/info.vue'),
  meta: {
    title: '积分介绍',
    // needLogin: false,
    showTabbar: false,
    // showHeader: false,
  }
}]

export default points
