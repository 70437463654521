/**
 * 商品相关
 */
import utils from '../../utils/utils'
import URL from '../url.js'
import http, { get, post } from '../http'
import config from '@/config'

// 单品详情页分享信息接口
export const productQrCode = data => get(URL.productQrCode, data, {
  baseURL: config.DHOST,
})
// 商品关联销售 - 获取推荐商品
export const getRelationProductList = (data) => {
  return http.request({
    baseURL: config.SHOST,
    url: URL.getRelationProductList,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
// 单品页以旧换新模块展示控制
export const getProductOldForNew = (data) => {
  return http.request({
    baseURL: config.DHOST,
    url: URL.getProductOldForNew,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
// 商品评价详情接口
export const getCommentsByCondition = data => get(URL.getComments, data)
// 商品评价详情页面获取商品标签接口
export const getProductImpressions = data => get(URL.getProductImpressions, data)
// 商品评价 获取评价数好评率
export const evaluation = data =>
  get(
    `${URL.evaluationInfo}${data.productId}.json`,
    {},
    {
      baseURL: config.DHOST,
    }
  )
// 商品评价 评价总数
export const toAssessList = data => get(URL.getCommentNum, data)
// 单品页基本静态信息_new
export const getProductBasicInfo = data =>
  get(
    `${URL.getProductBasicInfo}${data.productId}.json`,
    data,
    {
      baseURL: config.DHOST,
    }
  )
// 获取 商品 spu 分组信息
export const getVirtualSpuInfo = data =>
  get(
    `${URL.getVirtualSpuInfo}${data.productId}.json`,
    data,
    {
      baseURL: config.DHOST,
    }
  )

// 获取skus直播状态等信息
export const getSkusLiveInfo = data => post(URL.getSkuLives, data, {
  baseURL: config.HOST,
}, true)

// 获取千人千面的猜你喜欢
export const getGuessYouLike = (params, headers) => http.get(URL.getGuessYouLikeNew, {
  params,
  headers,
  baseURL: config.UPLUSHOST,
})

// 单品页动态信息接口_new
export const dynamic = (data, headers) =>
  get(utils.isInternalChannel() ? URL.internalDynamic : URL.dynamic, data, {
    ...headers,
    baseURL: config.DHOST,
  })

export const queryExclusivePrice = data => {
  return post(URL.queryExclusivePrice, data)
}
// 是否有优惠券
export const getHasCoupons = data => get(URL.getHasCoupons, data)
// 单品页可用优惠券详细列表
export const getCouponsByProductId = data => get(URL.getCouponsByProductId, data)
// 商品可用优惠券（单品页） (新接口)
export const getProductAvailableCoupons = (data) => get(URL.getProductAvailableCoupons, data) // DSG-25606
// 商品可用礼品券（单品页）
// export const getGiftCouponListByStoreAndSku = (data) => post(URL.getGiftCouponListByStoreAndSku, data)
// 商品可用礼品券（单品页）
export const getGiftCouponListByStoreAndSku = (data) => {
  return http.request({
    baseURL: config.HOST,
    url: URL.getGiftCouponListByStoreAndSku,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
// 结算页自动领券检测是否符合策略一
export const checkCouponReceiveStrategyFirst = (data) => {
  return http.request({
    baseURL: config.HOST,
    url: URL.checkCouponReceiveStrategyFirst,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
// 领取优惠券
export const receiveCoupon = data => get(URL.receiveCoupon, data)
// 服务说明
export const getDes = data =>
  get(
    `${URL.getDes}?sid=${data.sid}`,
    {},
    {
      baseURL: config.DHOST,
    }
  )
// 加减数量，获取运费 (子商品动态信息接口)
export const getFreightAndActivity = data =>
  get(URL.getFreightAndActivity, data, {
    baseURL: config.DHOST,
  })
// 单品页查询当前商品是否存在成套家电方案
export const hasProgram = data => get(URL.hasProgram, data)
// 单品页关联商品接口
export const relation = data =>
  get(URL.relation, data, {
    baseURL: config.DHOST,
  })
// 单品页图文详情接口_new
export const promos = data =>
  get(URL.promos, data, {
    baseURL: config.DHOST,
  })
// 单品页规格参数接口
export const specifications = data =>
  get(URL.specifications, data, {
    baseURL: config.DHOST,
  })

export const notifyWashingMachine = (data) =>
  post(URL.notifyWashingMachine, data, {
    baseURL: config.DHOST,
  })

// 单品页商品详情回传客服中心
export const postServiceCenter = (data) =>
  post(URL.postServiceCenter, data, {
    baseURL: config.DHOST,
  })

// 单品页取消收藏
export const cancelProductCollection = data => post(`${URL.cancelProductCollectionV2}${utils.parseParamsToQuery(data)}`, data, {
  baseURL: config.DHOST,
})

// 单品页收藏接口
export const productCollection = data =>
  post(`${URL.productCollectionV2}${utils.parseParamsToQuery(data)}`, data, {
    baseURL: config.DHOST,
    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
// 加购物车、购买检查库存
export const checkStockForNum = data =>
  get(URL.checkStockForNum, data, {
    baseURL: config.DHOST,
  })
// 检验到货通知是否发送
export const checkOnly = data =>
  get(URL.CHECK_NOTICE, data, {
    baseURL: config.DHOST,
  })
// 添加到货通知
export const insertNotice = data =>
  get(URL.ARRIVE_NOTICE, data, {
    baseURL: config.DHOST,
  })

//获取分期信息活动
export const getActivityInfoByScene = data => post(URL.getActivityInfoByScene, data)
//结算及收银台获取分期信息活动
export const getInstallmentInfoByScene = data => post(URL.getInstallmentInfoByScene, data)

// 检查是否可以预约
export const checkMemberReserve = data => get(URL.checkMemberReserve, data, {}, true)
// 预约抢购发送短信
export const sendSmsCaptcha = data => get(URL.sendSmsCaptcha, data)
// 确认预约
export const reserve = data => get(URL.reserve, data)
// 进入结算页接口 (创建订单)
export const createOrder = data => post(URL.createOrderNew, data, {}, false)
// 获取redKey接口
export const getOrderCreateUrl = data => post(URL.getOrderCreateUrl, data, {}, false)

export const sendMobileSms = data => get(URL.sendMobileSms, data)
export const createBusinessOpportunity = data => post(URL.createBusinessOpportunity, data)

// 获取订单结算信息
export const getPageInfo = data => get(URL.getPageInfo, data)
// 获取尾款结算页信息
export const getTailOrder = data => get(URL.GET_TAIL_ORDER, data)
// 获取店铺优惠券
export const getStoreCoupon = data => post(`${URL.getStoreCoupon}?sku=${data.sku}`, data)
// 订单可用优惠券（结算页）
export const getOrderAvailableCoupons = data => get(URL.getOrderAvailableCoupons, data)
// 获取平台优惠券
export const getOrderCoupon = data => post(URL.getOrderCoupon, data)
// 获取礼品券
export const getOrderCouponGift = data => post(URL.getOrderCouponGift, data)
// 定金+尾款优惠券
export const getTailCoupons = data => get(URL.GET_TAIL_COUPONS, data)
// 尾款选择优惠券
export const chooseTailCoupons = data => get(URL.CHOICE_TAIL_COUPENS, data)
// 尾款选择地址
export const chooseTailAddress = data => post(URL.CHOOSE_TAIL_ADDRESS, data, { baseURL: config.OHOST, })
// 尾款选发票
export const submitTailInvoice = data => post(URL.SUBMIT_TAIL_INVOICE, data)
// 选择店铺优惠券
export const choiceStoreCoupon = data => post(URL.choiceStoreCoupon + `${utils.parseParamsToQuery(data)}`, data)
// 选择平台优惠券
export const choiceOrderCoupon = data => post(`${URL.choiceOrderCoupon}${utils.parseParamsToQuery(data)}`, data)
// 选择礼品券
export const choiceOrderCouponGift = data => post(URL.choiceOrderCouponGift, data)
// 切换支付方式
export const updatePay = data => post(URL.updatePay, data)
// 获取结算页带过来的发票信息
export const toInvoice = data => get(URL.toInvoice, data)
// 查询发票列表
export const findInvoice = data => get(URL.findInvoice, data)

export const chooseActiveLabel = data => post(URL.chooseActiveLabel, data, {}, false)


// 撤销开票申请
export const cancelApplyInvoice = data => get(URL.cancelApplyInvoice, data)

// 获取增票列表信息
export const memberInvoice = data => get(URL.memberInvoice, data)
// 创新新的增票信息
export const createInvoice = data => post(URL.createInvoice, data)
// 更新增票信息
export const updateInvoice = data => post(URL.updateInvoice, data)
// 获取开票历史
export const getInvoiceApplyHistoryList = data => get(URL.getInvoiceApplyHistoryList, data)
// 批量删除增票信息
export const deleteBatchInvoice = data => post(URL.deleteBatchInvoice, data)
// 根据增票id获取增票信息
export const getInvoiceInfoById = data => get(URL.getInvoiceInfoById, data)
// 获取默认增票信息收货地址
export const defaultInvoiceAddress = data => get(URL.defaultInvoiceAddress, data)
// 下单时获取发票信息
export const getApplyInvoicePage = data => get(URL.getApplyInvoicePage, data)
// 获取 发票邮寄页所需信息
export const postApply = data => get(URL.postApply, data)
// 提交 发票邮寄信息
export const postSubmit = data => post(URL.postSubmit, data)
// 修改发票申请时获取发票信息
export const getReplaceApply = data => get(URL.getReplaceApply, data)
// 下单时取消选择发票
export const cancelInvoice = data => get(URL.cancelInvoice, data)
// 网单开票提交订单
export const submitInvoice = data => post(URL.submitInvoice, data)
// 修改发票申请
export const replaceSubmit = data => post(URL.replaceSubmit, data)
// 订单详情发票服务获取发票信息
export const getInvoiceInfo = data => get(URL.getInvoiceInfo, data)
// 提交发票信息
export const submitMemInvoice = data => post(URL.submitMemInvoice, data)
// 结算页获取配送时间
// export const getCanChoiceDate = data => post(URL.getCanChoiceDate, data)
export const getCanChoiceDate = data => post(`${URL.getCanChoiceDateNew}${utils.parseParamsToQuery(data)}`, data)

// getShopList 结算页 获取配送门店列表
export const getShopList = data => get(URL.getShopList, data)
// changeShop 结算页 获取配送门店列表
export const changeShop = data => post(URL.changeShop, data)

//以旧换新v2
export const getProductTradeInInfo = data => post(URL.getProductTradeInInfo, data, {
  baseURL: config.DHOST,
})

// 结算页选择配送时间
// export const choiceDate = data => post(`${URL.choiceDate}${utils.parseParamsToQuery(data)}`, data)
export const choiceDate = data => post(`${URL.choiceDateNew}${utils.parseParamsToQuery(data)}`, data)
// 判断是否签订(检查GPMS协议签订状态)
export const checkMemberContract = data => get(URL.checkMemberContract, data, {}, true)
// 签订协议
export const signContract = data => post(URL.signContract, data)
// 下单地址校验
export const validateAddress = data => get(`${URL.GET_VALIDATE_ADDRESS}?addressIds=${data.addressIds}`, {})
// 发送短信验证码接口
export const sendSmsCaptch = data => get(URL.sendSmsCaptch, data, {}, true)
// 校验价格是否变动 (提交订单之前调用)
export const getPriceChange = data => get(URL.getPriceChange, data)
// 选择或取消权益
export const setOrderRights = data => post(URL.selectOrderRights, data)
// 提交订单接口
export const asynSubmitOrder = data => post(URL.asynSubmitOrder, data, {}, true)
// 提交尾款订单
export const submitTailOrder = data => post(URL.SUBMIT_TAIL_ORDER, data)
// 支付前校验库存
export const orderPayCheckNew = data => get(URL.orderPayCheckNew, data)
// 支付前极速达时间校验
export const orderActiveLabelCheck = data => get(URL.orderActiveLabelCheck, data)
// 微信支付
export const wxpay = data =>
  get(URL.wxpay, data, {
    baseURL: config.FHOST,
  })
// 获取购物车数量
// export const getCartNumber = data => get(utils.isInternalChannel() ? URL.getIntervalCartNum : URL.getCartNumber, data) // DSG-25604
export const getCartNumber = (data) => {
  if (utils.isInternalChannel()) {
    return post(URL.getIntervalCartNum, data, {
      baseURL: config.PDHOST,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  } else {
    return get(URL.getCartNumber, data)
  }
}
// 获取购物车详情
// export const getCartList = data => get(utils.isInternalChannel() ? URL.getInternalCartList : URL.getCartList, data)
export const getCartList = (data) => {
  if (utils.isInternalChannel()) {
    return post(URL.getInternalCartList, data, {
      baseURL: config.PDHOST,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  } else {
    return get(URL.getCartList, data)
  }
}
// 单个加入购物车
export const addCart = data => post(utils.isInternalChannel() ? URL.internalCartAdd : URL.addCart, data,{
  baseURL: utils.isInternalChannel()? config.PDHOST : config.HOST,
})
// 单个更新购物车数量
export const updateCart = data => post(utils.isInternalChannel() ? URL.internalCartUpdate : URL.updateCart, data, {
  baseURL: utils.isInternalChannel()? config.PDHOST : config.HOST}) // DSG-25604
// 批量加入购物车
export const addBatch = data => post(URL.addBatch, data)
// 批量删除购物车
export const deleteBatch = (data) => {
  if (utils.isInternalChannel()) {
    return post(URL.internalCartRemoveMult, data, {
      baseURL: config.PDHOST,
    })
  } else {
    return get(URL.deleteBatch, data)
  }
}
// export const deleteBatch = data => get(utils.isInternalChannel() ? URL.internalCartRemoveMult : URL.deleteBatch, data)

// 白条分期策略
// export const getIoucCost = data => post(URL.WHITE_COST + `?payAmt=${data.payAmt}&proGroup=${data.proGroup}&sku=${data.sku}&token=${data.token}`)

// 问答区域
export const getMaxAnswerProductQuestion = data => get(URL.getMaxAnswerProductQuestion, data)
export const releaseProductQuestion = data => get(URL.releaseProductQuestion, data)
export const getPageProductQuestion = data => post(URL.getPageProductQuestion, data)
export const getPageProductAnswer = data => post(URL.getPageProductAnswer, data)
export const getPageProductComment = data => get(URL.getPageProductComment, data)
export const addProductAnswerComment = data => post(URL.addProductAnswerComment, data)
export const releaseProductAnswer = data => post(URL.releaseProductAnswer, data)
export const getPageMemberAnswer = data => get(URL.getPageMemberAnswer, data)
export const getpurchasedGoods = data => get(URL.getpurchasedGoods, data)
export const addProductAnswerLikes = data => get(URL.addProductAnswerLikes, data)
export const getPageMemberQuestion = data => get(URL.getPageMemberQuestion, data)
export const deleteProductQuestion = data => get(URL.deleteProductQuestion, data)
export const deleteProductAnswer = data => get(URL.deleteProductAnswer, data)
export const getMyQuestionAnswer = data => get(URL.getMyQuestionAnswer, data)
export const getProductQuestionInfo = data => get(URL.getProductQuestionInfo, data)
// -- 结算页相关 --

// 是否可领平台券
export const platCouponAvali = data => post(`/coupon/v1/couponBillingPage/hasCanReceiveOrderCoupon.json`, data)
// 是否可领店铺券
export const storeCouponAvali = data => post(`/coupon/v1/couponBillingPage/hasCanReceiveStoreCoupon.json`, data)

//  平台券可领取列表
export const platCouponList = data => post(`/coupon/v1/couponBillingPage/getCanReceiveOrderCoupon.json`, data)
//  礼品券可领取列表
export const platCouponGiftList = data => post(`/coupon/v1/couponBillingPage/getCanReceiveGiftCoupon.json`, data)

//  店铺券可领取列表
export const storeCouponList = data => post(`/coupon/v1/couponBillingPage/getCanReceiveStoreCoupon.json`, data)

//  订单结算页切换店铺券
export const changeStoreCoupon = data => post(`v3/h5/order/changeStoreCoupon.json`, data)
//  订单结算页切换平台券
export const changePlatCoupon = data => post(`v3/h5/order/changePlatCoupon.json`, data)
//  订单结算页切换礼品券
export const changePlatCouponGift = data => post(`v3/h5/order/changeGiftCoupon.json`, data)

// removeSmsCaptcha 验证码置为失效
export const removeSmsCaptcha = data => get(URL.removeSmsCaptcha, data)

// 查询单品权益列表
export const querySingleOnlineRightsList = data => post(URL.querySingleOnlineRightsList, data)

// 结算页查询订单权益
export const checkOrderRight = (data, config) => post(`${URL.checkOrderRight}${utils.parseParamsToQuery(data)}`, {}, config)

// 【预约服务时间】获取预约时间段
export const getReservationTimeQuantum = data => post(URL.getReservationTimeQuantum, data, {
  baseURL: config.OHOST,
})

// 【预约服务时间】提交预约服务时间
export const dealReservationTime = data => {
  return http.request({
    url: URL.dealReservationTime,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
// 单品页请求客服导购
export const getGuideByMdCode = (params) => get(URL.getGuideByMdCode, params)
// 结算页校验商场订单
export const validAccount = (data) => post(URL.validAccount, data)

// 释放所有的优惠券
export const cancelAllSelectedCoupons = () => post(URL.cancelAllSelectedCoupons, {})

// 释放所有的换新卡
export const cancelAllSelectedCards = () => post(URL.cancelAllSelectedCards, {})

// 结算页 自动领卡
export const receiveCards = () => post(URL.receiveCards, {})
// 结算页 自动领券
export const receiveCoupons = (data) => post(URL.receiveCoupons, data)
// 结算页获取 换新卡
export const getCanUseRechargeCardList = (data) => post(`${URL.getCanUseRechargeCardList}${utils.parseParamsToQuery(data)}`, data)
// 结算页 确认换新卡
export const confirmSelectedCard = (data) => post(URL.confirmSelectedCard, data)

// 门店购物清单相关

// 聚合页接口
export const getMdShoppingList = () => post(URL.getMdShoppingList, {}, {
  baseURL: config.PDHOST,
})
// 购物清单接口
export const getMdShoppingCart = (data) => post(URL.getMdShoppingCart, data, {
  baseURL: config.PDHOST,
})
// 获取商品场景价以及skku
export const getScenePrice = (data) => post(URL.getScenePrice, data, {
  baseURL: config.PDHOST,
})
// 获取生态商品的skku
export const getAttrAndSkku = (params) => get(URL.getAttrAndSkku, params, {
  baseURL: config.DHOST,
})

export const addSceneCart = (data) => post(URL.addSceneCart, data, {
  baseURL: config.PDHOST,
})

export const batchAddSceneCart = (data) => post(URL.batchAddSceneCart, data, {
  baseURL: config.PDHOST,
})

// 批量删除商品
export const bactchDeleteGoods = (data) => post(URL.bactchDeleteGoods, data, {
  baseURL: config.PDHOST,
})

// 更新商品的信息
export const updateShoppingCart = (data) => post(URL.updateShoppingCart, data, {
  baseURL: config.PDHOST,
})
// 批量更新商品信息
export const batchUpdateShoppingCart = (data) => post(URL.batchUpdateShoppingCart, data, {
  baseURL: config.PDHOST,
})
// 获取场景详情url
export const getFkUrl = (data) => post(URL.getFkUrl, data, {
  baseURL: config.PDHOST,
})
// 改价接口
export const changePrice = async (data) => {
  try {
    const date = new Date().toGMTString()
    const SIGNATURE = await utils.generateSignature({
      secretKey: '9Spc334OrgPxSNVk',
      signingString: `POST\n${URL.changePrice}\n\n2yTQs45RSTcvIodh\n${date}\nx-custom-a:${navigator.language}\n`
    })

    console.log('SIGNATURE:', {
      SIGNATURE
    })

    return http.request({
      baseURL: config.PDHOST,
      url: URL.changePrice,
      method: 'POST',
      data,
      headers: {
        Authorization: `hmac-auth-v1#2yTQs45RSTcvIodh#${SIGNATURE}#hmac-sha256#${date}#x-custom-a`,
        'x-custom-a': navigator.language
      }
    })
  } catch (err) {
    return Promise.reject(err)
  }
}
// 当前用户是否可以改价
export const canChangePrice = (params) => get(URL.canChangePrice, params)
// 结算页查询发票状况
export const verifyInvoice = (data) => post(URL.verifyInvoice, data)
// 获取网单公告列表
export const getOrderProductNoticeList = () => get(URL.getOrderProductNoticeList)
// 代客下单支付-用户扫码或点击分享链接
export const agentPayInit = (params) => get(URL.agentPayInit, params, {
  baseURL: config.OHOST
})
// 代客下单支付-导购端——查询订单详情
export const agentPayGuide = (params) => get(URL.agentPayGuide, params, {
  baseURL: config.OHOST
})
// 代客下单支付-用户端——查询订单详情
export const agentPayUser = () => get(URL.agentPayUser, null, {
  baseURL: config.OHOST
})
// 代客下单支付-手机号校验接口
export const agentPayMobile = () => get(URL.agentPayMobile, null, {
  baseURL: config.OHOST
})
// 代客下单支付-订单二维码
export const agentPayQrcode = (params) => get(URL.agentPayQrcode, params)
// 更换下单方式
export const changeOrder = (params) => post(URL.changeOrder, params)
// 保存顾客收货地址信息
export const addCustomerAddress = (params) => post(URL.addCustomerAddress, params)
// 查询顾客收货地址信息
export const getCustomerAddress = () => get(URL.getCustomerAddress)
// 获取授权人信息
export const getGrantCouponUser = (params) => get(URL.getGrantCouponUser, params)
// 更新库存类型
export const updateStock = (params) => post(URL.updateStock, params)
//
export const querySharerDisplayInfo = () => get(URL.querySharerDisplayInfo)
// 获取订单支付列表
export const getPayList = (params) => post(URL.GET_PAYLIST_METHODS, params)
// 更新优惠券授权
export const updateGrantCoupon = (params) => get(URL.updateGrantCoupon, params)
// plus会员开通入口
export const entrance = (params) => get(URL.entrance, params)
// 获取PLUS会员卡片列表
export const getPlusOrderProductList = () => get(URL.getPlusOrderProductList)
// 获取PLUS会员的礼品卡信息
export const getPlusVipGiftsInfo = () => post(URL.getPlusVipGiftsInfo, null, {
  baseURL: config.OHOST
})
// 获取PLUS会员的权益数据
export const getPlusVipRightsInfo = () => post(URL.getPlusVipRightsInfo, null, {
  baseURL: config.OHOST
})
// 选择plus会员开卡礼品
export const selectPlusGift = (params) => post(URL.selectPlusGift, params)
// 取消开通plus
export const cancelOpenPlusAccount = () => get(URL.cancelOpenPlusAccount)
// 用户开通plus会员，提交订单
export const submitPlusOrder = () => get(URL.submitPlusOrder)


// 非标品的预约 发送验证码
export const sendVerifyCode = (data) => post(URL.sendVerifyCode, data, {
  baseURL: config.DHOST
})

export const nonStandard = (data) => post(URL.nonStandard, data, {
  baseURL: config.DHOST
})
// 购机权益相关
export const queryOrderRight = (data) => post(URL.queryOrderRight, data, {
  baseURL: config.OHOST
})

export const getOrderRightCache = () => post(URL.getOrderRightCache, {}, {
  baseURL: config.OHOST
})

export const delOrderRightCache = (data) => post(URL.delOrderRightCache, data, {
  baseURL: config.OHOST
})


export const getBlOrders = (data) => post(URL.getBlOrders, data)

export const blCheckOrderRight = (data) => post(URL.blCheckOrderRight, data)

export const blSetOrderRight = (data) => post(URL.blSetOrderRight, data)

export const blSubmitOrderRight = (data) => post(URL.blSubmitOrderRight, data)

export const dealOrderRightLog = (data) => post(URL.dealOrderRightLog, data, {
  baseURL: config.OHOST
})

// 查询网单预约信息-结算页
export const queryReserveInfo = (data) => post(URL.queryReserveInfo, data)
// 查询预约时间的可选范围
export const queryReserveTimeRange = (data) => post(URL.queryReserveTimeRange, data)
// 提交预约时间
export const submitReserveTime = (data) => post(URL.submitReserveTime, data)
// 查询网单上的预约信息-网单详情页
export const queryReservationInfo = (data) => post(URL.queryReservationInfo, data, {
  baseURL: config.OHOST
})
// 提交网单上的预约信息
export const submitReservationInfo = (data) => post(URL.submitReservationInfo, data, {
  baseURL: config.OHOST
})
// 查询预约时间范围
export const queryReserveTimeRange2 = (data) => post(URL.queryReserveTimeRange2, data, {
  baseURL: config.OHOST
})
// 获取预约配送说明
export const getReserveLegend = params => get(URL.GET_HELP_RICHTEXT, params)

export const updateProductInfo =  (data) => post(URL.updateProductInfo, data)
