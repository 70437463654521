// 自定义指令
export const expose = {
  inserted: function(el, binding) {
    if (el._exposureObserver) { return }
    const observer = new IntersectionObserver(entries => {
      // console.log(el)
      if (entries[0].isIntersecting) {
        const callback = binding.value;
        callback && callback();
        // 如果有 once 修饰符 那么在回调触发后取消观察
        if (binding.arg === 'once') {
          if (observer) {observer.unobserve(el);}
        }
      }
    });
  
    observer.observe(el);
    el._exposureObserver = observer;
  },
  unbind: function(el) {
    if (el._exposureObserver) {
      el._exposureObserver.disconnect();
      el._exposureObserver = null;
    }
  }
}