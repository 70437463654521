const intendedAssignOrder = [
  {
    path: '/intended-assign-order',
    name: 'intendedAssignOrder',
    component: () => import('@/viewsToB/intendedAssignOrder/index.vue'),
    meta: {
      title: '我的意向用户',
      showTabbar: true,
      showInternalTabbar: true,
      keepAlive: true,
      showHeader: false,
      needLogin: true,
    }
  },
  {
    path: '/intended-staff-list',
    name: 'intendedStaffList',
    component: () => import('@/viewsToB/intendedAssignOrder/staffList.vue'),
    meta: {
      title: '员工列表',
      keepAlive: false,
      showTabbar: true,
      showInternalTabbar: true,
      showHeader: false,
      needLogin: true,
    }
  },
  {
    path: '/intended-remark',
    name: 'intendedRemark',
    component: () => import('@/viewsToB/intendedAssignOrder/remark.vue'),
    meta: {
      title: '备注',
      keepAlive: false,
      showTabbar: true,
      showInternalTabbar: true,
      showHeader: false,
      needLogin: true,
    }
  },
  {
    path: '/intended-following-status',
    name: 'intendedFollowingStatus',
    component: () => import('@/viewsToB/intendedAssignOrder/followingStatus.vue'),
    meta: {
      title: '跟进状态',
      keepAlive: false,
      showTabbar: true,
      showInternalTabbar: true,
      showHeader: false,
      needLogin: true,
    }
  },
  {
    path: '/intended-choose-order',
    name: 'intendedChooseOrder',
    component: () => import('@/viewsToB/intendedAssignOrder/chooseOrder.vue'),
    meta: {
      title: '选择订单',
      keepAlive: false,
      showTabbar: true,
      showInternalTabbar: true,
      showHeader: false,
      needLogin: true,
    }
  },
]

export default intendedAssignOrder