const synPartners = [
  {
    path: '/synPartners',
    name: 'synPartners',
    component: () => import('@/viewsToB/synPartners/index.vue'),
    meta: {
      title: '平台合伙人',
      keepAlive: false,
      showTabbar: false,
      showHeader: true,
    }
  },
  {
    path: '/synPartnerSearch',
    name: 'synPartnerSearch',
    component: () => import('@/viewsToB/synPartners/syn-partner-search.vue'),
    meta: {
      title: '搜索',
      keepAlive: true,
      showTabbar: false,
      showHeader: true,
    }
  },
  {
    path: '/synPartnerDetail',
    name: 'synPartnerDetail',
    component: () => import('@/viewsToB/synPartners/syn-partner-detail.vue'),
    meta: {
      title: '合伙人详情',
      keepAlive: true,
      showTabbar: false,
      showHeader: true,
    }
  }
]

export default synPartners
