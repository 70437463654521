const mobileWorkStationMessage = [
  {
    path: '/mobileWorkStationMessage',
    name: 'mobileWorkStationMessage',
    component: () => import('@/viewsToB/mobileWorkStationMessage/index.vue'),
    meta: {
      keepAlive: false,
      title: '消息',
      showTabbar: false,
      showHeader: false,
      needLogin: true,
      awaitlogin: true,
    },
  },
  {
    path: '/mobileWorkStationMessage/list/:messageType',
    name: 'mobileWorkStationMessageList',
    component: () => import('@/viewsToB/mobileWorkStationMessage/list.vue'),
    meta: {
      keepAlive: false,
      title: '消息',
      showTabbar: false,
      showHeader: false,
      needLogin: true,
      awaitlogin: true,
    },
  },
  {
    path: '/mobileWorkStationMessage/detail/:messageId',
    name: 'mobileWorkStationMessageDetail',
    component: () => import('@/viewsToB/mobileWorkStationMessage/detail.vue'),
    meta: {
      keepAlive: false,
      title: '消息详情',
      showTabbar: false,
      showHeader: false,
      needLogin: true,
      awaitlogin: true,
    },
  },
]

export default mobileWorkStationMessage
