
const flashSale = [
  {
    path: '/flashSale',
    name: 'flashSale',
    component: () => import(
      /* webpackChunkName: "flashSale" */
      '@/views/flashSalePage/new'),
    meta: {
      title: '发现好货',
      showHeader: false,
      trk_origin: 'fxhh_xsg',
    },
  },
  {
    path: '/flashSaleList',
    redirect: '/flashSale', //  https://ihaier.feishu.cn/docx/PDjrdLnetor04Excr9rcvcuPnmf 需求去掉
    name: 'flashSaleList',
    component: () => import(
      /* webpackChunkName: "flashSale" */
      '@/views/flashSalePage/list'),
    meta: {
      title: '限时购',
      showHeader: false,
      trk_origin: 'fxhhqb_lb',
    },
  },
]

export default flashSale
