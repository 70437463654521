import utils from '@/utils/utils'
import store from '@/store'
import { PlatformTypes } from '@/config/constMaps'

// 是否需要跳登录
export const needJumpLogin = (env = PlatformTypes.COMMON) => {
  const currentPlatform = utils.getCurrentPlatform()
  if (!store.getters.getLoginStatus && currentPlatform & env) {
    store.dispatch('doLogin')
    return true
  } else {
    return false
  }
}

// 自定义指令
export const checklogin = {
  bind: (el: HTMLElement, binding: any) => {
    el.addEventListener(binding.arg || 'click', (e) => {

      // console.log(`directives`, {
      //   eventType: binding.arg || 'click', // 可自己指定事件名，默认为click
      //   currentPlatform, // 当前env
      //   checkEnv: binding.value || PlatformTypes.COMMON, // 当前行为需要校验的环境
      //   needCheck: currentPlatform & (binding.value || PlatformTypes.COMMON), // 当前状态是否需要校验
      //   isLogin: store.getters.getLoginStatus // 当前是否已登录
      // })

      if (needJumpLogin(binding.value)) {
        e.stopPropagation()
        e.preventDefault()
      }

    }, true)
  }
}