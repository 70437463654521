const partnerRanking = [
  {
    path: '/partnerRanking',
    name: 'partnerRanking',
    component: () => import('@/views/partnerRanking/index.vue'),
    meta: {
      keepAlive: true,
      showTabbar: false,
      showHeader: false,
      needLogin: false,
    },
  },
  {
    path: '/rankRules',
    name: 'rankRules',
    component: () => import('@/views/partnerRanking/components/rankRules.vue'),
    meta: {
      title: '上榜规则',
      keepAlive: true,
      showTabbar: false,
      showHeader: true,
      needLogin: false,
    },
  },
]

export default partnerRanking
