/**
 * 全局过滤器文件
 */
import timeUtil from './TimeUtil';
/**
  * @description  格式化价钱  保留 n位小数
 */
export const formatPrice = (value: string | number, n = 2): string => {
  try {
    if (Number(value)) {
      return parseFloat(`${value}`).toFixed(n)
    } else {
      return '0.00'
    }
  } catch(err) {

    return '0.00'
  }
}

/**
  * @description 此处用的时间戳是秒级别，*1000之后再将时间戳格式化 返回2019-10-01 23:59:11
*/
export const formatDate = (value: number | string): string => {
  try {
    if (value && value.toString() !== '0') {
      const nowDate: Date = new Date(Number(value) * 1000);
      if (isNaN(nowDate.getTime())) return ''
      const _year: number = nowDate.getFullYear();
      let _month: number | string = nowDate.getMonth() + 1;
      _month = _month < 10 ? '0' + _month : _month;
      let _date: number | string = nowDate.getDate();
      _date = _date < 10 ? '0' + _date : _date;
      const _hour: number | string = nowDate.getHours() < 10 ? '0' + nowDate.getHours() : nowDate.getHours();
      const _minute: number | string = nowDate.getMinutes() < 10 ? '0' + nowDate.getMinutes() : nowDate.getMinutes();
      const _second: number | string = nowDate.getSeconds() < 10 ? '0' + nowDate.getSeconds() : nowDate.getSeconds();
      return _year + '-' + _month + '-' + _date + ' ' + _hour + ':' + _minute + ':' + _second;
    } else {
      return ''
    }
  } catch(err) {
    return ''
  }
}
/**
  * @description 此处用的时间戳是秒级别，*1000之后再将时间戳格式化 返回 格式: 6月1日 13:00:00
*/
export const formatDateExceptY = (value: number | string, withHour = true): string => {
  try {
    if (value && value.toString() !== '0') {
      const nowDate: Date = new Date(Number(value) * 1000);
      if (isNaN(nowDate.getTime())) return ''
      let _month: number | string = nowDate.getMonth() + 1;
      _month = _month < 10 ? '0' + _month : _month;
      let _date: number | string = nowDate.getDate();
      _date = _date < 10 ? '0' + _date : _date;
      const _hour: number | string = nowDate.getHours() < 10 ? '0' + nowDate.getHours() : nowDate.getHours();
      const _minute: number | string = nowDate.getMinutes() < 10 ? '0' + nowDate.getMinutes() : nowDate.getMinutes();
      const _second: number | string = nowDate.getSeconds() < 10 ? '0' + nowDate.getSeconds() : nowDate.getSeconds();
      
      if(withHour) {
        return _month + '月' + _date + '日 ' + _hour + ':' + _minute + ':' + _second;
      }else {
        return _month + '月' + _date + '日 '
      }
    } else {
      return ''
    }
  } catch(err) {
    return ''
  }
}

/**
  * @description 此处用的时间戳是秒级别，*1000之后再将时间戳格式化 返回 格式: 13:00
*/
export const formatDateTime = (value: number | string): string => {
  try {
    if (value && value.toString() !== '0') {
      const nowDate: Date = new Date(Number(value) * 1000);
      if (isNaN(nowDate.getTime())) return ''
      const _hour: number | string = nowDate.getHours() < 10 ? '0' + nowDate.getHours() : nowDate.getHours();
      const _minute: number | string = nowDate.getMinutes() < 10 ? '0' + nowDate.getMinutes() : nowDate.getMinutes();
      return _hour + ':' + _minute;
    } else {
      return ''
    }
  } catch(err) {
    return ''
  }
}

/**
  * @description  返回格式 10-01 23:59
*/
export const formatMonth = (value: number | string): string => {
  try {
    if (value && value.toString() !== '0') {
      const nowDate = new Date(value);
      if (isNaN(nowDate.getTime())) return ''
      let _month: number | string = nowDate.getMonth() + 1;
      _month = _month < 10 ? '0' + _month : _month;
      let _date: number | string = nowDate.getDate();
      _date = _date < 10 ? '0' + _date : _date;
      const _hour: number | string = nowDate.getHours() < 10 ? '0' + nowDate.getHours() : nowDate.getHours();
      const _minute: number | string = nowDate.getMinutes() < 10 ? '0' + nowDate.getMinutes() : nowDate.getMinutes();
      return _month + '-' + _date + ' ' + _hour + ':' + _minute;
    } else {
      return ''
    }
  } catch(err) {
    return ''
  }
}

/**
  * @description  返回格式 10-01 23:59 或 今天 23:59
*/
export const formatDateHasZh = (value: number | string): string => {
  try {
    if (value && value.toString() !== '0') {
      let valueFilter: number | string = value
      if (typeof valueFilter === 'string' && valueFilter.indexOf('-') !== -1) {
        valueFilter = valueFilter.replace(/-/g, '/')
      }
      const nowDate: Date = new Date(valueFilter);
      if (isNaN(nowDate.getTime())) return ''
      let _month: number | string = nowDate.getMonth() + 1;
      const _year: number = nowDate.getFullYear();
      _month = _month < 10 ? '0' + _month : _month;
      let _date: number | string = nowDate.getDate();
      _date = _date < 10 ? '0' + _date : _date;
      const _hour: number | string = nowDate.getHours() < 10 ? '0' + nowDate.getHours() : nowDate.getHours();
      const _minute: number | string = nowDate.getMinutes() < 10 ? '0' + nowDate.getMinutes() : nowDate.getMinutes();
      let ret = ''
      const today: Date = new Date()
      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)
      today.setMilliseconds(0)
      const todayTimeSpan: number = today.getTime()
      const isToday: number = (nowDate.getTime() - todayTimeSpan) / 1000 / 60 / 60
      if (isToday > 0 && isToday <= 24) {
        ret = `今天 ${_hour}:${_minute}`
      } else if (isToday < 0 && isToday >= -24) {
        ret = `昨天 ${_hour}:${_minute}`
      } else {
        ret = `${_year}-${_month}-${_date} ${_hour}:${_minute}`
      }
      return ret;
    } else {
      return ''
    }
  } catch(err) {
    return ''
  }
}
/**
  * @description  返回格式 10-01 23:59 或 今天 23:59
*/
export const formatDateHasZhNoY = (value: number | string):string => {
  try {
    if (value && value.toString() !== '0') {
      let valueFilter: number | string = value
      if (typeof valueFilter === 'string' && valueFilter.indexOf('-') !== -1) {
        valueFilter = valueFilter.replace(/-/g, '/')
      }
      const nowDate: Date = new Date(valueFilter);
      if (isNaN(nowDate.getTime())) return ''
      let _month: number | string = nowDate.getMonth() + 1;
      const _year: number = nowDate.getFullYear();
      _month = _month < 10 ? '0' + _month : _month;
      let _date: number | string = nowDate.getDate();
      _date = _date < 10 ? '0' + _date : _date;
      const _hour: number | string = nowDate.getHours() < 10 ? '0' + nowDate.getHours() : nowDate.getHours();
      const _minute: number | string = nowDate.getMinutes() < 10 ? '0' + nowDate.getMinutes() : nowDate.getMinutes();
      let ret = ''
      const today: Date = new Date()
      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)
      today.setMilliseconds(0)
      const todayTimeSpan: number = today.getTime()
      const isToday: number = (nowDate.getTime() - todayTimeSpan) / 1000 / 60 / 60
      if (isToday > 0 && isToday <= 24) {
        ret = `今天 ${_hour}:${_minute}`
      } else if (isToday < 0 && isToday >= -24) {
        ret = `昨天 ${_hour}:${_minute}`
      } else {
        ret = `${_month}月${_date}日 ${_hour}:${_minute}`
      }
      return ret;
    } else {
      return ''
    }
  } catch(err) {
    return ''
  }
}

/**
  * @description  将时间格式化 返回 2019.10.01
*/
export const formatDateYMD = (value: number | string, join = '.'): string => {
  try {
    if (!value) return ''
    const nowDate: Date = new Date(value);
    if (isNaN(nowDate.getTime())) return ''
    const _year: number = nowDate.getFullYear();
    let _month: number | string = nowDate.getMonth() + 1;
    _month = _month < 10 ? '0' + _month : _month;
    let _date: number | string = nowDate.getDate();
    _date = _date < 10 ? '0' + _date : _date;
    return _year + join + _month + join + _date;
  } catch(err) {
    return ''
  }
}

/**
  * @description  格式化时间  2019-01-01
  * @param value 毫秒数
 */
export function formatTime(value: number | string, joinFlag = '-'): string {
  const time = new Date(value)
  if (!value || !time || isNaN(time.getTime())) return ''

  return timeUtil.formatDate(time, joinFlag)
}

// 传入 时间戳 转换成 'yyyy-MM-dd HH:mm:ss' 这种格式
export const formatDateyyyyMMddHHmmss = (tmp: number | string): string => {
  return timeUtil.format(tmp)
}

/**
 * 金额千分制，保留两位小数， 例100,000,0.00
 */
export function fmtMoneyFilter(val: string | number): string {
  try {
    if ((val || val === 0) && !isNaN(Number(val))) {
      return parseFloat(`${val}`).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    } else {
      return '0.00'
    }
  } catch(err) {
    return '0.00'
  }
}

/**
 * 金额千分制，保留两位小数， 例100,000,0.00  但是只返回其中的整数部分
 */
export function fmtMoneyFilterInteger(val: string | number): string {
  try {
    if ((val || val === 0) && !isNaN(Number(val))) {
      const price = parseFloat(`${val}`).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').split('.');
      return price[0]
    } else {
      return '0'
    }
  } catch(err) {
    return '0'
  }
}

/**
 * 金额保留两位小数， 例1000000.00  但是只返回其中的整数部分
 */
export function fmtMoneyFilterInt(val: string | number): string | number {
  try {
    if ((val || val === 0) && !isNaN(Number(val))) {
      const price = parseFloat(`${val}`).toFixed(2).split('.');
      return price[0]
    } else {
      return '0'
    }
  } catch(err) {
    return '0'
  }
}

/**
 * 金额千分制，保留两位小数， 例100,000,0.00  但是只返回其中的小数部分
 */
export function fmtMoneyFilterDecimal(val: string | number): string {
  try {
    if ((val || val === 0) && !isNaN(Number(val))) {
      const price = parseFloat(`${val}`).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').split('.');
      return `.${price[1]}`
    } else {
      return '.00'
    }
  } catch(err) {
    return '.00'
  }
}

/**
 * 将图片url地址改为https
 * @param {string} url 图片url
 */
export function imageToHttps(url: string): string {
  if (!url) {
    return url || ''
  }
  if (url.indexOf('http:') >= 0) {
    url = url.replace('http:', 'https:');
  }
  return url;
}

/**
 * 取选缩略图
 * @param {string} size 拼接的参数，如：100
 * @param {string} url 图片url
 */
export function imageThumb(url?: string, size?: string | number): string {
  if (!url || !size) {
    return url || ''
  }
  try {
    let res = ''
    if (url.indexOf('statics02.ehaier.com') > 0 || url.indexOf('aliyuncs.com') > 0 || url.indexOf('cdn50.ehaier.com') > 0 || url.indexOf('cdn51.ehaier.com') > 0 || url.indexOf('ismcdn.haier.net') > 0) {
      res = url.indexOf('?x-oss-process=image') > 0
        ? `${url}/resize,m_lfit,h_${size},w_${size}`
        : `${url}?x-oss-process=image/resize,m_lfit,h_${size},w_${size}`
    } else if (url.indexOf('ehaier.com') > 0) {
      res = `${url}@${size}_${size}`
    }
    return res || url
  } catch(err) {
    return url;
  }
}

/**
 * 取webp
 * @param {string} url 图片url
 */
export function imageToWebp(url: string, webpSurpport: boolean): string {
  if (!url || !webpSurpport) {
    return url || ''
  }
  try {
    let res = ''
    if (url.indexOf('aliyuncs.com') > 0 || url.indexOf('cdn50.ehaier.com') > 0 || url.indexOf('cdn51.ehaier.com') > 0 || url.indexOf('ismcdn.haier.net') > 0) {
      res = url.indexOf('?x-oss-process=image') > 0
        ? `${url}/format,webp` : `${url}?x-oss-process=image/format,webp`
    }
    return res || url
  } catch(err) {
    return url
  }
}

/**
  * @description 时间戳格式化 返回2019-10-01 23:59:11
*/
export const formatDateNew = (value: string): string => {
  if (!value) {
    return ''
  }
  try {
    const date: Date = new Date(value);
    if (isNaN(date.getTime())) return ''
    const Y: string = date.getFullYear() + '.';
    const M: string = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '.';
    const D: string = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    const h: string = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    const m: string = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    const s: string | number = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return Y + M + D + h + m + s;
  } catch(errr) {
    return ''
  }
}

/**
 * @description 正则替换富文本内img标签src属性
 * @param {string} richText 富文本字符串
 * @param {bool} webpSurpport 是否支持webp
 */
export function handleImgSrc(richText: string, webpSurpport: boolean): (string | string[])[] {
  try {
    const patten = /<img [^>]*src=['"]([^'"]+)[^>]*>/gi
    const arr: string[] = []
    const result = richText.replace(patten, (a: string, b: string) => {
      arr.push(b)
      return a.replace(b, imageToWebp(imageToHttps(b), webpSurpport));
    })
    return [result, arr]
  } catch(err) {
    ['', []]
  }
}


/**
 * @description 截取价格小数点之前的文本
 * @param {float} price 价格
 */
export function formatPriceInt(price: number | string): string {
  try {
    if (isNaN(Number(price)) || !price) return '0'
    return (price + '').split('.')[0]
  } catch (err) {
    return '0'
  }
}

/**
 *
 * @param {*} 姓名脱敏
 * @returns
 */
export function formatName(str: string): string {
  try {
    if (str != null && str !== undefined) {
      if (str.length === 2) {
        return str.substring(0, 1) + '*' // 截取name 字符串截取第一个字符，
      } else if (str.length === 3) {
        return str.substring(0, 1) + '*' + str.substring(2, 3)// 截取第一个和第三个字符
      } else if (str.length > 3) {
        return str.substring(0, 1) + '*' + '*' + str.substring(3, str.length)// 截取第一个和大于第4个字符
      } else {
        return str
      }
    } else {
      return '';
    }
  } catch(err) {
    return ''
  }
}

/**
 *
 * @param {*} 身份证脱敏
 * @returns
 */
export function formIdentify(code: string): string {
  try {
    if (!code) return ''
    return code.replace(/^(\d{3})\d+([\dX]{4})$/, '$1********$2');
  } catch (err) {
    return ''
  }
}

/**
 * @description 截取价格小数点之后的文本
 * @param {float} price 价格
 */
export function formatPriceFloat(price: number | string): string {
  try {
    if ((price + '').includes('.')) {
      return '.' + (price + '').split('.')[1]
    }
    return '.00'
  } catch(err) {
    return '.00'
  }
}
/**
 * @description 截取时间字符串 截止到日
 * @param {string} value 日期字符串
 */
export function formatDateEndOfDay(value: string): string {
  try {
    if (value.split(' ').length > 0) {
      return value.split(' ')[0]
    }
    return '1111-11-11'
  } catch(err) {
    return ''
  }
}

/**
 * 格式化星期
 * @param {string} dateVal  ex: 2021-10-26｜2021年10月26日
 * @param {'星期|周'} dayPrefix  星期或周前缀
 * @param {boolean} withDate true 返回 '2021-10-26 周二'，false 返回 '周二'
 * @param {string} prefix 附加前缀 ex：'[周二'
 * @param {string} suffix 附加后缀 ex：'周二]'
 * @returns {string} ex:'周二'
 */
export function formatWeek(dateVal: string, dayPrefix = '周', withDate = false, prefix = '', suffix = ''): string {
  try {
    let week = ''
    if (dateVal) {
      const formatDate: string = dateVal.replace(/年|月|\//g, '-').replace('日', '')
      let weekArray: string[] = ['日', '一', '二', '三', '四', '五', '六']
      weekArray = weekArray.map(item => {
        return prefix + dayPrefix + item + suffix
      })
      week = weekArray[new Date(formatDate).getDay()]
      withDate && (week = dateVal + week)
    }
    return week;
  } catch(err) {
    return ''
  }
}

export function formatNextHour(dateVal: string | number): string {
  let nextHour = ''
  // 根据时间戳获取到小时和分钟，向后取整 并返回 HH:mm
  if (dateVal) {
    const milliseconds = Number(dateVal) * 1000
    const date = new Date(milliseconds)
    const hour = date.getHours()
    nextHour = `${hour + 1}:00`
  }
  return nextHour
}

/**
 * @description 脱敏手机号
 * @param {string} pre 手机号
 */
export function desensitizeMobile(pre: string): string {
  if (!pre) return ''
  return (pre + '').replace(/^(\d{3})\d+([\dX]{4})$/, '$1****$2')
}
/**
 * @description 脱敏邮箱
 * @param {string} pre 邮箱
 */
export function desensitizeEmail(pre: string): string {
  if (!pre) return ''
  return (pre + '').replace(/(.*).{2}[^@]*(@.*$)/, '$1**$2')
}
