
const bankzcStyleInfo = {
  '01020000': {
    'img': '3ICBC',
    'background': '#F3515E',
    'name': '工商银行'
  },
  '01030000': {
    'img': '2ABC',
    'background': '#47D08F',
    'name': '农业银行'
  },
  '01050000': {
    'img': '4CCB',
    'background': '#39A8FF',
    'name': '建设银行'
  },
  '01040000': {
    'img': '7BOC',
    'background': '#F3515E',
    'name': '中国银行'
  },
  '03010000': {
    'img': '14COMM',
    'background': '#39A8FF',
    'name': '交通银行'
  },
  '03020000': {
    'img': '1CITIC',
    'background': '#F3515E',
    'name': '中信银行'
  },
  '03080000': {
    'img': '5CMB',
    'background': '#F3515E',
    'name': '招商银行'
  },
  '03090000': {
    'img': '8CIB',
    'background': '#39A8FF',
    'name': '兴业银行'
  },
  '03180000': {
    'img': '6SZPAB',
    'background': '#FF8F24',
    'name': '平安银行'
  },
  '03030000': {
    'img': '12CEB',
    'background': '#BF70D7',
    'name': '光大银行'
  },
  '03110000': {
    'img': '17EGBANK',
    'background': '#FF8F24',
    'name': '恒丰银行'
  },
  '03040000': {
    'img': '11HXB',
    'background': '#F3515E',
    'name': '华夏银行'
  },
  '03050000': {
    'img': '10CMBC',
    'background': '#47D08F',
    'name': '民生银行'
  },
  '01000000': {
    'img': '13PSBC',
    'background': '#47D08F',
    'name': '邮储银行'
  },
  '03060000': {
    'img': '16GDB',
    'background': '#F3515E',
    'name': '广发银行'
  },

  '03100000': {
    'img': '9SPDB',
    'background': '#39A8FF',
    'name': '浦发银行'
  },
  'C1302533000018': {
    'img': 'wsbank',
    'background': '#39A8FF',
    'name': '网商银行'
  },
  'Z2004944000010': {
    'img': 'wxbank',
    'background': '#47D08F',
    'name': '微众'
  },
  '04031000': {
    'img': '15BCCB',
    'background': '#F3515E',
    'name': '北京银行'
  },

}

const bankStyleInfo = {}

const mapKeyObject = (arr, info) => {
  arr.forEach(item => {
    bankStyleInfo[item] = info
  })
}
// 建行
mapKeyObject(['01050000', '01050001', '01053930', '01059999', '25070344'], {
  'img': '4CCB',
  'background': '#39A8FF',
  'name': '建设银行',
  'theme': 'blue'
})

// 工行
mapKeyObject(['01020000', '01020418', '01023930'], {
  'img': '3ICBC',
  'background': '#F3515E',
  'name': '工商银行',
  'theme': 'red'
})

// 农行
mapKeyObject(['01035210', '01035800', '01030000', '01030001', '01033320', '01033930'], {
  'img': '2ABC',
  'background': '#47D08F',
  'name': '农业银行',
  'theme': 'green'
})

// 平安
mapKeyObject(['03070010', '06105840', '03180000', '04100000', '04105840', '05105840'], {
  'img': '6SZPAB',
  'background': '#FF8F24',
  'name': '平安银行',
  'theme': 'red'
})

// 交通银行
mapKeyObject(['03010000'], {
  'img': '14COMM',
  'background': '#39A8FF',
  'name': '交通银行',
  'theme': 'blue'
})

// 招商
mapKeyObject(['03080000', '03080010', '03080344', '03082900'], {
  'img': '5CMB',
  'background': '#F3515E',
  'bankName': '招商银行',
  'name': '招商银行',
  'theme': 'red'
})

// 兴业银行
mapKeyObject(['03090000', '03090010', '03330001'], {
  'img': '8CIB',
  'background': '#39A8FF',
  'name': '兴业银行',
  'theme': 'blue'
})

// 中信银行
mapKeyObject(['03020000', '03570000', '63020000'], {
  'img': '1CITIC',
  'background': '#F3515E',
  'name': '中信银行',
  'theme': 'red'
})

// 浦发银行
mapKeyObject(['15071126', '15071685', '15072220', '15072434', '15072610', '15072900', '15073022', '15073043', '15073327', '15073335', '15073374', '15074383', '15074667', '15074914', '15075523', '15075542', '15075612', '15075632', '15075638', '15076581', '15076900', '15076915', '15077071', '15077314', '15077934', '15077972', '15078213', '15078812', '63100000'], {
  'img': '9SPDB',
  'background': '#39A8FF',
  'name': '浦发银行',
  'theme': 'blue'
})

// 民生银行
mapKeyObject(['03050000', '03050001'], {
  'img': '10CMBC',
  'background': '#47D08F',
  'name': '民生银行',
  'theme': 'green'
})

// 华夏银行
mapKeyObject(['03040000', '03041100', '03045800', '63040000'], {
  'img': '11HXB',
  'background': '#F3515E',
  'name': '华夏银行',
  'theme': 'red'
})

// 光大银行
mapKeyObject(['03030000', '03033010', '03033930', '63030000'], {
  'img': '12CEB',
  'background': '#BF70D7',
  'name': '光大银行',
  'theme': 'blue'
})

// 邮政
mapKeyObject(['01003930', '01003900', '01004200', '01004900', '01005200', '01005500', '01005800', '01005840', '01006100', '01006400', '01006500', '01007000', '01007300', '01007700', '01009999', '01000000', '01001000', '01001200', '01001600', '01002600', '01002900', '01003000', '01003600', '61000000'], {
  'img': '13PSBC',
  'background': '#47D08F',
  'name': '邮政储蓄银行',
  'theme': 'green'
})

// 广发银行
mapKeyObject(['03060000'], {
  'img': '16GDB',
  'background': '#F3515E',
  'name': '广发银行',
  'theme': 'red'
})

// 恒丰银行
mapKeyObject(['03110000', '03114560', '63110000'], {
  'img': '17EGBANK',
  'background': '#FF8F24',
  'name': '恒丰银行'
})

// 渤海银行
mapKeyObject(['03170000', '03170003'], {
  'img': 'BHYH',
  'background': '#F3515E',
  'name': '渤海银行',
  'theme': 'green'
})

// 浙商银行
mapKeyObject(['03160000', '03160001', '03161100', '03162900', '03163310', '03163320', '03163330', '03163350', '03163370', '03163380', '03166510', '03167910'], {
  'img': 'ZSYH',
  'background': '#F3515E',
  'name': '浙商银行',
  'theme': 'red'
})

// 北京银行
mapKeyObject(['04031000', '64031000'], {
  'img': '15BCCB',
  'background': '#F3515E',
  'name': '北京银行',
  'theme': 'red'
})

// 上海银行
mapKeyObject(['04010000', '04012900', '04012902'], {
  'img': 'SHYH',
  'background': '#F3515E',
  'name': '上海银行',
  'theme': 'blue'
})

// 中国银行
mapKeyObject(['01040000', '01040446', '01042900'], {
  'img': '7BOC',
  'background': '#F3515E',
  'name': '中国银行',
  'theme': 'red'
})

// 微众银行
mapKeyObject(['Z2004944000010'], {
  'img': 'wxbank',
  'background': '#47D08F',
  'name': '微众',
  'theme': 'green'
})

// 网商银行
mapKeyObject(['C1302533000018'], {
  'img': 'wsbank',
  'background': '#39A8FF',
  'name': '网商银行',
  'theme': 'blue'
})

export default {
  namespaced: true,
  state: {
    bankRegInfo: {},
    bankStyleInfo: bankStyleInfo,
    bankzcStyleInfo: bankzcStyleInfo,
    freeMessage: '',
    freeOriginUrl: '',
  },
  getters: {
    getBankRegInfo: state => state.bankRegInfo,
    getBankzcStyleInfo: state => state.bankzcStyleInfo,
    getBankStyleInfo: state => state.bankStyleInfo,
    getFreeMessage: state => state.freeMessage,
    getFreeOriginUrl: state => state.freeOriginUrl,
  },
  mutations: {
    resetBankRegInfo: (state) => {
      state.bankRegInfo = {}
    },
    setBankRegInfo: (state, bankInfo) => {
      state.bankRegInfo = Object.assign({}, state.bankRegInfo, bankInfo)
    },
    setFreeMessage: (state, msg) => { state.freeMessage = msg },
    setFreeOriginUrl: (state, url) => { state.freeOriginUrl = url },
  }
}
