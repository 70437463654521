const mine = [
  {
    path: '/mine',
    name: 'mine',
    component: () => import('@/views/mine/index.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      showTabbar: true,
      needLogin: true,
    }
  },
  {
    path: '/myTransaction',
    name: 'myTransaction',
    component: () => import('@/views/mine/transaction/index.vue'),
    meta: {
      needLogin: true,
      title: '我的交易',
    }
  },
  {
    path: '/sgwhitepay',
    name: 'sgwhitepay',
    component: () => import('@/views/mine/sgWhitePay'),
    meta: {
      keepAlive: true,
      showTabbar: false,
      title: '智家白条',
    }
  },
  {
    path: '/bankcard',
    name: 'bankcard',
    component: () => import('@/views/mine/myBankCard'),
    meta: {
      keepAlive: false,
      showHeader: false,
      showTabbar: false,
      needLogin: true,
      title: '提现管理',
      awaitlogin: true,
    }
  },
  {
    path: '/unbindcard/:cardNo',
    name: 'unbindcard',
    component: () => import('@/views/mine/myBankCard/unbindCard'),
    meta: {
      keepAlive: false,
      showTabbar: false,
      title: '解绑银行卡',
      awaitlogin: true,
    }
  },
  {
    path: '/bindcard',
    name: 'bindcard',
    component: () => import('@/views/mine/myBankCard/bindCard'),
    meta: {
      keepAlive: false,
      needLogin: true,
      showTabbar: false,
      title: '绑定银行卡',
      awaitlogin: true,
    }
  },
  {
    path: '/bindalipay',
    name: 'bindalipay',
    component: () => import('@/views/mine/myBankCard/bindAliPay'),
    meta: {
      keepAlive: false,
      needLogin: true,
      showTabbar: false,
      title: '绑定支付宝账号',
    }
  },
  {
    path: '/unbindalipay/:cardNo',
    name: 'unbindalipay',
    component: () => import('@/views/mine/myBankCard/unbindAliPay'),
    meta: {
      keepAlive: false,
      needLogin: true,
      showTabbar: false,
      title: '解绑支付宝账号',
    }
  },
  {
    path: '/verificationcode',
    name: 'veriftycode',
    component: () => import('@/views/mine/myBankCard/veriftyCode'),
    meta: {
      keepAlive: true,
      showTabbar: false,
      title: '输入短信验证码',
    }
  },
  {
    path: '/trueAuthentication',
    name: 'trueAuthentication',
    component: () => import('@/views/mine/myBankCard/trueAuthentication'),
    meta: {
      keepAlive: true,
      showTabbar: false,
      awaitlogin: true,
      title: '实名认证',
    }
  },
  {
    path: '/bankslist',
    name: 'bankslist',
    component: () => import('@/views/mine/myBankCard/banksList'),
    meta: {
      keepAlive: true,
      showTabbar: false,
      title: '银行列表',
    }
  },
  {
    path: '/coupon',
    name: 'coupon',
    component: () => import('@/views/mine/coupon/index.vue'),
    meta: {
      keepAlive: false,
      title: '我的优惠券',
    }
  },
  {
    path: '/myCollection',
    name: 'myCollection',
    component: () => import('@/views/mine/myCollection/index.vue'),
    meta: {
      keepAlive: false,
      title: '我的收藏',
      showHeader: false,
    }
  },
  {
    path: '/diamond',
    name: 'diamond',
    component: () => import('@/views/mine/diamond/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '我的钻石',
    }
  },
  {
    path: '/setUp',
    name: 'setUp',
    component: () => import('@/views/mine/setUp/index.vue'),
    meta: {
      keepAlive: false,
      title: '设置',
    }
  },
  {
    path: '/address',
    name: 'address',
    component: () => import('@/views/mine/address/index.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      title: '收货地址',
      needLogin: true,
      awaitlogin: true,
    }
  },
  {
    path: '/addressSelect',
    name: 'addressSelect',
    component: () => import('@/views/addressSel/index.vue'),
    meta: {
      showHeader: false,
      needLogin: false,
    }
  },
  {
    path: '/addressEdit',
    name: 'addressEdit',
    component: () => import('@/views/mine/addressEdit/index.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      title: '编辑收货地址',
      awaitlogin: true
    }
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test'),
    meta: {
      keepAlive: false,
      title: '测试页',
    }
  },
  {
    path: '/testmemoleaks',
    name: 'testmemoleaks',
    component: () => import('@/views/test/test'),
    meta: {
      keepAlive: false,
      title: '测试页2',
    }
  },
  {
    path: '/vue277',
    component: () => import('@/views/test/vue277'),
    meta: {
      keepAlive: false,
      title: 'vue2.7测试',
    }
  },
  {
    path: '/checkLogin',
    component: () => import('@/views/test/checklogin'),
    meta: {
      keepAlive: false,
      title: 'checkLogin测试',
    }
  },
  {
    path: '/myReserve',
    name: 'myReserve',
    component: () => import('@/views/mine/myReserve/index.vue'),
    meta: {
      keepAlive: false,
      title: '我的预约',
    }
  },
  {
    path: '/relieve',
    name: 'relieve',
    component: () => import('@/views/mine/myBankCard/relieve.vue'),
    meta: {
      keepAlive: false,
      title: '银行卡',
    }
  },
  {
    path: '/relieveResult',
    name: 'relieveResult',
    component: () => import('@/views/mine/myBankCard/relieveSuccess.vue'),
    meta: {
      keepAlive: false,
      title: '结果页',
    }
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/mine/help/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
    }
  },
  {
    path: '/storeHelp',
    name: 'storeHelp',
    component: () => import('@/views/mine/help/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
    }
  },
  {
    path: '/billList',
    name: 'billList',
    component: () => import('@/views/mine/sales/billList.vue'),
    meta: {
      showHeader: false,
    }
  },
  {
    path: '/documentInfo',
    name: 'documentInfo',
    component: () => import('@/views/mine/help/documentInfo.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      notRequireLocation: true,
    }
  },
  {
    path: '/financialStation',
    name: 'financialStation',
    component: () => import('@/views/mine/sales/financialStation.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      title: '我的收益',
    }
  },
  {
    path: '/financeBillDetail',
    name: 'financeBillDetail',
    component: () => import('@/views/mine/sales/financeBillDetail.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      title: '账单详情',
    }
  },
  {
    path: '/financialStationLocked',
    name: 'financialStationLocked',
    component: () => import('@/views/mine/sales/locked.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/ecardlist',
    name: 'Ecardlist',
    component: () => import('@/views/mine/ecard/index.vue'),
    meta: {
      title: '我的礼品卡',
      showHeader: true,
    }
  },
  {
    path: '/ecarddetail/:cardNo',
    name: 'EcardDetail',
    component: () => import('@/views/mine/ecard/detail.vue'),
    meta: {
      title: '我的礼品卡',
      showHeader: true,
    }
  },
  {
    path: '/ecardUseProd/:cardNo',
    name: 'EcardUseProd',
    component: () => import('@/views/mine/ecard/ecardUseProd.vue'),
    meta: {
      title: '商品列表',
      showHeader: true,
    }
  },
  {
    path: '/ecardbind',
    name: 'Ecardbind',
    component: () => import('@/views/mine/ecard/bind.vue'),
    meta: {
      title: '绑定礼品卡',
      showHeader: true,
    }
  },
  {
    path: '/ecardhelp',
    name: 'EcardHelp',
    component: () => import('@/views/mine/ecard/help.vue'),
    meta: {
      title: '帮助说明',
      showHeader: true,
    }
  },
  {
    path: '/subscribeStore',
    name: 'subscribeStore',
    component: () => import('@/views/mine/subscribeStore'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '订阅店铺',
      awaitlogin: true
    }
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('@/views/mine/wallet'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '我的资产',
      needLogin: true,
    }
  },
  {
    path: '/cancelPhone',
    name: 'cancelPhone',
    component: () => import('@/views/mine/cancelPhone'),
    meta: {
      showHeader: false,
      showTabbar: false,
      title: '账号申诉',
    },
  },
]

export default mine
