import axios from '@/http/http'
import URL from '@/http/url'
import { Toast } from 'vant'

// const defaultLocation = { // 默认地址
//   region: '山东 青岛 崂山区 中韩街道',
//   provinceId: 16,
//   province: '山东',
//   cityId: 173,
//   city: '青岛',
//   districtId: 2450,
//   district: '崂山区',
//   townshipId: 12036596,
//   township: '中韩街道',
//   latitude: '36.12784',
//   longitude: '120.41803',
// }

export default {
  namespaced: true,
  actions: {
    doLocation({ commit }) {
      return new Promise((resolve) => {
        if (navigator.geolocation) {
          // axios.get('/dingweiStart.json?')
          try {
            navigator.geolocation.getCurrentPosition(({ coords }) => {
              if (coords.longitude === null || coords.latitude === null) {
                resolve(400)
                return;
              }
              // axios.get('/dingweiSuccess.json?err=' + JSON.stringify(coords))
              let longitude = coords.longitude;
              let latitude = coords.latitude;
              resolve({ latitude, longitude })
            }, error => {
              console.log('定位失败，err =====', error)
              resolve(400)
            }, { timeout: 3 * 1000 });
          } catch (error) {
            resolve(400)
            // axios.get('/dingweiErrorCatch.json?err=' + JSON.stringify(error))
          }
        } else {
          resolve(400)
        }
      })
    },
    doLocationInfo({ commit }, params) {
      if (!params || params === 400) {
        return 400
      }
      const p = { latitude: params.latitude, longitude: params.longitude }
      /**
       * 地址编辑页面增加参数 status = 1 区分其他调用此接口的地方 
       * 如果传了status=1 后端如果找不到地区会做提示，其他页面不提示，只是返回默认青岛地区
       */
      params.status && (p.status = 1)
      return axios.get(URL.GET_LOCATION, {
        params: p
      }).then(res => {
        const { success, data } = res.data
        if (success && data && data.province) {
          const region = `${data.province} ${data.city} ${data.district} ${data.township}`
          const location = {
            region,
            provinceId: data.provinceId,
            province: data.province,
            cityId: data.cityId,
            city: data.city,
            districtId: data.districtId,
            district: data.district,
            townshipId: data.townshipId,
            township: data.township,
            latitude: params.latitude,
            longitude: params.longitude,
            cityCode: data.cityCode,
            districtCode: data.districtCode,
            provinceCode: data.provinceCode,
            townshipCode: data.townshipCode,
            detailAddress: data.detailedAddr, //详细地址
            addressId: '',  //地址id 用户收货地址我的地址里的
            sourceType: 'user_select'
          }
          // // 仅展示 不存储
          if (params.noStore) {
            return { ...location }
          }
          commit('setLocationInfo', location, { root: true });
          // if (utils.isShopKeeper()) commit('setDefaultLocation', location, { root: true })
          return location
        } else {
          // if (!localStorage.getItem('location')) {
          //   commit('setLocationInfo', defaultLocation, { root: true });
          // }
          /**
           * 接口入参有 status = 1 的情况下
           * 根据经纬度获取省市区这个接口，后端的逻辑会根据国标码查询，查不到的时候就会返回 '1001' message提示为：‘当前地址无法识别，请手工选择！’
           */
          if (!success && res.data?.errorCode === '1001') {
            Toast(res.data.message)
            return 1001
          }
          return 400
        }
      }).catch(() => {
        return 400
      })
    }
  }
}
