const walletGift = [ {
  path: '/walletGift',
  name: 'walletGift',
  component: () => import('@/viewsToB/walletGift/index.vue'),
  meta: {
    title: '我的礼品卡',
    showHeader: false,
  }
}, {
  path: '/gift-card-desc',
  name: 'gift-card-desc',
  component: () => import('@/viewsToB/walletGift/gift-card-desc.vue'),
  meta: {
    title: '绑定须知',
    showHeader: false,
  }
}, {
  path: '/giftCardBind',
  name: 'giftCardBind',
  component: () => import('@/viewsToB/walletGift/bind.vue'),
  meta: {
    title: '绑定礼品卡',
  }
}]

export default walletGift
