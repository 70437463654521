const internalChannel = [
  {
    path: '/internalChannel',
    name: 'internalChannel',
    component: () => import('@/views/internalChannel/index.vue'),
    meta: {
      keepAlive: false,
      title: '家电内购',
      // needLogin: true,
      showInternalTabbar: true,
      showHeader: false,
    }
  },
  {
    path: '/internalWorkbench',
    name: 'internalWorkbench',
    component: () => import('@/views/internalChannel/workbench.vue'),
    meta: {
      keepAlive: false,
      title: '我的',
      // needLogin: true,
      showInternalTabbar: true,
      showHeader: false,
    }
  },
  {
    path: '/purAmount',
    name: 'purAmount',
    component: () => import('@/views/internalChannel/purAmount/index.vue'),
    meta: {
      keepAlive: false,
      title: '已用额度',
      showHeader: false,
    }
  },
  {
    path: '/purRules',
    name: 'purRules',
    component: () => import('@/views/internalChannel/purRules/index.vue'),
    meta: {
      keepAlive: false,
      title: '内购规则',
      showHeader: false,
    }
  },
  {
    path: '/ruleDetail',
    name: 'ruleDetail',
    component: () => import('@/views/internalChannel/purRules/ruleDetail.vue'),
    meta: {
      keepAlive: false,
      title: '内购规则',
    }
  },
  {
    path: '/ruleLegend',
    name: 'ruleLegend',
    component: () => import('@/views/internalChannel/purRules/ruleLegend.vue'),
    meta: {
      keepAlive: false,
      title: '内购规则',
    }
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    component: () => import('@/views/internalChannel/userInfo/index.vue'),
    meta: {
      keepAlive: false,
      title: '个人信息',
      showHeader: false,
    }
  },
]

export default internalChannel
