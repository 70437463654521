const bookingList = () => import(/* webpackChunkName: "groupBooking" */ '@/views/groupBooking/groupBooking.vue');
const bookingDetail = () => import(/* webpackChunkName: "groupBookingDetail" */ '@/views/groupBooking/groupBookingDetail.vue');
const bookingRules = () => import(/* webpackChunkName: "bookingRules" */ '@/views/groupBooking/groupBookingRules.vue');

const groupBooking = [
  {
    path: '/groupBooking',
    name: 'groupBooking',
    component: bookingList,
    meta: {
      keepAlive: false,
      title: '好物拼团',
      showTabbar: false,
      showHeader: false,
    },
  },
  {
    path: '/groupBookingDetail',
    name: 'groupBookingDetail',
    component: bookingDetail,
    meta: {
      needLogin: true,
      keepAlive: false,
      title: '好物拼团',
      showTabbar: false,
    },
  },
  {
    path: '/groupBookingRules',
    name: 'groupBookingRules',
    component: bookingRules,
    meta: {
      keepAlive: false,
      title: '拼团流程',
      showTabbar: false,
    },
  },
]

export default groupBooking
