
const balance = [
  {
    path: '/revenueHome',
    name: 'revenueHome',
    component: () => import('@/viewsToB/revenue/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '我的营收',
    }
  },
  {
    path: '/revenueBillList',
    name: 'revenueBillList',
    component: () => import('@/viewsToB/revenue/revenueBillList.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '账单列表',
    }
  },
  {
    path: '/zjbBillList',
    name: 'zjbBillList',
    component: () => import('@/viewsToB/revenue/zjbBillList.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '智家宝账单',
    }
  },
  {
    path: '/revenueCashOut',
    name: 'revenueCashOut',
    component: () => import('@/viewsToB/revenue/cashOut.vue'),
    meta: {
      keepAlive: true,
      // showHeader: false,
      title: '提现',
    }
  },
  {
    path: '/revenueBillDetail',
    name: 'revenueBillDetail',
    component: () => import('@/viewsToB/revenue/revenueBillDetail.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '账单详情',
    }
  },
]

export default balance
