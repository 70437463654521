const shoppingGuide = [
  {
    path: '/activateGuide',
    name: 'activateGuide',
    component: () => import('@/viewsToB/shoppingGuide/activateGuide.vue'),
    meta: {
      title: '开通聊天功能',
      keepAlive: false,
      needLogin: true,
      awaitlogin: true,
      showTabbar: false,
    }
  },
  {
    path: '/guideInfo',
    name: 'guideInfo',
    component: () => import('@/viewsToB/shoppingGuide/guideInfo.vue'),
    meta: {
      title: '我的资料',
      keepAlive: false,
      needLogin: true,
      awaitlogin: true,
      showTabbar: false,
    }
  },
  {
    path: '/guideLevel',
    name: 'guideLevel',
    component: () => import('@/viewsToB/shoppingGuide/guideLevel.vue'),
    meta: {
      title: '我的等级',
      keepAlive: false,
      needLogin: true,
      awaitlogin: true,
      showTabbar: false,
    }
  },
  {
    path: '/guideAchievement',
    name: 'guideAchievement',
    component: () => import('@/viewsToB/shoppingGuide/guideAchievement.vue'),
    meta: {
      title: '我的业绩',
      keepAlive: false,
      needLogin: true,
      awaitlogin: true,
      showTabbar: false,
    }
  },
  {
    path: '/guideOrderDetail',
    name: 'guideOrderDetail',
    component: () => import('@/viewsToB/shoppingGuide/guideOrderDetail.vue'),
    meta: {
      title: '订单详情',
      keepAlive: false,
      needLogin: true,
      awaitlogin: true,
      showTabbar: false,
    }
  },
]

export default shoppingGuide
