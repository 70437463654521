const cart = [
  {
    path: '/cart',
    name: 'cart',
    component: () => import(
      /* webpackChunkName: "cart" */
      '@/views/cart/index.vue'),
    meta: {
      keepAlive: false,
      title: '购物车',
      needLogin: true,
      showTabbar: true,
      showInternalTabbar: true,
      showHeader: false,
    }
  },
  {
    path: '/mdShopping',
    name: 'mdShopping',
    component: () => import(
      '@/views/cart/mdShopping.vue'),
    meta: {
      keepAlive: false,
      title: '场景购物清单',
      needLogin: true,
      awaitlogin: true
    }
  },
  {
    path: '/mdShoppingCart',
    name: 'mdShoppingCart',
    component: () => import(
      '@/views/cart/mdShoppingCart.vue'),
    meta: {
      keepAlive: false,
      title: '场景购物清单',
      needLogin: true,
      showHeader: false,
      awaitlogin: true
    }
  }
]

export default cart
