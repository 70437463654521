const tableau = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/tableau/index.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      showTabbar: false,
      title: '数据看板',
    }
  },
]

export default tableau
