export default {
  namespaced: true,
  state: {
    orderSnFromVuex: sessionStorage.getItem('sg_payreslut_orderSn') || ''
  },
  getters: {
    getOrderSnFromVuex(state) {
      return state.orderSnFromVuex || ''
    },
  },
  mutations: {
    setOrderSnFromVuex(state, payload) {
      sessionStorage.setItem('sg_payreslut_orderSn',payload)
      state.orderSnFromVuex = payload
    }
  },
}
