import utils from '@/utils/utils'
export default {
  namespaced: true,
  state: {
    // answerAreaInfo: utils.strJSONParse(localStorage.getItem('answer-area-info'), {}),
    questionInfo: utils.strJSONParse(localStorage.getItem('question-detail-info'), {}),
    replayInfo: utils.strJSONParse(localStorage.getItem('question-replay-info'), {}),
  },
  mutations: {
    // setAnswerAreaInfo(state, info) {
    //   state.answerAreaInfo = Object.assign(state.answerAreaInfo, info);
    //   localStorage.setItem('answer-area-info', JSON.stringify(state.answerAreaInfo))
    // },
    setQuestionInfo(state, info) {
      state.questionInfo = info;
      localStorage.setItem('question-detail-info', JSON.stringify(info))
    },
    setReplayInfo(state, info) {
      state.replayInfo = info;
      localStorage.setItem('question-replay-info', JSON.stringify(info))
    }
  },
  getters: {
    // getAnswerInfo: state => state.answerAreaInfo,
    getQuestionInfo: state => state.questionInfo,
    getReplayInfo: state => state.replayInfo
  },
  actions: {

  }
}
