import axios from '@/http/http'
import URL from '@/http/url'
import utils from '@/utils/utils';
import { Toast } from 'vant'
import UplusApi from '@uplus/uplus-api';
const instance = new UplusApi();
instance.initDeviceReady()
// const defaultLocation = { // 默认地址
//   region: '山东 青岛 崂山区 中韩街道',
//   provinceId: 16,
//   province: '山东',
//   cityId: 173,
//   city: '青岛',
//   districtId: 2450,
//   district: '崂山区',
//   townshipId: 12036596,
//   township: '中韩街道',
//   latitude: '36.12784',
//   longitude: '120.41803',
// }

export default {
  namespaced: true,
  actions: {
    doLocation({ commit }) {
      return new Promise((resolve, reject) => {
        instance.initDeviceReady().then(() => {
          instance.upLocationModule.getLocation().then(res => {
            // axios.get('/ccc.json?res=' + JSON.stringify(res)) // 调试
            if (res.retData) {
              // let longitude = res.retData.latitude;
              // let latitude = res.retData.longitude
              let longitude = res.retData.longitude;
              let latitude = res.retData.latitude;
              resolve({ latitude, longitude })
            } else {
              resolve(400)
            }
          }).catch(() => {
            resolve(400)
          })
        }).catch(e => { console.log(e) });
      })
    },
    doLocationInfo({ commit }, params) {
      if (!params || params === 400) {
        return 400
      }
      const p = { latitude: params.latitude, longitude: params.longitude }
      /**
       * 地址编辑页面增加参数 status = 1 区分其他调用此接口的地方 
       * 如果传了status=1 后端如果找不到地区会做提示，其他页面不提示，只是返回默认青岛地区
       */
      params.status && (p.status = 1)
      return axios.get(URL.GET_LOCATION, {
        params: p
      }).then(res => {
        const { success, data } = res.data
        if (success && data && data.province) {
          const region = `${data.province} ${data.city} ${data.district} ${data.township}`
          // utils.logColor('GET_LOCATION', 'yellow', data)
          const location = {
            region,
            provinceId: data.provinceId,
            province: data.province,
            cityId: data.cityId,
            city: data.city,
            districtId: data.districtId,
            district: data.district,
            townshipId: data.townshipId,
            township: data.township,
            latitude: params.latitude,
            longitude: params.longitude,
            cityCode: data.cityCode,
            districtCode: data.districtCode,
            provinceCode: data.provinceCode,
            townshipCode: data.townshipCode,
            detailAddress: data.detailedAddr, //详细地址
            addressId: '',  //地址id 用户收货地址我的地址里的
            sourceType: 'user_select'
          }
          // // 仅展示 不存储
          if (params.noStore) {
            return { ...location }
          }
          commit('setLocationInfo', location, { root: true });
          // if (utils.isShopKeeper()) commit('setDefaultLocation', location, { root: true })
          return location
        } else {
          // if (!utils.isShopKeeper() && !localStorage.getItem('location')) {
          //   commit('setLocationInfo', defaultLocation, { root: true });
          // }
          /**
           * 接口入参有 status = 1 的情况下
           * 根据经纬度获取省市区这个接口，后端的逻辑会根据国标码查询，查不到的时候就会返回 '1001' message提示为：‘当前地址无法识别，请手工选择！’
           */
          if (!success && res.data?.errorCode === '1001') {
            Toast(res.data.message)
            return 1001
          }
          return 400
        }
      }).catch(() => {
        return 400
      })
    }
  }
}
