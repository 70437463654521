
const goodsManage = [
  {
    path: '/goodsIndexToB',
    name: 'goodsIndexToB',
    component: () => import('@/viewsToB/goodsManage/goodsIndex/index.vue'),
    meta: {
      keepAlive: false,
      title: '  商品管理',
      showHeader: true,
    }
  },
  {
    path: '/goodsListToB',
    name: 'goodsListToB',
    component: () => import('@/viewsToB/goodsManage/goodsList/index.vue'),
    meta: {
      keepAlive: true,
      title: '商品列表',
      showHeader: false,
    }
  },
  {
    path: '/goodsSearchToB',
    name: 'goodsSearchToB',
    component: () => import('@/viewsToB/goodsManage/components/search.vue'),
    meta: {
      keepAlive: false,
      title: '商品搜索',
      showHeader: false,
    }
  },
  {
    path: '/goodsEditToB',
    name: 'goodsEditToB',
    component: () => import('@/viewsToB/goodsManage/goodsEdit/index.vue'),
    meta: {
      keepAlive: false,
      title: '商品编辑',
      showHeader: false,
    }
  },
  {
    path: '/goodsPartnerToB',
    name: 'goodsPartnerToB',
    component: () => import('@/viewsToB/goodsManage/goodsEdit/partner.vue'),
    meta: {
      keepAlive: false,
      title: '店铺合伙人佣金比例',
      showHeader: false,
    }
  },
  {
    path: '/goodsTypeToB',
    name: 'goodsTypeToB',
    component: () => import('@/viewsToB/goodsManage/goodsEdit/typeIndex.vue'),
    meta: {
      keepAlive: true,
      title: '子商品编辑',
      showHeader: false,
    }
  },
  {
    path: '/priceManageToB',
    name: 'priceManageToB',
    component: () => import('@/viewsToB/goodsManage/priceManage/index.vue'),
    meta: {
      keepAlive: true,
      title: '价格管理',
      showHeader: false,
    }
  },
  {
    path: '/priceEditToB',
    name: 'priceEditToB',
    component: () => import('@/viewsToB/goodsManage/priceEdit/index.vue'),
    meta: {
      keepAlive: false,
      title: '价格编辑',
      showHeader: false,
    }
  },
  {
    path: '/priceSearchToB',
    name: 'priceSearchToB',
    component: () => import('@/viewsToB/goodsManage/priceManage/components/search.vue'),
    meta: {
      keepAlive: false,
      title: '价格搜索',
      showHeader: false,
    }
  },
  {
    path: '/goodsExplainToB',
    name: 'goodsExplainToB',
    component: () => import('@/viewsToB/goodsManage/priceEdit/explain.vue'),
    meta: {
      keepAlive: false,
      title: '  价格说明',
      showHeader: true,
    }
  },
]

export default goodsManage
