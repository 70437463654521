/**
 * 首页热销榜相关
 */
import Url from '../url.js';
import axios from '../http';
import config from '@/config'

/**
 * 获取品类列表
 */
export const getCateList = params => axios.get(Url.cateList, { params });

/**
 * 获取商品价格
 */
export const getProdcutPrice = params => axios.get(Url.getPriceByProductList, {
  params,
  baseURL: config.HOST,
});

/**
 * 获取品类销量
 * @param {*} params { productCateIds: []} 品类productCateId
 */
export const getSaleNum = params => axios.post(Url.getSaleByCate, params);

/**
 * 分品类获取热销排行
 * @param {*} params
 * {
 *    streetId,
 *    cityId,
 *    regionId,
 *    provinceId,
 *    productCateIds,
 * }
 */
export const getHotSaleProducts = params => axios.post(Url.getHotSaleProducts, { ...params }, {
  baseURL: config.SHOST,
});

export default {
  getCateList,
  getProdcutPrice,
  getSaleNum,
  getHotSaleProducts
}
