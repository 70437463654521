export default {
  namespaced: true,
  state: {
    userInfo: {
      // 个人信息
    },
    /**
     * @description 用于存储当前选中的收货地址，如果没有选中，这里将会是默认收货地址
     * 组件中获取store中默认收货地址的方法
     * computed: {
         defaultAddress() {
           const context = this;
           return context.$store.getters['personalCenter/getDefaultAddress']
         },
       },
     * {{defaultAddress.co}}
     */
    defaultAddress: {
      // 收货地址默认
    },
  },
  getters: {
    getUserInfo: state => state.userInfo,
    getDefaultAddress: state => state.defaultAddress,
  },
  mutations: {
    /**
     * @description 保存个人信息
     */
    saveUserInfo(state, payload) {
      state.userInfo = payload
    },
    /**
     * @description 保存默认收货地址信息
     */
    saveDefaultAddress(state, payload) {
      state.defaultAddress = payload
    },
  },
  actions: {
    /**
     * @description 更新登录后进入我的页面获取的用户信息
     * @param payload 用户信息
     */
    userInfoUpdate: ({ commit, state, dispatch }, payload) => {
      commit('saveUserInfo', payload);
    },

    /**
     * @description 更新收货地址信息，初始展示的是当前用户的默认收货地址
     * @param payload 用户信息
     */
    defaultAddressUpdate: ({ commit, state, dispatch }, payload) => {
      commit('saveDefaultAddress', payload);
    },
  },
}
