
const nearshop = [
  {
    path: '/nearshop',
    name: 'nearshop',
    component: () => import(
      /* webpackChunkName: "nearShop" */
      '@/views/nearshop/index'),
    meta: {
      keepAlive: true,
      title: '附近好店',
    }
  }]

export default nearshop
