const skyNetCommissionSet = [
  {
    path: '/skyNetCommissionSet',
    name: 'skyNetCommissionSet',
    component: () => import('@/viewsToB/skyNetCommissionSet/index.vue'),
    meta: {
      title: '天网佣金设置',
      keepAlive: true,
      showTabbar: false,
    }
  },
  {
    path: '/skyNetCommissionDetail',
    name: 'skyNetCommissionDetail',
    component: () => import('@/viewsToB/skyNetCommissionSet/detail.vue'),
    meta: {
      title: '详情',
      keepAlive: true,
      showTabbar: false,
    }
  },
  {
    path: '/skyNetCommissionSearch',
    name: 'skyNetCommissionSearch',
    component: () => import('@/viewsToB/skyNetCommissionSet/search.vue'),
    meta: {
      title: '查询',
      keepAlive: true,
      showTabbar: false,
    }
  },
  {
    path: '/skyNetCommissionSearchResult',
    name: 'skyNetCommissionSearchResult',
    component: () => import('@/viewsToB/skyNetCommissionSet/search-result.vue'),
    meta: {
      title: '查询结果',
      keepAlive: true,
      showTabbar: false,
    }
  },
]

export default skyNetCommissionSet
