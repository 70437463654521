// 此模块仅用来处理第三方App接入时的身份识别和全局保存 ** 仅开关打开时起作用 commissionDisplay=1
import axios from '../../http/http'
import URL from '../../http/url'
import utils from '../../utils/utils'
import { UserCategoryEnum, UserRoleEnum, UserRoleNameEnum } from '../../config/constMaps'
// identifyFlag 身份标识(0普通用户及储备微店主，11旗舰店12非旗舰店13售后店14社会化生态店15品类店16品旗店17 001号店，21顾问，22普通合伙人，31平台合伙人)
const state = () => ({
  ownMdInfo: null,
  manageInfo: undefined,
  displayCommissonSwitchOn: false, // 保存最初路径中是否含有开关标示 (commissionDisplay=1)
  currentRole: utils.strJSONParse(sessionStorage.getItem('currentRole'), null),
})

// 支持的环境
const support = () => {
  return utils.isIMaker() || utils.isIhaierApp()
}
let loaded = false;
// getters
const getters = {
  getOwnMdInfo: (state) => {
    /**
     * 内涵的数据结构
     * 
        address = safeGet<String>(json['address']);
        mdCode = safeGet<String>(json['mdCode']);
        name = safeGet<String>(json['name']);
        storeCode = safeGet<String>(json['storeCode']);
        storeId = safeGet<int>(json['storeId']);
        storeType=safeGet<int>(json['storeType']);
     */
    return state.ownMdInfo
  },
  getCurrentRole: (state) => {
    if (utils.isShopKeeper() || utils.isScMpPeWechat() || utils.isStoreCenter()) {
      return state.currentRole
    } else {
      return null
    }
  },
  getUserRoles: (state, getters, rootState) => {
    let roles = []
    if (getters?.getCurrentRole?.userIdentity) {
      roles.push(getters?.getCurrentRole?.userIdentity)
    } else {
      roles = rootState?.userIdentifyInfo?.identifySet || []
    }
    return new Set(roles)
  },
  getUserCategory: (state, getters, rootState) => {
    let category = `${rootState?.userIdentifyInfo?.category || ''}`
    if (getters?.getCurrentRole?.userCategory) {
      category = getters?.getCurrentRole?.userCategory
    } else if (category === UserCategoryEnum.RESERVE_MASTER && !getters?.getUserRoles?.has(UserRoleEnum.RESERVE_MASTER)) {
      // /wing/member/identify/detail接口返回的category不区分储备微店主和普通用户，但私域端传递过来的时候会区分， 所以非私域环境下  需要根据二级角色辅助判定一级身份。
      category = UserCategoryEnum.NORMAL_USER
    }

    return category
  },
  getManageInfo: (state, getters) => {
    if (!state.manageInfo && !loaded) {
      try {
        const smi = JSON.parse(sessionStorage.getItem('manageInfo'))
        const sdc = sessionStorage.getItem('displayCommissonSwitchOn')
        if (smi) { state.manageInfo = smi }
        state.displayCommissonSwitchOn = sdc
        loaded = true
      } catch (e) {
        console.log(e);
      }
    }
    return state.manageInfo || {}
  },
  getSwitch: (state, getter) => {
    if (!state.manageInfo && !loaded) {
      try {
        const smi = JSON.parse(sessionStorage.getItem('manageInfo'))
        const sdc = sessionStorage.getItem('displayCommissonSwitchOn')
        if (smi) { state.manageInfo = smi }
        state.displayCommissonSwitchOn = sdc
        loaded = true
      } catch (e) {
        console.log(e);
      }
    }
    return state.displayCommissonSwitchOn
  },
  // 是否为合伙人
  getIsHUR: (state) => {
    const smi = JSON.parse(sessionStorage.getItem('manageInfo'))
    return [21, 22, 31].includes(smi.identifyFlag)
  },
  getIdentify: (state, getters) => {
    return getters.getManageInfo.identifyFlag
  },
  displayCommission: (state, getter) => {
    // return true
    return getter.getSwitch && [11, 12, 13, 14, 21, 22, 31].includes(getter.getIdentify)
  },
  displaySetting: (state, getter) => {
    // return true
    return !support()
  },
  displayStoreEnter: (state, getter) => {
    // return true
    return support() && [11, 12, 13, 14, 21, 22].includes(getter.getIdentify)
  },
  displayStoreModule: (state, getter) => {
    // return true
    return support() && [11, 12, 13, 14, 21, 22, 31].includes(getter.getIdentify)
  },
  getEguangCurrentMdCode: (state) => {
    return state.ownMdInfo?.mdCode || ''
  },
  getEguangStoreCode: (state) => {
    return state.ownMdInfo?.storeCode || ''
  }
}

// actions
const actions = {
  fetchManageInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(URL.myManage, {
        noLogin: true
      }).then((res) => {
        let userInfo = res.data.data
        commit('setManageInfo', userInfo)
        sessionStorage.setItem('manageInfo', JSON.stringify(userInfo))
        resolve(userInfo)
      }).catch((e) => {
        console.log(e)
        reject()
      })
    })
  },
}

// mutations
const mutations = {
  setMdInfo(state, info) {
    state.ownMdInfo = info
  },
  setManageInfo (state, info) {
    state.manageInfo = info
  },
  setSwitch(state, value) {
    state.displayCommissonSwitchOn = value
    sessionStorage.setItem('displayCommissonSwitchOn', value);
  },
  setCurrentRole (state, roles) {
    let Roles = null
    let userCategoryName = null
    let userIdentityName = null

    for (const key in UserCategoryEnum) {
      if (UserCategoryEnum[key] === roles?.userCategory) {
        userCategoryName = key
        break
      }
    }
    for (const key in UserRoleEnum) {
      if (UserRoleEnum[key] === roles?.userIdentity) {
        userIdentityName = UserRoleNameEnum[key]
        break
      }
    }

    if (userCategoryName || userIdentityName) {
      Roles = {
        userCategory: userCategoryName ? roles.userCategory : null,
        userCategoryName,
        userIdentity: userIdentityName ? roles.userIdentity : null,
        userIdentityName
      }

    }
    console.log('Roles', Roles)
    sessionStorage.setItem('currentRole', Roles ? JSON.stringify(Roles) : null)
    state.currentRole = Roles
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
