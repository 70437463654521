const scp = [
  {
    path: '/smartChoiceProvince',
    name: 'smartChoiceProvince',
    component: () => import('@/views/smartChoice/province/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '海尔智选省场馆',
    },
  },
  {
    path: '/smartChoiceProvinceProducts',
    name: 'smartChoiceProvinceProducts',
    component: () => import('@/views/smartChoice/province/allProduct/moreProduct/index.vue'),
    meta: {
      keepAlive: true,
      title: '全部商品',
      showHeader: false
    },
    props: true,
    beforeEnter: (to, from, next) => {
      localStorage.setItem('recentFrom', 'p')
      next()
    },
  },
  {
    path: '/smartChoiceProvinceThirdCate',
    name: 'smartChoiceProvinceThirdCate',
    component: () => import('@/views/smartChoice/thirdCateProduct/index.vue'),
    meta: {
      keepAlive: false,
      title: '三级分类',
    },
    props: true,
  },
  {
    path: '/smartChoiceCity',
    name: 'smartChoiceCity',
    component: () => import('@/views/smartChoice/city/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '海尔智选市场馆',
    },
    props: true,
    beforeEnter: (to, from, next) => {
      localStorage.setItem('recentFrom', 'c')
      next()
    },
  },
]

export default scp
