import GlobalLoading from './index.vue'

const globalLoading = function() {}

globalLoading.install = function(Vue) {
  const Instance = Vue.extend(GlobalLoading) // 生成构造器
  const Loading = new Instance() // 生成实例

  // document.body.appendChild(Loading.$mount().$el) // 挂载
  const actions = {
    open(options) {
      Loading.open(options)
    },
    close() {
      Loading.close()
    }
  }
  globalLoading.open = actions.open
  globalLoading.close = actions.close

  Vue.prototype.$globalLoading = actions
}

export default globalLoading
