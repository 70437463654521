// 组件传参共享
export default {
  namespaced: true,
  state: {
    shareStoreInfo: {}, //分享时店铺信息
    shareWxTMImgBase64: '', //分享时微信小程序码base64
  },
  mutations: {
    setShareStoreInfo(state, info) {
      state.shareStoreInfo = info
    },
    setShareWxTMImgBase64(state, base64){
      state.shareWxTMImgBase64 = base64
    }
  },
}
