const contract = [
  {
    path: '/contractDetail',
    name: 'contractDetail',
    component: () => import('@/viewsToB/contract/contract-detail.vue'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '合同详情',
    },
  },
  {
    path: '/contractList',
    name: 'contractList',
    component: () => import('@/viewsToB/contract/contract-list.vue'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '合同管理',
    },
  },
]

export default contract
