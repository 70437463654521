/** @enum {number}
 * @common 全部
 */
export enum PlatformTypes {
  /** all */
  COMMON = ~0,
  /** 三翼鸟app */
  SYN_APP = 1 << 0,
  /** 智家app */
  UPLUS = 1 << 1,
  /** 掌柜APP */
  SG_RN = 1 << 2,
  /** 移动工作台app */
  EG = 1 << 3,
  /** 爱创客 */
  IMAKER = 1 << 4,
  /** 支付宝小程序 */
  SC_MP_ALI = 1 << 5,
  /** 微信小程序 */
  SC_MP_WX = 1 << 6,
  /** 字节小程序 */
  SC_MP_BYTE = 1 << 7,
  /** 三翼鸟微信小程序 */
  SYN_MP_WX = 1 << 8,
  /** 三翼鸟百度小程序 */
  SYN_MP_BAIDU = 1 << 9,
  /**  三翼鸟大屏 */
  SYN_DP = 1 << 10,
  /** 三翼鸟h5 */
  SYN_H5 = 1 << 11,
  /** m站h5 */
  SC_H5 = 1 << 12,
  /** 内购频道 */
  INTERNAL_CHANNEL =  1 <<  13,
  /** 智家app小程序 */
  SC_MP_ZJ_WX =  1 <<  14,
}

export enum UserCategoryEnum {
  /**储备微店主 0 */
  RESERVE_MASTER = '0',

  /**店铺主 1 */
  STORE_MASTER = '1',

  /**店铺合伙人 2 */
  STORE_PARTNER = '2',

  /**平台合伙人 3 */
  PLATFORM_PARTNER = '3',

  /**普通用户 4 */
  NORMAL_USER = '4',

  /**设计师用户 7 */
  DESIGNER_USER = '7'
}

export enum UserRoleEnum {
  /**普通用户 */
  NORMAL_USER = '001',

  /**储备微店主 */
  RESERVE_MASTER = '002',

  /**plus会员 */
  PLUS_MEMBER = '501',

  /**店铺主 */
  STORE_MASTER = '100',

  /**旗舰店主 */
  FLAGSHIP_MASTER = '101',

  /**非旗舰店主 */
  TOWNSHIP_MASTER = '102',

  /**售后店主 */
  SALE_MASTER = '103',

  /**社会化生态店主 */
  SOCIETY_MASTER = '104',

  /**品类店主 */
  PRODUCT_MASTER = '105',

  /**品旗店主 */
  BRAND_MASTER = '106',

  /**001号店主 */
  ZERO_MASTER = '107',

  /**供应商主 */
  VENDOR_MASTER = '108',

  /**三翼鸟直营店主 */
  SYN_DIRECT_MASTER = '109',

  /**店铺设计师 */
  // STORE_DESIGNER = '121',

  /**店铺合伙人 */
  STORE_PARTNER = '200',

  /**旗舰店合伙人 */
  FLAGSHIP_PARTNER = '201',

  /**非旗舰店合伙人 */
  TOWNSHIP_PARTNER = '202',

  /**售后店合伙人 */
  SALE_PARTNER = '203',

  /**社会化生态店合伙人 */
  SOCIETY_PARTNER = '204',

  /**已激活直销员 */
  ACTIVATE_GUIDE = '211',

  /**未激活直销员 */
  INACTIVE_GUIDE = '212',

  /**在线导购 */
  ONLINE_GUIDE = '213',

  /**店铺合伙人设计师 */
  STORE_PARTNER_DESIGNER = '214',

  /**平台合伙人 */
  PLATFORM_PARTNER = '300',

  /**平台合伙人设计师 */
  PLATFORM_PARTNER_DESIGNER = '301',

  /**子账号 */
  SUB_ACC = '402',

  /**用户管家 */
  PM = '403',

  /**店长 */
  SHOP_OWNER = '404',

  /**店员 */
  CLERK = '405',

  /**客服 */
  CUSTOMER = '406',

  /**交互师 */
  INTERACTIVE_DESIGNER = '407',

  /**工程部经理 */
  ENG_DEPARTMENT_MANAGER = '408',

  /**交付经理 */
  DELIVERY_MANAGER= '409',

  /** 信息员 */
  INFORMATION_OFFICER = '410',

  /** 社区专员 */
  COMMUNITY_OFFICER = '411',

  /** 店铺设计师 */
  STORE_DESIGNER = '701',
  /** 设计师 */
  DESIGNER = '700',
  /** 三翼鸟官方设计师 */
  SYN_DESIGNER = '702',
  /** 共享设计师 */
  SHARE_DESIGNER = '703',
}
export enum UserRoleNameEnum {
  /**普通用户 */
  NORMAL_USER = '普通用户',

  /**储备微店主 */
  RESERVE_MASTER = '储备微店主',

  /**plus会员 */
  PLUS_MEMBER = 'plus会员',

  /**店铺主 */
  STORE_MASTER = '店铺主',

  /**旗舰店主 */
  FLAGSHIP_MASTER = '旗舰店主',

  /**非旗舰店主 */
  TOWNSHIP_MASTER = '非旗舰店主',

  /**售后店主 */
  SALE_MASTER = '售后店主',

  /**社会化生态店主 */
  SOCIETY_MASTER = '社会化生态店主',

  /**品类店主 */
  PRODUCT_MASTER = '品类店主',

  /**品旗店主 */
  BRAND_MASTER = '品旗店主',

  /**001号店主 */
  ZERO_MASTER = '001号店主',

  /**供应商主 */
  VENDOR_MASTER = '供应商主',

  /**三翼鸟直营店主 */
  SYN_DIRECT_MASTER = '三翼鸟直营店主',

  INFORMATION_OFFICER = '信息员',

  /**店铺设计师 */
  STORE_DESIGNER = '店铺设计师',

  COMMUNITY_OFFICER = '社区专员',

  DESIGNER = '设计师',

  SYN_DESIGNER = '三翼鸟官方设计师',

  SHARE_DESIGNER = '共享设计师',
  
  /**店铺合伙人 */
  STORE_PARTNER = '店铺合伙人',

  /**旗舰店合伙人 */
  FLAGSHIP_PARTNER = '旗舰店合伙人',

  /**非旗舰店合伙人 */
  TOWNSHIP_PARTNER = '非旗舰店合伙人',

  /**售后店合伙人 */
  SALE_PARTNER = '售后店合伙人',

  /**社会化生态店合伙人 */
  SOCIETY_PARTNER = '社会化生态店合伙人',

  /**已激活直销员 */
  ACTIVATE_GUIDE = '门店销售',

  /**未激活直销员 */
  INACTIVE_GUIDE = '未激活直销员',

  /**在线导购 */
  ONLINE_GUIDE = '在线导购',

  /**店铺合伙人设计师 */
  STORE_PARTNER_DESIGNER = '店铺合伙人设计师',

  /**平台合伙人 */
  PLATFORM_PARTNER = '平台合伙人',

  /**平台合伙人设计师 */
  PLATFORM_PARTNER_DESIGNER = '平台合伙人设计师',

  /**子账号 */
  SUB_ACC = '子账号',

  /**用户管家 */
  PM = '用户管家',

  /**店长 */
  SHOP_OWNER = '店长',

  /**店员 */
  CLERK = '店员',

  /**客服 */
  CUSTOMER = '客服',

  /**交互师 */
  INTERACTIVE_DESIGNER = '交互师',

  /**工程部经理 */
  ENG_DEPARTMENT_MANAGER = '工程部经理',

  /**交付经理 */
  DELIVERY_MANAGER= '交付经理',

}
