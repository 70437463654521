const partnerManager = [
  {
    path: '/partnerManager',
    name: 'partnerManager',
    component: () => import('@/viewsToB/partnerManager/index.vue'),
    meta: {
      title: '合伙人管理',
      keepAlive: true,
      showTabbar: false,
    }
  },
  {
    path: '/partnerManagerSearch',
    name: 'partnerManagerSearch',
    component: () => import('@/viewsToB/partnerManager/partner-search.vue'),
    meta: {
      title: '查询',
      keepAlive: true,
      showTabbar: false,
    }
  },
  {
    path: '/partnerManagerSearchResult',
    name: 'partnerManagerSearchResult',
    component: () => import('@/viewsToB/partnerManager/search-result.vue'),
    meta: {
      title: '查询',
      keepAlive: true,
      showTabbar: false,
    }
  },
  {
    path: '/partnerManagerDetail',
    name: 'partnerManagerDetail',
    component: () => import('@/viewsToB/partnerManager/partner-detail.vue'),
    meta: {
      title: '合伙人详情',
      keepAlive: true,
      showTabbar: false,
    }
  },
  {
    path: '/partnerManagerSetStoreSpecialName',
    name: 'partnerManagerSetStoreSpecialName',
    component: () => import('@/viewsToB/partnerManager/partner-set-name.vue'),
    meta: {
      title: '备注',
      keepAlive: true,
      showTabbar: false,
    }
  },
  {
    path: '/minePartners',
    name: 'minePartners',
    component: () => import('@/viewsToB/partnerManager/mine-partners.vue'),
    meta: {
      title: 'TA的合伙人',
      keepAlive: true,
      showTabbar: false,
    }
  },
]

export default partnerManager
