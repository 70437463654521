import axios from '../../http/http'
import Url from '../../http/url'
import utils from '../../utils/utils'

export default {
  namespaced: true,
  actions: {
    async refreshUserToken ({ commit, dispatch }) {
      try {
        const refreshToken = localStorage.getItem('refreshToken')
        if (!utils.isHaierApp() && refreshToken) {
          const res = await axios.get(Url.refreshToken, {params: {refreshToken}})
          const data = res?.data?.success ? res?.data?.data : null
          if (data) {
            commit('setAcsToken', data.access_token || '', { root: true })
            commit('setRefreshToken', data.refresh_token || '', { root: true })
            localStorage.setItem('accessToken', data.access_token || '')
            // localStorage.setItem('refreshToken', data.refresh_token || '')
            utils.setLocalStorage('refreshToken', data.refresh_token || '')

            return {
              success: true,
              token: data.access_token
            }
          } else {
            await dispatch('logout', null, { root: true })
            return {
              success: false,
              token: null
            }
          }
        } else {
          return {
            success: true,
            token: null
          }
        }
      } catch(err) {
        return {
          success: false,
          token: null
        }
      }
    },
    async getUserCenterToken({ commit, rootGetters }) {
      if (rootGetters.getLoginStatus) {
        return axios.post(Url.WHITE_ACCESS_TOKEN, {}).then(({ data }) => {
          if (data.success) {
            let accessToken = data.data || '';
            commit('setAcsToken', accessToken, { root: true });
            window.localStorage.setItem('accessToken', accessToken);
            return Promise.resolve({
              success: true,
              token: accessToken
            })
          } else {
            return Promise.resolve({
              success: false,
              token: null
            })
          }
        }).catch(() => {
          return Promise.resolve({
            success: false,
            token: null
          })
        })
      } else {
        return Promise.resolve({
          success: false,
          token: null
        })
      }
    },
    async doLogin({ dispatch, commit }, params) {
      if (params.type === 'mobile') {
        return axios.post(`${Url.mobileLogin}`, params).then(({ data }) => {
          let result = data
          if (result.success) {
            let { accessToken = '', refreshToken = '' } = result.data
            commit('setAcsToken', accessToken, { root: true })
            commit('setRefreshToken', refreshToken, { root: true })
            // localStorage.setItem('refreshToken', refreshToken)
            utils.setLocalStorage('refreshToken', refreshToken)
            localStorage.setItem('accessToken', accessToken)
            return dispatch('doSuccessSetInfo', result.data)
          } else {
            return {
              ...result,
              message: result.msg,
              errorCode: result.code
            }
          }
        });
      } else {
        return axios.post(Url.accountLogin, params).then(({ data }) => {
          let result = data
          if (result.success) {
            let { accessToken = '', refreshToken = '' } = result.data
            commit('setAcsToken', accessToken, { root: true })
            commit('setRefreshToken', refreshToken, { root: true })
            // localStorage.setItem('refreshToken', refreshToken)
            utils.setLocalStorage('refreshToken', refreshToken)
            localStorage.setItem('accessToken', accessToken)
            return dispatch('doSuccessSetInfo', result.data)
          } else {
            return {
              ...result,
              message: result.msg,
              errorCode: result.code
            }
          }
        });
      }
    },
    async doRegister({ dispatch, commit }, params) {
      return axios.post(`${Url.registerAccount}${utils.parseParamsToQuery(params)}`, params).then(({ data }) => {
        let result = data
        if (result.success) {
          return dispatch('doSuccessSetInfo', result.data)
        } else {
          return {
            code: 400,
            ...result
          }
        }
      })
    },
    doSuccessSetInfo({ dispatch, commit }, result) {
      let sdToken = result.accessToken || ''
      let refreshToken = result.refreshToken || ''
      return dispatch('getMember', { sdToken, refreshToken }, { root: true })
    },
    // async doTourists({ dispatch, commit }) {
    //   return axios.get(Url.GET_TOURISTS_TOKEN).then(({ data }) => {
    //     if (data.success) {
    //       let token = data.data
    //       if (token.indexOf('#') !== -1 || !localStorage.getItem('sessionValue')) {
    //         commit('setToken', token, { root: true });
    //         commit('setSessionValue', token, { root: true })
    //         localStorage.setItem('sessionValue', token)
    //         localStorage.setItem('token', token)
    //       }
    //       return token;
    //     } else {
    //       return 400
    //     }
    //   })
    // }
  },
}
