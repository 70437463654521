
const specialtyStore = [
  {
    path: '/specialtyStore',
    name: 'specialtyStore',
    component: () => import(
      /* webpackChunkName: "specialtyStore" */
      '@/views/specialtyStore/index'),
    meta: {
      keepAlive: true,
      title: '海尔智选',
      showHeader: false,
    },
  },
  {
    path: '/orderMore',
    name: 'orderMore',
    component: () => import('@/views/specialtyStore/order-more'),
    meta: {
      keepAlive: true,
      title: '预约抢购',
    },
  },
  // {
  //   path: '/flashMore',
  //   name: 'flashMore',
  //   component: () => import('@/views/specialtyStore/flash-more'),
  //   meta: {
  //     keepAlive: true,
  //     title: '限时抢购',
  //     showHeader: false,
  //   },
  // },
]

export default specialtyStore
