import { checklogin } from './login'
import { expose } from './expose'
import { preventClick, preventClickSingleton} from './preventClick'
export default {
  install(Vue) {
    Vue.directive('checklogin', checklogin)
    Vue.directive('expose', expose)
    Vue.directive('preventClick', preventClick)
    Vue.directive('preventClickSingleton', preventClickSingleton)
  }
}
