const storePartners = [{
  path: '/storePartners',
  name: 'storePartners',
  component: () => import('@/viewsToB/storePartners/index.vue'),
  meta: {
    title: '合伙人管理',
    keepAlive: false,
    showTabbar: false,
    showHeader: false,
  }
},
{
  path: '/unboundApply',
  name: 'unboundApply',
  component: () => import('@/viewsToB/storePartners/unbound-apply.vue'),
  meta: {
    title: '解绑合伙人',
    keepAlive: true,
    showTabbar: false,
  }
},
{
  path: '/unboundProgress',
  name: 'unboundProgress',
  component: () => import('@/viewsToB/storePartners/unbound-progress.vue'),
  meta: {
    title: '店铺合伙人解绑进度',
    keepAlive: true,
    showTabbar: false,
    showHeader: false,
  }
},
{
  path: '/searchInvitedPartners',
  name: 'searchInvitedPartners',
  component: () => import('@/viewsToB/storePartners/partner-search.vue'),
  meta: {
    title: '店铺合伙人管理-查询',
    keepAlive: true,
    showTabbar: false,
  }
},
{
  path: '/partnerDetail',
  name: 'partnerDetail',
  component: () => import('@/viewsToB/storePartners/partner-detail.vue'),
  meta: {
    title: '合伙人详情',
    keepAlive: true,
    showTabbar: false,
  }
},
{
  path: '/partnerInformationSet',
  name: 'partnerInformationSet',
  component: () => import('@/viewsToB/storePartners/partner-set.vue'),
  meta: {
    title: '店铺合伙人信息设置',
    keepAlive: false,
    showTabbar: false,
  }
},
{
  path: '/partnerDataAnalysis',
  name: 'partnerDataAnalysis',
  component: () => import('@/viewsToB/storePartners/partner-data-analysis.vue'),
  meta: {
    title: '数据分析',
    keepAlive: true,
    showTabbar: false,
  }
},
{
  path: '/partnerShare',
  name: 'partnerShare',
  component: () => import('@/viewsToB/storePartners/partner-share.vue'),
  meta: {
    title: '邀请成为合伙人',
    keepAlive: true,
    showTabbar: false,
    showHeader: false,
  }
},
{
  path: '/partnerSearchResults',
  name: 'partnerSearchResults',
  component: () => import('@/viewsToB/storePartners/search-results.vue'),
  meta: {
    title: '店铺合伙人管理-查询',
    keepAlive: true,
    showTabbar: false,
  }
},
{
  path: '/partnerInviteSuccess',
  name: 'partnerInviteSuccess',
  component: () => import('@/viewsToB/storePartners/invite-success.vue'),
  meta: {
    title: '升级成功',
    keepAlive: true,
    showTabbar: false,
    showHeader: false,
  }
},
]

export default storePartners
