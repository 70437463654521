const channel = [{
  path: '/channel',
  name: 'channel',
  component: () => import('@/views/channel/index.vue'),
  meta: {
    keepAlive: true,
    title: '频道',
    needLogin: false,
    showTabbar: false,
    showHeader: false,
    hasdownload: true,
    awaitlogin: true
  }
}, {
  path: '/newchannel/:channelid',
  name: 'newchannel',
  component: () => import('@/views/channel/index.vue'),
  meta: {
    keepAlive: true,
    title: '频道',
    needLogin: false,
    showTabbar: false,
    showHeader: false,
    awaitlogin: true,
    hasdownload: true,
  }
}, {
  path: '/fc',
  name: 'fc',
  component: () => import('@/views/channel/fc.vue'),
  meta: {
    keepAlive: true,
    title: '场景',
    needLogin: false,
    showTabbar: false,
    showHeader: false,
  }
}]

export default channel
