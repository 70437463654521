// 适用于单一按钮的防连点控制，比如 “提交订单”
export const preventClick = {
  inserted(el, binding) {
    let isClicked = false;
    el.addEventListener('click', async () => {
      if (isClicked) return;
      isClicked = true;
      try {
        await binding.value();
      } catch (error) {
        // 错误处理逻辑
      } finally {
        isClicked = false;
      }
    });
  }
}

let isClicked = false; 
// 适用于多个按钮触发同一事件，全局进行防连点控制的情况， 比如 “结算页-选择地址“
export const preventClickSingleton = {
  inserted(el, binding) {
    el.addEventListener('click', async () => {
      if (isClicked) return;
      isClicked = true;
      try {
        await binding.value();
      } catch (error) {
        // 错误处理逻辑
      } finally {
        isClicked = false;
      }
    });
  }
}