const finance = [{
  path: '/finance',
  name: 'finance',
  component: () => import('@/viewsToB/finance/index.vue'),
  meta: {
    title: '网商贷款',
    showHeader: false,
  }
}, {
  path: '/credit-financing',
  name: 'credit-financing',
  component: () => import('@/viewsToB/finance/credit-financing.vue'),
  meta: {
    title: '信用融资',
  }
}, {
  path: '/financing-history',
  name: 'financing-history',
  component: () => import('@/viewsToB/finance/financing-history.vue'),
  meta: {
    title: '融资记录',
    showHeader: false,
  }
}, {
  path: '/history-detail',
  name: 'history-detail',
  component: () => import('@/viewsToB/finance/history-detail.vue'),
  meta: {
    title: '融资记录明细',
    // showHeader: false,
  },
}, {
  path: '/van-link',
  name: 'van-link',
  component: () => import('@/viewsToB/finance/van-link.vue'),
  meta: {
    title: '万链金融信用融资',
    // showHeader: false,
  }
}, {
  path: '/van-link-req',
  name: 'van-link-req',
  component: () => import('@/viewsToB/finance/van-link-req.vue'),
  meta: {
    title: '万链金融信用融资',
    // showHeader: false,
  }
}]

export default finance
