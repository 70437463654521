const shop = [
  {
    path: '/shopDetail',
    name: 'shopDetail',
    // component: () => import('@/views/shop/shop/index.vue'),
    component: () => import('@/views/shopDetail/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '店铺详情',
      trk_origin: 'dp_all',
    },
  },
  {
    path: '/shopSelectList',
    name: 'shopSelectList',
    component: () => import('@/views/shopDetail/selectProdList/selectProdList.vue'),
    meta: {
      keepAlive: false,
      showHeader: true,
      title: '生态优选',
    },
  },
  {
    path: '/shopVRPreview',
    name: 'shopVRPreview',
    component: () => import('@/views/shopDetail/vrPreview/vrPreview.vue'),
    meta: {
      keepAlive: false,
      showHeader: true,
      title: 'VR预览',
    },
  },
  {
    path: '/shopUserCase',
    name: 'shopUserCase',
    component: () => import('@/views/shopDetail/userCase/userCase.vue'),
    meta: {
      keepAlive: false,
      showHeader: true,
      title: '用户案例',
    },
  },
  {
    path: '/shopCustomPage',
    name: 'shopCustomPage',
    component: () => import('@/views/shopDetail/customPage/customPage.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '', // 店铺自定义页面
    },
  },
  {
    path: '/shopInfo',
    name: 'shopInfo',
    // component: () => import('@/views/shop/info/index.vue'),
    component: () => import('@/views/shopDetail/info/shopInfo.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      title: '店铺信息',
    },
  },
  {
    path: '/shopPreview',
    name: 'shopPreview',
    // component: () => import('@/views/shop/info/index.vue'),
    component: () => import('@/views/shopDetail/shopPreview.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      title: '店铺预览',
    },
  },
  {
    path: '/shopCustomPagePreview',
    name: 'shopCustomPagePreview',
    // component: () => import('@/views/shop/info/index.vue'),
    component: () => import('@/views/shopDetail/customPagePreview/customPagePreview.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      title: '自定义页面预览',
    },
  },
  {
    path: '/shopSybird',
    name: 'shopSybird',
    // component: () => import('@/views/shop/info/index.vue'),
    component: () => import('@/views/shopDetail/sybird/sybird.vue'),
    meta: {
      keepAlive: false,
      showHeader: true,
      title: '', // 三翼鸟介绍页
    },
  },
  {
    path: '/voucher',
    name: 'voucher',
    component: () => import(
      /* webpackChunkName: "voucher" */
      '@/views/shop/voucher/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '领券中心',
      hasdownload: true,
    },
  },
  {
    path: '/newvoucher',
    name: 'newvoucher',
    component: () => import('@/views/shop/voucher/new.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      title: '领券中心',
      hasdownload: true,
    },
  },
  {
    path: '/voucher/package/:activityId',
    name: 'voucherPackageDetail',
    component: () => import('@/views/shop/voucher/components/voucherPackage/voucherPackageDetail.vue'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '券包详情',
      hasdownload: false,
      needLogin: true,
      awaitlogin: true
    },
  },
  {
    path: '/voucherdetail',
    name: 'voucherdetail',
    component: () => import('@/views/shop/voucher/newdetail.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      title: '商品列表',
    },
  },
  {
    path: '/certification',
    name: 'certification',
    component: () => import('@/views/shop/certification/index.vue'),
    meta: {
      keepAlive: false,
      title: '海尔智家',
    },
  },
  {
    path: '/shopLicense',
    name: 'shopLicense',
    component: () => import('@/views/shop/license/index.vue'),
    meta: {
      keepAlive: false,
      title: '企业资质',
    }
  },
  {
    path: '/usablecouponlist',
    name: 'usablecouponlist',
    // component: () => import('@/views/shop/usablecouponlist/usable-coupon-list.vue'),
    component: () => import('@/views/shop/voucher/newdetail.vue'),
    meta: {
      keepAlive: false,
      title: '商品列表',
      showHeader: false,
      hasdownload: true,
    }
  },
  {
    path: '/usablecardlist',
    name: 'usablecardlist',
    component: () => import('@/views/shop/voucher/cardDetail.vue'),
    meta: {
      keepAlive: false,
      title: '商品列表',
      showHeader: false,
      hasdownload: true,
    }
  },
  {
    path: '/shopNearby',
    name: 'shopNearby',
    component: () => import(
      /* webpackChunkName: "shopNearby" */
      '@/views/shop/nearby'),
    meta: {
      keepAlive: true,
      title: '附近好店',
    },
  },
  {
    path: '/payState',
    name: 'payState',
    component: () => import('@/views/order/cashier/pay-state'),
    meta: {
      keepAlive: false,
      title: '支付结果',
      showHeader: false,
    },
  },
  {
    path: '/shopSuitList',
    name: 'shopSuitList',
    component: () => import('@/views/shopSuit/shopSuitSearch.vue'),
    meta: {
      keepAlive: false,
      title: '热门成套方案',
      showHeader: false,
    },
  },
  {
    path: '/shopSuitSearch',
    name: 'shopSuitSearch',
    component: () => import('@/views/shopSuit/shopSuitSearch.vue'),
    meta: {
      keepAlive: false,
      title: '',
      showHeader: false,
    },
  },
  {
    path: '/shopSuitDetail',
    name: 'shopSuitDetail',
    component: () => import('@/views/shopSuit/shopSuitDetail.vue'),
    meta: {
      keepAlive: false,
      title: '热销成套方案',
      showHeader: false,
    },
  },
  {
    path: '/rights-all',
    name: 'rights-all',
    component: () => import('@/views/shopDetail/shop-vip/rights-all.vue'),
    meta: {
      title: '所有权益与奖品',
      showHeader: false,
    },
  },
  {
    path: '/rights-confirm',
    name: 'rights-confirm',
    component: () => import('@/views/shopDetail/shop-vip/rights-confirm.vue'),
    meta: {
      title: '核销奖品',
    },
  },
  {
    path: '/shopActivity',
    name: 'shopActivity',
    component: () => import('@/views/shopDetail/activity/index.vue'),
    meta: {
      keepAlive: false,
      showHeader: true,
      title: '店内活动',
    },
  },
  {
    path: '/shopFtDetail',
    name: 'shopFtDetail',
    component: () => import('@/views/shopFtDetail/index.vue'),
    meta: {
      showHeader: false,
      title: '家电套购详情'
    }
  },
  {
    path: '/shopDetail/decorationAppointmentDetail',
    name: 'decorationAppointmentDetail',
    component: () => import('@/views/shopDetail/component/top-bar/selected/houseDecorationBookingAct/detail.vue'),
    meta: {
      showHeader: false,
      title: '家装预约'
    }
  }
]

export default shop
