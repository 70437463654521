export default [
  {
    path: '/cardquan',
    name: 'cardquan',
    component: () => import('@/viewsToB/cardQuan/index.vue'),
    meta: {
      showHeader: false,
      title: '海尔卡券',
    }
  },
  {
    path: '/cardtypelist',
    name: 'cardQuanDetailList',
    component: () => import('@/viewsToB/cardQuan/detailList.vue'),
    meta: {
      showHeader: false,
      title: '海尔卡券',
    }
  },
  {
    path: '/cardDetail',
    name: 'cardQuanDetail',
    component: () => import('@/viewsToB/cardQuan/detail.vue'),
    meta: {
      showHeader: false,
      title: '券详情',
    }
  },
  {
    path: '/tabDetialList',
    name: 'cardQuanTabList',
    component: () => import('@/viewsToB/cardQuan/tabList.vue'),
    meta: {
      showHeader: false,
      title: '券明细',
    }
  }
]