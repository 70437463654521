
/**
 * 卡券部分
 */
// 卡券类型
export enum QUAN_TYPE {
  ALL = 'ALL',
  REBATE_COUPON = 'REBATE_COUPON', //满减券
  DISCOUNT_COUPON = 'DISCOUNT_COUPON', //折扣券	
  REALITY_GIFT_COUPON = 'REALITY_GIFT_COUPON', //实物礼品券
  VIRTUAL_GIFT_COUPON = 'VIRTUAL_GIFT_COUPON', //虚拟礼品券
  FIXED_CASH_COUPON = 'FIXED_CASH_COUPON', // 定额现金券
  NO_PURCHASE_SERVICE_COUPON = 'NO_PURCHASE_SERVICE_COUPON', //不购机服务券
}
// 卡券活动类型
export enum ACTIVE_TYPE {
  ALL = '',
  ACTIVATE = 'ACTIVATE', //进行中
  PAUSE = 'PAUSE', //已暂停
  FINISH = 'FINISH', //完成
  READY = 'READY', //未开始
}
// 卡券状态类型
export enum STATUS_TYPE {
  ALL = 'ALL', //全部
  WAIT_USE = 'WAIT_USE', //待使用
  WAIT_EXPIRE = 'WAIT_EXPIRE', //即将过期
  EXPIRED = 'EXPIRED', //已过期
  INVALID = 'INVALID', //已作废
  WAIT_EXCHANGE = 'WAIT_EXCHANGE', //待兑换
  CHECK_OFF = 'CHECK_OFF', //已核销
  FINISH_SETTLE = 'FINISH_SETTLE', //结算成功
  NO_SETTLE = 'NO_SETTLE', //不结算
  TO_SETTLE = 'TO_SETTLE', //待结算
}

// 卡券来源
export enum CARDQUAN_SOURCE {
  '商家自建',
  '平台活动'
}

//卡券平台
export enum CARDQUAN_PLATFORM {
  ZHI_JIA = '智家APP',
  SAN_YI_NIAO = '三翼鸟APP',
  SAN_YI_NIAO_APP = '三翼鸟微信小程序',
  HAI_GOU = '嗨购',
  YI_LI_HUO = '易理货',
  ZHI_BO = '直播平台',
  DUI_BEI = '兑呗',
  WECHAT_APP = '三翼鸟支付宝小程序',
  WECHAT_P_APP = '海尔智家体验店',
  DOUYIN_APP = '三翼鸟移动工作台',
  // ZHI_JIA = '智家',
  // ZHI_JIA_APP = '智家app',
  // SAN_YI_NIAO = '三翼鸟',
  // SAN_YI_NIAO_APP = '三翼鸟小程序',
  // HAI_GOU = '嗨购',
  // YI_LI_HUO = '易理货',
  // ZHI_BO = '直播平台',
  // DUI_BEI = '兑呗',
  // WECHAT_APP = '微信小程序渠道',
  // WECHAT_P_APP = '微信私域小程序',
  // DOUYIN_APP = '抖音私域小程序',
}