// import axios from '@/http/http'
// import qs from 'qs';
// import Url from '../../http/url'
import UplusApi from '@uplus/uplus-api';
// import utils from '../../utils/utils';
const instance = new UplusApi();
instance.initDeviceReady()

export default {
  namespaced: true,
  actions: {
    async getUplusToken({ dispatch, commit }) {
      let outterTimmer
      console.log('getUplusToken===========>')
      return new Promise((resolve, reject) => {
        instance.initDeviceReady().then(() => {
          clearTimeout(outterTimmer)
          let count = 0;
          const doGetToken = () => {
            const run = () => {
              instance.upUserModule.getOauthData().then((res) => {
                console.log(res, '+++++++getOauthData++++++')
                if (res && res.retData && res.retData.user_center_access_token) {
                  let ucAccessToken = res.retData.user_center_access_token
                  commit('setSdToken', ucAccessToken, { root: true });
                  resolve(res)
                } else {
                  count++;
                  if (count < 3) {
                    let timer = setTimeout(() => {
                      clearTimeout(timer)
                      run();
                    }, 200)
                  } else {
                    // localStorage.setItem('sessionValue', '')
                    // localStorage.setItem('sdToken', '') // 防止切换环境导致登录页唤起 切换环境清除智家登录sdtoken
                    // commit('setSdToken', '', { root: true });// 防止切换环境导致登录页唤起
                    // commit('setLoginStatus', false, { root: true })
                    // resolve(400)
                    console.log('getUplusToken，清除登陆信息')
                    dispatch('logout', null, { root: true }).then(() => {
                      resolve(400)
                    }).catch(e => { console.log(e) });
                  }
                }
              }).catch(() => {
                count++;
                if (count < 3) {
                  let timer = setTimeout(() => {
                    clearTimeout(timer)
                    run();
                  }, 200)
                } else {
                  // localStorage.setItem('sessionValue', '')
                  // localStorage.setItem('sdToken', '') // 防止切换环境导致登录页唤起 切换环境清除智家登录sdtoken
                  // commit('setSdToken', '', { root: true });// 防止切换环境导致登录页唤起
                  // commit('setLoginStatus', false, { root: true })
                  // resolve(400)
                  console.log('getUplusToken 异常，清除登陆信息')
                  dispatch('logout', null, { root: true }).then(() => {
                    resolve(400)
                  }).catch(e => { console.log(e) });
                }
              })
            }
            run();
          }
          doGetToken()
        }).catch(e => { console.log(e) });
        outterTimmer = setTimeout(() => {
          reject(new Error('init error'))
        }, 100);
      })
      // return dispatch('getAuthorize', '7ebf8c57-83ae-4315-85b1-43220237aa8d')
    },
    async getAuthorize({ dispatch, commit }, payload) {
      const { sdToken, ignoreDefaultDeliveryAddress } = payload
      if (!sdToken) {
        return
      }
      commit('setAcsToken', sdToken, { root: true })
      commit('setLoginStatus', true, { root: true });
      // localStorage.setItem('accessToken', sdToken)
      // localStorage.setItem('isLogin', true)
      return dispatch('getMember', { sdToken, ignoreDefaultDeliveryAddress }, { root: true })
    },

    /*
    async getAuthorize({ dispatch, commit }, sdToken) {
      // DSG-24972 区分注册来源
      const regSource = utils.isSyBird() ? 'SynApp'
        : utils.isSyBirdMP() ? 'SynWxshop'
          : utils.isUplus() ? 'ZjApp'
            : utils.isIMaker() ? 'PtpartnerUc-iMaker'
              : utils.isIhaierApp() ? 'PtpartnerUc-ihaier' : 'shopuc'
      let url = Url.GET_AUTHORIZE + `?platform=2&regSource=${regSource}&callbackUrl=` + encodeURIComponent('https://m.ehaier.com');
      return axios.get(url, {
        headers: { 'Authorization': sdToken }, // sdToken
      }).then(async ({ data }) => {
        if (data.success) {
          let mId = data.data.mId
          return dispatch('getMember', { sdToken, mId, ignoreDefaultDeliveryAddress })
        } else {
          // localStorage.setItem('token', '')
          localStorage.setItem('sessionValue', '')
          localStorage.setItem('sdToken', '') // 防止切换环境导致登录页唤起 切换环境清除智家登录sdtoken
          commit('setSdToken', '', { root: true });// 防止切换环境导致登录页唤起
          commit('setLoginStatus', false, { root: true })
          await dispatch('logout', null, { root: true })
          return 400
        }
      }).catch(async e => {
        await dispatch('logout', null, { root: true })
        return 400
      })
    },
    async getMember({ dispatch, commit }, { sdToken, mId, ignoreDefaultDeliveryAddress }) {
      let url = Url.GET_MEMBER
      return axios.post(url, { token: mId }, {
        params: { token: mId },
        headers: {
          'Authorization': sdToken,
          // 'Content-Type': 'application/x-www-form-urlencoded'
        },
      }).then(async ({ data }) => {
        if (data.success) {
          let result = data.data
          let mid = result.mid
          let ucid = result.ucId
          let accessToken = result.accessToken
          let sessionKey = result.sessionKey
          let sessionValue = result.sessionValue
          let token = result.token
          let userInfo = {}
          userInfo.username = result.userName
          userInfo.avatarImage = result.avatarImageFileId
          userInfo.loginName = result.loginName
          userInfo.birthday = result.birthday
          userInfo.phone = result.mobile
          userInfo.email = result.email
          userInfo.cartNumber = result.cartNumber
          userInfo.gender = result.gender
          userInfo.isNewUser = result.isNewUser
          userInfo.isStoreMember = result.isStoreMember
          userInfo.nickName = result.nickName
          userInfo.rankName = result.rankName
          userInfo.promotionCode = result.promotionCode
          userInfo.resultSign = result.resultSign

          commit('setLoginStatus', true, { root: true });
          commit('setmId', mid, { root: true });
          commit('setUcId', ucid, { root: true });
          commit('setAcsToken', accessToken, { root: true })
          commit('setSessionKey', sessionKey, { root: true })
          commit('setSessionValue', sessionValue, { root: true })
          commit('setUserInfo', userInfo, { root: true })
          commit('setToken', token, { root: true });

          // set locaStorage
          localStorage.setItem('isLogin', true)
          localStorage.setItem('ucId', ucid)
          localStorage.setItem('token', token)
          localStorage.setItem('sdToken', sdToken)
          localStorage.setItem('accessToken', accessToken)
          localStorage.setItem('mId', mid)
          localStorage.setItem('sessionKey', sessionKey)
          localStorage.setItem('sessionValue', sessionValue)
          localStorage.setItem('userInfo', JSON.stringify(userInfo))
          if (utils.isShopKeeper() || utils.isUplus()) dispatch('chat/updateDeviceToCloud', true, { root: true })

          gio && gio('setUserId', ucid);

          dispatch('isHasStore', { mid, sdToken }, { root: true })
          if (!localStorage.getItem('location') && !ignoreDefaultDeliveryAddress) {
            dispatch('defaultDeliveryAddress', null, { root: true })
          }
          return result
        } else {
          // localStorage.setItem('token', '')
          localStorage.setItem('sessionValue', '')
          commit('setLoginStatus', false, { root: true })
          await dispatch('logout', null, { root: true })
          return 400
        }
      }).catch(async e => {
        await dispatch('logout', null, { root: true })
        return 400
      })
    }
    */
  },
}
