
const invoiceManage = [
  {
    path: '/invoiceIndexToB',
    name: 'invoiceIndexToB',
    component: () => import('@/viewsToB/invoiceManage/invoiceIndex/index.vue'),
    meta: {
      keepAlive: false,
      title: '  发票管理',
      showHeader: true,
    }
  },
  {
    path: '/invoiceListToB',
    name: 'invoiceListToB',
    component: () => import('@/viewsToB/invoiceManage/invoiceList/index.vue'),
    meta: {
      keepAlive: true,
      title: '发票列表',
      showHeader: false,
    }
  },
  {
    path: '/invoiceSearchToB',
    name: 'invoiceSearchToB',
    component: () => import('@/viewsToB/invoiceManage/components/search.vue'),
    meta: {
      keepAlive: false,
      title: '发票搜索',
      showHeader: false,
    }
  },
  {
    path: '/invoiceEditToB',
    name: 'invoiceEditToB',
    component: () => import('@/viewsToB/invoiceManage/invoiceEdit/index.vue'),
    meta: {
      keepAlive: false,
      title: '发票编辑',
      showHeader: true,
    }
  },
  {
    path: '/invoiceDetailToB',
    name: 'invoiceDetailToB',
    component: () => import('@/viewsToB/invoiceManage/invoiceDetail/index.vue'),
    meta: {
      keepAlive: false,
      title: '发票详情',
      showHeader: false,
    }
  },
  {
    path: '/invoiceAtRed',
    name: 'invoiceAtRed',
    component: () => import('@/viewsToB/invoiceManage/invoiceEdit/atRed.vue'),
    meta: {
      keepAlive: false,
      title: '发票冲红',
      showHeader: true,
    }
  },
]

export default invoiceManage
