const synWorkbench = [
  {
    path: '/synWorkbench',
    name: 'synWorkbench',
    component: () => import(
      '@/views/synWorkbench/index'),
    meta: {
      keepAlive: true,
      title: '三翼鸟工作台',
      showHeader: false,
    },
  },
  {
    path: '/openAccount',
    name: 'openAccount',
    component: () => import(
      '@/views/synWorkbench/openAccount'),
    meta: {
      keepAlive: true,
      title: '平台开户',
      showHeader: false,
    },
  },
]

export default synWorkbench
