import utils from '@/utils/utils'
export default {
  namespaced: true,
  state: {
    shopInfo: utils.strJSONParse(sessionStorage.getItem('sg_shop_store_info') || {} ), //店铺相关信息
    shopActivityInfo: utils.strJSONParse(sessionStorage.getItem('sg_shop_activity_info')|| {}), //店铺活动信息
  },
  getters: {
    getShopInfo(state) {
      return state.shopInfo
    },
    getShopActivityInfo(state){
      return state.shopActivityInfo
    }
  },
  mutations: {
    setShopInfo(state, info) {
      let result = {
        ...state.shopInfo,
        ...info
      }
      sessionStorage.setItem('sg_shop_store_info',JSON.stringify(result))
      state.shopInfo = result
    },
    setShopActivityInfo(state, info) {
      let result = {
        ...state.shopActivityInfo,
        ...info
      }
      sessionStorage.setItem('sg_shop_activity_info',JSON.stringify(result))
      state.shopActivityInfo = result
    },
  },
}
