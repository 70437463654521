const systemPermissionManage = [
  {
    path: '/systemPermissionManage',
    name: 'systemPermissionManage',
    component: () => import('@/viewsToB/systemPermissionManage/index.vue'),
    meta: {
      title: '系统权限管理',
      keepAlive: false,
      showTabbar: false,
      showHeader: true,
    }
  }
]

export default systemPermissionManage
