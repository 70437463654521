
const orderManage = [
  {
    path: '/orderIndex',
    name: 'orderIndex',
    component: () => import('@/viewsToB/orderManage/orderIndex/index.vue'),
    meta: {
      keepAlive: false,
      title: '  订单管理',
      showHeader: true,
    }
  },
  {
    path: '/orderListToB',
    name: 'orderListToB',
    component: () => import('@/viewsToB/orderManage/orderListToB/index.vue'),
    meta: {
      keepAlive: true,
      title: '订单列表',
      showHeader: false,
    }
  },
  {
    path: '/orderDetailToB',
    name: 'orderDetailToB',
    component: () => import('@/viewsToB/orderManage/orderDetailToB/index.vue'),
    meta: {
      keepAlive: false,
      title: '订单详情',
      showHeader: false,
    }
  },
  {
    path: '/orderSearchToB',
    name: 'orderSearchToB',
    component: () => import('@/viewsToB/orderManage/components/search.vue'),
    meta: {
      keepAlive: true,
      title: '订单搜索',
      showHeader: false,
    }
  },
  {
    path: '/orderRepairsToB',
    name: 'orderRepairsToB',
    component: () => import('@/viewsToB/orderManage/orderRepairsToB/index.vue'),
    meta: {
      keepAlive: true,
      title: '售后订单',
      showHeader: false,
    }
  },
  {
    path: '/repairsIndex',
    name: 'repairsIndex',
    component: () => import('@/viewsToB/orderManage/orderDetailToB/repairsIndex.vue'),
    meta: {
      keepAlive: false,
      title: '售后详情',
      showHeader: false,
    }
  },
  {
    path: '/returnDetail',
    name: 'returnDetail',
    component: () => import('@/viewsToB/orderManage/orderDetailToB/returnDetail.vue'),
    meta: {
      keepAlive: false,
      title: '退款详情',
      showHeader: true,
    }
  },
  {
    path: '/workOrder',
    name: 'workOrder',
    component: () => import('@/viewsToB/orderManage/workOrder/index.vue'),
    meta: {
      keepAlive: true,
      title: '工单管理',
      showHeader: false,
    }
  },
  {
    path: '/workDetail',
    name: 'workDetail',
    component: () => import('@/viewsToB/orderManage/orderDetailToB/workDetail.vue'),
    meta: {
      keepAlive: false,
      title: '工单详情',
      showHeader: false,
    }
  },
  {
    path: '/storeOrderListToB',
    name: 'storeOrderListToB',
    component: () => import('@/viewsToB/orderManage/storeOrderListToB/index.vue'),
    meta: {
      keepAlive: false,
      title: '直销员商场订单',
      showHeader: false,
    }
  },
  {
    path: '/aloneAudit',
    name: 'aloneAudit',
    component: () => import('@/viewsToB/orderManage/storeOrderListToB/audit/aloneAudit.vue'),
    meta: {
      keepAlive: false,
      title: '商场订单审核',
      showHeader: false,
    }
  },
  {
    path: '/batchAudit',
    name: 'batchAudit',
    component: () => import('@/viewsToB/orderManage/storeOrderListToB/audit/batchAudit.vue'),
    meta: {
      keepAlive: false,
      title: '商场订单批量审核',
      showHeader: false,
    }
  },
  {
    path: '/orderOccupyStock',
    name: 'orderOccupyStock',
    component: () => import('@/viewsToB/orderManage/orderOccupyStock/index.vue'),
    meta: {
      keepAlive: false,
      title: '占库发货',
      showHeader: true,
    }

  },
  {
    path: '/orderReverseAgentUser',
    name: 'orderReverseAgentUser',
    component: () => import('@/viewsToB/orderManage/orderReverseAgentUser/index.vue'),
    meta: {
      keepAlive: false,
      title: '代申请售后',
      showHeader: false,
    }
  },
]

export default orderManage
