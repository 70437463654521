import exclusivePrice from './exclusivePrice'
import common from './common'
import cida from './cida'

//商机二维码录入
import businessOpportunity from './businessOpportunity'
// 今日任务
import dailyTask from './dailyTask'
import partner from './partner'
import phoneManage from './phoneManage'
import settings from './settings'
import mobileWorkStationPlatformPartners from './mobileWorkStationPlatformPartners'
import shopPartners from './shopPartners'
import mobileWorkStationMessage from './mobileWorkStationMessage'
import shoppingGuide from './shoppingGuide'
import orderManage from './orderManage'
import shopData from './shopData'

import balance from './balance'
import finance from './finance'
import walletGift from './walletGift'

import storePartners from './storePartners'
import partnersUnbound from './partnersUnbound'

// 合伙人管理
import partnerManger from './partnerManager'

// 天网佣金设置
import skyNetCommissionSet from './skyNetCommissionSet'

import revenue from './revenue'
// 店铺雷达
import storeRadar from './storeRadar'
import goodsManage from './goodsManage'
import invoiceManage from './invoiceManage'

import contract from './contract'

// 交互师
import interactionDesigner from './interactionDesigner'

// 海尔卡券
import cardQuan from './cardQuan/index'
// 邀请入会
import vipInvite from './vipInvite'
// 常用二维码列表
import qrCodeList from './qrCodeList'
// 意向派单
import intendedAssignOrder from './intendedAssignOrder'
// 快下单
import quickPlaceOrder from './quickPlaceOrder'
import sceneLight from './sceneLight'

// 三翼鸟平台合伙人
import synPartners from './synPartners'
// 三翼鸟工作台 系统权限管理
import systemPermissionManage from './systemPermissionManage'

export default [
  ...businessOpportunity,
  ...common,
  ...cida,
  ...exclusivePrice,
  ...dailyTask,
  ...mobileWorkStationPlatformPartners,
  ...shopPartners,
  ...mobileWorkStationMessage,
  ...phoneManage,
  ...settings,
  ...partner,
  ...cardQuan,
  ...shoppingGuide,
  ...orderManage,
  ...balance,
  ...finance,
  ...walletGift,
  ...shopData,
  ...storePartners,
  ...partnersUnbound,
  ...partnerManger,
  ...skyNetCommissionSet,
  ...revenue,
  ...storeRadar,
  ...goodsManage,
  ...interactionDesigner,
  ...invoiceManage,
  ...vipInvite,
  ...qrCodeList,
  ...intendedAssignOrder,
  ...quickPlaceOrder,
  ...sceneLight,
  ...contract,
  ...synPartners,
  ...systemPermissionManage,
]
