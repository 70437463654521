export enum PaySourceID {
  UPLUSAPP = '10000001', //智家APP 
  SYBIRDAPP = '10000007', //三翼鸟APP
  SHOPKEEPERAPP = '10000012', // 工作台APP
  H5 = '10000005', //普通H5
  ISERVICE = '10000014' ,//爱服务
  QUICKMONEY = '10000015', //块钱
  USERMANAGER = '10000016', //用户管家
  HAIERNEIGOU = '10000017', //海尔内购
  WXGONGZHONGHAO = '10000006', // 微信公众号
}