const designerChat = [
  {
    path: '/activeInteractionDesignerChat',
    name: 'activeInteractionDesignerChat',
    component: () => import('@/viewsToB/interactionDesigner/activeChat.vue'),
    meta: {
      title: '开通聊天功能',
      keepAlive: false,
      needLogin: true,
      awaitlogin: true,
      showTabbar: false,
    }
  },
]
export default designerChat
