import URL from '@/http/url'
import { post } from '@/http/http'
import { Toast } from 'vant'
import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    orderRightInfos: [], // 权益信息
    selectedRights: [], // 选择的权益
    proInfo: [], // 商品信息
    giveupStatus: false, // 是否放弃权益
    isExclusive: false, // 是否是专属价提交订单
    hasNewAddress: false, // 结算页是否手动选择了其他收货地址
    addressInfo: {} // 切换的收货地址
  },
  getters: {
    getOrderRightInfos(state) {
      return state.orderRightInfos || []
    },
    getSelectedRights(state) {
      return state.selectedRights
    },
    getOrderProInfo(state) {
      return state.proInfo
    },
    getGiveupStatus(state) {
      return state.giveupStatus
    },
    getExclusiveStatus(state) {
      return state.isExclusive
    }
  },
  mutations: {
    setNewAddress(state, val) {
      state.hasNewAddress = val
    },
    setAddressInfo(state, val) {
      state.addressInfo = val
    },
    setOrderRightInfos(state, payload) {
      payload.map(item => {
        Vue.set(item, 'showDesc', false) // 手动添加数据双向绑定
      })
      state.orderRightInfos = payload
    },
    /**
     * @description 过滤所选权益对应的礼物
     * @param {*} state
     */
    setSelectedRights(state) {
      // state.selectedRights =
      let _selected = []
      state.orderRightInfos.map(item => {
        if (item.selectedGiftDescList) {
          _selected = _selected.concat(item.selectedGiftDescList)
        }
      })
      state.selectedRights = _selected
    },
    setOrderProInfo(state, payload) {
      state.proInfo = payload
    },
    setGiveupStatus(state, payload) {
      state.giveupStatus = payload
    },
    /**
     * @description 显示或隐藏权益描述
     * @param {*} state
     * @param {*} payload
     */
    showDesc(state, payload) {
      state.orderRightInfos.map(item => {
        if (item.rightsNo === payload.rightsNo) {
          item.showDesc = !payload.showDesc
        }
      })
    },
    setExclusiveStatus(state, payload) {
      state.isExclusive = payload
    }
  },
  actions: {
    /**
     * @description 选择或取消权益
     */
    selectOrderRights({ commit }, params) {
      return new Promise((resolve, reject) => {
        post(URL.selectOrderRights, params).then((res) => {
          if (res.success) {
            commit('setOrderRightInfos', res.data || [])
            resolve(true)
          } else {
            Toast(res.message)
            resolve(false)
          }
        }).catch((err) => {
          reject(err)
        })
      })
    }
  }
}
