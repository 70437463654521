import Url from '../url.js';
import axios from '../http';
import utils from '../../utils/utils.js';
import config from '@/config'
/**
 *
 * @param {*} options
 * clientAgent	客户端Agent，非必填，如不传则从header里获取user-agent	string
 * redirectUri	目标系统url	string
 * redirectUriTest	(测试环境)目标系统url	string
 * target	目标系统	string
 * targetTest	(测试环境)目标系统	string
 */
export const getUcLoginUrl = options => axios.get(Url.getUcLoginUrl, {
  params: {
    // clientAgent: options.clientAgent || '',
    redirectUri: !utils.isProdEnv() ? options.redirectUriTest : options.redirectUri,
    target: !utils.isProdEnv() ? options.targetTest : options.target,
  }
})


export const getGlobalTipsMsg = (childMenuId) => axios.get(Url.getGlobalTipsMsg, {
  params: {
    childMenuId,
  }
})

//根据经纬度获取四级地址 params: { latitude: xxx, longitude: xxx }
export const getAddressLocationInfo = (params) => axios.get(Url.GET_LOCATION, {
  params
})

//公域端生成小程序太阳码
export const getClientSunCode = (data, headers) => axios.post(Url.GET_CLIENT_SUN_CODE, data, {
  baseURL: config.UPLUSHOST,
  headers,
})