export default {
  namespaced: true,
  state: {
    tempInvoice: null
  },
  mutations: {
    setTempInvoice(state, info) {
      state.tempInvoice = info
    }
  },
}
