export default {
  namespaced: true,
  /**
   * prizeAddress : {id, name, mobile, address}
   */
  state: {
    prizeAddress: null
  },
  getters: {
    getPrizeAddress(state) {
      return state.prizeAddress || null
    },
  },
  mutations: {
    setPrizeAddress(state, payload) {
      state.prizeAddress = payload
    }
  },
}
