import { getCateList } from '@/http/service/rankList.js';
export default {
  namespaced: true,
  state: {
    catesList: [],
  },
  mutations: {
    save(state, params) {
      state.catesList = params
    },
  },
  getters: {
    catesList: state => state.catesList,
  },
  actions: {
    async getNavigationCates({ commit }) {
      try {
        const res = await getCateList({ parentId: 0 });
        if (res.data.success) {
          const catesList = [];
          !!res.data.data && res.data.data.map(item => {
            let catesId = [];
            if (item.url && item.url.includes('productCateId=')) {
              catesId = item.url.replace('productCateId=', '').split(';');
              catesList.push({
                ...item,
                catesId
              });
            }
          });
          console.log('类目：', catesList);
          commit('save', catesList);
        }
      } catch (error) {
        console.log('获取分类错误');
      }
    },

  }
}
