const phoneManage = [
  {
    path: '/phoneManage',
    name: 'phoneManage',
    component: () => import('@/viewsToB/settings/phoneManage/index.vue'),
    meta: {
      title: '手机号管理',
      showHeader: false,
      keepAlive: true,
      showTabbar: false,
    }
  },
  {
    path: '/changePhone',
    name: 'changePhone',
    component: () => import('@/viewsToB/settings/phoneManage/changePhone.vue'),
    meta: {
      title: '更换手机号',
      keepAlive: true,
      showTabbar: false,
    }
  },
  {
    path: '/unbindPhone',
    name: 'unbindPhone',
    component: () => import('@/viewsToB/settings/phoneManage/unbindPhone.vue'),
    meta: {
      title: '解绑手机号',
      keepAlive: true,
      showTabbar: false,
    }
  },
]

export default phoneManage
