const gift = [{
  path: '/gift',
  name: 'gift',
  component: () => import('@/views/gift/index.vue'),
  meta: {
    title: '体验产品领取',
    showHeader: false,
    needLogin: false,
    showTabbar: false,
  }
}, {
  path: '/gift-success',
  name: 'gift-success',
  component: () => import('@/views/gift/success.vue'),
  meta: {
    title: '体验产品领取',
    needLogin: false,
    showTabbar: false,
    showHeader: false,
  }
}]

export default gift
