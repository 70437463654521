const loginReg = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/loginReg/login.vue'),
    meta: {
      title: '登录',
      keepAlive: true,
      showTabbar: false,
      showHeader: false,
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/loginReg/registerView.vue'),
    meta: {
      title: '注册',
      keepAlive: false,
      showTabbar: false,
    }
  },
  {
    path: '/retrieve',
    name: 'retrieve',
    component: () => import('@/views/loginReg/retrieve.vue'),
    meta: {
      title: '找回密码',
      keepAlive: false,
      showTabbar: false,
    }
  },
  {
    path: '/ssoAuth',
    name: 'ssoAuth',
    component: () => import('@/views/loginReg/ssoAuth'),
    meta: {
      showHeader: false,
      showTabbar: false,
    }
  },
  {
    path: '/registerSuccess',
    name: 'registerSuccess',
    component: () => import('@/views/loginReg/register-success.vue'),
    meta: {
      title: '注册成功',
      keepAlive: false,
      showHeader: false,
      showTabbar: false,
    }
  },
  {
    path: '/appHelp',
    name: 'appHelp',
    component: () => import('@/views/appHelp/index.vue'),
    meta: {
      title: '隐私权政策',
    }
  },
  {
    path: '/awaitlogin',
    name: 'awaitlogin',
    component: () => import('@/views/loginReg/awaitlogin.vue'),
    meta: {
      title: '',
      keepAlive: false,
      showHeader: false,
      showTabbar: false,
    }
  },
]

export default loginReg
