export default [
  {
    path: '/cancelAccount',
    name: 'cancelAccount',
    component: () => import('@/viewsToB/settings/cancelAccount'),
    meta: {
      showHeader: false,
      showTabbar: false,
      title: '账号注销',
    },
  },
]
