const messageDetail = [
  {
    // path: '/messageDetail/:id/:relationId',
    path: '/messageDetail/:id',
    name: 'messageDetail',
    component: () => import('@/views/messageDetail/index.vue'),
    meta: {
      keepAlive: false,
      title: '平台消息',
      showTabbar: false,
      needLogin: true,
    },
  },
]

export default messageDetail
