import Url from '../url.js'
import http from '../http'
import config from '@/config'

interface PromiseResult<T extends object> {
  success: boolean,
  data: T | null,
  message: string | null,
  [propNames: string]: any
}


/**
 * 获取虚拟资产信息
 */
interface BenefitInfo {
  benefitAccounts: Array<{
    benefitType?: string,
    count?: number,
    proportion?: string
    quota?: string,
    billing?: string,
    paymentSettle?: string,
    commisionSettle?: string,
    icon?: string,
    useCountMax?: number
  }>
}
export const getBenefitInfo = async ():Promise<PromiseResult<BenefitInfo>> => {
  try {
    const result = await http.post(Url.getBenefitInfo)
    return result.data
  } catch (e) {
    console.log(e)
  }
}


/**
 * 获取虚拟资产信息
 */
interface UseBenefit {
  benefitType?: string,
  count?: number,
  amt?:string,
  p: string,
  bids: Array<string>,
  useCountMax: number
}
export const doUseBenefit = async (params: {
  use?: boolean,
  benefit: UseBenefit
}):Promise<PromiseResult<{createOrderBenefitVO: UseBenefit}>> => {
  try {
    const result = await http.post(Url.useBenefit, params)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

//获取可选门店
export const getCanChoiceMdList = async() => {
  try {
    const result = await http.get(Url.getCanChoiceMdList)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

// 交付类型
export const setchoiceDeliverType = async({deliverType, opUuid}) => {
  try {
    const result = await http.post(Url.setchoiceDeliverType + `?deliverType=${deliverType}&opUuid=${opUuid}`)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

export const saveOfflineAmount = async(params: {
  offlineAmount: string,
  opUuid: string
})=>{
  try {
    const result = await http.get(Url.saveOfflineAmount, {params})
    return result.data
  } catch (e) {
    console.log(e)
  }
}

export const useBenefitNew = async (data: any) => {
  try {
    const result = await http.post(Url.useBenefitNew, data)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

export const updateProductNum = async (data: any, config: any) => {
  try {
    const result = await http.post(Url.updateProductInfo, data, config)
    return result.data
  } catch (e) {
    console.log(e)
  }
}


export const getCommunityInfo = async (): Promise<PromiseResult<[{id:string, name:string}]>>  => {
  try {
    const result = await http.post(Url.getCommunityInfo)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

export const saveVillageInfo = async (data:any) => {
  try {
    const result = await http.post(Url.saveVillageInfo,data)
    return result.data
  } catch (e) {
    console.log(e)
  }
}
// 进入结算页接口 (创建订单)
export const handleUnoccupiedStock = async(params: {
  handleFlag: number,
  orderProductUuids: string[]
}):Promise<any> => {
  try {
    const result = await http.post(Url.handleUnoccupiedStock, params)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

// 获取待核销柜电套餐订单
export const getUnverifiedGdtcOrder = async (params: {mobile: string, pageNum: number, pageSize: number}) :Promise<any> => {
  try {
    const result = await http.get(Url.getUnverifiedGdtcOrder, {
      params,
      baseURL: config.OHOST,
    })
    return result.data
  } catch (e) {
    console.log(e)
  }
}

// 验证订单是否能核销
export const verifiedGdtcOrder = async (params: { corderSn: string }) :Promise<any> => {
  try {
    const result = await http.get(Url.verifiedGdtcOrder, {params})
    return result.data
  } catch (e) {
    console.log(e)
  }
}

// 获取柜电套餐详情
export const getOrderProductGdtc = async (params: { corderSn: string }) :Promise<any> => {
  try {
    const result = await http.get(Url.getOrderProductGdtc, {
      params,
      baseURL: config.OHOST,
    })
    return result.data
  } catch (e) {
    console.log(e)
  }
}


// 下单类型切换
export const handleOrderPlace = async (data):Promise<any> => {
  try {
    const result = await http.post(Url.handleOrderPlace, data)
    return result.data
  } catch (e) {
    console.log(e)
  } 
}

// 分阶段信息
export const submitOrderStagePay = async (data):Promise<any> => {
  try {
    const result = await http.post(Url.submitOrderStagePay, data)
    return result.data
  } catch (e) {
    console.log(e)
  }
}


// 查询抖音券
export const queryDetailAndCheckUseByTitokCode = async (data):Promise<any> => {
  try {
    const result = await http.post(Url.queryDetailAndCheckUseByTikTokCode + `?couponCode=${data.couponCode}`)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

// 查询可用或不可用抖音券列表
export const getCanOrNotUseTitokCouponList = async (data):Promise<any> => {
  try {
    const result = await http.post(Url.getCanOrNotUseCouponList, data)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

// 选择抖音券
export const choiceTikTokCoupon = async (data):Promise<any> => {
  try {
    const result = await http.post(Url.choiceTikTokCoupon + `?choiceCouponCodes=${data.choiceCouponCodeList}`)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

// 修改抖音券
export const changeTikTokCoupon = async (data):Promise<any> => {
  try {
    const result = await http.post(Url.changeTikTokCoupon + `?changeCouponCode=${data.changeCouponCode}&choicedCouponCodes=${data.choicedCouponCodes}`)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

//  带单人查询
export const getYlhDist = async (data):Promise<any> => {
  try {
    const result = await http.post(Url.getYlhDist, data)
    return result.data
  } catch (e) {
    console.log(e)
  }
}


// 修改带单人
export const changeYlhDist = async (data):Promise<any> => {
  try {
    const result = await http.post(Url.changeYlhDist, data)
    return result.data
  } catch (e) {
    console.log(e)
  }
}
