export default {
  namespaced: true,
  state: {
    curEcardPrice: 0, //当前E卡可抵扣的金额
    userSelectEcardNo: [], //当前选择的E卡 smartId
  },
  mutations: {
    setCurEacrdPrice(state, price){
      state.curEcardPrice = price
    },
    setAllSelectEcard(state, cardNos){
      state.userSelectEcardNo = [...cardNos]
    },
    setUserSelectEcardNo(state, cardNo){
      if(!state.userSelectEcardNo.includes(cardNo)){
        state.userSelectEcardNo.push(cardNo)
      }
    },
    setUserUnselectEcardNo(state, cardNo){
      let index = state.userSelectEcardNo.findIndex(item => item === cardNo)
      state.userSelectEcardNo.splice(index, 1)
    }
  },
}