const recharge = [
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('@/views/recharge'),
    meta: {
      showHeader: false,
      title: '充值中心',
    }
  },
  {
    path: '/rechargelist',
    name: 'rechargelist',
    component: () => import('@/views/recharge/rechargelist'),
    meta: {
      title: '充值记录',
    }
  },
  {
    path: '/rechargedetail',
    name: 'rechargedetail',
    component: () => import('@/views/recharge/rechargedetail'),
    meta: {
      title: '订单详情',
    }
  },
  {
    path: '/rechargeresult',
    name: 'rechargeresult',
    component: () => import('@/views/recharge/rechargeresult'),
    meta: {
      title: '支付结果',
    }
  }
]

export default recharge
