const goodsRight = [
  {
    path: '/searchGoodsRight',
    name: 'searchGoodsRight',
    component: () => import('@/views/goodsRight/search/index.vue'),
    meta: {
      title: '权益查询',
      showHeader: false,
      needLogin: true
    },
  },
  {
    path: '/updateGoodsRight',
    name: 'updateGoodsRight',
    component: () => import('@/views/goodsRight/update/index.vue'),
    meta: {
      title: '权益补录',
      showHeader: true,
      needLogin: true
    },
  }
]

export default goodsRight
