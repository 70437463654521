
const storeRadar = [
  {
    path: '/userKanban',
    name: 'userKanban',
    component: () => import('@/viewsToB/storeRadar/userKanban/index.vue'),
    meta: {
      keepAlive: false,
      title: '数据看板',
      showHeader: true,
    }
  },
  {
    path: '/userList',
    name: 'userList',
    component: () => import('@/viewsToB/storeRadar/userList/index.vue'),
    meta: {
      keepAlive: true,
      title: '用户列表',
      showHeader: false,
    }
  },
  {
    path: '/messageTemplate',
    name: 'messageTemplate',
    component: () => import('@/viewsToB/storeRadar/messageTemplate/index.vue'),
    meta: {
      keepAlive: false,
      title: '设置消息模板',
      showHeader: true,
    }
  },
  {
    path: '/userNews',
    name: 'userNews',
    component: () => import('@/viewsToB/storeRadar/userNews/index.vue'),
    meta: {
      keepAlive: false,
      title: '发送消息',
      showHeader: true,
    }
  },
  {
    path: '/userSearchToB',
    name: 'userSearchToB',
    component: () => import('@/viewsToB/storeRadar/components/search.vue'),
    meta: {
      keepAlive: true,
      title: '用户搜索',
      showHeader: false,
    }
  },
  {
    path: '/userDetail',
    name: 'userDetail',
    component: () => import('@/viewsToB/storeRadar/userDetail/index.vue'),
    meta: {
      keepAlive: false,
      title: '用户明细',
      showHeader: true,
    }
  },
  {
    path: '/realTime',
    name: 'realTime',
    component: () => import('@/viewsToB/storeRadar/realTime/index.vue'),
    meta: {
      keepAlive: false,
      title: '实时数据',
      showHeader: false,
    }
  },
  {
    path: '/pageTransfer',
    name: 'pageTransfer',
    component: () => import('@/viewsToB/storeRadar/components/pageTransfer.vue'),
    meta: {
      keepAlive: false,
      title: '中转页',
      showHeader: false,
    }
  },
]

export default storeRadar
