const shopPartners = [
  {
    path: '/shopPartners',
    name: 'shopPartners',
    component: () => import('@/viewsToB/shopPartners/index.vue'),
    meta: {
      title: '合伙人管理',
      keepAlive: true,
      showTabbar: false,
      showHeader: true,
      needLogin: true,
      awaitlogin: true,
    }
  },
  {
    path: '/shopPartners/add',
    name: 'shopPartnersAdd',
    component: () => import('@/viewsToB/shopPartners/index-add.vue'),
    meta: {
      title: '',
      keepAlive: true,
      showTabbar: false,
      showHeader: false,
      needLogin: true,
      awaitlogin: true,
    }
  },
]

export default shopPartners
