import { Dialog } from 'vant'
import { getUserSig, updateDeviceToCloud } from '../../http/service/chat'
import utils from '@/utils/utils'
import UplusApi from '@uplus/uplus-api';

const instance = new UplusApi()
instance.initDeviceReady()
let fetchLogin = false // 防止重复登录
let hasDeviceRecord = false // 已保存过的设备id的话则不会再调接口了， 除非登录行为触发

const ON_SDK_CHANGE = (commit, status) => {
  commit('setTimStatus', status)
}

const ON_KICKED_OUT = (commit, dispatch) => {
  commit('setTimStatus', false)
  Dialog.alert({
    title: '提示',
    message: '您的账号已在其他设备登录，请重新登录。',
  }).then(() => {
    dispatch('timLogin').then(res => {
      location.reload()
    }).catch(e => console.log(e))
  })
}

const ON_SDK_ERROR = (err) => {
  throw new Error(err)
}

export default {
  namespaced: true,
  state: {
    isTimLogin: false,
    conversationList: [],
    currentConversationId: null,
    messageList: []
  },
  mutations: {
    setTimStatus(state, val) {
      state.isTimLogin = val
    },
    updateConversationList(state, val) {
      val.sort((a, b) => {
        const timeB = b.lastMsgTime || b.lastMessage.lastTime
        const timeA = a.lastMsgTime || a.lastMessage.lastTime
        return timeB - timeA
      })
      state.conversationList = val
    },
    setCurrentConversationId(state, id) {
      state.currentConversationId = id
    },
    updateMessageList(state, val) {
      state.messageList = val
    },
    setMessageSendStatus(state, val) {
      state.messageList.forEach(element => {
        if (val.ID === element.ID) {
          Object.assign(element, val)
        }
      });
    },
  },
  actions: {
    async timLogin({ rootGetters, state, dispatch }) {
      if (!fetchLogin && !state.isTimLogin && rootGetters.mId) {
        if (utils.isShopKeeper() || utils.isUplus()) dispatch('updateDeviceToCloud', true)
        fetchLogin = true
        try {
          const { data } = await getUserSig(rootGetters.mId)
          if (data?.success && data?.data) {
            return window.$tim.login({
              userID: `${rootGetters.mId}`,
              userSig: data.data
            }).then(res => {
              fetchLogin = false
              return Promise.resolve(res)
            })
          } else {
            fetchLogin = false
            // Toast(data.message)
            return Promise.resolve(data)
          }
        } catch (err) {
          fetchLogin = false
          throw new Error(err)
        }
      }
    },
    async timLogout({ state }) {
      if (state.isTimLogin) window.$tim.logout()
    },
    updateDeviceToCloud(context, fromLogin) {
      if (hasDeviceRecord && !fromLogin) return
      hasDeviceRecord = true
      let terminal = utils.isShopKeeper() ? 3 : 2
      console.log('updateDeviceToCloud')
      instance.initDeviceReady().then(() => {
        instance.upAppinfoModule.getAppInfo().then(({ retData }) => {
          if (retData?.clientId) {
            updateDeviceToCloud({
              device_token: retData.clientId,
              device_type: utils.isIos() ? 'ios' : 'android',
              terminal
            })
          }
        }).catch(e => { console.log(e) });
      }).catch(e => { console.log(e) });
    },
    initListener({ commit, dispatch }) {
      // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
      window.$tim.on(window.$TIM.EVENT.SDK_READY, ON_SDK_CHANGE.bind(null, commit, true))
      // IM当前端被踢掉
      window.$tim.on(window.$TIM.EVENT.KICKED_OUT, ON_KICKED_OUT.bind(null, commit, dispatch))
      // SDK NOT READT
      window.$tim.on(window.$TIM.EVENT.SDK_NOT_READY, ON_SDK_CHANGE.bind(null, commit, false))
      // SDK内部出错
      window.$tim.on(window.$TIM.EVENT.ERROR, ON_SDK_ERROR)
    },
    stopListener() {
      // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
      window.$tim.off(window.$TIM.EVENT.SDK_READY, ON_SDK_CHANGE)
      // IM当前端被踢掉
      window.$tim.off(window.$TIM.EVENT.KICKED_OUT, ON_KICKED_OUT)
      // SDK NOT READT
      window.$tim.off(window.$TIM.EVENT.SDK_NOT_READY, ON_SDK_CHANGE)
      // SDK内部出错
      window.$tim.off(window.$TIM.EVENT.ERROR, ON_SDK_ERROR)
      // 销毁IM实例
      window.$tim.destroy()
    }
  }
}
