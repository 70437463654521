import Url from '../url.js';
import axios from '../http';
import config from '@/config'

export const updateDeviceToCloud = (params) => axios.get(Url.updateDeviceToCloud, {
  params,
  noLogin: true
})

export const getUserSig = () => axios.get(Url.getUserSig, { noLogin: true })

export const getConversationList = (params) => axios.get(Url.getConversationList, { params })

export const getUserSessionMaterial = (data) => axios.post(Url.userSessionMaterial, data)

export const getMessageList = (params) => axios.get(Url.getMessageList, { params })

export const getGoodsGuide = (params) => axios.get(Url.getGoodsGuide, { params })

export const autoProductMsg = (data) => axios.post(`${Url.autoProductMsg}?time=${new Date().getTime()}`, data)

export const getStoreGuide = (params) => axios.get(Url.getStoreGuide, { params })

export const getGuideProfile = (params) => axios.get(Url.getGuideProfile, { params })

export const uploadImg = (data) => axios.post(Url.uploadImg, data, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

export const activateGuide = (data) => axios.post(Url.activateGuide, data)

export const guideLevel = () => axios.get(Url.guideLevel)

export const getMemberPerformance = (params) => axios.get(Url.getMemberPerformance, {
  params,
  baseURL: config.OHOST
})

export const getGuideOrderList = (data) => axios.post(Url.myOrderUrlNew, data, {
  baseURL: config.OHOST
})

export const getGuideOrderDetail = (data) => axios.post(Url.getGuideOrderDetail, data, {
  baseURL: config.OHOST
})

export const getShoppingGuideConfig = (params) => axios.get(Url.getShoppingGuideConfig, { params })

export const generateExclusivePrice = (data) => axios.post(Url.generateExclusivePrice, data)

export const setUpWechatNumber = (params) => axios.get(Url.setUpWechatNumber, { params })

export const getDesignerInfo = () => axios.get(Url.getDesignerInfo)

export const activateIM = (data) => axios.post(Url.activateIM, data)

export const checkActivated = () => axios.get(Url.checkActivated, {})
