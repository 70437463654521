const mobileWorkStationPlatformPartners = [
  {
    path: '/platformPartners',
    name: 'platformPartners',
    component: () => import('@/viewsToB/mobileWorkStationPlatformPartners/index.vue'),
    meta: {
      title: '平台合伙人管理',
      keepAlive: true,
      showTabbar: false,
      showHeader: false,
      needLogin: true,
      awaitlogin: true,
    }
  },
  {
    path: '/platformPartners/level',
    name: 'platformPartnersLevel',
    component: () => import('@/viewsToB/mobileWorkStationPlatformPartners/index-level.vue'),
    meta: {
      title: '等级规则',
      keepAlive: false,
      showTabbar: false,
      showHeader: false,
      needLogin: true,
      awaitlogin: true,
    }
  },
  {
    path: '/platformPartners/find',
    name: 'platformPartnersFind',
    component: () => import('@/viewsToB/mobileWorkStationPlatformPartners/index-find.vue'),
    meta: {
      title: '平台合伙人管理-查询',
      keepAlive: true,
      showTabbar: false,
      showHeader: false,
      needLogin: true,
      awaitlogin: true,
    }
  },
  {
    path: '/platformPartners/findResult',
    name: 'platformPartnersFindResult',
    component: () => import('@/viewsToB/mobileWorkStationPlatformPartners/index-find-result.vue'),
    meta: {
      title: '平台合伙人管理-查询结果',
      keepAlive: false,
      showTabbar: false,
      showHeader: false,
      needLogin: true,
      awaitlogin: true,
    }
  },
  {
    path: '/platformPartners/indexDataAnalysis',
    name: 'platformPartnersIndexDataAnalysis',
    component: () => import('@/viewsToB/mobileWorkStationPlatformPartners/index-data-analysis.vue'),
    meta: {
      title: '平台合伙人管理',
      keepAlive: false,
      showTabbar: false,
      showHeader: false,
      needLogin: true,
      awaitlogin: true,
    }
  },
  {
    path: '/platformPartners/:memberId',
    name: 'platformPartnersDetail',
    component: () => import('@/viewsToB/mobileWorkStationPlatformPartners/partners-detail.vue'),
    meta: {
      title: '合伙人详情',
      keepAlive: false,
      showTabbar: false,
      showHeader: false,
      needLogin: true,
      awaitlogin: true,
    }
  },
  {
    path: '/platformPartners/:memberId/order',
    name: 'platformPartnersDetailOrder',
    component: () => import('@/viewsToB/mobileWorkStationPlatformPartners/partners-detail-order.vue'),
    meta: {
      title: '订单明细',
      keepAlive: false,
      showTabbar: false,
      showHeader: false,
      needLogin: true,
      awaitlogin: true,
    }
  },
  {
    path: '/platformPartners/:memberId/data',
    name: 'platformPartnersDetailData',
    component: () => import('@/viewsToB/mobileWorkStationPlatformPartners/partners-detail-data.vue'),
    meta: {
      title: '数据分析',
      keepAlive: false,
      showTabbar: false,
      showHeader: false,
      needLogin: true,
      awaitlogin: true,
    }
  },
  {
    path: '/platformPartnersSetting',
    name: 'mobileWorkStationPlatformPartnersSetting',
    component: () => import('@/viewsToB/mobileWorkStationPlatformPartnersSetting/index.vue'),
    meta: {
      title: '平台合伙人信息设置',
      keepAlive: false,
      showTabbar: false,
      showHeader: true,
      needLogin: true,
      awaitlogin: true,
    }
  },
  {
    path: '/platformPartnersCert',
    name: 'mobileWorkStationPlatformPartnersCert',
    component: () => import('@/viewsToB/mobileWorkStationPlatformPartnersCert/cert-step-I.vue'),
    meta: {
      title: '员工认证',
      keepAlive: false,
      showTabbar: false,
      showHeader: true,
      needLogin: true,
      awaitlogin: true,
    }
  },
  {
    path: '/platformPartnersCert/certII',
    name: 'mobileWorkStationPlatformPartnersCertII',
    component: () => import('@/viewsToB/mobileWorkStationPlatformPartnersCert/cert-step-II.vue'),
    meta: {
      title: '员工认证',
      keepAlive: false,
      showTabbar: false,
      showHeader: true,
      needLogin: true,
      awaitlogin: true,
    }
  },
  {
    path: '/platformPartnersCert/certIII',
    name: 'mobileWorkStationPlatformPartnersCertIII',
    component: () => import('@/viewsToB/mobileWorkStationPlatformPartnersCert/cert-step-III.vue'),
    meta: {
      title: '员工认证',
      keepAlive: false,
      showTabbar: false,
      showHeader: true,
      needLogin: true,
      awaitlogin: true,
    }
  },
]

export default mobileWorkStationPlatformPartners
