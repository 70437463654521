const bankStyleInfo = {
  '03020000': {
    'img': '1CITIC',
    'background': '#F3515E',
    'name': '中信银行'
  },
  '01030000': {
    'img': '2ABC',
    'background': '#47D08F',
    'name': '农业银行'
  },
  '01020000': {
    'img': '3ICBC',
    'background': '#F3515E',
    'name': '工商银行'
  },
  '01050000': {
    'img': '4CCB',
    'background': '#39A8FF',
    'name': '建设银行'
  },
  '03080000': {
    'img': '5CMB',
    'background': '#F3515E',
    'name': '招商银行'
  },
  '03180000': {
    'img': '6SZPAB',
    'background': '#FF8F24',
    'name': '平安银行'
  },
  '01040000': {
    'img': '7BOC',
    'background': '#F3515E',
    'name': '中国银行'
  },
  '03090000': {
    'img': '8CIB',
    'background': '#39A8FF',
    'name': '兴业银行'
  },
  '03100000': {
    'img': '9SPDB',
    'background': '#39A8FF',
    'name': '浦发银行'
  },
  '03050000': {
    'img': '10CMBC',
    'background': '#47D08F',
    'name': '民生银行'
  },
  '03040000': {
    'img': '11HXB',
    'background': '#F3515E',
    'name': '华夏银行'
  },
  '03030000': {
    'img': '12CEB',
    'background': '#BF70D7',
    'name': '光大银行'
  },
  '01000000': {
    'img': '13PSBC',
    'background': '#47D08F',
    'name': '邮储银行'
  },
  '01009999': {
    'img': '13PSBC',
    'background': '#47D08F',
    'name': '邮储银行'
  },
  '03010000': {
    'img': '14COMM',
    'background': '#39A8FF',
    'name': '交通银行'
  },
  '04031000': {
    'img': '15BCCB',
    'background': '#F3515E',
    'name': '北京银行'
  },
  '03060000': {
    'img': '16GDB',
    'background': '#F3515E',
    'name': '广发银行'
  },
  '03110000': {
    'img': '17EGBANK',
    'background': '#FF8F24',
    'name': '恒丰银行'
  },
  '04233310': {
    'img': '18HCCB',
    'background': '#39A8FF',
    'name': '杭州银行'
  },
  '04083320': {
    'img': '19NBCB',
    'background': '#FF8F24',
    'name': '宁波银行'
  },
  'C1302533000018': {
    'img': 'wsbank',
    'background': '#39A8FF',
    'name': '网商银行'
  },
  'Z2004944000010': {
    'img': 'wxbank',
    'background': '#47D08F',
    'name': '微众'
  },
  // '88888888': {
  //   'img': '20KJT',
  //   'background': 'linear-gradient(90deg, #80B4FF 0%, #4C84FF 97%)',
  //   'name': '快捷通'
  // }
  '88888888': {
    'img': '20KJT',
    'background': '#39A8FF',
    'name': '快捷通'
  },
  'zfb': {
    'img': 'alipay-rd',
    'background': '#2878FF',
    'name': '支付宝'
  }
}

export default {
  namespaced: true,
  state: {
    bankRegInfo: {
      // bankPhone: '',
      // bankCode: '',
      // phone: '',
      // name: '',
      // cardNo: '',
      // identityNo: '',
    },
    bankStyleInfo: bankStyleInfo
  },
  getters: {
    getBankRegInfo: state => state.bankRegInfo,
    getBankStyleInfo: state => state.bankStyleInfo,
  },
  mutations: {
    setBankRegInfo: (state, bankInfo) => {
      state.bankRegInfo = Object.assign({}, bankInfo)
    }
  }
}
