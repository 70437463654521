const invite = [
  {
    path: '/invite',
    name: 'invite',
    component: () => import('@/views/invite/invite.vue'),
    meta: {
      title: '邀请成为合伙人',
      keepAlive: false,
      showHeader: false,
      showTabbar: false,
    }
  },
  {
    path: '/add',
    name: 'add',
    component: () => import('@/views/invite/add.vue'),
    meta: {
      title: '待邀请合伙人',
      needLogin: true,
      keepAlive: false,
      showTabbar: false,
    }
  },
]
export default invite
