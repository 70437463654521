const businessOpportunity = [
  {
    path: '/businessOpportunity',
    name: 'businessOpportunity',
    component: () => import('@/viewsToB/businessOpportunity'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '商机录入',
      needLogin: true,
    },
  },
  {
    path: '/businessOpportunityDetail',
    name: 'businessOpportunityDetail',
    component: () => import('@/viewsToB/businessOpportunity/businessOpportunityDetail.vue'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '代客录入',
      needLogin: true,
    },
  },
  {
    path: '/businessOpportunityResult',
    name: 'businessOpportunityResult',
    component: () => import('@/viewsToB/businessOpportunity/businessOpportunityResult.vue'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '提交成功',
      needLogin: true,
    },
  },
]
export default businessOpportunity
