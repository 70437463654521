<template>
  <div v-if="tipsList?.length" class="float-container" :class="{'active': show}" :style="{'top': tipsTop}" @touchstart="touchStart($event)" @touchmove="touchMove($event)">
    <div @click="toggle" v-if="!show">公告 <svg class="icon-svg icon-more" aria-hidden="true">
      <use xlink:href="#icon-universal_more"></use>
    </svg></div>
    <div class="float-flex" v-if="show">
      <div class="flex-swiper">
        <van-swipe :autoplay="2000" :loop="true" :show-indicators="false" style="height: .3rem" class="float-tips" vertical>
          <van-swipe-item v-for="item in tipsList" :key="item.id" @click="toDetail(item)">
            <div class="item-tips">{{item.title}}</div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <svg @click="toggle" class="icon-svg icon-close" aria-hidden="true">
        <use xlink:href="#icon-universal_close"></use>
      </svg>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect, computed} from 'vue'
import { PlatformTypes } from '@/config/constMaps'
import utils from '@/utils/utils'
import { getGlobalTipsMsg } from '@/http/service/common'
import { useStore } from '@/store'

const store = useStore()
const getCurrentRole = computed(() => store.getters['mymanage/getCurrentRole'])

const tipsTop = ref('1rem')
const touchStart = (event) => {
  tipsTop.value = event.target.getBoundingClientRect().top
  console.log(tipsTop.value)
}
const touchMove = (event) => {
  tipsTop.value = event.targetTouches[0].pageY + 'px'
}

const show = ref(false)
const tipsList = ref([])
const props = defineProps({
  childMenuId: String
})

const fetchData = async(id)=>{
  if(!id) return
  try {
    let {data} = await getGlobalTipsMsg(id)
    tipsList.value = data.result
  } catch (error) {}
}

watchEffect(()=>{
  console.log('props.childMenuId',props.childMenuId)
  fetchData(props.childMenuId)
})

const toggle = () => {
  show.value = !show.value
}

const toDetail = (item) => {
  utils.trackWithVariable({
    platformConf: {
      [PlatformTypes.EG]: {
        id: 'YG20355',
        param: ['click_title', 'click_menuid', 'click_id', 'url', 'role_type']
      },
    },
  }, {
    click_title: '',
    click_menuid: props.childMenuId,
    click_id: item.id,
    url: item.fileUrl,
    role_type: getCurrentRole?.value?.userIdentity
  }).then(res => {
    console.log('gio=====>',res, getCurrentRole.value)
    utils.openNewWindow(item.fileUrl, true, false, true)
  }).catch(()=>{
    utils.openNewWindow(item.fileUrl, true, false, true)
  })
  
}
</script>

<style lang="scss" scoped>
.float-container{
  position: fixed;
  top: 1rem;
  left: 0;
  padding: .16rem .1rem;
  height: .3rem;
  background: $themeSynMain;
  z-index: 99;
  color: #fff;
  font-size: .22rem;
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
  &.active{
    right: 0;
    border-radius: 0;
  }
}
.float-flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.float-tips{
  height: .3rem;
}
.icon-more,.icon-close{
  width: .22rem;
  height: .22rem;
  color: #fff;
}
.item-tips{
  max-width: 7rem;
  height: .3rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>