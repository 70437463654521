export default {
  namespace: true,
  state: {
    scrollEleTop: 0
  },
  mutations: {
    storeScrollEleTop(state, val) {
      console.log(state, val, '储存scrollEleTop')
      state.scrollEleTop = val
    }
  }
}
