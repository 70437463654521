import { Toast } from 'vant';
import { ERROR_TOAST } from '@/config/constKeys'
import utils from '@/utils/utils'

import {
  getCartNumber,
  getCartList,
  addCart,
  updateCart,
  deleteBatch,
} from '@/http/service/goods'
export default {
  namespaced: true,
  state: {
    cartNumber: 0,
    // totalPrice: 0,
    goodsList: [],
    invalidList: [], // 下架的商品
    isPlus: false, // 是否是plus会员
  },
  getters: {
    totalPrice: state => {
      let _totalPrice = 0
      state.goodsList.forEach(item => {
        if (item.goodsSelected) {
          if (state.isPlus && !!item.plusPrice) {
            _totalPrice += item.plusPrice * item.number
          } else {
            _totalPrice += item.price * item.number
          }
        }
      })
      return _totalPrice
    },
    selectAllFlag: state => {
      let onSaleGood = state.goodsList.filter(i => { return i.onSale !== 0 })
      if (onSaleGood.length) {
        return !onSaleGood.some(item => {
          return item.goodsSelected === false
        })
      } else {
        return false
      }
    },
    selectNoneFlag: state => {
      if (state.goodsList.length) {
        return !state.goodsList.some(item => {
          return item.goodsSelected === true
        })
      } else {
        return true
      }
    },
  },
  mutations: {
    setCartNumber (state, number) {
      state.cartNumber = number
    },
    setIsPlus(state, isPlus) {
      state.isPlus = isPlus
    },
    // setTotalPrice(state, totalPrice) {
    //   state.totalPrice = totalPrice
    // },
    setGoodsList (state, list) {
      state.goodsList = list;
    },
    setInvalidList(state, list) {
      state.invalidList = list;
    },
    addGoodsItem (state, goodsItem) {
      let _pushFlag = false
      state.goodsList.forEach(item => {
        if (item.productId === goodsItem.productId) {
          _pushFlag = true
          item.number++
        }
      })
      if (!_pushFlag) {
        state.goodsList.push(goodsItem)
      }
    },
    selectGoodsItem (state, index) {
      state.goodsList[index].goodsSelected = !state.goodsList[index].goodsSelected
    },
    unSelectGoodsItem (state, index) {
      state.goodsList[index].goodsSelected = false
    },
    selecAlltGoodsItem (state, goodsSelected) {
      state.goodsList.forEach(item => {
        item.goodsSelected = goodsSelected
      })
    },
    selecSaleGoodsItem(state, goodsSelected) {
      state.goodsList.forEach(item => {
        if (item.onSale !== 0) {
          item.goodsSelected = goodsSelected
        } else {
          item.goodsSelected = false
        }
      })
    },
    resetGoodsItemSwiperLeftFlag (state) {
      state.goodsList.forEach(item => {
        item.swipeleftFlag = false;
      })
    },
    swiperGoodsItem (state, params) {
      state.goodsList[params.index].swipeleftFlag = params.swipeleftFlag
    },
  },
  actions: {
    /**
     * @description 加数量
     */
    // 修改bug DSG-25619
    async addGoodsCount ({ dispatch, state }, index) {
      let _goodsItem = {
        attrValueNames: state.goodsList[index].attrValueNames,
        number: utils.isInternalChannel() ? +state.goodsList[index].number + 1 : 1,
        productId: state.goodsList[index].productId,
        skku: state.goodsList[index].skku,
        sku: state.goodsList[index].sku,
        visitStore: state.goodsList[index].visitStore,
        mdCode: state.goodsList[index].mdCode,
        externalSource: state.goodsList[index].externalSource,
      }
      await dispatch('updateCart', _goodsItem)
      state.goodsList[index].number++
      utils.gioTrackWithVariable('MB13416', {
        user_id: localStorage.getItem('ucId') ? localStorage.getItem('ucId') + '' : 'null',
        member_id: localStorage.getItem('mId') ? localStorage.getItem('mId') + '' : 'null',
        productId_var: _goodsItem.productId + ''
      })
    },
    /**
     * @description 减数量
     */
    async subGoodsCount ({ dispatch, state }, index) {
      if (state.goodsList[index].number > 1) {
        let _goodsItem = {
          attrValueNames: state.goodsList[index].attrValueNames,
          number: utils.isInternalChannel() ? +state.goodsList[index].number - 1 : -1,
          productId: state.goodsList[index].productId,
          skku: state.goodsList[index].skku,
          sku: state.goodsList[index].sku,
          visitStore: state.goodsList[index].visitStore,
          mdCode: state.goodsList[index].mdCode,
          externalSource: state.goodsList[index].externalSource,
        }
        await dispatch('updateCart', _goodsItem)
        state.goodsList[index].number--
        utils.gioTrackWithVariable('MB13417', {
          user_id: localStorage.getItem('ucId') ? localStorage.getItem('ucId') + '' : 'null',
          member_id: localStorage.getItem('mId') ? localStorage.getItem('mId') + '' : 'null',
          productId_var: _goodsItem.productId + ''
        })
      } else {
        Toast('数量不能少于1')
      }
    },
    /**
     * @description 删除
     */
    async removeGoodsItem ({ dispatch, state }, index) {
      await dispatch('deleteBatch', state.goodsList[index].skku ? state.goodsList[index].skku : state.goodsList[index].sku)
      state.goodsList.splice(index, 1)
    },
    /**
   * @description 批量删除
   * isInvalid 是否是下架删除
   */
    removeGoodsItemMulti ({ dispatch, state }, isInvalid = false) {
      console.log(isInvalid)
      let params = ''
      if (isInvalid) {
        state.invalidList.forEach(i => {
          if (i.onSale === 0) {
            params = `${params},${i.skku ? i.skku : i.sku}`
          }
        })
        dispatch('deleteBatch', params).then(res => {
          if (res.success) {
            state.invalidList = state.invalidList.filter(i => { return i.onSale })
          } else {
            Toast(res.message || ERROR_TOAST)
          }
        })
      } else {
        const ids = []
        state.goodsList.forEach(i => {
          if (i.goodsSelected) {
            params = `${params},${i.skku ? i.skku : i.sku}`
            ids.push(i.productId)
          }
        })
        if (ids.length) {
          utils.gioTrackWithVariable('MB13414', {
            user_id: localStorage.getItem('ucId') ? localStorage.getItem('ucId') + '' : 'null',
            member_id: localStorage.getItem('mId') ? localStorage.getItem('mId') + '' : 'null',
            productId_var: ids.join()
          })
        }
        dispatch('deleteBatch', params).then(res => {
          if (res.success) {
            state.goodsList = state.goodsList.filter(i => { return !i.goodsSelected })
          } else {
            Toast(res.message || ERROR_TOAST)
          }
        })
      }
    },
    /**
     * @description 获取购物车列表
     * @selectIds 初始选中的商品 array类型
     */
    getCartList: ({ commit, rootState }, selectIds = []) => {
      return getCartList({
        // channelId: '',
        streetId: rootState.locationInfo.townshipId,
        unSale: 2,
        shareId: rootState.shareId,
        longitude: rootState.locationInfo.longitude || '',
        latitude: rootState.locationInfo.latitude || '',
      }).then(res => {
        if (res.success) {
          if (res.data && res.data.cartProducts) {
            if (selectIds.length) {
              res.data.cartProducts.forEach(item => {
                if (selectIds.includes(item.productId.toString())) {
                  item.goodsSelected = true
                } else {
                  item.goodsSelected = false
                }
                item.swipeleftFlag = false
              })
            } else {
              res.data.cartProducts.forEach(item => {
                item.goodsSelected = true
                item.swipeleftFlag = false
              })
            }
          }
          if (utils.isInternalChannel()) {
            // 内购购物车列表适配无 onSale
            if (res.data && res.data.cartProducts) {
              res.data.cartProducts.forEach(item => {
                item.onSale = item.onSale ? item.onSale : 1
              })
            }
            if (res.data && res.data.unSaleProducts) {
              res.data.unSaleProducts.forEach(item => {
                item.onSale = 0
              })
            }
          }
          commit('setIsPlus', res.data ? res.data.plus : false);
          commit('setGoodsList', res.data ? res.data.cartProducts : [])
          commit('setInvalidList', res.data ? res.data.unSaleProducts : [])
          // commit('totalPrice', res.data ? res.data.totalPrice : 0)
        } else {
          Toast(res.message)
        }
      }).catch(e => console.log(e))
    },
    /**
     * @description 获取购物车数量
     */
    getCartNumber: ({ commit }) => {
      getCartNumber({}).then(res => {
        if (res.success) {
          commit('setCartNumber', res.data)
        } else {
          Toast(res.message)
        }
      }).catch(e => console.log(e))
    },
    /**
     * @description 单个加入购物车
     */
    addCart: ({ dispatch, rootState }, goodsItem) => {
      let param = {
        attrValueNames: goodsItem.attrValueNames,
        number: goodsItem.number,
        productId: goodsItem.productId,
        region: rootState.locationInfo.townshipId,
        skku: goodsItem.skku,
        sku: goodsItem.sku,
        visitStore: goodsItem.visitStore,
        comId: goodsItem.comId,
        caseId: goodsItem.caseId || '',
        directSaleType: goodsItem.directSaleType,
        undertakeStore: goodsItem.undertakeStore,
        shareId: goodsItem.shareId,
        mdCode: goodsItem.mdCode,
        exclusiveId: goodsItem.exclusiveId,
        externalSource: goodsItem.externalSource,
      }
      if (utils.isInternalChannel()) {
        param = {
          number: goodsItem.number,
          sku: goodsItem.sku,
        }
      }
      addCart(param).then(res => {
        if (res.success) {
          if (utils.isInternalChannel()) {
            Toast('加入购物车成功')
            dispatch('getCartNumber')
          } else {
            if (res.data) {
              Toast('加入购物车成功')
              // dispatch('getCartList', goodsItem)
              dispatch('getCartNumber')
            }
          }
        } else {
          Toast(res.message)
        }
      }).catch(e => console.log(e))
    },
    /**
     * @description 单个更新购物车数量
     */
    updateCart: ({ dispatch, rootState }, goodsItem) => {
      return new Promise((resolve, reject) => {
        updateCart({
          attrValueNames: goodsItem.attrValueNames,
          number: goodsItem.number,
          productId: goodsItem.productId,
          region: rootState.locationInfo.townshipId,
          skku: goodsItem.skku,
          sku: goodsItem.sku,
          visitStore: goodsItem.visitStore,
          mdCode: goodsItem.mdCode || '',
          externalSource: goodsItem.externalSource || '',
        }).then(res => {
          if (res.success) {
            dispatch('getCartNumber')
            resolve()
          } else {
            Toast(res.message)
          }
        }).catch(e => console.log(e))
      })
    },
    /**
     * @description 批量删除购物车
     */
    deleteBatch: ({ dispatch, rootState }, sku) => {
      return new Promise((resolve, reject) => {
        let param = {}
        if (utils.isInternalChannel()) {
          let skuList = (sku || '').split(',')
          let cmds = []
          skuList.forEach(item => {
            if (item) {
              cmds.push({ sku: item })
            }
          })
          param.cmds = cmds
        } else {
          param.skus = sku
        }
        deleteBatch(param).then(res => {
          if (res.success) {
            dispatch('getCartNumber')
          } else {
            Toast(res.message)
          }
          resolve(res)
        }).catch(e => console.log(e))
      })
    },
  },
}
