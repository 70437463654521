/**
 * 示例文件
 * 可单独封装接口请求方法
 * 文件根据相应功能模块语义化命名
 * 导出至'@/http/index'
 */
import http, { get, post } from '../http';
import URL from '../url';
import config from '@/config'
import utils from '@/utils/utils'

export const test = (params) => {
  /* http.config.baseURL = "http://localhost:8080/api/"
  //设置请求前拦截器
  http.interceptor.request = (config) => {
    config.header = {
      "token": "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
    }
  } */
  // 设置请求结束后拦截器
  http.interceptor.response = (response) => {
    console.log('个性化response....')
    // 判断返回状态 执行相应操作
    return response;
  }
  return http.request({
    baseURL: 'https://unidemo.dcloud.net.cn/',
    url: 'ajax/echo/text?name=uni-app',
    dataType: 'text',
    params,
  })
}

// 轮播图
export const banner = (params) => {
  return http.request({
    url: '/banner/36kr',
    method: 'GET',
    params,
    // handle:true
  })
}

// 个人中心 我的优惠券
export const getMyCouponList = (params) => {
  return http.request({
    url: URL.getCouponsByMemberId,
    method: 'get',
    params,
  })
}

// 个人中心 我的换新卡
export const getMyCards = (params) => {
  return http.request({
    url: URL.getMyCards,
    method: 'get',
    params,
  })
}

// 订单详情
export const getOrderDetail = (params) => {
  return http.request({
    url: URL.orderdetail,
    method: 'GET',
    params,
  })
}
// 订单详情(新)
export const getOrderProductDetail = (params) => {
  return post(URL.orderProductDetail, params, {
    baseURL: config.OHOST,
  })
}

// 订单详情新接口（暂时用来控制”确认收货“按钮的逻辑，[TODO]后续会整体迁移，完全替代上面的旧接口）
export const postOrderProductDetail = (data) => {
  return http.request({
    url: URL.orderProductDetail,
    method: 'POST',
    data,
    baseURL: config.OHOST,
  })
}

// 订单详情根据网单号获取礼品券信息
export const getOgcListByCorderSn = (params) => {
  return http.request({
    url: URL.getOgcListByCorderSn,
    method: 'GET',
    params,
    baseURL: config.OHOST,
  })
}

// 订单详情页获取物流追踪信息
export const getTrackFlowOrderDetail = (params) => {
  return http.request({
    url: URL.trackFlowOrderDetail,
    method: 'POST',
    params,
  })
}
// 物流追踪信息列表 网单号不传查订单所有，网单号传则查网单号下全部
export const orderProductTrackList = (params) => {
  return http.request({
    url: URL.orderProductTrackList,
    method: 'POST',
    params,
  })
}

// 物流追踪信息列表 获取门店订单 发货单列表
export const getGoodsMsgList = (params) => {
  return http.request({
    url: URL.getGoodsMsgList,
    method: 'GET',
    params,
  })
}

// 订单详情 物流追踪节点
export const getOrderTrack = (params) => {
  return http.request({
    url: URL.orderTrack,
    method: 'POST',
    params,
  })
}
// 订单详情 物流追踪节点 （新）=>多网单物流节点 添加
export const orderProductTrack = (params) => {
  return http.request({
    url: URL.orderProductTrack,
    method: 'POST',
    params,
  })
}
// 地图轨迹信息展示
export const orderProductTrackGeo = (params) => {
  return http.request({
    url: URL.orderProductTrackGeo,
    method: 'POST',
    params,
  })
}

// 线下订单物流节点
export const getYlhLogisticsMsgList = (params) => {
  return http.request({
    url: URL.getYlhLogisticsMsgList,
    method: 'GET',
    params,
  })
}

// 根据发货单号获取运单号
export const getLogisticsCode = (params) => {
  // return post(URL.getLogisticsCode, params, {}, true)
  return http.request({
    url: URL.getLogisticsCode,
    method: 'POST',
    params,
  })
}

// 订单详情 物流追踪
export const getOrderTrackDetail = (params) => {
  return http.request({
    url: URL.trackOrder,
    method: 'GET',
    params,
  })
}

// 订单详情 退款进程（逆向物流）
export const getRefundProcessDetail = (params) => {
  return http.request({
    url: URL.getRefundProcessDetail,
    method: 'GET',
    params,
  })
}

// 个人中心 判断是否是微店主
export const getIsWdHostDetail = (params) => {
  return http.request({
    url: URL.isWdHost,
    method: 'GET',
    params,
  })
}

// 个人中心 获取用户基本信息
export const getUserInfoDetail = (params) => {
  return http.request({
    url: URL.myManage,
    method: 'GET',
    params,
  })
}

// 个人中心 获取用户钱包信息
export const getWalletDetail = (params) => {
  return http.request({
    url: URL.wallet,
    method: 'GET',
    params,
  })
}

// 个人中心 获取用户订单数量信息
export const getOrderCountDetail = (params) => {
  return http.request({
    url: URL.getOrderCount,
    method: 'GET',
    params,
  })
}

// 个人中心 我的团队 用户信息获取
export const getTeamUserDetail = (params) => {
  return http.request({
    url: URL.findOrderProductUserInfo,
    method: 'GET',
    params,
  })
}

// 个人中心 我的团队 团队、合伙人信息获取
export const getTeamPartnerAndTeamDetail = (params) => {
  return http.request({
    url: URL.pagingChildren,
    method: 'GET',
    params,
  })
}

// 个人中心 收货地址列表
export const getAddressList = (params, noLogin) => {
  return http.request({
    url: URL.getMemberAddress,
    method: 'GET',
    params,
    noLogin
  })
}

// 个人中心 收货地址 删除收货地址
export const deleteAddress = (params) => {
  return http.request({
    url: URL.deleteAddress + '?id=' + params.m,
    method: 'POST',
  })
}

// 个人中心 收货地址 批量删除收货地址
export const deleteBatchAddress = (params) => {
  return http.request({
    url: URL.deleteBatchAddress + '?ids=' + params.m,
    method: 'POST',
  })
}

// 个人中心 收货地址 新增收货地址 保存
export const createAddress = (data) => {
  return http.request({
    url: URL.insertAddress,
    method: 'POST',
    data,
  })
}

// 个人中心 收货地址 编辑收货地址 根据id获取详细信息
export const getAddressById = (id) => {
  return http.request({
    url: URL.getAddress + '?id=' + id,
    method: 'GET'
  })
}

// 个人中心 收货地址 编辑收货地址 保存
export const editSaveAddress = (data) => {
  return http.request({
    url: URL.updateAddress,
    method: 'POST',
    data,
  })
}

// 个人中心 获取订单列表数据
export const getOrderList = (params) => {
  // 内购订单统一传orderType=101
  if (utils.isInternalChannel() && !params.hasOwnProperty('orderType')) {
    params.orderType = 101
  }
  return get(URL.myOrderUrl, params, {}, true)
  // return http.request({
  //   url: URL.myOrderUrl,
  //   method: 'GET',
  //   params,
  // })
}
export const getRepairsList = (data) => {
  return post(URL.getRepairsList, data, {
    baseURL: config.OHOST,
  })
}
// 查询发票超时赔付订单列表
export const getCompensationOrders = (params) => {
  return post(URL.getCompensationOrders, params, {
    'Content-Type': 'application/json',
  })
}

// 个人中心 获取易理货订单列表
export const getStoreOrderList = (params) => {
  return get(URL.getStoreOrder, params, {}, true)
}

// 个人中心 获取getMemberId
export const getMemberId = (params) => {
  return http.request({
    url: URL.getMemberId,
    method: 'GET',
    params,
  })
}

// 个人中心 升级微店主
export const upgradeShopkeeper = (params) => {
  return http.request({
    url: URL.wdApplySubmit,
    method: 'post',
    params,
  })
}

// 优惠券 转赠优惠券
export const couponShare = (params) => {
  return http.request({
    url: URL.couponShare,
    method: 'post',
    params,
  })
}

// 优惠券 领取他人转赠给自己的优惠券
export const receiveShareCoupon = (params) => {
  return http.request({
    url: URL.receiveShareCoupon,
    method: 'post',
    params,
  })
}

// 我的收藏 -- 商品收藏列表(新)
export const myCollectionList = (data) => {
  return http.request({
    baseURL: config.DHOST,
    url: URL.myCollectionListV2,
    method: 'post',
    data: data,
  })
}
// 我的收藏 -- 批量取消收藏（新）
export const cancelCollection = (data) => {
  return http.request({
    baseURL: config.DHOST,
    url: URL.cancelCollectionV2,
    method: 'post',
    data: data,
  })
}
// 我的收藏 -- 店铺收藏列表
export const storeCollectionList = (params) => {
  return http.request({
    url: URL.storeCollection,
    method: 'get',
    params,
  })
}

export const getCollectScene = (data) => {
  return http.request({
    baseURL: config.DHOST,
    url: URL.getCollectScene,
    method: 'post',
    data: data,
  })
}

// 订单列表-删除订单
export const deleteOrder = (params) => {
  return http.request({
    url: URL.deleteOrder,
    method: 'get',
    params,
  })
}

// 订单列表-取消订单
export const cancelOrder = (params) => {
  return http.request({
    url: URL.cancelOrder,
    method: 'get',
    params,
  })
}

// 网单详情页回传网单信息给客服中心
export const customerPreCall = (data) => {
  return http.request({
    baseURL: config.OHOST,
    url: URL.customerPreCall,
    method: 'post',
    data: data,
  })
}

// 修改结算页收货地址
export const changeAddressId = (params) => {
  return http.request({
    url: URL.changeAddress,
    method: 'get',
    params,
  })
}

// 频道页配置数据接口
export const detailInfo = (params) => {
  return post(URL.detailInfo, params, {})
}
// 根据Fc与地址获取最优Fk信息
export const getSceneDetailUrl = (params) => {
  return get(URL.getSceneDetailUrl, params, {
    baseURL: config.DHOST,
  })
}

// 通用商品查询列表
export const getCommonProductList = (params) => {
  return post(URL.getCommonProductList, params, {
    baseURL: config.SHOST,
  })
}

// 获取解决方案(类似全屋用水解决方案)
export const getSolutionDetails = (params) => {
  return get(URL.solutionDetails, params, {}, true)
  // return http.request({
  //   url: URL.solutionDetails,
  //   method: 'get',
  //   params,
  // })
}

// 获取方案页优惠券列表
export const getSolutionCoupons = (params) => {
  return get(URL.scenesCoupons, params, {}, true)
}

// 解决方案预约
export const toMakeAppointment = (params) => {
  return http.request({
    url: URL.makeAppointment,
    method: 'post',
    params,
  })
}

// 解决方案清单的商品列表
export const getSequenceProducts = (params) => {
  return http.request({
    url: URL.sequenceProducts,
    method: 'get',
    params,
  })
}

// 进入结算页接口 创建订单
export const createOrder = (data) => {
  return post(URL.createOrderNew, data, {}, true)
  // return http.request({
  //   url: URL.createOrderNew,
  //   method: 'post',
  //   data,
  // })
}
// 批量添加购物车
export const addBatch = (data) => {
  return post(URL.addBatch, data, {})
}

// 订单部分--确认收货
export const confirmReceive = (params) => {
  return http.get(URL.confirmReceive, {
    params,
    cancelRepeat: true,
  })
  // return http.request({
  //   url: URL.confirmReceive,
  //   method: 'get',
  //   params,
  // })
}

// 解决方案列表
export const getListByProductId = (params) => {
  return http.request({
    url: URL.listByProductId,
    method: 'get',
    params,
  })
}

// 获取评价数好评率
export const evaluationInfo = (data) => {
  return http.request({
    baseURL: config.DHOST,
    url: `${URL.evaluationInfo}${data.productId}.json`,
    method: 'get',
    data,
  })
}

// 获取即将过期的优惠券
export const getCouponsByMemberIdOneDay = (params) => {
  return http.request({
    url: URL.getCouponsByMemberIdOneDay,
    method: 'get',
    params,
  })
}

// 智能场景背景图片
export const scenesImage = (params) => {
  return http.request({
    url: URL.scenesImage,
    method: 'get',
    params,
  })
}

// 智能场景定位信息
export const scenesCoordinate = (params) => {
  return http.request({
    url: URL.scenesCoordinate,
    method: 'get',
    params,
  })
}

// 智能场景图商品信息
export const scenesProducts = (params) => {
  return http.request({
    url: URL.sequenceProducts,
    method: 'get',
    params,
  })
}

const getProgramLivesInfo = data => post(URL.getProgramLives, data, {}, true)

// 检查尾款支付
export const checkTailOrder = (params) => {
  return get(URL.checkTailOrder, params, {}, true)
  // return http.request({
  //   url: URL.checkTailOrder,
  //   method: 'get',
  //   params,
  // })
}

// 生成尾款单结算页
export const createTailOrder = (params) => {
  return http.request({
    url: URL.createTailOrder,
    method: 'get',
    params,
  })
}

// 商品收藏/取消收藏
export const cancelProductCollection = (params) => {
  return http.request({
    url: URL.cancelProductCollectionV2,
    method: 'post',
    params,
    baseURL: config.DHOST,
  })
}

// 客服调用日志
export const traceLog = (params) => {
  return post(URL.traceLog, params, {}, true)
}
// 商家客服是否可用
export const getCustomer = (params) => {
  return http.request({
    url: URL.getCustomer,
    method: 'get',
    params,
  })
}
// 获取会员手机号、邮箱（Base64加密）
export const getMemberInfo = (params) => {
  return http.request({
    url: URL.getMemberInfo,
    method: 'get',
    params,
  })
}

// 店铺关注/取消关注
export const collectStore = (params) => {
  return http.request({
    url: URL.collectStore,
    method: 'get',
    params,
  })
}

// 获取快递信息
export const getExpress = (params) => {
  return http.request({
    url: URL.getExpress,
    method: 'get',
    params,
  })
}

// 退出功能
export const logout = (params) => {
  return http.request({
    url: URL.logout,
    method: 'get',
    params,
  })
}

// 个人中心-获取我的预约列表
export const getMyReserve = (params) => {
  return http.request({
    url: URL.myReserve,
    method: 'get',
    params,
  })
}

// 转赠优惠券
export const transfer = (params) => {
  return http.request({
    url: URL.transfer,
    method: 'get',
    params,
  })
}
// 收货地址-地理编码
export const getLbs = (params) => {
  return http.request({
    url: URL.getLbsGeo,
    method: 'get',
    params,
  })
}
// 收货地址-逆地理编码
export const getLbsRegeo = (params) => {
  return http.request({
    url: URL.getLbsRegeo,
    method: 'get',
    params,
  })
}

// 收货地址-根据街道国标码获取商城地址库省市区街道
export const getPcr = (params) => {
  return http.request({
    url: URL.getPcr,
    method: 'post',
    params,
  })
}

// 根据街道国标码获取商城地址库省市区街道 和 【经纬度】！！
export const pcrByCode = (params) => {
  return http.request({
    url: URL.pcrByCode,
    method: 'post',
    params,
  })
}

// 解析二维码参数
export const getQrExtraParam = (params) => {
  return http.request({
    url: URL.getQrExtraParam,
    method: 'get',
    params,
  })
}

// 生成二维码参数
export const genreateQr = (params) => {
  return http.request({
    url: URL.genreateQr,
    method: 'post',
    data: params,
  })
}

// 收货地址-获取标签
export const getLabels = (params) => {
  return http.request({
    url: URL.labels,
    method: 'get',
    params,
  })
}

// 收货地址-根新增/修改地址标签
export const getUpdateLabel = (params) => {
  return post(URL.updateLabel, params)
}
// 用户申请发票超时赔付
export const invoiceApply = (params) => {
  return http.request({
    url: URL.invoiceApply,
    method: 'GET',
    params,
  })
}

// 根据订单号获取支付单列表分阶段信息
export const getOrderStagePayment = (params) => {
  return http.request({
    url: URL.getOrderStagePayment,
    method: 'post',
    data: params,
    baseURL: config.OHOST
  })
}

// 根据网单号获取支付单列表分
export const getOrderProductStagePayment = (params) => {
  return http.request({
    url: URL.getOrderProductStagePayment,
    method: 'post',
    data: params,
    baseURL: config.OHOST
  })
}

// 提交赔付申请信息
export const invoiceApplySubmit = (params) => {
  return post(URL.invoiceApplySubmit, params, {
    'Content-Type': 'application/json'
  })
}
// 撤销发票超时赔付申请
export const invoiceCompensationcancel = (params) => {
  return http.request({
    url: URL.invoiceCompensationcancel,
    method: 'GET',
    params,
    baseURL: config.OHOST
  })
}
// 查看发票超时赔付详情
export const invoiceApplyDetail = (params) => {
  return http.request({
    url: URL.invoiceApplyDetail,
    method: 'GET',
    params,
  })
}
// 根据memberId查询进入店铺日志
export const getMdCodeByMid = (params) => {
  return http.request({
    url: URL.queryByMemberId,
    method: 'get',
    params,
  })
}

// 进入门店查询
export const getMdIsUse = (params) => {
  return http.request({
    url: URL.queryIsUse,
    method: 'get',
    params,
  })
}


export default {
  getOrderStagePayment,
  getOrderProductStagePayment,
  getMyCouponList,
  getMyCards,
  getOrderDetail,
  invoiceCompensationcancel,
  postOrderProductDetail,
  getOrderProductDetail,
  getOgcListByCorderSn,
  getTrackFlowOrderDetail,
  orderProductTrackList,
  getGoodsMsgList,
  getOrderTrackDetail,
  getRefundProcessDetail,
  getIsWdHostDetail,
  getUserInfoDetail,
  getWalletDetail,
  getOrderCountDetail,
  getTeamUserDetail,
  getTeamPartnerAndTeamDetail,
  getAddressList,
  getOrderList,
  getRepairsList,
  getCompensationOrders,
  deleteAddress,
  deleteBatchAddress,
  createAddress,
  getAddressById,
  editSaveAddress,
  getMemberId,
  upgradeShopkeeper,
  couponShare,
  receiveShareCoupon,
  myCollectionList,
  cancelCollection,
  storeCollectionList,
  getCollectScene,
  deleteOrder,
  cancelOrder,
  changeAddressId,
  getSolutionDetails,
  getSolutionCoupons,
  toMakeAppointment,
  getSequenceProducts,
  createOrder,
  addBatch,
  confirmReceive,
  getListByProductId,
  evaluationInfo,
  getCouponsByMemberIdOneDay,
  scenesImage,
  scenesCoordinate,
  scenesProducts,
  getProgramLivesInfo,
  checkTailOrder,
  createTailOrder,
  cancelProductCollection,
  getCustomer,
  traceLog,
  getMemberInfo,
  collectStore,
  getExpress,
  logout,
  getMyReserve,
  transfer,
  getLbs,
  getLbsRegeo,
  getPcr,
  getLabels,
  getUpdateLabel,
  getOrderTrack,
  getStoreOrderList,
  orderProductTrack,
  getYlhLogisticsMsgList,
  getLogisticsCode,
  orderProductTrackGeo,
  pcrByCode,
  detailInfo,
  getSceneDetailUrl,
  getCommonProductList,
  getQrExtraParam,
  genreateQr,
  invoiceApply,
  invoiceApplySubmit,
  invoiceApplyDetail,
  getMdCodeByMid,
  getMdIsUse,
}
