<template>
  <transition name="toast-bounce">
    <div class="toast" v-if="show">
      <p v-if="type==1">完成浏览商城商品{{time}}s，成长值<span>+2</span>，积分<span>+2</span></p>
      <p v-if="type==2">完成分享商品任务，成长值<span>+2</span>，积分<span>+2</span></p>
      <a href="javascript:;" @click.stop="goPage">获取更多奖励 <svg-icon className="svg-icon" svg-path="assets/svgs/toast/shuangjiantou.svg" xlink="shuangjiantou"/></a>
    </div>
  </transition>
</template>

<script>
import UplusApi from '@uplus/uplus-api';
const instance = new UplusApi()
instance.initDeviceReady()

export default {
  name: 'toast',
  data() {
    return {
      show: false,
      time: '',
      type: 1, // 1、浏览任务 2、分享任务
    }
  },
  methods: {
    goPage() {
      let eventId = this.type === 1 ? 'mall_index_click_getAward' : 'mall_pro_click_getAward'
      let variable = {}
      if (this.type === 2) {
        variable.productId_var = this.params.productId || 'null'
      } else {
        variable = null
      }
      this.$util.gioTrackWithVariable(eventId, variable).then(() => {
        instance.initDeviceReady().then(() => {
          instance.upVdnModule.goToPage({ url: 'mpaas://haierVipCode?needAuthLogin=1#/cn/distcord/vipTask' });
        }).catch(e => { console.log(e) });
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.toast{
  font-size: .24rem;
}

.svg-icon{
  width: .2rem;
  height: .2rem;
}

.toast-bounce-enter-active{
  animation: bounce-in .3s;
}
.toast-bounce-leave-active {
  animation: bounce-in .3s reverse;
}
.toast{
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: .24rem .48rem;
  background: rgba(0,0,0,.7);
  border-radius: .24rem;
  color: #fff;
  z-index: 300;
  text-align: center;
  p{
    margin-bottom: .14rem;
    white-space: nowrap;
  }
  span{
    color: #FFB60C;
  }
  a{
    color: #88CAF7;
  }
}
@keyframes bounce-in {
  0%{
    transform: translateX(-50%) translateY(-20%);
  }
  100%{
    transform: translateX(-50%) translateY(-50%);
  }
}
</style>
