<template>
  <van-image class="van-img" :src="src"></van-image>
</template>

<script>
export default {
  props: ['src']
}
</script>

<style lang="scss" scoped>
img[lazy="loading"]{
  display: inline-block;
  width: 100%;
  height: 100%;
  background: url(https://cdn09.ehaier.com/shunguang/H5/www/img/sgmobile/750_360.png) center center no-repeat;
  background-size: cover;
  border: none;
  outline: none;
}
.van-img{
  width: 100%;
  height: 100%;
  img{
    transform: translateZ(0);
    vertical-align: top;
  }
}
</style>
