import ua from '@/utils/ua'

export const LBS_KEY = '7c964766c309377bc93c3d88470cd995';
export const ERROR_TOAST = '网络请求失败，请稍后重试';
export const DEFAULT_LOGO = 'https://cdn09.ehaier.com/shunguang/H5/www/img/sgmobile/uplus_logo.png';
export const DEFAULT_TITLE = '海尔智家';
export const DEFAULT_DESC = '海尔智家，海尔家电正品保障';
export const DEFAULT_LOGO_SYBIRD = 'https://cdn09.ehaier.com/shunguang/H5/www/img/sgmobile/new_sybird_logo.png';
export const DEFAULT_TITLE_SYBIRD = '海尔智家';
export const DEFAULT_DESC_SYBIRD = '定制智慧家 就找三翼鸟';
export const DEFAULT_TITLE_PARTNER = '三翼鸟－定制智慧家';
export const DEFAULT_TITLE_CONSULTANT = '我是三翼鸟合伙人，邀您一起加入'
export const DEFAULT_DESC_PARTNER = '海尔官方平台，海量正品，来三翼鸟一起瓜分亿元佣金吧！';
export const DEFAULT_LOGO_SHARE = ua.isSyBirdOrShopKeeper() ? DEFAULT_LOGO_SYBIRD : DEFAULT_LOGO;
export const DEFAULT_TITLE_SHARE = ua.isSyBirdOrShopKeeper() ? DEFAULT_TITLE_SYBIRD : DEFAULT_TITLE
export const DEFAULT_DESC_SHARE = ua.isSyBirdOrShopKeeper() ? DEFAULT_DESC_SYBIRD : DEFAULT_DESC
// export const INTERNAL_AUTHENTICATION_SERVICE_TEST = 'http://10.131.144.90:13025/ihaier-runtime-auth-server'
// export const INTERNAL_AUTHENTICATION_SERVICE_PROD = 'http://10.131.144.90:13025/ihaier-runtime-auth-server'
export const INTERNAL_AUTHENTICATION_SERVICE_TEST = 'https://m-test.ehaier.com/v3/platform/ihaier/checkUrl.json' // 内购鉴权服务 测试
export const INTERNAL_AUTHENTICATION_SERVICE_PROD = 'https://m.ehaier.com/v3/platform/ihaier/checkUrl.json' // 内购鉴权服务 生产
export const INTERNAL_AUTHENTICATION_APPID_PROD = 'cli_a22c491fa779100b' // 内购应用 appid 生产
export const INTERNAL_AUTHENTICATION_APPID_TEST = 'cli_a205abbc9afa100d' // 内购应用 appid 测试
export const INTERNAL_AUTHENTICATION_CLIENTID_TEST = '8de33acdf9b5ef3abf8e06881067250b' // 内购应用 clientId 测试
export const INTERNAL_AUTHENTICATION_CLIENTID_PROD = '1347adc45cb50879c7301873f7c5b881' // 内购应用 clientId 测试

export const TIM_SDK_APPID = 1400582587
export const TIM_SDK_APPID_TEST = 1400595597
