import cancelAccount from './cancelAccount'
const settings = [
  ...cancelAccount,
  {
    path: '/accountSecurity',
    name: 'accountSecurity',
    component: () => import('@/viewsToB/settings/accountSecurity.vue'),
    meta: {
      showHeader: true,
      showTabbar: false,
      title: '账户安全',
      awaitlogin: true
    }
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('@/viewsToB/settings/changePassword/index.vue'),
    meta: {
      title: '修改密码',
      showTabbar: false,
      showHeader: true,
      needLogin: true,
    },
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import('@/viewsToB/settings/changePassword/components/forgetPassword.vue'),
    meta: {
      title: '忘记原密码',
      showTabbar: false,
      showHeader: true,
      needLogin: true,
    },
  },
  {
    path: '/newPassword',
    name: 'newPassword',
    component: () => import('@/viewsToB/settings/changePassword/components/newPassword.vue'),
    meta: {
      title: '修改密码',
      showTabbar: false,
      showHeader: true,
      needLogin: true,
    },
  },
]

export default settings
