const dailyTask = [
  {
    path: '/dailyTask',
    name: 'dailyTask',
    component: () => import('@/viewsToB/dailyTask'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '任务分享',
      needLogin: true,
    },
  },
  {
    path: '/dailyTaskDetail',
    name: 'dailyTaskDetail',
    component: () => import('@/viewsToB/dailyTask/components/dailyTaskDetail.vue'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '任务分享',
      needLogin: true,
    },
  },
  {
    path: '/historicalTask',
    name: 'historicalTask',
    component: () => import('@/viewsToB/dailyTask/components/historicalTask.vue'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '历史任务',
      needLogin: true,
    },
  },
]

export default dailyTask
