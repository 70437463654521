import { PaySourceID } from './paySourceEnum'
import ua from '@/utils/ua'

//根据环境返回paysoureId
export const getOrderPaySourceIdByEnv = ():PaySourceID => {
  if(ua.isUplus()){
    return PaySourceID.UPLUSAPP
  }else if(ua.isSyBird()){
    return PaySourceID.SYBIRDAPP
  }else if(ua.isShopKeeper()){
    return PaySourceID.SHOPKEEPERAPP
  }else if(ua.isInternalChannel()){
    return PaySourceID.HAIERNEIGOU
  }else if(ua.isWeixin()){
    return PaySourceID.WXGONGZHONGHAO
  }else{
    return PaySourceID.H5
  }
}