import utils from '@/utils/utils'
import { QUAN_TYPE, STATUS_TYPE } from '@/utils/enum'

export default {
  namespaced: true,
  state: {
    mapQuan: {
      [QUAN_TYPE.REBATE_COUPON]: '满减券',
      [QUAN_TYPE.DISCOUNT_COUPON]: '折扣券',
      [QUAN_TYPE.REALITY_GIFT_COUPON]: '礼品券',
      [QUAN_TYPE.FIXED_CASH_COUPON]: '定额现金券'
    },
    mapStatus: {
      [STATUS_TYPE.ALL]: '全部',
      [STATUS_TYPE.WAIT_USE]: '待使用',
      [STATUS_TYPE.WAIT_EXPIRE]	:'即将过期',
      [STATUS_TYPE.EXPIRED]	: '已过期',
      [STATUS_TYPE.INVALID]	:'已作废',
      [STATUS_TYPE.WAIT_EXCHANGE]	: '待兑换',
      [STATUS_TYPE.CHECK_OFF]: '已核销',
      [STATUS_TYPE.FINISH_SETTLE]	: '已结算',
      [STATUS_TYPE.NO_SETTLE]	: '不结算-客户全部出资',
      [STATUS_TYPE.TO_SETTLE]: '待结算',
    },
    quanDetailInfo: utils.strJSONParse(sessionStorage.getItem('sg_card_quan_detail_info'),{})
  },
  getters: {
    getQuanDetailInfo(state) {
      return state.quanDetailInfo || {}
    },
  },
  mutations: {
    setQuanDetailInfo(state, payload) {
      sessionStorage.setItem('sg_card_quan_detail_info', JSON.stringify(payload))
      state.quanDetailInfo = payload
    }
  },
}
