import dayjs from 'dayjs'
import utils from '@/utils/utils'

const mapTermCn = {
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五'
}

export default {
  namespaced: true,
  state: {
    preTotalApplyPrice: null, //上一次总金额，切换地址优惠券，E卡钻石等
    totalApplayPrice: null,  //总应付金额
    // initProductList: [], //商品初始信息
    productList: [], //商品信息
    stageTerm: 2, //总阶段数
    stageList: [], //分阶段信息
    curStage: 1, //当前选择的阶段
    curStageCn: '一', //当前阶段

    orderUuid: '', //订单UUid

    lastStageInfo: null, //上次分阶段信息

    initStageInfo: [], //分阶段初始信息
  },
  mutations: {
    //设置订单uuid
    setOrderUuid(state, uuid){
      state.orderUuid = uuid
    },
    //设置最新分阶段信息
    setLastStageInfo(state, info){
      state.lastStageInfo = info
    },
    setRestLastStageInfo(state){
      state.stageTerm = state.lastStageInfo?.stageTerm || 2
      state.stageList = utils.strJSONParse(JSON.stringify(state.lastStageInfo?.stageList), [])
    },
    //设置网单列表
    setProductList(state, lists){
      state.productList = lists
    },
    //设置总支付金额
    setCurTotalApplayPrice(state, price) {
      if(!state.preTotalApplyPrice){
        state.totalApplayPrice = state.preTotalApplyPrice = price
      }else{
        state.preTotalApplyPrice = state.totalApplayPrice
        state.totalApplayPrice = price
      }
    },
    //设置当前阶段
    setCurStage(state, stage){
      state.curStage = stage
      state.curStageCn = mapTermCn[stage]
    },
    //设置阶段支付日期
    setCurStageDate(state, {stage, date}){
      state.stageList[stage-1].plannedPayTime = date
    },
    //设置分阶段信息
    setStageList(state, info){
      state.stageList = info
    },
    //设置阶段数
    setStageTerm(state, count) {
      state.stageTerm = count
    },
    //记录初始值
    setInitStageInfo(state) {
      let arr = []
      let { stageTerm, stageList } = state
      for(let i=0; i<stageTerm; i++){
        let json = {
          initStageInfoDueAmt: stageList[i].dueAmt,  //当前阶段初始化值
          initStageInfoPayRate: stageList[i].payRate, //当前阶段初始化比例
          initStagePayDate: stageList[i].plannedPayTime, //支付日期
          inputStatus: false,
          inputPrice: '',
          initOrderProductList: []
        }
        for(let j=0; j<state.productList.length; j++){
          let pro = {
            initOrderProductPayIndexDueAmt: stageList[i].opList[j].dueAmt,
            initOrderProductPayIndexPayRate: stageList[i].opList[j].payRate,
          }
          json.initOrderProductList.push(pro)
        }
        arr.push(json)
      }
      state.initStageInfo = arr
    },

    // 默认自动试算
    autoAcumlateStageTerm(state){
      /**
       * 试算逻辑
       * 
       * 1.每阶段占比 perStage ===> 1 / 阶段数
       * 2.每阶段应付金额 
       *    stageOnePrice: 一阶段
       *      stagePricea: 如果有不能分阶段 a 商品 默认都放到第一阶段 100%  没有 a商品应付金额 * perStage
       *      stagePriceb, stagePricec: b.c 商品应付金额 * perStage 
       *    
       *    stageOnePrice = stagePricea + stagePriceb + stagePricec
       *      
       *    stageTwoprice: 二阶段
       *      stagePricea: 0
       *      stagePriceb, stagePricec: b.c 商品应付金额 * perStage 
       * 
       *    ...依次
       *     
       * 3、最后阶段应付金额:
       *       用总金额 - 前面阶段金额和
       *       abc每个商品： 应付金额 - 前面对应商品金额和
       *         
       * 4、试算完后 用修改商品金额，在每个阶段修改金额后，只提示超多少或缺多少，不联动处理       
       */

      state.stageList = [] //重置
      const { stageTerm, productList, totalApplayPrice } = state
      const perStage = (1 / stageTerm).toFixed(4)
      console.log('perStage===>',perStage)
      let stageProdtSum = 0; // 每阶段累计金额 用于最后做减法
      let stageProdtSumRadio = 0;
      let stageProdtSumSsf = 0;

      let itemStageSum = 0; //当前阶段累计金额
      let itemStageSumRadio = 0;  //当前阶段累计比例
      let itemStageSumSsf = 0; //当前阶段累计运费

      let itemProductSum = {} //跨阶段每个商品累计金额
      let itemProductSumSsf = {} //跨阶段每个商品累计运费
      let itemProductSumRadio = {} //每个商品累计占比
      for(let i = 1; i<=stageTerm; i++){
        let stageInfo = {  //阶段信息
          dueAmt: 0,
          payRate: 0,
          payRatePercent: 0,
          periodNo: i,
          plannedPayTime: dayjs().format('YYYY-MM-DD'),
          periodCn: mapTermCn[i],
          opList: [],
          dueSsf: 0,
          inputStatus: false,
          inputPrice: ''
        }
        itemStageSum = itemStageSumRadio = itemStageSumSsf = 0
        for(let j=0; j<productList.length; j++){
          if(!itemProductSum[productList[j].uuid]){
            itemProductSum[productList[j].uuid] = 0
          }
          if(!itemProductSumRadio[productList[j].uuid]){
            itemProductSumRadio[productList[j].uuid] = 0
          }
          if(!itemProductSumSsf[productList[j].uuid]){
            itemProductSumSsf[productList[j].uuid] = 0
          }

          if(i === stageTerm){ //最后阶段
            let itemStage = {
              orderProductUuid: '',
              dueAmt: 0,
              payRate: 0,
              payRatePercent: 0,
              dueSsf: 0,
              canPeriod: productList[j].canPeriod,
              originPrice: productList[j].price, //网单商品原价
              payPrice: productList[j].pam, //网单商品应付金额
              uuid: productList[j].uuid
            }
            let curPrice = productList[j].pam
            if(!productList[j].canPeriod){ //是非拆单商品默认放到第一阶段
              itemStage.dueAmt = 0
              itemStage.payRate = 0
              itemStage.payRatePercent = 0
              itemStage.dueSsf = 0
            }else {
              itemStage.dueAmt = Number((curPrice - itemProductSum[productList[j].uuid]).toFixed(2))
              itemStage.payRate = Number((1 - itemProductSumRadio[productList[j].uuid]).toFixed(4))
              itemStage.payRatePercent = Number((itemStage.payRate * 100).toFixed(2))
              itemStage.dueSsf = 0
            }
            // itemStageSum += itemStage.dueAmt
            // itemStageSumRadio += itemStage.payRate
            stageInfo.opList.push(itemStage)
          }else{
            let itemStage = { //商品在本阶段信息
              orderProductUuid: '',
              dueAmt: 0,
              payRate: 0,
              payRatePercent: 0,
              dueSsf: 0,
              canPeriod: productList[j].canPeriod,
              originPrice: productList[j].price, //网单商品原价
              payPrice: productList[j].pam, //网单商品应付金额
              uuid: productList[j].uuid
            }
            let curPrice = productList[j].pam
            let curSsf = productList[j].ssf 
  
            if(!productList[j].canPeriod && i == 1){ //是非拆单商品默认放到第一阶段
              itemStage.dueAmt = curPrice
              itemStage.payRate = 1
              itemStage.payRatePercent = 100
              itemStage.dueSsf = curSsf
            }else if(!productList[j].canPeriod){
              itemStage.dueAmt = 0
              itemStage.payRate = 0
              itemStage.payRatePercent = 0
              itemStage.dueSsf = 0
            }else {
              itemStage.dueAmt = Number((curPrice * perStage).toFixed(2))
              itemStage.payRate = curPrice ? Number((itemStage.dueAmt / curPrice).toFixed(4)) : 0
              itemStage.payRatePercent = Number((itemStage.payRate * 100).toFixed(2))
              itemProductSum[productList[j].uuid] += (itemStage.dueAmt + itemStage.dueSsf)
              itemProductSumRadio[productList[j].uuid] += itemStage.payRate
              itemProductSumSsf[productList[j].uuid] += itemStage.dueSsf
            }

            itemStageSum += (itemStage.dueAmt + itemStage.dueSsf)
            itemStageSumRadio += itemStage.payRate
            itemStageSumSsf += itemStage.dueSsf
            stageInfo.opList.push(itemStage)
          }
        }

        if(i === stageTerm){
          stageInfo.dueAmt = Number((totalApplayPrice - stageProdtSum).toFixed(2))
          stageInfo.payRate = Number((1- stageProdtSumRadio).toFixed(4))
          stageInfo.payRatePercent = Number((stageInfo.payRate * 100).toFixed(2))
          stageInfo.dueSsf = Number(itemStageSumSsf.toFixed(2))
        }else{
          stageInfo.dueAmt = Number(itemStageSum.toFixed(2))
          stageInfo.payRate = Number((itemStageSum / totalApplayPrice).toFixed(4))
          stageInfo.payRatePercent = Number((stageInfo.payRate * 100).toFixed(2))
          stageInfo.dueSsf = Number(itemStageSumSsf.toFixed(2))
          stageProdtSum += stageInfo.dueAmt
          stageProdtSumRadio += stageInfo.payRate
          stageProdtSumSsf += stageInfo.dueSsf
          
        }

        state.stageList.push(stageInfo)

      }
      console.log('stageList===>', state.stageList)
    },
    resetStageTerm(state) {
      state.stageTerm = 2
      state.stageList = []
    },

    //恢复最近的初始值
    resetLastedModify(state, {index, stage, status, isLast}){
      // if(state.stageTerm === 2) return
      let stageInfo = state.stageList[stage - 1]
      let { initStageInfo } = state
      if(!status){ //关闭恢复初始值
        console.log('isLast',isLast)
        if(!isLast){
          stageInfo.opList.forEach((item,idx) => {
            item.dueAmt = initStageInfo[stage-1].initOrderProductList[idx].initOrderProductPayIndexDueAmt
            item.payRate = initStageInfo[stage-1].initOrderProductList[idx].initOrderProductPayIndexPayRate
            item.payRatePercent = Number((item.payRate * 100).toFixed(2))
          })
  
          stageInfo.dueAmt = initStageInfo[stage - 1].initStageInfoDueAmt
          stageInfo.payRate = initStageInfo[stage - 1].initStageInfoPayRate
          stageInfo.payRatePercent = Number((stageInfo.payRate * 100).toFixed(2))
          stageInfo.plannedPayTime = initStageInfo[stage - 1].initStagePayDate
        }else{
          stageInfo.plannedPayTime = initStageInfo[stage - 1].initStagePayDate
        }
      }else{ //确认修改，同步初始值
        initStageInfo[stage - 1].initOrderProductList.forEach((item,idx) => {
          item.initOrderProductPayIndexDueAmt = stageInfo.opList[idx].dueAmt
          item.initOrderProductPayIndexPayRate = stageInfo.opList[idx].payRate
        })
    
      
        initStageInfo[stage - 1].initStageInfoDueAmt = stageInfo.dueAmt
        initStageInfo[stage - 1].initStageInfoPayRate = stageInfo.payRate
        initStageInfo[stage - 1].initStagePayDate = stageInfo.plannedPayTime
      }
      
    },


    // 商品金额输入更新阶段信息
    updateAcumlateStageTerm(state, {index, stage, type, input, price, ssf}){
      let stageInfo = state.stageList[stage - 1]
      console.log('updateAcumlateStageTerm==>',index,stage, ssf)
      //todo 加运费

      if(!type){ //金额
        stageInfo.opList[index].dueAmt = Number(input.toFixed(2))
        stageInfo.opList[index].payRate = Number((input / price).toFixed(4))
        stageInfo.opList[index].payRatePercent = Number((stageInfo.opList[index].payRate * 100).toFixed(2))
      }else{ //比例
        stageInfo.opList[index].payRate = Number((input / 100).toFixed(4))
        stageInfo.opList[index].dueAmt = Number((stageInfo.opList[index].payRate * price).toFixed(2))
        stageInfo.opList[index].payRatePercent = input
      }
      let sum = 0, sumSsf = 0
      for(let i=0; i<stageInfo.opList.length; i++){
        //处理运费
        if(!stageInfo.opList[i].dueAmt){ //金额为0的去运费
          stageInfo.opList[i].dueSsf = 0
        }else if(ssf && index == i){
          stageInfo.opList[i].dueSsf = ssf
        }

        sum += (stageInfo.opList[i].dueAmt + stageInfo.opList[i].dueSsf) 
        sumSsf += stageInfo.opList[i].dueSsf
      }
      stageInfo.dueAmt = Number(sum.toFixed(2))
      stageInfo.payRate = Number((stageInfo.dueAmt / state.totalApplayPrice).toFixed(4))
      stageInfo.payRatePercent = Number((stageInfo.payRate * 100).toFixed(2))
      stageInfo.dueSsf = Number(sumSsf.toFixed(2))
    },
  },
  actions: {
    // 自动试算最后一阶段
    autoComputedLastStageInfo({state}){
      let totalApplayPrice = state.totalApplayPrice
      let stageList = state.stageList
      let stageLen = stageList.length
      let stageSum = 0;
      let stageSumPayRate = 0
      let itemProductSum = {} //跨阶段每个商品累计金额
      let itemProductPayRateSum = {}

      // stageList.forEach((item, index) => {
      //   let isLast = !(index < stageLen - 1) //是否最后一阶段
      //   if(!isLast){
      //     item.opList.forEach(op => {
      //       if(itemProductSum[op.uuid]){
      //         itemProductSum[op.uuid] += op.dueAmt
      //         itemProductPayRateSum[op.uuid] += op.payRate
      //       }else{
      //         itemProductSum[op.uuid] = op.dueAmt
      //         itemProductPayRateSum[op.uuid] = op.payRate
      //       }
      //     })
      //   }
      // })

      
      stageList.forEach((item,index) => {
        let isLast = !(index < stageLen - 1) //是否最后一阶段
        if(!isLast){
          stageSum += item.dueAmt
          stageSumPayRate += item.payRate
          item.opList.forEach(op => {
            if(itemProductSum[op.uuid] !== undefined){
              itemProductSum[op.uuid] = Number((itemProductSum[op.uuid] + op.dueAmt).toFixed(2))
              itemProductPayRateSum[op.uuid] = Number((itemProductPayRateSum[op.uuid] + op.payRate).toFixed(4))
            }else{
              itemProductSum[op.uuid] = op.dueAmt
              itemProductPayRateSum[op.uuid] = op.payRate
            }
          })
        }else{ 
          console.log('itemProductSum',itemProductSum)
          /**
           * 最后一阶段，如果修改金额超出减除负数时，置0 不进行兜底，而是显示超出金额
           * 未超出时进行兜底
           */
          let lastTempAmt = 0 //最后一阶段商品总额
          let lastTempPayRate = 0
          item.opList.forEach(op => {
            if(itemProductSum[op.uuid] !== undefined){
              op.dueAmt = op.payPrice - itemProductSum[op.uuid] < 0 ? 0 : Number((op.payPrice - itemProductSum[op.uuid]).toFixed(2))
              op.payRate = 1 - itemProductPayRateSum[op.uuid] < 0 ? 0 : Number((1 - itemProductPayRateSum[op.uuid]).toFixed(4))
              op.payRatePercent = Number((op.payRate * 100).toFixed(2))
            }
            // lastTempAmt = Number((lastTempAmt + op.dueAmt).toFixed(2))
            // lastTempPayRate = Number((lastTempPayRate + op.payRate).toFixed(4))
            lastTempAmt += Number(op.dueAmt)
            lastTempPayRate = Number((lastTempAmt/totalApplayPrice).toFixed(4))
          })

          console.log('aaaaaaaaaa-->',Number((totalApplayPrice - stageSum).toFixed(2)) - Number(lastTempAmt.toFixed(2)))
          let flag = Number((totalApplayPrice - stageSum).toFixed(2)) - Number(lastTempAmt.toFixed(2)) >= 0 //是否超额 大于未超额
          item.dueAmt = flag ? Number((totalApplayPrice - stageSum).toFixed(2)) : Number(lastTempAmt.toFixed(2))
          item.payRate = flag ?  Number((1 - stageSumPayRate).toFixed(4)) : Number(lastTempPayRate.toFixed(4))
          item.payRatePercent = Number((100 - stageSumPayRate * 100).toFixed(2))
        }
        
      })

    },

    // 分2阶段时，自动试算另一阶段
    autoComputedOtherStageInfoWhenTwoStage({state}){
      console.log('autoComputedOtherStageInfoWhenTwoStage')
      // 1 --> 1 2 -> 0  
      const resetIndex = state.curStage === 1 ? 1 : 0
      const stageInfo = state.stageList[state.curStage - 1]
      const resetStageInfo = state.stageList[resetIndex]

      resetStageInfo.dueAmt = Number((state.totalApplayPrice - stageInfo.dueAmt).toFixed(2))
      resetStageInfo.payRate = Number((1 - stageInfo.payRate).toFixed(4))
      resetStageInfo.payRatePercent = Number((resetStageInfo.payRate * 100).toFixed(2))
      resetStageInfo.opList.forEach((item,index) => {
        item.dueAmt = Number((item.payPrice - stageInfo.opList[index].dueAmt).toFixed(2))
        item.payRate = Number((1 - stageInfo.opList[index].payRate).toFixed(4))
        item.payRatePercent = Number((item.payRate * 100).toFixed(2))
      })
    },


    //输入阶段金额，试算各个子商品金额
    /**
     * 1、所有都可分阶段
      每个商品支付比例  = 阶段输入金额 /总应付金额
      每个商品支付金额 =  每个商品比例 * 每个商品全额
      最后一个兜底

      2、部分可分阶段
        A商品500不能分阶段，本阶段应付金额用户输入的是1000(输入小于500 toast提示)，
        BC商品支付比例看各自商品总额占所有可以分阶段商品总金额占比
        B 占比 = B 总额 / 所有可以分阶段商品总金额
        B 金额 = (1000 - 500) * B占比
        C 占比 = C 总额 / 所有可以分阶段商品总金额
        最后一个兜底
     * 
     */
    updateStageProductItem({state}, type){
      console.log('根据阶段输入金额试算商品金额',type)
      //计算每个可分阶段商品金额在总商品金额的占比
      let prodcutCanPeriodTotalPrice = 0 //能分阶段的总金额
      let productNoPeriodTotalPrice = 0 // 不能分阶段的总金额
      let canPeriodLen = 0 //可分阶段商品数量
      let noPeriodLen = 0 //不可分阶段商品数量
      // let canPeriodCount = state.productList.find(item => item.canPeriod).length
      // console.log('canPeriodCount===>',canPeriodCount)
      //设置当前阶段金额
      const curStageInfo = state.stageList[state.curStage - 1]

      curStageInfo.opList.forEach(item => {
        if(item.canPeriod){
          canPeriodLen += 1
          prodcutCanPeriodTotalPrice += Number(item.payPrice)
        }else{
          noPeriodLen += 1
          productNoPeriodTotalPrice += Number(item.dueAmt)
        }
      })
      console.log('productNoPeriodTotalPrice===>',productNoPeriodTotalPrice, prodcutCanPeriodTotalPrice)
        
    
      if(type){ //按比例
        if((curStageInfo.inputPrice/100) * state.totalApplayPrice > (prodcutCanPeriodTotalPrice + productNoPeriodTotalPrice)){
          return {
            success: false,
            message: '阶段内含有不可分阶段商品, 金额应小于可分阶段商品金额之和'
          }
        }
        if((curStageInfo.inputPrice/100) < (productNoPeriodTotalPrice/state.totalApplayPrice)){
          return {
            success: false,
            message: '阶段内含有不可分阶段商品, 金额应大于不可分阶段商品之和'
          }
        }
  
        curStageInfo.dueAmt = Number((curStageInfo.inputPrice / 100 * state.totalApplayPrice).toFixed(2))
        curStageInfo.payRate = Number((curStageInfo.inputPrice/100).toFixed(4))
        curStageInfo.payRatePercent = Number((curStageInfo.payRate * 100).toFixed(2))

        // let len = curStageInfo.opList.length - 1
        let tempPriceSum = productNoPeriodTotalPrice //每个商品金额之和
        let canPeriodCount = 0;
        let tempStagePrice = curStageInfo.dueAmt - productNoPeriodTotalPrice // 输入阶段金额 - 不可分阶段之和
        
        curStageInfo.opList.forEach((item, index) => {
          if(item.canPeriod){
            canPeriodCount += 1
            if(canPeriodCount < canPeriodLen){
              let itemRate = item.payPrice / prodcutCanPeriodTotalPrice
              item.dueAmt = Number((tempStagePrice * itemRate).toFixed(2))
              item.payRate = Number((item.dueAmt / item.payPrice).toFixed(4))
              item.payRatePercent = Number((item.payRate * 100).toFixed(2))
              tempPriceSum += item.dueAmt
            }else{
              item.dueAmt = Number((curStageInfo.dueAmt - tempPriceSum).toFixed(2))
              item.payRate = Number((item.dueAmt/item.payPrice).toFixed(4))
              item.payRatePercent = Number((item.payRate * 100).toFixed(2))
            }
          }
        })

      }else{ //按金额
        if(curStageInfo.inputPrice > (prodcutCanPeriodTotalPrice + productNoPeriodTotalPrice)){
          return {
            success: false,
            message: '阶段内含有不可分阶段商品, 金额应小于可分阶段商品金额之和'
          }
        }
        if(curStageInfo.inputPrice < productNoPeriodTotalPrice){
          return {
            success: false,
            message: '阶段内含有不可分阶段商品, 金额应大于不可分阶段商品之和'
          }
        }
        curStageInfo.dueAmt = Number(curStageInfo.inputPrice)
        curStageInfo.payRate = Number((curStageInfo.inputPrice / state.totalApplayPrice).toFixed(4))
        curStageInfo.payRatePercent = Number((curStageInfo.payRate * 100).toFixed(2))

        // let len = curStageInfo.opList.length - 1
        let tempPriceSum = 0 //每个商品金额之和
        let canPeriodCount = 0;
        let tempStagePrice = curStageInfo.dueAmt - productNoPeriodTotalPrice // 输入阶段金额 - 不可分阶段之和
        curStageInfo.opList.forEach((item, index) => {
          if(item.canPeriod){
            canPeriodCount += 1
            if(canPeriodCount < canPeriodLen){
              let itemRate = item.payPrice / prodcutCanPeriodTotalPrice
              item.dueAmt = Number((tempStagePrice * itemRate).toFixed(2))
              item.payRate = Number((item.dueAmt / item.payPrice).toFixed(4))
              item.payRatePercent = Number((item.payRate * 100).toFixed(2))
              tempPriceSum = Number((item.dueAmt + tempPriceSum).toFixed(2))
            }else{
              item.dueAmt = Number((tempStagePrice - tempPriceSum).toFixed(2))
              item.payRate = Number((item.dueAmt / item.payPrice).toFixed(4))
              item.payRatePercent = Number((item.payRate * 100).toFixed(2))
            }
          }
        })

      }
    
      return {
        success: true
      }
      // curStageInfo.dueAmt = type ? Number((curStageInfo.inputPrice / 100 * state.totalApplayPrice).toFixed(2)) : Number(curStageInfo.inputPrice)
      // curStageInfo.payRate = type ? Number((curStageInfo.inputPrice/100).toFixed(4)) : Number((curStageInfo.inputPrice / state.totalApplayPrice).toFixed(4))
      // curStageInfo.payRatePercent = Number((curStageInfo.payRate * 100).toFixed(2))
        
    },

    //校验是否有阶段金额小于0.1的
    checkStageAmountValid({ state }){
      let checkValue = false
      let price = 0
      let ratePerent = 0
      state.stageList.forEach(item => {
        if(item.dueAmt < 0.1){
          checkValue = true
          if(item.dueAmt < 0){
            price = Math.abs(item.dueAmt)
            ratePerent = Math.abs(item.payRatePercent)
          }
        }
      })
      return {
        checkValue,
        price,
        ratePerent
      }
    },


    //计算与实际金额的差值
    computedAmountDifferent({state}){
      let curTotalPrice = 0, curTotalPayRate = 0
      let totalPrice = state.totalApplayPrice;

      let subProductPriceFlag = false //是否存在子商品价格总和与金额不符标识
      let subProdSumPrice = {}
      state.stageList.forEach(item => {
        //用于校验阶段支付金额是否不足或超出
        curTotalPrice += item.dueAmt
        curTotalPayRate += item.payRate
        //校验子商品金额是否超出或不足
        item.opList.forEach(oItem => {
          if(subProdSumPrice[oItem.uuid] !== undefined){
            subProdSumPrice[oItem.uuid] += oItem.dueAmt
          }else{
            subProdSumPrice[oItem.uuid] = oItem.dueAmt
          }
        })
      });

      state.productList.forEach(item => {
        console.log('subProdSumPrice[item.uuid]', subProdSumPrice[item.uuid], item.pam)
        if(Number(item.pam).toFixed(2) !== Number(subProdSumPrice[item.uuid]).toFixed(2)){
          subProductPriceFlag = true
        }
      })
      
      curTotalPrice = curTotalPrice.toFixed(2)
      let isEqual = (curTotalPrice > totalPrice) ? 1 : ((curTotalPrice < totalPrice) ? -1 : 0)
      let status = subProductPriceFlag && !isEqual ?  2 : isEqual
      let result = {
        status, //超额 1 ,不足 -1，相等 0, 2 子商品总金额不对
        price: Number(Math.abs(curTotalPrice - totalPrice).toFixed(2)),
        rate: Number(Math.abs(curTotalPayRate - 1).toFixed(4)),
        ratePerent: Number((Math.abs(curTotalPayRate - 1).toFixed(4) * 100).toFixed(2))
      }
      if(status === -1){
        result.message = '您目前设置的金额不足'
      }
      if(status === 1){
        result.message = '您目前设置的金额超出'
      }
      if(status === 2){
        result.message = '商品阶段拆分金额之和与商品总金额或比例不一致'
      }
      return result
    }
  }
}