const messageList = [
  {
    path: '/messageCenter',
    name: 'messageCenter',
    component: () => import('@/views/messageCenter/index.vue'),
    meta: {
      keepAlive: true,
      title: '平台消息',
      showTabbar: false,
      needLogin: true,
    },
  },
]

export default messageList
