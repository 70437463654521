
const orders = [
  {
    path: '/orderCashier/:orderSn',
    name: 'cashier',
    component: () => import('@/views/order/cashier'),
    meta: {
      showHeader: false,
      title: '智家收银台',
    }
  },
  {
    path: '/orderCashierEPay/:orderSn',
    name: 'ePay',
    component: () => import('@/views/order/cashier/epay-rn.vue'),
    meta: {
      showHeader: false,
      title: '工行E支付',
    },
  },
  {
    path: '/orderCashierB2BPay/:payUrl',
    name: 'b2bPay',
    component: () => import('@/views/order/cashier/b2b-pay.vue'),
    meta: {
      showHeader: false,
      title: '公对公支付',
    },
  },
  {
    path: '/stagePaySetting',
    name: 'stagePaySetting',
    component: () => import('@/views/order/stagePaySetting'),
    meta: {
      showHeader: false,
      title: '分阶段设置'
    }
  },
  {
    path: '/stagePaySettingDetail',
    name: 'stagePaySettingDetail',
    component: () => import('@/views/order/stagePaySetting/detail.vue'),
    meta: {
      showHeader: true,
      title: '分阶段设置详情'
    }
  },
  {
    path: '/freepwdpay',
    name: 'freepwdpay',
    component: () => import('@/views/freepwdPay'),
    meta: {
      showHeader: false,
      needLogin: true,
    }
  },
  {
    path: '/freeapply',
    name: 'freeapply',
    component: () => import('@/views/freepwdPay/freeapply'),
    meta: {
      title: '',
      needLogin: true,
      showHeader: false,
    }
  },
  {
    path: '/scanpay/:orderSn',
    name: 'scanpay',
    component: () => import('@/views/order/cashier/scan-pay.vue'),
    meta: {
      title: '线上聚合扫码支付',
      showHeader: false,
    }
  },
  {
    path: '/scanmiddle/:orderSn',
    name: 'scanmiddle',
    component: () => import('@/views/order/cashier/scan-middle.vue'),
    meta: {
      title: '线上聚合扫码支付',
    }
  },
  {
    path: '/freesubmit',
    name: 'freesubmit',
    component: () => import('@/views/freepwdPay/freesubmit'),
    meta: {
      showHeader: false,
      needLogin: true,
    }
  },
  {
    path: '/freestate',
    name: 'freestate',
    component: () => import('@/views/freepwdPay/freestate'),
    meta: {
      showHeader: false,
      needLogin: true,
    }
  },
  {
    path: '/applyagree',
    name: 'applyagree',
    component: () => import('@/views/freepwdPay/agree'),
    meta: {
      title: '中国工商银行无卡支付客户服务协议',
      needLogin: true,
    }
  },
  {
    path: '/freebanklist',
    name: 'freebanklist',
    component: () => import('@/views/freepwdPay/banklist'),
    meta: {
      title: '支持银行',
    }
  },
  {
    path: '/orderpaymiddle',
    name: 'ordermiddle',
    component: () => import('@/views/order/cashier/middlePage'),
    meta: {
      title: '支付',
      showHeader: false,
    }
  },
  {
    path: '/iframe',
    name: 'iframe',
    component: () => import('@/views/order/cashier/iframe'),
    meta: {
      title: '',
      showHeader: false,
    }
  },
  {
    path: '/orderCreate',
    name: 'create',
    component: () => import('@/views/order/create/index.vue'),
    meta: {
      keepAlive: false,
      title: '确认订单',
      showHeader: false,
      needLogin: true,
      awaitlogin: true
    }
  },
  {
    path: '/orderCreateNew',
    name: 'createNew',
    component: () => import('@/views/order/create/new.vue'),
    meta: {
      showHeader: false,
      needLogin: true,
      awaitlogin: true
    }
  },
  {
    path: '/orderTailold',
    name: 'tailOld',
    component: () => import('@/views/order/tail/index.vue'),
    meta: {
      keepAlive: false,
      title: '尾款结算',
    }
  },
  {
    path: '/orderTail',
    name: 'tail',
    redirect: '/orderCreateNew'
  },
  {
    path: '/orderVoucherold',
    name: 'ordervoucherold',
    component: () => import('@/views/order/voucher/index.vue'),
    meta: {
      keepAlive: false,
      title: '可用优惠券',
    }
  },
  {
    path: '/orderVoucher',
    name: 'ordervoucher',
    // component: () => import('@/views/order/voucher/index.vue'),
    component: () => import('@/views/order/voucher/new.vue'),
    meta: {
      keepAlive: false,
      title: '可用优惠券',
    }
  },
  {
    path: '/invoicenew',
    name: 'invoiceNew',
    component: () => import('@/views/order/invoiceNew/index.vue'),
    meta: {
      keepAlive: true,
      title: '开具发票',
      showHeader: false
    }
  },
  {
    path: '/orderInvoice',
    name: 'invoice',
    component: () => import('@/views/order/invoice/invoiceApply/index.vue'),
    meta: {
      keepAlive: true,
      title: '开具发票',
      showHeader: false
    }
  },
  {
    path: '/orderInvoiceList',
    name: 'orderInvoiceList',
    component: () => import('@/views/order/invoice/invoiceList/index.vue'),
    meta: {
      keepAlive: false,
      title: '发票列表',
      showHeader: false
    }
  },
  {
    path: '/invoiceHistory',
    name: 'invoiceHistory',
    component: () => import('@/views/order/invoice/invoiceHistory.vue'),
    meta: {
      keepAlive: false,
      title: '申请历史',
      showHeader: true
    }
  },
  {
    path: '/invoiceExpress',
    name: 'invoiceExpress',
    component: () => import('@/views/order/invoice/invoiceExpress/index.vue'),
    meta: {
      keepAlive: false,
      title: '填写发票快递信息',
      showHeader: false
    }
  },
  {
    path: '/manageInvoice',
    name: 'manageInvoice',
    component: () => import('@/views/order/invoice/invoiceApply/manageInvoice/index.vue'),
    meta: {
      keepAlive: false,
      title: '管理增票信息',
      showHeader: false
    }
  },
  {
    path: '/batchDeleteInvoice',
    name: 'batchDeleteInvoice',
    component: () => import('@/views/order/invoice/invoiceApply/batchDeleteInvoice/index.vue'),
    meta: {
      keepAlive: false,
      title: '批量删除增票信息',
      showHeader: false
    }
  },
  {
    path: '/editInvoiceInfo',
    name: 'editInvoiceInfo',
    component: () => import('@/views/order/invoice/invoiceApply/editInvoiceInfo/index.vue'),
    meta: {
      keepAlive: false,
      title: '编辑增票信息',
      showHeader: false
    }
  },
  {
    path: '/orderInvoiceStatus',
    name: 'orderInvoiceStatus',
    component: () => import('@/views/order/invoice/invoiceStatus/index.vue'),
    meta: {
      keepAlive: false,
      title: '开票状态',
      showHeader: false,
    }
  },
  {
    path: '/invoiceOverTime',
    name: 'invoiceOverTime',
    component: () => import('@/views/order/invoice/invoiceOvertime.vue'),
    meta: {
      keepAlive: false,
      title: '发票服务',
    }
  },
  {
    path: '/invoiceNodata',
    name: 'invoiceNodata',
    component: () => import('@/views/order/invoice/invoiceNodata.vue'),
    meta: {
      keepAlive: false,
      title: '发票服务',
    }
  },
  {
    path: '/invoiceInvalid',
    name: 'invoiceInvalid',
    component: () => import('@/views/order/invoice/invoiceInvalid.vue'),
    meta: {
      keepAlive: false,
      title: '发票服务',
    }
  },
  {
    path: '/invoiceReverse',
    name: 'invoiceReverse',
    component: () => import('@/views/order/invoice/invoiceReverse.vue'),
    meta: {
      keepAlive: false,
      title: '发票服务',
    }
  },
  {
    path: '/orderPay',
    name: 'pay',
    component: () => import('@/views/order/pay/index.vue'),
    meta: {
      keepAlive: false,
      title: '',
    }
  },
  {
    path: '/orderlistold',
    name: 'orderListOld',
    component: () => import('@/views/order/orderList/index.vue'),
    // component: () => import('@/views/order/newOrderList/index.vue'),
    meta: {
      keepAlive: true,
      title: '我的订单',
      needLogin: true,
      showHeader: false,
    }
  },
  {
    path: '/orderlist',
    name: 'orderList',
    // component: () => import('@/views/order/orderList/index.vue'),
    component: () => import('@/views/order/newOrderList/index.vue'),
    meta: {
      keepAlive: true,
      title: '我的订单',
      needLogin: true,
      showHeader: false,
    }
  },
  {
    path: '/storeOrderList',
    name: 'storeOrderList',
    component: () => import(
      '@/views/order/orderList/storeOrderList.vue'),
    meta: {
      keepAlive: false,
      title: '门店订单',
      needLogin: true,
      showHeader: true,
    }
  },
  {
    path: '/orderDetailOld',
    name: 'orderDetailOld',
    component: () => import('@/views/order/orderDetail/index.vue'),
    // component: () => import('@/views/order/newOrderDetail/index.vue'),
    meta: {
      keepAlive: false,
      title: '订单详情',
      showHeader: false,
    }
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('@/views/order/newOrderDetail/index.vue'),
    // component: () => import('@/views/order/orderDetail/index.vue'),
    meta: {
      keepAlive: false,
      title: '订单详情',
      showHeader: false,
      trk_origin: 'ddxq_dd',
    }
  },
  {
    path: '/refundProcess',
    name: 'refundProcess',
    component: () => import('@/views/order/refundProcess/index.vue'),
    meta: {
      keepAlive: false,
      title: '退款详情',
    }
  },
  {
    path: '/trackingOrders',
    name: 'trackingOrders',
    component: () => import('@/views/order/trackingOrders/index.vue'),
    meta: {
      keepAlive: false,
      title: '物流详情',
    }
  },
  {
    path: '/logistigsTrack',
    name: 'logistigsTrack',
    component: () => import('@/views/order/trackingOrders/logistigs-track.vue'),
    meta: {
      keepAlive: false,
      title: '物流详情',
    }
  },
  {
    path: '/mapTrackingOrders',
    name: 'mapTrackingOrders',
    component: () => import('@/views/order/mapTrackingOrders/index.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      title: '物流详情',
    }
  },
  {
    path: '/gpsRrsTrace',
    name: 'gpsRrsTrace',
    component: () => import('@/views/order/mapTrackingOrders/gpsRrsTrace.vue'),
    meta: {
      keepAlive: false,
      showHeader: true,
      title: '物流轨迹',
    }
  },
  {
    path: '/trackingDetail',
    name: 'trackingDetail',
    component: () => import('@/views/order/trackingDetail/index.vue'),
    meta: {
      keepAlive: true,
      title: '物流详情',
    }
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('@/views/order/refund/details.vue'),
    meta: {
      keepAlive: false,
      title: '退款详情',
    }
  },
  {
    path: '/refund',
    name: 'refund',
    component: () => import('@/views/order/refund/refund.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      title: '申请退款',
    }
  },
  {
    path: '/overtime',
    name: 'overtime',
    component: () => import('@/views/order/refund/overtime.vue'),
    meta: {
      keepAlive: false,
      // showHeader: false,
      title: '申请发票超时补偿',
    }
  },
  {
    path: '/overtime-detail',
    name: 'overtime-detail',
    component: () => import('@/views/order/refund/overtime-detail.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      title: '申请发票超时补偿详情',
    }
  },
  {
    path: '/equity',
    name: 'equity',
    component: () => import('@/views/order/refund/equity.vue'),
    meta: {
      keepAlive: false,
      title: '填写维权信息',
    }
  },
  {
    path: '/repair',
    name: 'repair',
    component: () => import('@/views/order/refund/repair.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '退款/售后',
    }
  },
  {
    path: '/consult',
    name: 'consult',
    component: () => import('@/views/order/refund/consult.vue'),
    meta: {
      keepAlive: false,
      title: '协商历史',
    }
  },
  {
    path: '/return',
    name: 'return',
    component: () => import('@/views/order/refund/return.vue'),
    meta: {
      keepAlive: false,
      title: '填写退货物流',
    }
  },
  {
    path: '/evaluate',
    name: 'evaluate',
    component: () => import('@/views/order/refund/evaluate.vue'),
    meta: {
      keepAlive: false,
      title: '发表评价',
    }
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('@/views/order/refund/success.vue'),
    meta: {
      keepAlive: false,
      title: '评价成功',
    }
  },
  {
    path: '/itSoluteDetail',
    name: 'itSoluteDetail',
    component: () => import('@/views/order/itSoluteDetail/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '场景详情',
    }
  },
  {
    path: '/itSoluteList',
    name: 'itSoluteList',
    component: () => import('@/views/order/itSoluteList/index.vue'),
    meta: {
      keepAlive: false,
      title: '解决方案',
    }
  },
  {
    path: '/intelligentScene',
    name: 'intelligentScene',
    component: () => import('@/views/order/intelligentScene/index.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      title: '智慧场景',
    }
  },
  {
    path: '/viewEvaluation',
    name: 'viewEvaluation',
    component: () => import('@/views/order/refund/view-evaluation.vue'),
    meta: {
      keepAlive: false,
      title: '查看评价',
    }
  },
  {
    path: '/review',
    name: 'review',
    component: () => import('@/views/order/refund/review.vue'),
    meta: {
      keepAlive: false,
      title: '追加评价',
    }
  },
  {
    path: '/reviewSuccess',
    name: 'reviewSuccess',
    component: () => import('@/views/order/refund/review-success.vue'),
    meta: {
      keepAlive: false,
      title: '评价成功',
    }
  },
  {
    path: '/orderSearch',
    name: 'orderSearch',
    component: () => import('@/views/order/orderSearch/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '订单搜索',
    }
  },
  {
    path: '/orderSearchOld',
    name: 'orderSearchOld',
    component: () => import('@/views/order/orderSearch/old.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '订单搜索',
    }
  },
  {
    path: '/thridEvaluate',
    name: 'thridEvaluate',
    component: () => import('@/views/order/thridEvaluate/index.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      title: '发表评价', // 外部订单评价
      needLogin: true,
    }
  },
  {
    path: '/agentPay',
    name: 'agentPay',
    component: () => import('@/views/order/create/agentPay/index.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      title: '代客下单', 
      needLogin: true,
    }
  },
  {
    path: '/userOrderList',
    name: 'userOrderList',
    component: () => import('@/views/order/userOrderList/index.vue'),
    meta: {
      keepAlive: true,
      title: '顾客订单',
      needLogin: false,
      showHeader: false,
    }
  },
  {
    path: '/reserve',
    name: 'reserve',
    component: () => import('@/views/order/reserve/index.vue'),
    meta: {
      keepAlive: true,
      title: '预约配送',
      needLogin: true,
    }
  },
]

export default orders
