import utils from '@/utils/utils'
import { INTERNAL_AUTHENTICATION_APPID_PROD, INTERNAL_AUTHENTICATION_APPID_TEST,INTERNAL_AUTHENTICATION_CLIENTID_TEST, INTERNAL_AUTHENTICATION_CLIENTID_PROD } from '@/config/constKeys'
import { Toast } from 'vant'
export default {
  namespaced: true,
  state: {
    ihaier2UserInfo: utils.strJSONParse(localStorage.getItem('ihaier2UserInfo'), null),
  },
  getters: {
    ihaier2UserInfo(state) {
      return state.ihaier2UserInfo
    }
  },
  mutations: {
    setIhaier2UserInfo(state, info) {
      state.ihaier2UserInfo = info
      console.log('setIhaier2UserInfo====444=====>',JSON.stringify(info))
      localStorage.setItem('ihaier2UserInfo', JSON.stringify(info))
    }
  },
  actions: {
    async getIhaier2UserInfo({ commit }) {
      try {
        // 文档： https://ihaier.feishu.cn/docs/doccnQnVLYjvgJFWGlUeC2XkvMp
        const { configUserCenter, login } = window.__USERCENTER__
        // console.log('ENV====>', !utils.isProdEnv())
        // console.log('getIhaier2UserInfo===>', window.__USERCENTER__)
        configUserCenter({
          clientId: utils.isProdOrPreEnv() ? INTERNAL_AUTHENTICATION_CLIENTID_PROD : INTERNAL_AUTHENTICATION_CLIENTID_TEST, // 账号中心cliendtId,此内容在应用中心-基础配置中获取
          ssoUrl: utils.isProdOrPreEnv() ? 'https://iama.haier.net' : 'https://iam-test.haier.net', // 账号中心域名
          appId: utils.isProdOrPreEnv() ? INTERNAL_AUTHENTICATION_APPID_PROD : INTERNAL_AUTHENTICATION_APPID_TEST, // 开放平台创建应用获取（open.feishu.cn）
          tokenUrl: utils.isProdOrPreEnv()  ? 'https://m.ehaier.com/v3/platform/ihaier/code2token.json' : 'https://m-test.ehaier.com/v3/platform/ihaier/code2token.json',
        })
        console.log('login start=============777=======>')
        const { userInfo, success } = await login({'invalidateToken': true})
        console.log('login success========>', success)
        if (success) {
          console.log('getIhaier2UserInfo===>', userInfo)
          commit('setIhaier2UserInfo', userInfo)
        } else {
          Toast('员工数据获取异常，请重新登录ihaier后重试。')
          utils.closeLarkWebView()
        }
      } catch (error) {
        Toast('员工数据获取异常，请您重新登录ihaier后重试。')
        utils.closeLarkWebView()
      }
    }
  }
}
