
const sceneLight = [
  {
    path: '/sceneLight',
    name: 'sceneLight',
    redirect: '/quickPlaceOrder',
    component: () => import('@/viewsToB/sceneLight'),
    meta: {
      keepAlive: false,
      title: '快下单',
      showHeader: false,
    }
  },
  {
    path: '/sceneLightDetail',
    name: 'sceneLightDetail',
    component: () => import('@/viewsToB/sceneLight/sceneDetail'),
    meta: {
      keepAlive: false,
      title: '',
      showHeader: false,
    }
  },
  {
    path: '/myScheme',
    name: 'myScheme',
    component: () => import('@/viewsToB/myScheme/index.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      title: '我的方案',
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/viewsToB/searchs/index.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      title: '搜索',
    }
  },

]

export default sceneLight
