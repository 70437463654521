const couponsCollectWithScan = [{
  path: '/couponsCollectWithScan',
  name: 'couponsCollectWithScan',
  component: () => import('@/views/couponsCollectWithScan/index.vue'),
  meta: {
    title: '扫码领券',
    showHeader: false,
    needLogin: true,
    showTabbar: false,
  }
}]

export default couponsCollectWithScan
