const goods = [
  {
    path: '/goodsDetail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "goodsDetail" */ /* webpackPrefetch: true */ '@/views/goods/detail/index.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      showTabbar: false,
      title: '商品详情',
      hasdownload: true,
      trk_origin: 'spxq_all',
    },
  },
  {
    path: '/goodsAnswer',
    name: 'goodsanswer',
    component: () => import('@/views/goods/answer/list.vue'),
    meta: {
      title: '问答专区',
      showHeader: false,
    }
  },
  {
    path: '/goodsAnswerDetail/:id',
    name: 'goodsanswerdetail',
    component: () => import('@/views/goods/answer/detail.vue'),
    meta: {
      title: '问题详情',
      showHeader: false,
    }
  },
  {
    path: '/goodsAnswerAdd',
    name: 'goodsansweradd',
    component: () => import('@/views/goods/answer/add.vue'),
    meta: {
      title: '我要提问',
      showHeader: false,
      needLogin: true,
    }
  },
  {
    path: '/goodsToAnswer/:id',
    name: 'goodstoanswer',
    component: () => import('@/views/goods/answer/answer.vue'),
    meta: {
      title: '我来回答',
      needLogin: true,
      showHeader: false
    }
  },
  {
    path: '/goodsToReplay/:id',
    name: 'goodstoreplay',
    component: () => import('@/views/goods/answer/replay.vue'),
    meta: {
      title: '问题详情',
      needLogin: true,
      showHeader: false
    }
  },
  {
    path: '/goodsAnswerMine',
    name: 'goodsanswermine',
    component: () => import('@/views/goods/answer/mine.vue'),
    meta: {
      title: '我的问答',
      needLogin: true,
    }
  },
  {
    path: '/goodsComment',
    name: 'comment',
    component: () => import('@/views/goods/comment/index.vue'),
    meta: {
      keepAlive: false,
      showTabbar: false,
      title: '商品评价',
    }
  },
  {
    path: '/hotImgs',
    name: 'hotImgs',
    component: () => import('@/views/goods/hotImgs/hot-imgs.vue'),
    meta: {
      keepAlive: false,
      showTabbar: false,
      title: '晒图评价',
      showHeader: false,

    }
  },
  {
    path: '/serviceInner',
    name: 'serviceInner',
    component: () => import('@/views/goods/customer-service/index.vue'),
    meta: {
      keepAlive: false,
      // showTabbar: false,
      showHeader: false,
      title: '客服',
    }
  },
  {
    path: '/customerService',
    name: 'customerService',
    component: () => import('@/views/goods/customer-service/container.vue'),
    meta: {
      keepAlive: false,
      showTabbar: false,
      showHeader: false,
      title: '海尔智家售前在线客服',
      needLogin: true,
    }
  },
  {
    path: '/customerServiceWx',
    name: 'customerServiceWx',
    component: () => import('@/views/goods/customer-service/wx-customer-service.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      title: '海尔智家售前在线客服',
      needLogin: true,
    }
  },
  {
    path: '/collectDiscount',
    name: 'collectDiscount',
    component: () => import('@/views/goods/collectDiscount/index.vue'),
    meta: {
      title: '凑单推荐',
    }
  },
  // {
  //   path: '/exclusiveFinish',
  //   name: 'exclusiveFinish',
  //   component: () => import('@/views/goods/exclusive-finish/index.vue'),
  //   meta: {
  //     keepAlive: false,
  //     showTabbar: false,
  //     title: '提醒',
  //   }
  // }
]

export default goods
