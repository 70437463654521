import { PlatformTypes } from '@/config/constMaps'
import Qs from 'qs'
import { isEmpty } from 'lodash'
import UplusApi from '@uplus/uplus-api'
import ua from './ua'
import type Utils from './utils.d'

const instance = new UplusApi()
instance.initDeviceReady()


let trackPlatform: PlatformTypes = PlatformTypes.COMMON

const utils: Partial<Utils> = {
  /**
   * @desc 新的埋点方法
   * @param {object} config 必传对象 config.platformConf是某次埋点的具体规则，包扩埋点支持的env、eventId和variable字段集合；config.commonParams是说所有支持的platform下，都需要上报的参数。
   * @desc config注意事项：
      1. config.platformConf中的key使用[PlatformTypes.key]，例如[PlatformTypes.EG]，添加环境可以在PlatformTypes中添加枚举项，定好key，value则等于将1左移n(n < 31)位。
      2. 埋点方法内trackPlatform匹配config.platformConf.key的逻辑是 枚举值 > 枚举值位运算 > [PlatformTypes.COMMON]
      3. 埋点最终上报的参数是commonParams与platformConf.xx.param两个集合的并集。
      4. 若在某埋点不需要传参数，则param和commonParams可以不传也可以传空数组
   * @param {object} variable 非必传对象 包含埋点的全部字段，埋点方法内会根据platform来判断eventId和所需的参数来进行上报，若埋点不需要传参数，调用trackWithVariable可以不传variable。
   * 示例如下：
    trackWithVariable({
      platformConf: {
        // config的key实际上是一个数字，取值为[PlatformTypes.key]。
        [PlatformTypes.EG]: {
          id: "BD023021",
          param: ["origin"],
        },
        // 以下是位运算写法，表示SYN_APP、SYN_H5和SC_H5环境下共用同一个埋点规则
        [PlatformTypes.SYN_APP | PlatformTypes.SYN_H5 | PlatformTypes.SC_H5]: {
          id: "H5283813",
          param: ["md_name"],
        },
        // 除以上列出的platform，其余的情况统一使用COMMON埋点规则，若不传COMMON则表示 “除以上列出的platform外，不上报埋点”
        [PlatformTypes.COMMON]: {
          id: "H5283815",
        }
      },
      // 所有支持的platform都需要上报的参数。
      commonParams: ['store_id', 'md_code']
    }, {
      store_id: this.tarckInfo.storeId || null,
      md_code: this.tarckInfo.mdCode || null,
      md_name: this.tarckInfo.mdName || null,
      orgin: this.tarckInfo.origin || null,
      aaa: "1231321",
      bbb: 1231,
      ccc: 80830918231,
      abchdads: "演示参数多传的情况，为了适应各端参数不同或不一致的情况",
    })
   */
  async trackWithVariable(config: { platformConf: PlatformConf; commonParams?: Array<string> }, variable?: Record<string, string | number>) {

    try {
      // 确认当前的trackPlatform，只执行一次
      this.getCurrentPlatform()
      if (!trackPlatform) return
      const platformConf: PlatformConf = config.platformConf
      // 枚举值精确匹配
      let event = platformConf[trackPlatform] || null

      const COMMON_TYPE: PlatformTypes = Number(PlatformTypes.COMMON)
      if (!event) {
        // 枚举值位运算匹配
        for (const key in platformConf) {
          const k = Number(key)
          if (k === COMMON_TYPE) continue
          const ok = Number(trackPlatform)
          if (ok & k) {
            event = platformConf[key]
            break
          }
        }
      }
      // 取common
      if (!event) event = platformConf[COMMON_TYPE] || null
      // console.log('found event', event)
      if (!event) {
        return {
          success: true,
          message: 'ua not support',
          params: null,
        }
      }
      event.param = Array.from(new Set([...(config.commonParams || []), ...(event.param || [])]))
      if (event.param?.length && !variable) {
        return {
          success: false,
          message: 'variable not found',
          params: null,
        }
      }

      type Params = {
        eventId: string | number
        variable?: Record<string, string>
      }
      const params: Params = {
        eventId: event.id
      }
      if (event.param?.length && variable) {
        params.variable = {}
        event.param.forEach((el: string | number) => {
          params.variable[el] = variable[el] === undefined ? 'null' : `${variable[el]}` || 'null'
        })
      }
      if (ua.isHaierApp()) {
        await instance.initDeviceReady()
        if (params.variable) {
          await instance.upTraceModule.gioTrackWithVariable(params)
        } else {
          await instance.upTraceModule.gioTrack(params)
        }
        console.log('haierApp report', params)
        return {
          success: true,
          params,
        }
      } else if (window.gio) {
        await window.gio('track', params.eventId, params.variable || {})
        console.log('gio report', params)
        return {
          success: true,
          params,
        }
      } else {
        return {
          success: false,
          message: 'gio not found',
          params,
        }
      }
    } catch (err) {
      return {
        success: false,
        message: 'report failed',
        params: null,
        err,
      }
    }
  },
  getCurrentPlatform() {
    if (trackPlatform !== PlatformTypes.COMMON) return trackPlatform
    if (ua.isInternalChannel()) {
      trackPlatform = PlatformTypes.INTERNAL_CHANNEL
    } else if (ua.isSyBird()) {
      trackPlatform = PlatformTypes.SYN_APP
    } else if (ua.isUplus()) {
      trackPlatform = PlatformTypes.UPLUS
    } else if (ua.isSgRn()) {
      trackPlatform = PlatformTypes.SG_RN
    } else if (ua.isShopKeeper()) {
      trackPlatform = PlatformTypes.EG
    } else if (ua.isIMaker()) {
      trackPlatform = PlatformTypes.IMAKER
    } else if (ua.isScMpAli()) {
      trackPlatform = PlatformTypes.SC_MP_ALI
    } else if (ua.isScMpWechat()) {
      trackPlatform = PlatformTypes.SC_MP_WX
    } else if (ua.isScMpByte()) {
      trackPlatform = PlatformTypes.SC_MP_BYTE
    } else if (ua.isSyBirdWeChatMP()) {
      trackPlatform = PlatformTypes.SYN_MP_WX
    } else if (ua.isSyBirdBaiduMP()) {
      trackPlatform = PlatformTypes.SYN_MP_BAIDU
    } else if (ua.isSYNDP()) {
      trackPlatform = PlatformTypes.SYN_DP
    } else if (ua.isSyBirdH5()) {
      trackPlatform = PlatformTypes.SYN_H5
    } else if (ua.isZhiJiaMP()) {
      trackPlatform = PlatformTypes.SC_MP_ZJ_WX
    } else {
      trackPlatform = PlatformTypes.SC_H5
    }
    return trackPlatform
  },
  /**
   * @desc 日期格式化
   */
  dateFormat(fmt: string, date: Date) {
    let ret
    // type Normal =
    const opt: Record<string, string> = {
      'Y+': date.getFullYear().toString(), // 年
      'm+': (date.getMonth() + 1).toString(), // 月
      'd+': date.getDate().toString(), // 日
      'H+': date.getHours().toString(), // 时
      'M+': date.getMinutes().toString(), // 分
      'S+': date.getSeconds().toString(), // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    }
    for (const k in opt) {
      ret = new RegExp('(' + k + ')').exec(fmt)
      if (ret) {
        fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
      }
    }
    return fmt
  },

  /**
   * 获取APP信息
   * @returns 
   */
  async getAppInfo(): Promise<Record<string, any>>{
    if (ua.isHaierApp()) {
      await instance.initDeviceReady()
      const { retCode, retInfo, retData } = await instance.upAppinfoModule.getAppInfo()
      console.log(retCode, retInfo)
      return retData || null
    }
  },

  /**
   * 返回客户端定义字段
   * 后端channelId的值
   */
  getBackEndChannelId():number|string{
    if(ua.isUplus()){
      return 61
    }else if(ua.isSyBird()){
      return 62
    }else if(ua.isShopKeeper()){
      return ua.isIos() ? 78 : 77
    }else {
      return 60
    }
  },


  /**
   * 手机号隐藏中间4位
   */
  phoneFormat(tel:Number | string):string{
    const reg = /^(\d{3})\d{4}(\d{4})$/;  
    return tel.toString().replace(reg, "$1****$2");
  },
  /**
   * 延迟执行
   */
  setTimeOutFn(fn: Function, delay = 200) {
    const t = setTimeout(() => {
      clearTimeout(t)
      fn()
    }, delay)
  },
  /**
   * 延迟尝试执行n次
   * fn 函数返回 true 时代表终止
   */
  setTimeOutAsyncFnCount(fn: Function, delay = 200, times = 3) {
    let count = 1
    const run = () => {
      if(count > times) return
      count++;
      const t = setTimeout(async () => {
        clearTimeout(t)
        const end = await fn()
        if(!end){
          run()
        }
      }, delay)
    }
    run()
  },

  /**
   * 十六进制颜色转rgba
   */
  hexToRgba(hex: string, opacity: number) {
    return 'rgba(' + parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ',' + parseInt('0x' + hex.slice(5, 7)) + ',' + opacity + ')'
  },

  /** 保留几位小数不足补零 非四舍五入*/
  formatNumDot(value: number, n = 2):string {
    // 去掉小数点 存为数组
    const arrayNum = value.toString().split(".");
    if(n === 0) return arrayNum[0]
    //只有一位（整数）
    if (arrayNum.length == 1) {
      return value.toString() + "." + "0".repeat(n);
    }
    if (arrayNum.length > 1) {
      //小数点右侧 如果小于n位 则补n个0
      if (arrayNum[1].length < n) {
        return value.toString() + "0".repeat(n-arrayNum[1].length);
      }else{ //直接截取
        return arrayNum[0] +'.' + arrayNum[1].toString().slice(0,n)
      }
    }
  },

  /**
   * 检测url并拼接字符串
   */
  checkUrladdString(targetUrl: string, str: string) {
    if (targetUrl.indexOf('#') !== -1) {
      const arr = targetUrl.split('#')
      let startStr = arr[0]
      const endStr = arr[1]
      if (arr[0].indexOf('?') !== -1) {
        startStr += `&${str}`
      } else {
        startStr += `?${str}`
      }
      targetUrl = startStr + '#' + endStr
    } else {
      if (targetUrl.indexOf('?') !== -1) {
        targetUrl += `&${str}`
      } else {
        targetUrl += `?${str}`
      }
    }
    return targetUrl
  },
  /**
   * 获取根元素html上面设置的字体大小，用于计算以rem单位的值
   */
  getHtmlFontSize(): number {
    return parseInt(window.getComputedStyle(document.documentElement, null).getPropertyValue('font-size'))
  },
  /**
   * 数字格式化，小于10的数字补齐为2位
   */
  numberToDouble(num: number):string {
    return  (num < 10 ? ('0' + num) : ('' + num));
  },
  
  /**
   * 对象转url query串
   * @param queryObj 
   * @returns 
   */
  jsonToQueryUrl(queryObj: any){
    const queryParams = new URLSearchParams()
    Object.entries(queryObj).forEach(([key, value]) => {
      queryParams.append(key, value as string)
    })
    return queryParams.toString()
  },
  /**
   * 字符型json串 处理
   * @param json str
   * @param defalutVal 默认值
   */
  strJSONParse(jsonstr: string, defalutVal: Object): Object {
    try {
      return JSON.parse(jsonstr) || defalutVal
    } catch (err) {
      return defalutVal
    }
  },
  /** 
   * log colorTag
   */
  logColor(tagName = 'Log', bgColor = 'red', ...arg){
    if(process.env.VUE_APP_ENV !== 'production'){
      console.log(`%c${tagName}`,`padding: 0 4px;border-radius:4px;color:white;background:${bgColor};`, ...arg)
    }
  },
  
  /**
   * 数字转汉字
   * @param num 
   * @returns 
   */
  convertToChinaNum(num: number) {
    const arr1 = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    const arr2 = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千','万', '十', '百', '千','亿'];//可继续追加更高位转换值
    if(!num || isNaN(num)){
      return "零";
    }
    const english = num.toString().split("")
    let result = "";
    for (let i = 0; i < english.length; i++) {
      const des_i = english.length - 1 - i;//倒序排列设值
      result = arr2[i] + result;
      const arr1_index = english[des_i];
      result = arr1[arr1_index] + result;
    }
    //将【零千、零百】换成【零】 【十零】换成【十】
    result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十');
    //合并中间多个零为一个零
    result = result.replace(/零+/g, '零');
    //将【零亿】换成【亿】【零万】换成【万】
    result = result.replace(/零亿/g, '亿').replace(/零万/g, '万');
    //将【亿万】换成【亿】
    result = result.replace(/亿万/g, '亿');
    //移除末尾的零
    result = result.replace(/零+$/, '')
    //将【零一十】换成【零十】
    //result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
    //将【一十】换成【十】
    result = result.replace(/^一十/g, '十');
    return result;
  },
  /**
   * 容器参数添加 处理
   * @param url str
   * @param params 参数的key和value 非必填
   * @param containerParams 容器所需的参数 非必填
   */
  checkUrlAddParams (url: string, params?: { [key: string]: any }, containerParams?: { [key: string]: any }) {
    try {
      if (!url) return ''

      const parseParamsToQuery = (p) => {
        let query = ''
        if (p) {
          for (const [key, value] of Object.entries(p)) {
            if (value !== null && value !== undefined && value !== '') { // 数字 “0” 要保留, false 要保留
              query = query + key + '=' + value + '&'
            }
          }
          return query.substring(0, query.length - 1)
        }
        return ''
      }

      const dealNormalUrl = (nomalUrl: string, nomalParams: { [key: string]: any }) => {
        if (nomalUrl.indexOf('#') > nomalUrl.indexOf('?')) {
          const urlArr = nomalUrl.split('#')
          if (urlArr.length === 1) urlArr.push('/')
          urlArr[urlArr.length - 1] = dealNormalUrl(urlArr[urlArr.length - 1], nomalParams)
          nomalUrl = urlArr.join('#')
        } else {
          const urlArr = nomalUrl.split('?')
          if (urlArr.length === 1) urlArr.push('')
          const queryString = urlArr[urlArr.length - 1]
          const p = queryString ? Qs.parse(queryString) : {}
  
          urlArr[urlArr.length - 1] = parseParamsToQuery({
            ...p,
            ...nomalParams
          })
          nomalUrl = urlArr.join('?')
        }
        return nomalUrl
      }

      if (containerParams && !isEmpty(containerParams)) {
        if (url.includes('#')) {
          const urlArr = url.split('#')
          urlArr[0] = dealNormalUrl(urlArr[0], containerParams)
          url = urlArr.join('#')
        } else {
          url = dealNormalUrl(url, containerParams)
        }
      }

      if (params && !isEmpty(params)) {
        url = dealNormalUrl(url, params)
      }
      // const urlArr = url.split('#')
      // const domainUrlArr = urlArr[0].split('?')
      // let domainUrl = ''
      // for (let i = 0; i < domainUrlArr.length; i++) {
      //   domainUrl += `${domainUrlArr[i]}${!i ? '?underneathStatusBar=1' : ''}${domainUrlArr[i + 1] ? (i ? '?' : '&') : ''}`
      // }
      // urlArr[0] = domainUrl
      // url = urlArr.join('#')
    } catch (err) {
      console.log(err)
    }

    return url
  },
  
  setLocalStorage(key: string, value: string) {
    const isDef = function (v) {
      return v !== undefined && v !== null
    }
    if (value === 'undefined' || !isDef(value)) {
      return
    }
    try {
      window.localStorage.setItem(key, value)
    } catch (err) {
      console.log(err);
    }
  },

  /**
   * 获取剪贴板内容
   * 1.haier app中使用uplus api
   * 2.浏览器中使用clipboard api
   */
  getClipboard(failMsg?: string): Promise<string> {
    const commonRejectMsg = failMsg || '读取剪贴板失败'

    return new Promise((resolve, reject) => {
      if (ua.isHaierApp()) {
        instance.initDeviceReady().then(() => {
          // 当初写这块功能时，uplus的getClipboard存在问题，导致app崩溃，后续更新升级uplus即可。再遇到app崩溃，可考虑这个原因。
          instance.upSystemModule.getClipboard().then(result => {
            console.log(result);
            const { retCode, retData, retInfo } = result
            if (retCode === '000000') {
              resolve(retData.value)
            } else {
              console.error(retInfo);
              reject(commonRejectMsg)
            }
          }).catch(e => {
            console.error(e);
            reject(commonRejectMsg)
          })
        }).catch(e => {
          console.error(e);
          reject(commonRejectMsg)
        })
      } else {
        if (navigator.clipboard) {
          if (navigator.permissions) {
            navigator.permissions.query({ name: 'clipboard-read' as PermissionName}).then(permissionStatus => {
              if (permissionStatus.state === 'denied') {
                reject('请设置浏览器以允许读取剪贴板或手动输入')
              } else {
                navigator.clipboard.readText().then(text => {
                  resolve(text)
                }).catch(e => {
                  console.error(e);
                  reject(commonRejectMsg)
                })
              }
            })
          } else {
            reject(commonRejectMsg)
          }
        } else {
          reject(commonRejectMsg)
        }
      }
    })
  },

}

export default utils