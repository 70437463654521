const category = [
  {
    path: '/category',
    name: 'category',
    component: () => import('../../views/category/index.vue'),
    meta: {
      keepAlive: true,
      title: '分类',
      showTabbar: true,
      showInternalTabbar: true,
      showHeader: false,
    },
  },
  {
    path: '/categoryInternal',
    name: 'category',
    component: () => import('@/views/category/internal.vue'),
    meta: {
      keepAlive: true,
      title: '分类',
      showTabbar: false,
      showInternalTabbar: false,
      showHeader: false,
    },
  },
  {
    path: '/categoryList',
    name: 'categoryList',
    component: () => import('../../views/category/categoryList.vue'),
    meta: {
      title: '智慧家电',
      trk_origin: 'zhjd_pb',
    }
  }
]

export default category
