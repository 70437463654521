export default [
  {
    path: '/unconfirmList',
    name: 'unconfirmList',
    component: () => import('@/viewsToB/partner/unconfirmList.vue'),
    meta: {
      showHeader: true,
      showTabbar: false,
      title: '待确认合伙人',
    },
  },
]