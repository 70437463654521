import { getCanOrNotUseTitokCouponList } from '@/http/service/create.ts'

export default {
  namespaced: true,
  state: {
    // tailUseCouponCodes: utils.strJSONParse(sessionStorage.getItem('sg_tailorder_usecouponcodes'), [])//尾款用券券码
    mdInfoList: [], //新的门店信息
    userSelectMdInfo: {}, //用户选择门店信息

    gdtcSelectedInfo: null, //柜电套餐选中的核销商品
    creatOrderPageInfo: null,
  },
  getters: {
    // getTailUseCouponCodes(state) {
    //   return state.tailUseCouponCodes || []
    // },
    creatOrderPageInfo: state => state.creatOrderPageInfo,
    getUserSelectMdInfo(state) {
      return state.userSelectMdInfo
    },
    getMdInfoList(state) {
      return state.mdInfoList
    },

    getGdtcSelectedInfo(state) {
      return state.gdtcSelectedInfo
    }
  },
  mutations: {
    // setTailUseCouponCodes(state, payload) {
    //   state.tailUseCouponCodes = payload
    //   sessionStorage.setItem('sg_tailorder_usecouponcodes', JSON.stringify(payload))
    // }
    setCreatOrderPageInfo(state, creatOrderPageInfo){
      state.creatOrderPageInfo = creatOrderPageInfo
    },
    setMdInfoList(state, payload) {
      state.mdInfoList = payload || []
    },
    setUserSelectMdInfo(state, payload) {
      state.userSelectMdInfo = payload || {}
    },

    setGdtcSelectedInfo(state, info){
      state.gdtcSelectedInfo = info
    }
  },
  actions: {
    async fetchCanOrNotUseTitokCouponList({ state }, useOrNot){
      let mdProducts = state.creatOrderPageInfo?.mdOrders[0] || null
      let products = mdProducts?.ops?.map(item => {
        return {
          sku: item.sku,
          price: item.price,
          number: item.num,
          mdCode: mdProducts.mdCode,
          mdName: mdProducts.mdName,
          storeCode: item.osc,
          storeName: item.osName,
          brandId: item.br,
          departmentCode: item.dep,
          opUuid: item.uuid,
        }
      }) || []

      let choiceCouponCodeList = []
      state.creatOrderPageInfo?.orderCoupons?.forEach(item => {
        if(item.type === 'TIKTOK'){
          choiceCouponCodeList = item.selectedCouponCodes || []
        }
      }) || []

      const { success, message, data: result } = await getCanOrNotUseTitokCouponList({
        orderUuid: state.creatOrderPageInfo?.orderUuid,
        choiceCouponCodeList,
        products,
        useOrNot,
      })
      console.log('getCanOrNotUseTitokCouponList===>',success, message, result)
      return {
        success,
        message,
        result,
      }
    }
  }
}
