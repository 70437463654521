import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue'
import UplusApi from '@uplus/uplus-api';
import App from './App.vue'
import util from './utils/utils'
import store from './store'
import router from './router'
import http from './http/http'
import * as filters from '@/utils/filters'
import toggleSwitch from './config/toggleSwtich'
import './config/globalVant'
import vanImg from '@/components/vanimg'
import SvgIcon from '@/components/svgIcon'
import ReviewTimeToast from '@/components/reviewTimeToast';
// import CommonAlert from '@/components/alert/common-alert.vue'
import './assets/styles/reset.css'
import 'vant/lib/index.css'
import './assets/styles/common.css';
import './assets/styles/icon.css';
import './assets/styles/vant-modify.css'; // 自定义样式覆盖vant原生
import './utils/iconUtil' // 注册全局icon对象
import Loading from './components/loading/index'
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { version } from '../package.json'
import HOST from '@/config'
import { TIM_SDK_APPID, TIM_SDK_APPID_TEST } from '@/config/constKeys'
import TIM from 'tim-js-sdk'
import URL from '@/http/url.js'
// polyfill  intersection-observer 兼容性问题
import __ from 'intersection-observer'
import '@vant/touch-emulator';

import directives from '@/directives'

Vue.use(directives)

util.loadScript('https://cdn09.ehaier.com/shunguang/H5/www/iconfont/font_3209078_gstj2jffru/iconfont.js')

const useProdAppId = (process.env.VUE_APP_ENV !== 'development') ? 1 : 0
// 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
const tim = TIM.create({
  SDKAppID: useProdAppId ? TIM_SDK_APPID : TIM_SDK_APPID_TEST
}) // SDK 实例通常用 tim 表示
tim.setLogLevel(useProdAppId) // 0 普通级别，日志量较多，接入时建议使用；1 release级别，SDK 输出关键信息，生产环境时建议使用

Vue.prototype.$TIM = TIM
Vue.prototype.$tim = tim
window.$tim = tim
window.$TIM = TIM

if (process.env.VUE_APP_MOCK) {
  // const { worker } = await import('./mocks/browser');
  // worker.start()
}

// 阻止Promise抛出reject但是没有写catch
window.onunhandledrejection = (event) => {
  console.log(event)
  // 在生产线上环境 拦截上报，隔离promise没有catch的问题
  if (util.isProdDistribution()) {
    event.preventDefault(); // 需要
  } else {
    // alert('你的Promise没有catch,请立即查看控制台并修复,原因: ' + event.reason)
    console.error(event.reason)
  }
}

// 仅在生产环境 线上版本引入sentry 忽略ihaier1.0 和 老掌柜的报错
if (util.isProdDistribution() && !util.isSgRn() && !util.isIhaierApp()) {
  Sentry.init({
    release: version,
    dsn: "https://52c0342623da4458a29ba02f80813171@ss.haier.net/69",
    ignoreErrors: [
      /NotAllowedError:\w+/,
      "Can't find variable: $",
      'NotSupportedError',
      '$ is not defined',
      "Can't find variable: CloudHubJSBridge",
      'ToutiaoJSBridge is not defined',
      "Object [object Object] has no method '_handleMessageFromToutiao'",
      'AbortError', // 被操作中断的异常
    ],
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    logErrors: true,
  });
}


// throw new Error('test sentry')
// store.dispatch('account/refreshUserToken')

const instance = new UplusApi()
instance.initDeviceReady()
Vue.use(ReviewTimeToast);
Vue.use(SvgIcon)
Vue.use(vanImg)
Vue.use(Loading)

// 缩短300ms点击延迟
// var FastClick = require('fastclick');
// FastClick.attach(document.body);
// util.winTorem()
util.checkWebpFeature('lossy', function(feature, result) {
  Vue.prototype.$webpSurpport = result
})

// Vue.component('common-alert', CommonAlert);
Vue.config.productionTip = false
Vue.prototype.$http = http
Vue.prototype.$toggleSwitch = toggleSwitch;
Vue.prototype.$util = util;
// 全局CDN图片跟路径
Vue.prototype.$cdnImageUrl = HOST.HOST_PIC;
Vue.prototype.$aliossUrl = HOST.HOST_PIC_ALOSS
// 图片名称后缀
Vue.prototype.$cdnImageUrlSuffix = util.isSyBird() ? 'syn' : '';
// 主题
Vue.prototype.$theme = {
  color: util.isSyBird() ? '#be965a' : '#2283E2',
};
// 图片验证码
Vue.prototype.$HOST = HOST.HOST;
// 注册全局过滤器
Object.keys(filters).forEach(filter => {
  Vue.filter(filter, filters[filter])
})

// 优化keep alive组件的销毁过程，防止返回时 创建新的vnode实例
const tmp = Vue.prototype.$destroy;
const f = function() {
  if (this.$vnode && this.$vnode.data.keepAlive) {
    if (this.$vnode.parent && this.$vnode.parent.componentInstance && this.$vnode.parent.componentInstance.cache) {
      if (this.$vnode.componentOptions) {
        var key = this.$vnode.key == null
          ? this.$vnode.componentOptions.Ctor.cid + (this.$vnode.componentOptions.tag ? `::${this.$vnode.componentOptions.tag}` : '')
          : this.$vnode.key;
        var cache = this.$vnode.parent.componentInstance.cache;
        var keys = this.$vnode.parent.componentInstance.keys;
        if (cache[key]) {
          if (keys.length) {
            var index = keys.indexOf(key);
            if (index > -1) {
              keys.splice(index, 1);
            }
          }
          delete cache[key];
        }
      }
    }
  }
  tmp.apply(this, arguments);
}
Vue.prototype.$keepAliveDestroy = f;

// 对keepAlive的页面进行记录滚动位置
Vue.mixin({
  // data() {
  //   return {
  //      sg_scrollTop: 0, 注释后改为非响应式 滚动位置不需要响应式
  //   }
  // },
  methods: {
    getCleanQuerys () {
      const query = util.strJSONParse(JSON.stringify(this.$route.query), {})
      const deleteKeys = ['access_token', 'async', 'addressInfo', 'streetCode', 'closewebview', 'sg_rn_app', 'userCategory', 'userIdentity']

      deleteKeys.forEach(element => {
        delete query[element]
      })

      return query
    },
  },
  beforeRouteLeave(to, from, next) {
    // 只针对 keep-alive 的页面记录滚动位置
    let $content = document.querySelector('#app-warp'); // 列表的外层容器
    let scrollTop = $content ? $content.scrollTop : 0;
    console.log('scrollTop', scrollTop)
    // from.meta.scrollTop = scrollTop;  //解决多页面同组件 滚动位置共享问题
    this.sg_scrollTop = scrollTop;
    next();
  },
  activated() {
    let scrollTop = this.sg_scrollTop;
    if (scrollTop && scrollTop !== 0) {
      // 初次进入页面不执行，返回页面执行
      let $content = document.querySelector('#app-warp');
      if ($content) {
        this.$nextTick(() => {
          $content.scrollTop = scrollTop;
        })
      }
    }
    /**
     * 新增自定义选项gioBeforeMethods，当methods中存在同名方法时候提前调用
     */
    if (this.$options.gioBeforeMethods) {
      Object.keys(this.$options.gioBeforeMethods).forEach(i => {
        if (!this[i].beforeRewrite) {// 如果同名方法重写一次后不再重写
          const originFunc = this[i]
          this[i] = (...p) => {
            this.$options.gioBeforeMethods[i] = this.$options.gioBeforeMethods[i].bind(this)
            this.$options.gioBeforeMethods[i](...p)
            originFunc(...p)
          }
          this[i].beforeRewrite = 'done'
        }
      })
    }
    /**
     * 新增自定义选项gioAfterMethods，当methods中存在同名方法时候最后调用
     */
    if (this.$options.gioAfterMethods) {
      Object.keys(this.$options.gioAfterMethods).forEach(i => {
        if (!this[i].afterRewrite) {// 如果同名方法重写一次后不再重写
          const originFunc = this[i]
          this[i] = (...p) => {
            originFunc(...p)
            this.$options.gioAfterMethods[i] = this.$options.gioAfterMethods[i].bind(this)
            this.$options.gioAfterMethods[i](...p)
          }
          this[i].afterRewrite = 'done'
        }
      })
    }
  }
})

if (!util.isProdDistribution()) {
  // 非生产、线上部署环境下 打开devtools
  Vue.config.devtools = true;
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

// // vconsole + vue-vconsole-devtools
// if(!util.isProdDistribution() && process.env.VUE_APP_V_CONSLOE === 'show'){
//   Promise.all([import("vconsole"), import("vue-vconsole-devtools")]).then(
//     (res) => {
//       if (res.length === 2) {
//         Vue.config.devtools = true;
//         window.__VUE_DEVTOOLS_GLOBAL_HOOK__.emit("init",Vue)
//         const VConsole = res[0].default;
//         const Devtools = res[1].default;
//         Devtools.initPlugin(new VConsole());
//       }
//     }
//   );
// }


// 监听安卓物理返回键
if (util.isAndroid()) {
  instance.initDeviceReady().then(() => {
    instance.androidPhysicalBtForBack({
      process: true,
      deviceChangeListener: () => {
        console.log('process为true，android物理返回动作时触发,在此执行相应逻辑');
        store.dispatch('doHistoryBack')
      },
      eventKey: 'backEvent'
    });
  }).catch(e => { console.log(e) });
}

// 处理移动端滚动穿透问题
Vue.prototype.$modalHelper = ((bodyCls) => {
  let scrollTop;
  return {
    afterOpen: function () {
      scrollTop = document.scrollingElement.scrollTop;
      document.body.classList.add(bodyCls);
      document.body.style.top = -scrollTop + 'px';
    },
    beforeClose: function () {
      document.body.classList.remove(bodyCls);
      // scrollTop lost after set position:fixed, restore it back.
      document.scrollingElement.scrollTop = scrollTop;
    }
  };
})('dialog-open')
// 内嵌iframe调用返回时 可能需要关闭容器，暴露接口
window.addEventListener('message', function(event) {
  const data = event.data
  try {
    const dataObj = JSON.parse(data)
    if (dataObj.type === 'close') {
      store.dispatch('doHistoryBack')
    }
  } catch (error) {
    // console.log(error)
  }
})
