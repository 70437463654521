
import ToastComponent from './toast';

export default {
  install (Vue) {
    const ToastConstructor = Vue.extend(ToastComponent);
    const instance = new ToastConstructor();
    var timer = null;
    Vue.prototype.$reviewTimetoast = function(params = {}) {
      instance.$mount(document.createElement('div'));
      document.body.appendChild(instance.$el);

      instance.params = params;
      instance.show = true;
      instance.type = params.type || 1
      instance.time = params.time || 30;
      clearTimeout(timer);
      timer = setTimeout(() => {
        instance.show = false;
        clearTimeout(timer);
      }, params.duration || 5000);
    }
  }
};
