import Vue from 'vue'
import Vuex from 'vuex'
import utils from '@/utils/utils'
import uplusAccount from './uplus/account'
import uplusLocation from './uplus/location'
import account from './modules/account'
import location from './modules/location'
import md5 from '@/utils/md5'
import ihaier2Account from './ihaier2.0/account'
import sgRnAccount from './sgRn/account'
import personalApi from '@/http/service/index';

import cart from './modules/cart'
import common from './modules/common'
import personalCenter from './modules/personalCenter';
import bankcard from './modules/bankcard'
import freebankcard from './modules/freebankcard'
import ecard from './modules/ecard'
import smartChoice from './modules/smartChoice'
import answerArea from './modules/answerArea'
import createOrder from './modules/createorder'
import orderInvoice from './modules/orderInvoice'
import assignOrder from './modules/assignOrder'
import cardQuan from './modules/cardQuan'
import shop from './modules/shop'
import history from './modules/history'
import orderRight from './modules/orderRight'
import rankList from './modules/rankList'
import chat from './modules/chat'
import order from './modules/order'
import goodsDetail from './modules/goodsDetail'
import slotmac from './modules/slotmac'
import quickPlaceOrder from './modules/quickPlaceOrder'
import attrMutiPop from './modules/attrMutiPop'
import stagePay from './modules/stagePay'
import shoppingList from './modules/shoppingList'

import router from '@/router'
import UplusApi from '@uplus/uplus-api';

import axios from '../http/http'
import URL from '@/http/url'

import { getUcLoginUrl } from '@/http/service/common.js'
import { getOrderCreateUrl } from '@/http/service/goods.js'
import mymanage from './modules/mymanage';
import ClipboardJS from 'clipboard'; // 复制插件
import { Toast } from '@sg/sg-vant'
import { customServiceSceneMap, customServiceSceneMapNew } from '@/config/constMap'
import { DEFAULT_LOGO_SHARE, DEFAULT_TITLE_SHARE, DEFAULT_DESC_SHARE } from '@/config/constKeys'
import { PlatformTypes } from '@/config/constMaps'


const instance = new UplusApi()
instance.initDeviceReady()

if (process && process.env.NODE_ENV === 'test') {
  UplusApi.instance = instance
}

Vue.use(Vuex)

// 客服默认accountId
const DEFAULT_ACCOUNT_ID = utils.isSyBird() ? 'ThreeWingedBird' : 'zhijiashop'
const PID = utils.isUplus() ? 'zjapp' : utils.isShopKeeper() ? 'ygapp' : utils.isSyBird() ? 'synapp' : 'm'
export const defaultLocation = { // 默认地址  18个属性
  region: '山东 青岛 崂山区 中韩街道',
  provinceId: 16,
  province: '山东',
  cityId: 173,
  city: '青岛',
  districtId: 2450,
  district: '崂山区',
  townshipId: 12036596,
  township: '中韩街道',
  latitude: '36.12784',
  longitude: '120.41803',
  cityCode: '370200',
  districtCode: '370212',
  provinceCode: '370000',
  townshipCode: '370212001000',
  detailAddress: '', //详细地址
  addressId: '',  //地址id 用户收货地址我的地址里的
  sourceType: 'default' //地址类型: default默认 user_select用户定位  location: app自动定位 first_shipping_address：默认收货地址
}
// if (utils.isShopKeeper()) {
//   defaultLocation = {
//     region: '',
//     provinceId: 0,
//     province: '',
//     cityId: 0,
//     city: '',
//     districtId: 0,
//     district: '',
//     townshipId: 0,
//     township: '',
//     latitude: '',
//     longitude: '',
//     cityCode: '',
//     districtCode: '',
//     provinceCode: '',
//     townshipCode: '',  
//     detailAddress: '',  
//     addressId: '',
//     sourceType: 'default'
//   }
// }
// const defaultDeliveryAddress = { // 默认配送地址
//   region: '山东 青岛 崂山区 中韩街道',
//   provinceId: 16,
//   province: '山东',
//   cityId: 173,
//   city: '青岛',
//   districtId: 2450,
//   district: '崂山区',
//   townshipId: 12036596,
//   township: '中韩街道',
//   latitude: '36.12784',
//   longitude: '120.41803',
//   type: 'default', // default:默认 location:定位 userSelected:用户选择
// }
// console.log(333, utils)
// console.log('parseURLQueryValue===>', utils.parseURLQueryValue(window.location.href, 'downloadAppType'))

export const APP_USER_ADDR_KEY = 'eshop_user_address'


export const Store = {
  state: {
    isLogin: utils.strJSONParse(localStorage.getItem('isLogin'), false),
    ucId: localStorage.getItem('ucId'),
    token: localStorage.getItem('token'),
    sdToken: localStorage.getItem('sdToken'),
    accessToken: localStorage.getItem('accessToken'),
    mId: localStorage.getItem('mId'),
    storeCode: localStorage.getItem('storeCode'), // 店铺88码
    downloadAppType: utils.parseURLQueryValue(window.location.href, 'downloadAppType') || 1, // 页面顶部显示下载APP引导头类型 0-不显示 1-引导打开智家App，2-引导打开三翼鸟App 3-智家掌柜
    refreshToken: localStorage.getItem('refreshToken'),
    expiresSeconds: localStorage.getItem('expiresSeconds'),

    sourceType: sessionStorage.getItem('sc_source_type'),
    sourceCode: sessionStorage.getItem('sc_source_code'),

    // sessionKey: localStorage.getItem('sessionKey'),
    // sessionValue: localStorage.getItem('sessionValue'),
    userInfo: utils.strJSONParse(localStorage.getItem('userInfo'), {}),
    userIdentifyInfo: utils.strJSONParse(localStorage.getItem('userIdentifyInfo'), {}),
    isHasStore: utils.strJSONParse(localStorage.getItem('isHasStore'), false),
    locationInfo: defaultLocation,
    // isDefaultLocation: localStorage.getItem('isDefaultLocation') ? JSON.parse(localStorage.getItem('isDefaultLocation')) : true, // 是否默认定位地址标识
    // deliveryAddress: localStorage.getItem('deliveryAddress') ? JSON.parse(localStorage.getItem('deliveryAddress')) : defaultDeliveryAddress,
    reviewTimeInfo: {}, // 浏览时长相关数据
    reviewSetInteral: null, // 浏览时长计时器
    globTips: '', // DSG-45604 商家平台菜单功能说明配置
    pathName: '/',
    shareId: sessionStorage.getItem('shareUserId') || '',
    needLogin: false,
    isShareTask: false, // 分享任务标记 如果是分享任务至为 true，分享结束至为 false
    traceSource: '', // 产业跳转m站任意页面 业务来源统计字段
    inputUserName: '', // 商家中心跳转店铺页 用户账号
    inputMobile: '', // 商家中心跳转店铺页 用户绑定手机号

    locationStatus: true // 工作台环境下是否能获取到定位，false时展示定位选择遮罩
  },
  mutations: {
    // setDefaultLocation: (state, obj) => {
    //   defaultLocation = obj
    //   state.locationStatus = true
    // },
    setLocationStatus: (state, value) => {
      state.locationStatus = value
    },
    // setLocatinAreaCode: (state, areaCode) => {
    //   state.locationInfo = {
    //     ...state.locationInfo,
    //     ...areaCode,
    //   }
    //   localStorage.setItem('location', JSON.stringify(state.locationInfo))
    // },
    setDownloadAppType: (state, type) => { state.downloadAppType = type },
    setLoginStatus: (state, status) => { state.isLogin = status },
    setAuthorzeToken: (state, token) => { state.token = token },
    setSdToken: (state, sdToken) => { state.sdToken = sdToken },
    setUcId: (state, ucid) => { state.ucId = ucid },
    setmId: (state, mId) => { state.mId = mId },
    setSourceTypeAndCode: (state, { sourceType, sourceCode }) => { state.sourceType = sourceType; state.sourceCode = sourceCode },
    setExpiresSeconds: (state, expiresSeconds) => { state.expiresSeconds = expiresSeconds },
    setRefreshToken: (state, refreshToken) => { state.refreshToken = refreshToken },
    setStoreCode: (state, storeCode) => { state.storeCode = storeCode },
    setAcsToken: (state, accessToken) => { state.accessToken = accessToken },
    setUserInfo: (state, userInfo) => { state.userInfo = userInfo },
    setUserIdentifyInfo: (state, userIdentifyInfo) => { state.userIdentifyInfo = userIdentifyInfo },
    setSessionKey: (state, sessionKey) => { state.sessionKey = sessionKey },
    setSessionValue: (state, sessionValue) => { state.sessionValue = sessionValue },
    setHasStore: (state, status) => { state.isHasStore = status },
    setIsShareTask: (state, status) => { state.isShareTask = status },
    setTraceSource: (state, status) => { state.traceSource = status },
    setInputUserName: (state, inputUserName) => { state.inputUserName = inputUserName },
    setInputMobile: (state, inputMobile) => { state.inputMobile = inputMobile },
    setToken: (state, token) => { state.token = token },
    setLocationInfo: (state, locationInfo) => {
      console.log('setLocationInfo====>123', locationInfo)
      if (!locationInfo || !locationInfo.provinceId) return
      // // 各页面触发该方法是，有可能覆盖掉之前保存国标码，所以增加此逻辑
      // if (state.locationInfo.townshipId === locationInfo.townshipId) {
      //   locationInfo = {
      //     ...locationInfo,
      //     ...state.locationInfo,
      //   }
      // }
      state.locationInfo = locationInfo
      localStorage.setItem('location', JSON.stringify(locationInfo))
      //app全局存key
      if(utils.isUplus()){
        utils.setAppStorageStringValue(APP_USER_ADDR_KEY, JSON.stringify(locationInfo))
      }
    },
    // 浏览时长相关数据
    setReviewTimeInfo: (state, info) => {
      state.reviewTimeInfo = Object.assign({}, state.reviewTimeInfo, info);
    },
    setShowTips: (state, code) => {
      state.globTips = code
    },
    // setDeliveryAddress: (state, locationInfo) => {
    //   state.deliveryAddress = {
    //     ...locationInfo,
    //   }
    //   localStorage.setItem('deliveryAddress', JSON.stringify(state.deliveryAddress));
    // },
    // setDeliveryAddressByDefault: (state) => {
    //   state.deliveryAddress = {
    //     ...defaultDeliveryAddress,
    //   }
    //   localStorage.setItem('deliveryAddress', JSON.stringify(state.deliveryAddress));
    // },
    setIntercept: (state, path) => {
      state.pathName = path
    },
    setNeedLogin: (state, need) => {
      state.needLogin = need
    },
    resetState: (state) => {
      state.isLogin = false;
      state.ucId = ''
      state.token = ''
      state.sdToken = ''
      state.accessToken = ''
      state.refreshToken = ''
      state.mId = ''
      state.userInfo = ''
      state.isHasStore = ''
      state.deliveryAddress = ''
    },
    setShareId: (state, shareid) => { state.shareId = shareid },
  },
  getters: {
    getLoginStatus: state => state.isLogin,
    ucId: state => state.ucId,
    sdToken: state => state.sdToken,
    token: state => state.token,
    accessToken: state => state.accessToken,
    mId: state => state.mId || '',
    storeCode: state => state.storeCode,
    sourceTypeAndCode: state => ({ sourceType: state.sourceType, sourceCode: state.sourceCode }),
    getUserInfo: state => state.userInfo,
    getUserIdentifyInfo: state => state.userIdentifyInfo,
    getSessionKey: state => state.sessionKey,
    getSessionValue: state => state.sessionValue,
    getHasStore: state => state.isHasStore,
    getLocation: state => { return { latitude: state.locationInfo.latitude, longitude: state.locationInfo.longitude } },
    getlocationInfo: state => {
      return state.locationInfo || defaultLocation
    },
    getReviewTimeInfo: state => state.reviewTimeInfo,
    getShareId: state => state.shareId,
    // 获取用户中心Token，（免登时到三翼鸟的token，容器内使用sdToken，非容器内使用accessToken（有区别，待后端修改））
    getUserCenterToken: state => {
      if (utils.isHaierApp()) {
        return state.sdToken || ''
      } else {
        return state.accessToken || ''
      }
    }
    // getDeliveryAddress: state => state.deliveryAddress,
  },
  actions: {
    async getIntactSceneUrl(context, { url, params }) {
      try {
        if (!url) return ''
        let query = {}
        query.userId = this.state.ucId || ''
        if (url?.indexOf('sceneDetail') > -1) {
          query.shareId = this.state.mId || ''
          query.shareUserId = this.state.ucId || ''
        }

        const getters = this.getters
        if (!utils.isHaierApp()) {
          const { token } = await this.dispatch('account/refreshUserToken')
          query.userAccessToken = token || getters.getUserCenterToken || ''
        }
        query.userPhone = getters.getUserInfo.phone || ''
        if (getters.getlocationInfo) {
          query.cityCode = getters.getlocationInfo.cityCode
          query.districtCode = getters.getlocationInfo.districtCode
          query.provinceCode = getters.getlocationInfo.provinceCode
          query.streetCode = getters.getlocationInfo.townshipCode
        }
        if (params) {
          query = { ...query, ...params }
        }
        return utils.checkUrlAddParams(url, query)
      } catch (err) {
        console.log(err)
        return ''
      }
    },
    queryIdentity({ commit }) {
      console.log('queryIdentity===========>')
      return axios.get(URL.GET_IDENTIFY_INFO).then(({ data }) => {
        let result = data;
        if (result.success) {
          commit('setUserIdentifyInfo', result.data)
          localStorage.setItem('userIdentifyInfo', JSON.stringify(result.data))
        } else {
          return 400
        }
      })
    },
    isHasStore({ dispatch, commit }, { mid, sdToken }) {
      let url = URL.GET_HAS_STORE
      return axios.get(url, {
        params: {
          memberId: mid
        },
        headers: { 'Authorization': sdToken },
      }).then(({ data }) => {
        if (data.success) {
          commit('setHasStore', data.data)
          localStorage.setItem('isHasStore', data.data)
        } else {
          return 400
        }
      })
    },
    // doTouristsLogin({ dispatch }) {
    // return dispatch('account/doTourists')
    // },
    // 从智家直接进入需要用户登录认证相关页面的处理逻辑
    handleUpAuth({ commit, dispatch }) {
      // 获取智家sdToken
      return dispatch('uplusAccount/getUplusToken').then(res => {
        // axios.get('/abc.json?res=' + JSON.stringify(res))
        if (res === 400) {
          return false
        } else {
          if (this.state.isLogin) {
            let sdToken = res.retData.user_center_access_token
            let oldSdToken = localStorage.getItem('accessToken')
            utils.logColor('cccccccc', 'blue', sdToken !== oldSdToken)
            // 切换账号 用新token换登录
            if (sdToken !== oldSdToken) {
              //切换账号重置定位key
              if(utils.isHaierApp()){
                commit('setLocationInfo', defaultLocation)
              }
              return dispatch('uplusAccount/getAuthorize', { sdToken }).then(result => {
                // axios.get('/ddd.json?result=' + JSON.stringify(result))
                if (result === 400) {
                  return false
                } else {
                  commit('setLoginStatus', true)
                  return true
                }
              })
            } else {
              commit('setLoginStatus', true)
              return true
            }
          } else {
            let sdToken = res.retData.user_center_access_token
            return dispatch('uplusAccount/getAuthorize', { sdToken }).then(result => {
              // axios.get('/eee.json?result=' + JSON.stringify(result))
              if (result === 400) {
                return false
              } else {
                commit('setLoginStatus', true)
                return true
              }
            })
          }
        }
      })
    },
    /**
     * logout 退出登录
     * @param {*} context
     * @param {*} route 跳转路由，为null的时候不跳转
     */
    logout({ dispatch, commit }, route) {
      console.log('logout ==========>')
      return new Promise((resolve, reject) => {
        personalApi.logout({}).then(async res => {
          console.log('logout api success ==========>')
          // 清除store的数据
          dispatch('resetStore')
          // 清除gio的用户id
          gio && gio('clearUserId');
          // 调getToken获取游客token
          // await dispatch('doTouristsLogin')
          if (route) {
            router.replace(route)
          }
          resolve()
          if (utils.isShopKeeper()) {
            instance
              .initDeviceReady()
              .then(() => {
                instance.upStorageModule
                  .setTemporaryStorage({
                    key: 'eshop_main_storage_key_h5_logout',
                    value: 'true',
                  })
                  .then(
                    (result) => console.log('result', result),
                    (error) => console.log('error', error)
                  )
              }).catch((e) => {
                console.log(e)
              })
          }
        })
      })
    },
    // 去登录
    doLogin({ commit, dispatch }, callBackPath) {
      if (utils.isHaierApp()) {
        // 工作台中 为了避免跳转登录后可以通过手势返回，改为工作台特殊的跳转登录方式
        if (utils.isShopKeeper()) { 
          return instance
            .initDeviceReady()
            .then(() => {
              instance.upStorageModule
                .setTemporaryStorage({
                  key: 'eshop_main_storage_key_h5_logout',
                  value: 'true',
                })
                .then(
                  (result) => console.log('result', result),
                  (error) => console.log('error', error)
                )
            }).catch((e) => {
              console.log(e)
            })
        }
        return dispatch('callUplusLoginPage').then(res => {
          // 优家登录成功
          if (res !== 400) {
            // 取优家token，并交换成我们的token
            return dispatch('uplusAccount/getUplusToken').then(result => {
              if (result === 400) {
                return 400
              } else {
                // let sdToken = result.retData.sdToken || result.retData.extras.sdToken
                commit('setLoginStatus', true)
                let sdToken = result.retData.user_center_access_token
                console.log('====================================');
                console.log(3);
                console.log('====================================');
                return dispatch('uplusAccount/getAuthorize', { sdToken }).then(rsd => {
                  return rsd
                })
              }
            })
          } else {
            // 优家登录失败
            // localStorage.setItem('token', '')
            localStorage.setItem('sessionValue', '')
            commit('setLoginStatus', false)
            return 400
          }
        })
      } else if (utils.isScMp() || utils.isSyBirdMP()) {
        // 商城小程序\三翼鸟小程序环境需要拉起小程序登录页面
        if (!callBackPath) {
          callBackPath = window.location.href
        }
        utils.loadUniMPEnvDeps().then(() => {
          uni.postMessage({
            data: {
              type: ['path'],
              path: encodeURIComponent(callBackPath)
            }
          })
          if (utils.isScMp()) {
            uni.navigateTo({ url: '/pages/publicPages/webViewLazyLogin/loginHelp' })
          } else if (utils.isSyBirdMP()) {
            uni.navigateTo({ url: '/pages/login/login' })
          }
        }).catch(e => {
          // 拉取失败处理
          console.log(e)
        })
      } else if (utils.isZhiJiaMP() || utils.isZhiJiaTTMP()) { // 智家App小程序
        if (!callBackPath) {
          callBackPath = window.location.href
        }
        const encodeCallback = encodeURIComponent(callBackPath)
        console.log('智家App小程序dologin===', `/subPagesC/login/login?queryFromWebview=${encodeCallback}`);
        utils.loadUniMPEnvDeps().then(() => {
          uni.navigateTo({ url: `/subPagesC/login/login?queryFromWebview=${encodeCallback}` })
        }).catch(e => {
          // 拉取失败处理
          console.log('小程序拉取失败');
          console.log(e)
        })
      } else if (utils.isSyBirdH5()) { // 三翼鸟H5环境登录
        let gap = '?'
        if (window.location.href.indexOf('?') >= 0) {
          if (window.location.search.length > 0) {
            gap = '&'
          } else {
            gap = ''
          }
        }
        const targetUrl = encodeURIComponent(`${decodeURIComponent(window.location.href)}${gap}access_token=`)
        if (utils.isProdOrPreEnv()) {
          utils.openNewWindow(`https://sybird-oss.haier.net/resource/scene/index.html#/login?type=order&afterLoginUrl=${targetUrl}`, true)
        } else {
          utils.openNewWindow(`https://syntest.haier.net/resourceTest/scene/index.html#/login?type=order&afterLoginUrl=${targetUrl}`, true)
        }
      } else if (utils.isSgRn()) {
        window.postMessage(JSON.stringify({
          type: 'login'
        }))
      } else if (utils.isInternalChannel()) {
        Toast('登录状态失效。');
        // 内购环境登录状态异常关闭容器
        utils.closeLarkWebView()
      } else {
        if (callBackPath) {
          router.push({
            path: '/login',
            query: {
              callBackPath: encodeURIComponent(callBackPath)
            }
          })
        } else {
          router.push('/login')
        }
      }
    },
    // 掉智家登录页
    callUplusLoginPage({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        instance.initDeviceReady().then(() => {
          instance.upVdnModule.goToPageForResult({
            url: utils.isShopKeeper() ? 'flutter://eshop.main/login' : 'mpaas://usercenter'
          }).then((res) => {
            console.log('mpaas://usercenter', res);
            if (res.retData.cancelLogin) {
              resolve(400)
            } else {
              resolve(true)
            }
            // if (res.retData.loginByH5 || res.retData.loginSuccess) {
            //   commit('setLoginStatus', true)
            //   resolve(res)
            // } else {
            //   localStorage.setItem('sessionValue', '') // 设置 游客token axios TokenAuthorization 使用 sessionValue
            //   commit('setLoginStatus', false)
            //   resolve(400)
            // }
          }).catch((e) => {
            if (e.retCode === '110002') {
              resolve(true)
            } else {
              localStorage.setItem('sessionValue', '')
              commit('setLoginStatus', false)
              resolve(400)
            }
          });
        }).catch(e => { console.log(e) });
      })
    },
    // h5 登录
    doH5Login({ dispatch }, params) {
      return dispatch('account/doLogin', params)
    },
    doRegister({ dispatch }, params) {
      return dispatch('account/doRegister', params)
    },
    async getBase64MemberInfo({ commit, dispatch }, { mId }) {
      return personalApi.getMemberInfo({ memberId: mId }).then(res => {
        if (res.data.success) {
          if (res.data.data.ucMemberId) {
            const data = {
              ucMemberId: res.data.data.ucMemberId,
              userName: res.data.data.userName,
              mobile: res.data.data.mobile,
              email: res.data.data.email,
            }
            localStorage.setItem('userBase64Info', JSON.stringify(data))
          }
        } 
      })
    },
    // 根据商城accessToken获取用户信息
    async getMember({ dispatch, commit, state }, { sdToken, expiresSeconds, refreshToken, ignoreDefaultDeliveryAddress }) {
      let url = URL.GET_USERINFO
      return axios.get(url).then(async ({ data }) => {
        let resp = data
        if (resp.success) {
          let result = resp.data
          let mid = result.mid
          let ucid = result.ucId
          let userInfo = {}
          userInfo.username = result.userName
          userInfo.avatarImage = result.avatarImageFileId
          userInfo.loginName = result.loginName
          userInfo.birthday = result.birthday
          userInfo.phone = result.mobile
          userInfo.email = result.email
          userInfo.cartNumber = result.cartNumber
          userInfo.gender = result.gender
          userInfo.isNewUser = result.isNewUser
          userInfo.isStoreMember = result.isStoreMember
          userInfo.nickName = result.nickName
          userInfo.rankName = result.rankName
          userInfo.promotionCode = result.promotionCode
          userInfo.resultSign = result.resultSign

          commit('setLoginStatus', true);
          commit('setmId', mid);
          commit('setUcId', ucid);
          commit('setAcsToken', sdToken)
          commit('setUserInfo', userInfo)
          commit('setExpiresSeconds', expiresSeconds)
          commit('setRefreshToken', refreshToken)
          dispatch('getBase64MemberInfo', { mId: mid })

          utils.logColor('ignoreDefaultDeliveryAddress', 'yellow', ignoreDefaultDeliveryAddress)
          if (state.locationInfo.sourceType === 'default' && !ignoreDefaultDeliveryAddress) {
            dispatch('defaultDeliveryAddress', null, { root: true })
          }

          // set locaStorage
          localStorage.setItem('isLogin', true)
          localStorage.setItem('ucId', ucid)
          localStorage.setItem('accessToken', sdToken)
          localStorage.setItem('mId', mid)
          localStorage.setItem('expiresSeconds', expiresSeconds)
          // localStorage.setItem('refreshToken', refreshToken)
          utils.setLocalStorage('refreshToken', refreshToken || '')
          localStorage.setItem('userInfo', JSON.stringify(userInfo))
          try {
            dispatch('chat/timLogin')
          } catch (err) {
            console.log(err)
          }

          gio && gio('setUserId', ucid);

          dispatch('isHasStore', { mid, sdToken })
          dispatch('queryIdentity')
          
          return resp
        } else {
          if (utils.isHaierApp()) { // APP内
            console.log('++++++++++++++++++')
            commit('setLoginStatus', false)
            await dispatch('logout', null)
            return 400
          } else { // 普通h5
            console.log('==============')
            commit('setLoginStatus', false)
            await dispatch('logout', null)
            return resp
          }
        }
      }).catch(async e => {
        commit('setLoginStatus', false)
        await dispatch('logout', null)
        return 400
      })
    },

    // 获取经纬度
    doLocation({ dispatch }, force) {
      // let fixPosition = false // 是否需要重新定位
      // let locationStr = localStorage.getItem('location')
      // let locationInfo
      // if (!locationStr) {
      //   fixPosition = true
      // } else {
      //   try {
      //     locationInfo = JSON.parse(locationStr)
      //     if (!locationInfo || !locationInfo.provinceId || locationInfo.provinceId === 'undefined') {
      //       fixPosition = true
      //       console.log('触发重新定位逻辑---store')
      //     }
      //   } catch (error) {
      //     fixPosition = true
      //     console.log('解析local定位信息异常', error)
      //   }
      // }

      // if (!fixPosition && !force) {
      //   let locationInfo = utils.strJSONParse(localStorage.getItem('location'), {})
      //   return {
      //     latitude: locationInfo.latitude,
      //     longitude: locationInfo.longitude
      //   }
      // }
      if (utils.isHaierApp()) {
        return dispatch('uplusLocation/doLocation')
      } else {
        return dispatch('location/doLocation')
      }
    },
    // 根据经纬度获取详细定位
    doLocationInfo({ dispatch }, location = { latitude: '36.12784', longitude: '120.41803' }) {
      // if (localStorage.getItem('location') && !location.force) {
      //   return JSON.parse(localStorage.getItem('location'))
      // }
      if (utils.isHaierApp()) {
        return dispatch('uplusLocation/doLocationInfo', location)
      } else {
        return dispatch('location/doLocationInfo', location)
      }
    },

    async doHistoryBack({ dispatch }, forceGoBack = false) { // 新增一个强制back的控制参数，解决外部直播页直接跳转单品详情无法返回的问题
      // axios.get('/11.json?history=' + JSON.stringify(window.history.state) + '&historylength=' + window.history.length)
      let closewebview = router._stack === 0 || utils.parseQueryValue('closewebview') || router.currentRoute.query.closewebview;
      console.log('history state', window.history.state, router._stack)
      console.log(window.location.href, 'location.href')
      /**
       * closewebview不为0，并且不存在state(未进行pushState()和replaceState(),history栈顶为空)或closewebview显示声明要关闭容器 则走容器关闭方法,否则走H5的返回
       * @param {*} cb 
       */
      const backUtil = (cb) => {
        if (closewebview !== 0 && (!window.history.state || closewebview)) {
          // 走容器关闭方法 
          cb()
        } else {
          // 走 h5 router back
          if (router.currentRoute.query.goback) {
            router.go(-router.currentRoute.query.goback)
          } else {
            router.back()
          }
        }
      }
      if (utils.isHaierApp()) {
        const cb = () => {
          instance.initDeviceReady().then(() => {
            // localStorage.clear(); // 清除登录避免用户切换账号无法同步账号信息
            instance.upLiveModule.closeFloatWindow().catch(e => { console.log('清除浮动窗口失败', e); })
            instance.closeH5ContainerView();
          }).catch(e => {
            console.log(e);
            // axios.get('/22.json?error=' + JSON.stringify(e))
          });
        }
        backUtil(cb)
      } else if (utils.isScMp() || utils.isSyBirdMP()) {
        const cb = () => {
          utils.loadUniMPEnvDeps().then(() => {
            uni && uni.navigateBack()
          }).catch(e => {
            // 拉取失败处理
            console.log(e)
          })
        }
        backUtil(cb)
      } else if (utils.isSgRn()) {
        console.log('rn closewebview')
        if (window.location && window.location.href.indexOf('sg_rn_app') !== -1) {
          console.log('close rn')
          console.log(window.originalPostMessage);
          if (window.originalPostMessage) {
            window.postMessage(JSON.stringify({
              type: 'close',
            }));
          } else {
            console.log('postMessage未注入');
            setTimeout(() => {
              window.postMessage(JSON.stringify({
                type: 'close',
              }), '');
            }, 100)
          }
        } else {
          console.log('rn back')
          router.back()
        }
      } else if (utils.isInternalChannel()) {
        // 内购返回处理
        console.log('内购 返回==========》')
        if ((!window.history.state || router._stack === 0)) {
          if (window.history.state) {
            console.log('window.history.state===111=====>', router._stack)
            window.history.go(-1)
          } else {
            // 内购 容器关闭逻辑
            const ihaierSdk = await utils.initLarkEnv()
            try {
              const closeRes = ihaierSdk.navigation.close()
              console.log('closeRes======111=======>', closeRes)
            } catch (err) {
              console.log('closeWebview error==================>', err);
            }
          }
        } else {
          if (router.currentRoute.query.goback) {
            router.go(-router.currentRoute.query.goback)
            console.log('内购 返回====go======》')
          } else {
            console.log('内购 返回====back======》')
            router.back()
          }
        }
      }
      // DSG-43996 【M站】从第三方页面跳转到商城页面时，点击商城左上角返回，需返回来源页，而非商城首页，即“从哪来回哪去”
      // 如果没有上级页面，如果露出了返回键 则点击无效果
      // 所以注释掉了下面的代码  
      // else if ((!window.history.state || router._stack === 0) && !forceGoBack) {
      //   router.replace('/')
      // } 
      else {
        // axios.get('/33.json')
        if (router.currentRoute.query.goback) {
          router.go(-router.currentRoute.query.goback)
        } else {
          router.back()
        }
      }
    },
    // 路由拦截返回拦截的路由
    feedback({ commit }, path) {
      commit('setIntercept', path)
    },
    feedNeed({ commit }, need) {
      commit('setNeedLogin', need)
    },
    // 根据切换的地理位置信息获取经纬度(第三方高德接口)，该方法默认会更改本地存储，onlyread设置为true表示只获取经纬度。
    getLatLongByLocationInfo({ commit }, info) {
      return axios.get(URL.GET_LATLONG, {
        params: {
          addressName: info.region ? info.region : null,
          townshipId: info.townshipId ? info.townshipId : null,
        },
      }).then(({ data }) => {
        let result = data.data;
        if (result.geocodes.length >= 1) {
          let loc = result.geocodes[0].location.split(',')
          // cityCode: '370200',
          // districtCode: '370212',
          // provinceCode: '370000',
          // townshipCode: '370212001000',
          const areaCode = result.geocodes[0] && result.geocodes[0].areaCode
          if (areaCode && !info.onlyread) {
            // commit('setLocationInfo', {
            //   region: areaCode.regionName,
            //   provinceId: areaCode.provinceId,
            //   province: areaCode.pName,
            //   cityId: areaCode.cityId,
            //   city: areaCode.cName,
            //   districtId: areaCode.regionid,
            //   district: areaCode.rName,
            //   townshipId: areaCode.streetId,
            //   township: areaCode.sName,
            //   latitude: loc[1],
            //   longitude: loc[0],
            // })
            // commit('setLocatinAreaCode', {
            //   provinceCode: areaCode.provinceCode,
            //   cityCode: areaCode.cityCode,
            //   districtCode: areaCode.regionCode,
            //   townshipCode: areaCode.streetCode,
            // })
            // 切换位置统一上报埋点
            utils.trackWithVariable({
              platformConf: {
                [PlatformTypes.INTERNAL_CHANNEL]: {
                  id: 'IH20015',
                  param: ['province', 'city', 'county', 'street']
                },
              },
            }, {
              province: areaCode.pName,
              city: areaCode.cName,
              county: areaCode.rName,
              street: areaCode.sName,
            }).then(res => {
              console.log('report res', res)
            })
          }
          return {
            latitude: loc[1],
            longitude: loc[0],
            areaCode: areaCode,
          }
        } else {
          return 400
        }
      }).catch(() => {
        return 400
      })
    },
    // 登录成功设置默认收货地址为当前定位信息
    async defaultDeliveryAddress({ commit, state }) { 
      utils.logColor('defaultDeliveryAddress', 'green', state.locationInfo.sourceType)
      // 本地存默认地址时，登陆取默认收货地址
      if(state.locationInfo.sourceType !== 'default') return
      try {
        let address;
        const { data } = await axios.get(URL.getMemberAddress)
        if (data.data && data.data.length) {
          data.data.forEach(item => {
            if (item.de.toString() === '1') {
              address = item
            }
          })
        }

        if (address && address.rn) {
          const result = await this.dispatch('getLatLongByLocationInfo', { 'region': address.rn })
          let textArr = address.rn.split(' ')
          let locationInfo = {
            region: address.rn,
            provinceId: address.pi,
            province: textArr[0],
            cityId: address.ci,
            city: textArr[1],
            districtId: address.ri,
            district: textArr[2],
            townshipId: address.si,
            township: textArr[3],
            latitude: result.latitude,
            longitude: result.longitude,
            provinceCode: result.areaCode?.provinceCode,
            cityCode: result.areaCode?.cityCode,
            districtCode: result.areaCode?.regionCode,
            townshipCode: result.areaCode?.streetCode,
            detailAddress: address.ar,  
            addressId: address.id,
            sourceType: 'first_shipping_address'
          }
          commit('setLocationInfo', locationInfo)
          // if (result.areaCode) {
          //   commit('setLocatinAreaCode', result.areaCode)
          // }
        } 
      } catch (err) {
        console.log(err)
      }
    },
    resetStore({ commit }) {
      // **注意** 不直接用clear 是为了保留定位信息
      localStorage.setItem('isLogin', false)
      localStorage.setItem('ucId', '')
      localStorage.setItem('token', '')
      localStorage.setItem('sdToken', '')
      localStorage.setItem('accessToken', '')
      // localStorage.setItem('refreshToken', '')
      utils.setLocalStorage('refreshToken', '')
      localStorage.setItem('mId', '')
      localStorage.setItem('sessionKey', '')
      localStorage.setItem('sessionValue', '')
      localStorage.setItem('userInfo', '')
      localStorage.setItem('isHasStore', '')
      commit('resetState')
      commit('mymanage/setCurrentRole', null)
    },
    /**
     * 目前只针对 首页分类区 及 金刚区 的跳转
     * @param {*} type
     * @param {*} params
     * @param {*} elementId // type为 21 点击图片分享复制地址需要的元素的elementId
     */
    goPageByType({ commit, dispatch, state }, { type, params, routerkey, env, elementId }) {
      // if (routerkey) {
      //   if (type === 5 || type === 16) {
      //     params += params.indexOf('?') !== -1 ? `&routerkey=${new Date().getTime()}` : `?routerkey=${new Date().getTime()}`
      //   } else {
      //     params += `&routerkey=${new Date().getTime()}`
      //   }
      // }
      if (typeof params === 'string' && params?.includes('#/sceneDetail') && utils.isHaierApp() && !params?.includes('underneathStatusBar')) {
        params = utils.checkUrlAddParams(params, null, {
          underneathStatusBar: 1
        })
      }
      switch (type) {
        case 1: // 单品页
          if (env && env === 'alimp') { // 支付宝小程序内
            my.navigateTo({
              url: `/pages/product-detail/index?${params}`,
            })
          } else if (env && env === 'alienv') { // 支付宝浏览器内
            window.location.href = `https://ds.alipay.com/?scheme=${encodeURIComponent(`alipays://platformapi/startapp?appId=2021002120664108&page=https://ds.alipay.com/?scheme=alipays://platformapi/startapp?appId=2021002120664108&page=pages/product-detail/index&query=${encodeURIComponent(params)}`)}`
          } else {
            router.push(`/goodsDetail?${params}`)
          }
          break;
        case 2: // 领券中心
          router.push(`/voucher`)
          break;
        case 3: // 游戏页
          router.push(`/games/luckyWheell?${params}`)
          break;
        case 4: // 主题活动
          router.push(`/bannerSubject?${params}`)
          break;
        case 5: // 自定义链接 无导航
          // window.location.href = params
          utils.openNewWindow(params, true, false, false);
          break;
        case 7:
          if (params) {
            router.push(`/goodsDetail?productId=${params}`);
          }
          break;
        case 11: // 搜索结果
          if (params) {
            router.push(`/goodsSearch?${params}`)
          } else {
            router.push(`/goodsSearch`)
          }
          break;
        case 12: // 分类首页
          router.push(`/category`)
          break;
        case 13: // 分类筛选结果
          if (params) {
            router.push(`/categorySearch?${params}`)
          } else {
            router.push(`/categorySearch`)
          }
          break;
        case 14: // 海尔智选
          router.push(`/specialtyStore`)
          break;
        case 15: // 场景超市
          router.push(`/scene?routerkey=${new Date().getTime()}`)
          // utils.openNewWindow(params, true);
          break;
        case 16: // 自定义链接 有导航
          utils.openNewWindow(params, true);
          // window.location.href = params
          break;
        case 17: // app内协议类链接
          utils.openNewWindow(params, true, true);
          break;
        case 18: // 动态活动
          router.push(`/bannerCustom?${params}`)
          break;
        case 19: // 优惠券详情
          router.push(`/usablecouponlist?${params}`)
          break;
        case 20: // 分享
          let mid = localStorage.getItem('mId') || ''
          const shareHandler = (info) => {
            if (utils.isHaierApp()) {
              utils.inAppShare({
                title: info.title || (utils.isSyBird() ? '三翼鸟app' : '海尔智家'),
                desc: info.title || (utils.isSyBird() ? '三翼鸟app' : '海尔智家'),
                url: info.url
              })
            } else {
              const clipboard = new ClipboardJS(`#banner${info.elementId}`, {
                text: function (trigger) {
                  return info.url;
                }
              });
              clipboard.on('success', () => {
                Toast('活动链接复制成功！');
              });
              clipboard.on('error', () => {
                Toast('复制失败');
              });
            }
          }
          if (params.indexOf('partners') > -1) {
            if (state.isLogin) {
              axios.get(URL.myManage).then((res) => {
                let userInfo = res.data.data
                if (userInfo && [22, 21, 31].includes(userInfo.identifyFlag)) {
                  shareHandler({
                    title: '海尔智家',
                    desc: '邀请您成为平台合伙人，一起开启海尔新零售！',
                    url: `${params}&share=${mid}`,
                    elementId
                  })
                } else {
                  Toast('您暂无权限邀请')
                }
              })
            } else {
              dispatch('doLogin')
            }
          } else {
            shareHandler({
              url: `${params}&share=${mid}`,
              elementId,
            })
          }
          break;
        case 21: // 申请
          if (params.indexOf('partners') > -1) {
            if (state.isLogin) {
              axios.get(URL.myManage).then((res) => {
                let userInfo = res.data.data
                // 判断是否为合伙人
                if (userInfo && [21, 22].includes(userInfo.identifyFlag)) {
                  Toast('您已是店铺合伙人')
                } else if (Number(userInfo.identifyFlag) >= 10 && Number(userInfo.identifyFlag) <= 20) {
                  Toast('您已是商家身份')
                } else if (userInfo.identifyFlag === 0) {
                  utils.openNewWindow(`${params}&share=${0}`, true, false, false)
                } else if (userInfo.identifyFlag === 31) {
                  Toast('您已是平台合伙人')
                } else {
                  Toast('您暂无申请权限')
                }
              })
            } else {
              dispatch('doLogin')
            }
          } else {
            if (state.isLogin) { // 自定义链接无导航
              utils.openNewWindow(params, true, false, false)
            } else {
              dispatch('doLogin', params)
            }
          }
          break;
        case 22:
          /**
               * 自定义跳小程序
               * 如果后台配置了微信/抖音/支付宝路径，再检测是否是其环境
               *
               */
          const pathMap = utils.strJSONParse(params, Object.create(null))
          console.log('pathMap:', pathMap)
          if (pathMap.wx) {
            utils.loadScript('//res.wx.qq.com/open/js/jweixin-1.3.2.js').then(() => {
              console.log('loadScript success')
              wx.miniProgram.getEnv(res => {
                console.log('getEnv success:', res)
                if (res.miniprogram) {
                  wx.miniProgram.navigateTo({ url: pathMap.wx })
                }
              })
            })
          }

          if (pathMap.tt) {
            utils.loadScript('//s3.pstatp.com/toutiao/tmajssdk/jssdk-1.0.1.js').then(() => {
              const isTTWebView = navigator.userAgent.toLowerCase().includes('toutiaomicroapp')
              if (isTTWebView) {
                tt.miniProgram.navigateTo({ url: pathMap.tt })
              }
            })
          }

          if (pathMap.my) {
            // 支付宝环境
            if (navigator.userAgent.includes('AliApp')) {
              utils.loadScript('https://appx/web-view.min.js').then(() => {
                my.getEnv(res => {
                  // 支付宝小程序内
                  if (res.miniprogram) {
                    my.navigateTo({ url: pathMap.my })
                  } else {
                    // 支付宝浏览器内
                    let path = pathMap.my
                    let query = ''
                    if (path.includes('?')) {
                      const arr = path.split('?')
                      path = arr[0]
                      query = arr[1]
                    }
                    window.location.href = `https://ds.alipay.com/?scheme=${encodeURIComponent(`alipays://platformapi/startapp?appId=2021002120664108&page=https://ds.alipay.com/?scheme=alipays://platformapi/startapp?appId=2021002120664108&page=${path}${query ? `&query=${encodeURIComponent(query)}` : ''}`)}`
                  }
                })
              })
            }
          }
          break;
        case 23: // 有导航第三方登录
          let json = utils.parseQueryToJson(params);
          getUcLoginUrl({
            redirectUri: json.redirectUrl,
            target: json.target,
            targetTest: json.target,
            redirectUriTest: json.redirectUrl,
          }).then(({ data }) => {
            if (data.success) {
              utils.openNewWindow(data.data, true);
            } else {
              Toast(data.message)
            }
          })
          break;
        case 24: // 无导航第三方登录
          let query = utils.parseQueryToJson(params);
          getUcLoginUrl({
            redirectUri: query.redirectUrl,
            target: query.target,
            targetTest: query.target,
            redirectUriTest: query.redirectUrl,
          }).then(({ data }) => {
            if (data.success) {
              utils.openNewWindow(data.data, true, false, false);
            } else {
              Toast(data.message)
            }
          });
          break;
        case 25: // 不做任何操作
          break;
        case 26: // 登录自定义链接
          if (state.isLogin) { // 自定义链接无导航
            utils.openNewWindow(params, true)
          } else {
            dispatch('doLogin', params)
          }
          break;
        case 28: // 跳转智家魔方
          router.push(`/magicCube?${params}`)
          break;
        case 29: // 跳转客服
          const encodedURL = (url) => {
            if (!url) return ''
            if (window && window.encodeURIComponent) {
              return window.encodeURIComponent(url)
            }
            return url
          }
          params.fromPageUrl = document.URL
          if (utils.isHaierApp()) {
            dispatch('handleUpAuth').then( async isLogin => {
              if (isLogin) {
                if (localStorage.getItem('mId')) {
                  if (!localStorage.getItem('userBase64Info')) {
                    await dispatch('getBase64MemberInfo', { mId: localStorage.getItem('mId') })
                  }
                  const resdata =  JSON.parse(localStorage.getItem('userBase64Info'))
                  if (resdata.ucMemberId) {
                    if (resdata.ucMemberId) {
                      const keyName = customServiceSceneMap[params.sceneId] || '首页'
                      const sceneId = customServiceSceneMapNew()[keyName].sceneId
                      const entranceId = customServiceSceneMapNew()[keyName].entranceId

                      const data = {
                        userId: resdata.ucMemberId,
                        accountId: params.accountId ? params.accountId : DEFAULT_ACCOUNT_ID,
                        sign: md5.hex_md5(`accountId = ${params.accountId ? params.accountId : DEFAULT_ACCOUNT_ID} and userId = ` + resdata.ucMemberId),
                        userName: resdata.userName,
                        fromPageUrl: params.fromPageUrl,
                        fromPageTitle: `${customServiceSceneMap[params.sceneId] || '首页'}`,
                        origin: 'haierApp',
                        sceneId: sceneId,
                        entranceId: entranceId,
                      }
                      // 打印日志
                      personalApi.traceLog(data)

                      utils.openNewWindow(`mpaas://CustomerService/?sign=${data.sign}&accountId=${data.accountId}&fromPageUrl=${encodedURL(params.fromPageUrl)}&fromPageTitle=${customServiceSceneMap[params.sceneId] || '首页'}&device=app&pId=${PID}&cardId=${params.cardId || ''}&sceneId=${sceneId}&entranceId=${entranceId}&titleBar=#/zhijia`, true, true);
                    } else {
                      dispatch('doLogin')
                    }
                  }
                } else {
                  dispatch('doLogin')
                }
                // }
              }
            })
          } else {
            router.push({
              path: '/customerService',
              query: params
            })
          }
          break;
        case 30: // 发现好货
          router.push({
            path: '/flashSale',
          })
          break;
        case 31: // 生活家内容专区
          let url = !utils.isProdEnv() ? 'https://ys-zjrs.haier.net/zjapp/popular-active/index.html' : 'https://zjrs.haier.net/haierActivitys/popular-active/index.html';
          utils.openNewWindow(url, true, false, false, false);
          break
        case 101: // 自定义链接 替换链接
          utils.openNewWindow(params, true, false, false, true);
          break;
        case 102: // 小程序跳转 redirect
          /**
                 * 自定义跳小程序
                 * 如果后台配置了微信/抖音/支付宝路径，再检测是否是其环境
                 *
                 */

          const map = utils.strJSONParse(params, Object.create(null))
          console.log('map:', map)
          utils.loadUniMPEnvDeps().then(() => {
            uni.navigateTo({ url: map.wx })
          }).catch(e => {
            // 拉取失败处理
            console.log(e)
          })

          // if (map.wx) {
          //   utils.loadScript('//res.wx.qq.com/open/js/jweixin-1.3.2.js').then(() => {
          //     console.log('loadScript success')
          //     wx.miniProgram.getEnv(res => {
          //       console.log('getEnv success:', res)
          //       if (res.miniprogram) {
          //         wx.miniProgram.redirectTo({ url: map.wx })
          //       }
          //     })
          //   })
          // }
          break
        case 103: // 跳转以旧换新估值页面
          // 1. 生成redKey 2. 区分环境跳转对应链接 3. 不开新容器（是否会导致返回关闭容器问题‘）
          if (!params.proList) {
            Toast('未查询到商品信息')
            return
          }
          getOrderCreateUrl({
            proList: [params.proList],
            sourceCode: params.sourceCode,
            sourceType: params.sourceType,
            fillinOrderPriority: params.fillinOrderPriority,
            addressId: params.addressId
          }).then(res=>{
            if (res.success) {
              let redKey = res.data.redkey
              let urlDomain = utils.isProdOrPreEnv() ? 'https://barter.haier.net/renewalh5/valuation-index' : 'https://testdb.haier.net/renewalh5/valuation-index'
              let sourceSystem = utils.isShopKeeper() ? 122 : utils.isUplus() ? 119 : 126
              let sku = params.proList['sku']
              let mdCode = params.proList['mdCode'] || undefined
              let fullUrl = `${urlDomain}?sourceSystem=${sourceSystem}&accountToken=${state.accessToken}&redKey=${redKey}&skuCoding=${sku}&phone=${params.phone}&orderBehalf=${params.fillinOrderPriority}`
              if (mdCode) { fullUrl = fullUrl + `&storeCode=${mdCode}` }
              window.location.href = fullUrl
              // utils.openNewWindow()
            } else {
              Toast(res.msg)
            }
          }).catch(e=>{
            Toast(e.message)
          })
          break;
      }
    },
    /**
     *
     * 浏览计时功能
     * @param {*} 参数暂定
     */
    doReviewTime({ commit, state }, params) {
      if (utils.isHaierApp()) {
        clearInterval(state.reviewSetInteral);
        utils.smMsgSdk();
        commit('setReviewTimeInfo', {
          ...params,
          totalTime: params.time || 30,
          leftTime: params.time || 30
        })
        return new Promise((resolve, reject) => {
          // let setInter = null;
          let time = params.time || 30;
          let deviceInfo = {};
          instance.initDeviceReady().then(() => {
            instance.upAppinfoModule.getAppInfo().then(info => {
              console.log('info', info)
              deviceInfo = info.retData
            }).catch(e => { console.log(e) });
          }).catch(e => { console.log(e) });
          const run = () => {
            commit('setReviewTimeInfo', {
              leftTime: time
            })
            if (time < 0) {
              clearInterval(state.reviewSetInteral);
              if (deviceInfo.appKey) {
                utils.dealSmDeviceId().then(deviceId => {
                  axios.post(URL.getNotifyTaskFinished, {
                    'accountToken': state.sdToken,
                    'clientId': deviceInfo.clientId,
                    'appVersion': deviceInfo.appVersion,
                    'appKey': deviceInfo.appKey,
                    'appId': deviceInfo.appId,
                    'accessToken': state.sdToken,
                    'taskCode': params.taskCode,
                    'deviceId': deviceId,
                    'eventId': params.eventId,
                    'userId': params.userId,
                    'taskSource': params.taskSource || 'KMH'
                  }).then(() => {
                    resolve(true)
                  })
                }).catch(() => {
                  resolve(false)
                })
              } else {
                resolve(false)
              }
            }
            time--;
          }
          state.reviewSetInteral = setInterval(run, 1000);
          run()
        })
      } else {
        return Promise.resolve(false)
      }
    },

    /**
     * 微信二次分享
     * title 默认 三翼鸟和移动工作台：定制智慧家 就找三翼鸟，其他：海尔智家
     * desc 默认 三翼鸟和移动工作台：定制智慧家 就找三翼鸟，其他：海尔智家，海尔家电正品保障
     * thumImage 默认 三翼鸟和移动工作台：三翼鸟logo，其他：海尔智家logo
     */
    WeChatSecondShare({ commit }, options = {}) {
      if (utils.isWeixin()) {
        // 微信内二次分享
        utils.loadScript('//res.wx.qq.com/open/js/jweixin-1.3.2.js').then(() => {
          const url = window.location.href
          axios.get(URL.WXJsSdkConfig + '?url=' + encodeURIComponent(url)).then(({ data }) => {
            let result = data
            console.log(result, 'share RESULT');
            if (result.success && result.data) {
              wx.config({
                debug: false,
                appId: result.data.appId,
                timestamp: result.data.timestamp,
                nonceStr: result.data.nonceStr,
                signature: result.data.signature,
                jsApiList: [
                  'onMenuShareTimeline',
                  'onMenuShareAppMessage'
                ]
              });

              wx.ready(() => {
                console.log('wx share ready')
                wx.onMenuShareTimeline({
                  title: options.title || DEFAULT_TITLE_SHARE,
                  link: url,
                  imgUrl: options.thumImage || DEFAULT_LOGO_SHARE,
                  success: function () { },
                  fail: function () { }
                });
                // 获取“分享给朋友”按钮点击状态及自定义分享内容接口
                wx.onMenuShareAppMessage({
                  title: options.title || DEFAULT_TITLE_SHARE,
                  desc: options.desc || DEFAULT_DESC_SHARE,
                  link: url,
                  imgUrl: options.thumImage || DEFAULT_LOGO_SHARE,
                  success: function () { },
                  fail: function () { },
                  cancel: function () { }
                });
              })
            }
          })
        })
      }
    },

    /**
     * banner跳转统一方法
     * @param {*} hyperLinkType banner类型 接口对应字段为linkType或hyperLinkType
     * @param {*} hyperLink 跳转链接 接口对应字段为hyperLink或link
     * @param {*} relationId 关联的主题活动Id  接口对应字段relationId
     * @param {*} isWeChatMp 是否微信小程序环境
     * @param {*} isByteDanceMP 是否字节跳动小程序环境
     * @param {*} accessToken 适配第三方页面跳转传token
     */
    bannerClick({ commit, dispatch }, queryParams) {
      let { hyperLinkType, hyperLink, relationId, isWeChatMp, isByteDanceMP, accessToken } = queryParams
      console.group('banner跳转');
      console.log(hyperLinkType);
      console.log(hyperLink);
      console.log(relationId);
      console.groupEnd();
      let tempArr = hyperLink && hyperLink.toString().split('&');
      switch (hyperLinkType) {
        case '-1': // 日常活动 bannerDaily
          break;
        case '0': // 主题活动
          if (isWeChatMp) {
            wx.miniProgram.navigateTo({
              url: `/pages/bannerTheme/index?id=${relationId}`
            })
          } else if (isByteDanceMP) {
            tt.miniProgram.navigateTo({
              url: `/pages/bannerTheme/index?id=${relationId}`
            })
          } else {
            router.push(`/bannerSubject?id=${relationId}`);
          }
          break;
        case '1': // 单品
          const productId = tempArr[0].slice(tempArr[0].indexOf('=') + 1);
          if (isWeChatMp) {
            wx.miniProgram.navigateTo({
              url: `/pages/product/detail/index?productId=${productId}`
            })
          } else if (isByteDanceMP) {
            tt.miniProgram.navigateTo({
              url: `/pages/product/detail/index?productId=${productId}`
            })
          } else if (utils.isSgRn()) {
            window.postMessage(
              JSON.stringify({
                type: 'goProduct',
                data: {
                  id: productId,
                },
              })
            );
          } else {
            router.push(`/goodsDetail?productId=${productId}`);
          }
          break;
        case '2': // 领券中心/优惠券详情页
        case '19':
          if (!hyperLink) {
            if (isWeChatMp) {
              wx.miniProgram.navigateTo({ url: '/pages/couponCenter/index' });
            } else if (isByteDanceMP) {
              tt.miniProgram.navigateTo({ url: '/pages/couponCenter/index' });
            } else {
              router.push(`/voucher`);
            }
          } else {
            const arr = hyperLink.split('=')[1].split(',')

            let activityCode = arr ? arr[0] : '';
            let couponTemplateCode = arr.length > 1 ? arr[1] : '';
            let sourceChannel = arr.length > 2 ? arr[2] : '';

            if (arr.length > 1) {
              activityCode = arr ? arr[0] : '';
              couponTemplateCode = arr.length > 1 ? arr[1] : '';
              sourceChannel = arr.length > 2 ? arr[2] : '';
            } else {
              const hyperLinkObj = utils.parseQueryToJson(hyperLink);
              activityCode = hyperLinkObj.activityCode;
              couponTemplateCode = hyperLinkObj.couponTemplateCode;
              sourceChannel = hyperLinkObj.sourceChannel;
            }
            router.push(`/couponInfo?activityCode=${activityCode}&couponTemplateCode=${couponTemplateCode}&sourceChannel=${sourceChannel}`)
            if (isWeChatMp) {
              wx.miniProgram.navigateTo({
                url: `/pages/mine/coupon/couponproduct/index?activityCode=${activityCode}&couponTemplateCode=${couponTemplateCode}&sourceChannel=${sourceChannel}`,
              });
            } else if (isByteDanceMP) {
              tt.miniProgram.navigateTo({
                url: `/pages/mine/coupon/couponproduct/index?activityCode=${activityCode}&couponTemplateCode=${couponTemplateCode}&sourceChannel=${sourceChannel}`,
              });
            } else {
              router.push(`/couponInfo?activityCode=${activityCode}&couponTemplateCode=${couponTemplateCode}&sourceChannel=${sourceChannel}`);
            }
          }
          break;
        case '3': // 大转盘游戏
          console.log('====================================');
          console.log(hyperLink);
          console.log('====================================');
          router.push(`/games/luckyWheell?${hyperLink}`);
          break;
        case '4': // 主题活动
          if (tempArr[0].slice(tempArr[0].indexOf('=') + 1) === '主题活动') {
            const bannerId = tempArr[1].slice(tempArr[1].indexOf('=') + 1);
            if (isWeChatMp) {
              wx.miniProgram.navigateTo({
                url: `/pages/bannerTheme/index?id=${bannerId}`
              })
            } else if (isByteDanceMP) {
              tt.miniProgram.navigateTo({
                url: `/pages/bannerTheme/index?id=${bannerId}`
              })
            } else {
              router.push(`/bannerSubject?id=${bannerId}`);
            }
          } else if (tempArr[0].slice(tempArr[0].indexOf('=') + 1) === '日常活动') {
            // 日常活动暂时不用，未实现页面
          }
          break;
        case '5': // 自定义类型页
          dispatch('bannerTypeFiveClick', { hyperLink, accessToken });
          break;
        case '7': // 新品
          if (hyperLink) {
            const productId = hyperLink;
            if (isWeChatMp) {
              wx.miniProgram.navigateTo({
                url: `/pages/product/detail/index?productId=${productId}`
              })
            } else if (isByteDanceMP) {
              tt.miniProgram.navigateTo({
                url: `/pages/product/detail/index?productId=${productId}`
              })
            } else if (utils.isSgRn()) {
              window.postMessage(
                JSON.stringify({
                  type: 'goProduct',
                  data: {
                    id: productId,
                  },
                })
              );
            } else {
              router.push(`/goodsDetail?productId=${productId}`);
            }
          }
          break;
        case '8': // 社区
          break;
      }
    },
    /**
     * banner活动类型为5(自定义类型)时跳转逻辑
     * @param {*} hyperLink 跳转链接
     * @param {*} accessToken 跳转第三方页面传递token
     */
    bannerTypeFiveClick({ commit }, queryParams) {
      let { hyperLink, accessToken } = queryParams
      console.log('banner-5-click:', hyperLink);
      if (!hyperLink) {
        return;
      }
      const urls = hyperLink.replace(/http:\/\/|https:\/\//g, '').split('/');
      if (hyperLink.includes('ehaier.com/www')) { // 老m站页面
        if (urls.indexOf('CustomPage') >= 0) { // 跳转bannerDetail
          const detailId = urls.slice(-1);
          router.push(`/bannerCustom?id=${detailId}`);
        } else if (urls.indexOf('couponsDetail') >= 0) {
          const activityCodeAndcouponTemplateCode = hyperLink.split('couponsDetail')[1].replace(new RegExp('/', 'g'), '');
          const arr = activityCodeAndcouponTemplateCode.split(',')
          const activityCode = arr ? arr[0] : '';
          const couponTemplateCode = arr.length > 1 ? arr[1] : '';
          const sourceChannel = arr.length > 2 ? arr[2] : '';
          router.push(`/couponInfo?activityCode=${activityCode}&couponTemplateCode=${couponTemplateCode}&sourceChannel=${sourceChannel}`)
        } else if (urls.indexOf('itSoluteDetail') >= 0) { // 成套家装方案
          const id = hyperLink.split('itSoluteDetail')[1].replace(new RegExp('/', 'g'), '');
          router.push(`/itSoluteDetail?id=${id}`);
        } else if (urls.indexOf('fullHouseSolution') >= 0) { // 全屋方案列表
          const id = hyperLink.split('fullHouseSolution')[1].replace(new RegExp('/', 'g'), '');
          router.push(`/intelligentScene?scenesId=${id}`);
        }
      } else if (hyperLink.includes('ehaier.com/sgmobile')) { // 新m站页面
        const newMRoutes = hyperLink.split('ehaier.com/sgmobile/')[1];
        const params = newMRoutes.split('/');
        if (newMRoutes.includes('sgwhitepay')) { // 白条开通页面，掌柜跳转自己的白条页
          if (utils.isSgRn()) {
            window.postMessage(
              JSON.stringify({
                type: 'goWhitePay',
              })
            );
          } else {
            router.push(`/sgwhitepay`);
          }
        } else if (newMRoutes.includes('banner/') && params.length >= 2) {
          router.push(`/bannerCustom?id=${params[1]}`);
        } else if (newMRoutes.includes('bannerTheme/') && params.length >= 2) {
          router.push(`/bannerSubject?id=${params[1]}`);
        } else if (newMRoutes.includes('couponDetail/') && params.length >= 3) {
          router.push(`/couponInfo?activityCode=${params[1]}&couponTemplateCode=${params[2]}&sourceChannel=${params[3]}`)
        } else {
          router.push(`/${newMRoutes}`);
        }
      } else { // 第三方页面
        if (hyperLink.indexOf('haier.vhallyun.com') >= 0 || hyperLink.indexOf('t-haier.vhallyun.com') >= 0) {
          // 适配直播跳转
          const accessTokenQuery = accessToken ? `?accessToken=${accessToken}` : ''
          const url = decodeURIComponent(hyperLink.replace('param=', ''))
          console.log('直播:', `${url}${accessToken}`)
          utils.openNewWindow(`${url}${accessTokenQuery}`, true);
        } else {
          utils.openNewWindow(decodeURIComponent(hyperLink.replace('param=', '')), true);
        }
      }
    },

  },
  modules: {
    chat, // 即时通讯IM
    account, // 海尔智家商城登录注册
    location, // 海尔智家商城H5定位
    uplusAccount, // 智家登录
    uplusLocation, // 智家定位
    ihaier2Account,
    sgRnAccount, // 商城Rn登录注册
    common, //组件间共享数据
    goodsDetail, // 商品详情
    // home,
    // classify,
    // coupon,
    cardQuan,
    ecard,
    shop, //店铺
    answerArea, // 问答区域
    createOrder, //结算订单及尾款结算
    orderInvoice, // 订单发票模块
    assignOrder, // 意向派单
    cart,
    personalCenter,
    bankcard,
    freebankcard,
    smartChoice,
    history,
    orderRight, // 权益
    rankList,
    mymanage, // 身份，认证信息等
    order, // 订单信息，比如订单号orderSn
    slotmac, // 老虎机游戏
    attrMutiPop,
    quickPlaceOrder,
    stagePay, //分阶段付款
    shoppingList, // 购物清单
  },
}

const store = new Vuex.Store(Store)

export const useStore = () => store

export default store