const scene = [{
  path: '/scene',
  name: 'scene',
  component: () => import('@/views/scene/index.vue'),
  meta: {
    keepAlive: true,
    title: '场景超市',
    needLogin: false,
    showTabbar: false,
    showHeader: false,
  }
}]

export default scene
