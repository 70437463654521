const partnersUnbound = [
  {
    path: '/partnersUnboundList',
    name: 'partnersUnboundList',
    component: () => import('@/viewsToB/partnersUnbound/index.vue'),
    meta: {
      title: '申请解绑合伙人',
      keepAlive: true,
      showTabbar: false,
    }
  },
  {
    path: '/partnerUnboundDetail',
    name: 'partnerUnboundDetail',
    component: () => import('@/viewsToB/partnersUnbound/unbound-detail.vue'),
    meta: {
      title: '解绑详情',
      keepAlive: true,
      showTabbar: false,
    }
  },
]

export default partnersUnbound
