export default {
  namespaced: true,
  state: {
    requery: [1, 1, 1, 1, 1], // 1、保持现状，2、刷新页面
    btnSelectedIndex: [0, 0, 0, 0, 0], // 按钮选中的索引
  },
  getters: {
    getRequery(state) {
      return state.requery
    },
    getBtnSelectedIndex(state) {
      return state.btnSelectedIndex
    },
  },
  mutations: {
    setRequery(state, payload) {
      state.requery = payload
    },
    setBtnSelectedIndex(state, payload) {
      state.btnSelectedIndex = payload
    },
  },
}