/**
 * 店铺相关
 */
import URL from '../url.js'
import { get, post } from '../http'
import config from '@/config'
import utils from '../../utils/utils'

// 店铺信息
export const manage = data => get(URL.manage, data)
// 关注店铺
export const collectStore = data => get(URL.collectStore, data)
// 获取商家营业执照
export const enterprise = data => get(URL.enterprise, data)
// 店铺分类
export const getNavigations = data => get(URL.cateList, data)
// 获取图形验证码（商城）
export const getImgCaptcha = data => get(URL.getImgCaptcha, data)
// 领券中心商品类目列表
export const getViewCouponCate = data => get(URL.getViewCouponCate, data)
// 领券中心
export const getCouponsCenter = data => get(URL.getCouponsCenter, data, {}, true)
// 领券
export const receiveCoupon = data => get(URL.receiveCoupon, data)
// 获取券包
export const getVoucherPackageList = data => get(URL.getVoucherPackageList, data)
// 领取券包
export const receiveVoucherPackage = data => post(`${URL.receiveVoucherPackage}${utils.parseParamsToQuery(data)}`)
// 分类商品列表
export const commonLoadItemNew = data =>
  get(URL.commonLoadItemNew, data, {
    baseURL: config.SHOST,
  }, true)
// 异步获取信息接口(获取商品价钱)
export const getPriceByProductList = data =>
  get(URL.getPriceByProductList, data, {
    baseURL: config.HOST,
  }, true)
// 获取商品分类项
export const wdMarketFiltrate = data =>
  get(URL.wdMarketFiltrate, data, {
    baseURL: config.SHOST,
  })
export const getNearbyList = data => get(URL.nearbyList, data, {})
export const getBusinessCustomer = data =>
  get(URL.getBusinessCustomer, data, {
    baseURL: config.HOST4,
  })
export const GET_BANNER = data => get(URL.GET_BANNER, data)

// export const getCouponsList = data => get(URL.getCouponsCenter, data, {}, true)
// 获取店铺的直播信息
export const getLiveByStoreId = data => get(URL.getLiveByStoreId, data)
// 获取限时抢购商品列表
export const getStoreFlashSale = data => get(URL.getStoreFlashSale, data)
// 获取限时抢购商品列表（新）
export const getStoreFlashSaleNew = data => get(URL.getStoreFlashSaleNew, data)

// 获取店铺的商品分类
export const getCate = data => get(URL.getCate, data)
// 新获取店铺分类
export const getStoreProductFirstCate = params => get(URL.getStoreProductFirstCate, params)

/** 店招模块接口 */
export const getStoreSign = param => get(URL.storeSign, param);
/** 首页精选模块接口 自定义页面数据 */
export const getSelectedModel = param => get(URL.storeSelectedModel, param);
/** 自定义页面数据 */
export const getCustomPageModel = param => get(URL.getCustomPage, param);
/** 用户案例 老的场景接口筛选type为case */
export const getUserCases = param => get(URL.GET_STORE_SCENE, param);
/** 推荐商品列表 生态优选列表 */
export const getRecommendList = params => get(URL.recommendProducts, params);
/** VR预览列表 */
export const getVRPreviews = param => get(URL.storeVRPreviewList, param);
/** 预售专区 */
export const getPreSales = (param, isPlatForm) => {
  if (isPlatForm) {
    return get(URL.platformPreSale, param);
  } else {
    return post(`${URL.preSaleList}?storeId=${param.storeId}&mdCode=${param.mdCode}`);
  }
}

// 店铺首页 活动tab 大转盘
export const getShopGameList = (param, config) => get(URL.getShopGameList, param, config);

// 店铺首页-获取活动信息
export const marketingActivityInfo = param => get(URL.marketingActivityInfo, param);

// 店铺首页-活动-家装预约活动详情 sg/cms/activity/homeDecoration/activityInfo.json?activityId=29393
export const homeDecorationActivityInfo = param => get(URL.homeDecorationActivityInfo, param);

// 店铺首页-活动-家装预约活动详情-无意向金提交表单
export const receiveMemberRight = param => post(URL.receiveMemberRight, param);

/** 店铺场景筛选条件 */
export const getStoreSceneTabs = param => get(URL.sceneTabList, param);
/** 店铺场景列表 */
export const getStoreScene = param => post(URL.sceneList, param);

/** 店铺场景列表(新) */
export const getStoreSceneNew = param => get(URL.sceneListNew, param, {
  baseURL: config.DHOST
});

/** 店铺分店列表 */
export const getSubStoreList = param => get(URL.subStoreList, param);
// /** 店铺预览接口 */
export const getShopPreview = param => post(URL.shopPreview, param, {
  baseURL: config.STOREHOST
});
// /** 店铺自定义页面预览接口 */
export const getCustomPagePreview = param => post(URL.customPagePreview, param, {
  baseURL: config.STOREHOST
});
/** 店招模块预览接口 */
export const getStoreSignPreview = param => get(URL.storeSignPreview, param, {
  baseURL: config.STOREHOST
});
/** 店铺上新接口 */
export const getStoreNewProducts = param => get(URL.storeNewProducts, param);
/** 验证当前位置是否在私域返回 */
export const checkPrivateDomain = param => get(URL.checkPrivateDomain, param);
// 获取套购列表
export const getSuitList = data => get(URL.getShopSuitList, data)
// 获取套购广告图
export const getSuitImage = data => get(URL.getShopSuitImage, data)
// 获取套购详情
export const getSuitDetail = data => post(URL.getShopSuitDetail, data)

export const validateTabOrSpace = data => post(URL.validateTabOrSpace, data)

export const checkIdentity = data => get(URL.checkIdentity, data)

/** 发现好货 */
export const getFindGoods = (param) => get(URL.storeFindGoods, param);


// 场景套购
export const queryStoreFtState = (param) => get(URL.queryStoreFtState, param, {
  baseURL: config.DHOST
})

export const queryStoreFTCategory = (param) => get(URL.queryStoreFTCategory, param, {
  baseURL: config.DHOST
})

export const queryStoreFTList = (data) => post(URL.queryStoreFTList, data, {
  baseURL: config.DHOST
})

export const getFtDetail = (param) => get(URL.getFtDetail, param, {
  baseURL: config.DHOST
})

export const getFtCate = (data) => post(URL.getFtCate, data)

export const getSelectDetailData = (data) => post(URL.getSelectDetailData, data, {
  baseURL: config.PDHOST
})

// 批量查询商品是否有权益
export const queryOrderRightSingleExistList = data => post(URL.queryOrderRightSingleExistList, data, {
  baseURL: config.OHOST
})

export const queryOrderRightAndCouponList = data => post(URL.getRightsAndCoupon, data)


// 快下单获取店铺商品
export const quickPlaceOrderStoreProduct = data => post(URL.getStoreProduct, data)
export const exclusiveQueryList = data => post(URL.queryMyQuotation, data)
export const getQueryByRtSeq = (params) => get(URL.queryByRtSeq, params)
export const saveQuickOrderList = data => post(URL.quickOrderSave, data)
export const updateQuickOrderList = data => post(URL.quickOrderUpdateProduct, data)
export const queryProductListBySku = data => post(URL.queryProductListBySku, data)
export const quotationClear = data => post(URL.quotationClear, data)
export const quotationShare = data => post(URL.quotationShare, data)
export const qutoationSaveFromSeq = data => post(URL.qutoationSaveFromSeq, data) 
export const qutoationModifyQuotation = params =>get(URL.qutoationModifyQuotation,params)
export const qutoationTransformSeq = data => post(URL.qutoationTransformSeq, data)
export const qutoatiionQueryBySeq = params => get(URL.qutoatiionQueryBySeq, params)
export const qutoationEnable = data => post(URL.qutoationEnable, data)
export const qutoationSaveFromLK = data => post(URL.qutoationSaveFromLK, data)
export const qutoationPreOrderFromLk = data => post(URL.qutoationPreOrderFromLk, data)
export const qutoationAnalysisRtSeq = params => get(URL.qutoationAnalysisRtSeq, params)
export const qutoationShareFromLk = data => post(URL.qutoationShareFromLk, data)
export const qutoationProfitcenter = data => post(URL.qutoationProfitcenter, data)
export const qutoationMdList = data => post(URL.qutoationMdList, data)
export const qutoationDefaultName = params => get(URL.qutoationDefaultName, params)
export const qutoationBlackButtons = params => get(URL.qutoationBlackButtons, params)
export const qutoationCouponDetail = data => post(URL.qutoationCouponDetail, data)

// 获取用户方案利润中心聚合信息
export const getProfitcenterInfo = data => post(URL.getProfitcenterInfo,data)
// 获取轻设计场景列表
export const getSceneList = data => post(URL.getSceneList,data)
// 删除轻设计场景
export const deleteScenes = data => post(URL.deleteScenes,data)
// 更新轻设计场景内容
export const updateScenes = data => post(URL.updateScenes,data)
// 更新轻设计场景内容
export const pluPrice = data => post(URL.pluPrice,data)
// 商品是否存在关联场景
export const markingList = data => post(URL.marking, data)

// 商品关联场景Top5
export const sceneRelation = data => post(URL.sceneRelation, data)

// 关联场景详情的主推商品sku
export const sceneMainComponents = data => post(URL.sceneMainComponents,data)

// 关联场景详情备选商品sku
export const sceneAlternateComponents = data => post(URL.sceneAlternateComponents,data)

// 购物清单商品改价
export const sceneChangePirce = data => post(URL.sceneChangePirce,data)

// 场景快下单保存方案
export const sceneSaveCase = data => post(URL.sceneSaveCase,data)

// 计算利润中心归属
export const analysis = data => post(URL.analysis,data)
