// 全局配置文件
console.log(process.env)
let HOST = process.env.VUE_APP_MHOSTA
let HOST4 = process.env.VUE_APP_HOST4
let PHOST = process.env.VUE_APP_PHOST
let DHOST = process.env.VUE_APP_DHOST
let OHOST = process.env.VUE_APP_OHOST
let SHOST = process.env.VUE_APP_SHOST
let FHOST = process.env.VUE_APP_FHOST
let THOST = process.env.VUE_APP_THOST
let HOST_PIC = process.env.VUE_APP_HOST_PIC
let ADMINEHOST = process.env.VUE_APP_ADMINEHOST
let STOREHOST = process.env.VUE_APP_STOREHOST
let SYNHOST = process.env.VUE_APP_SYNHOST // 三翼鸟不同环境host
let UPLUSHOST = process.env.VUE_APP_UPLUS
let PDHOST = process.env.VUE_APP_PD
let UPLUSSYN = process.env.VUE_APP_SYN
let HOST_PIC_ALOSS = process.env.VUE_APP_HOST_ALIOSS
export default {
  HOST,
  HOST4,
  PHOST,
  DHOST,
  OHOST,
  SHOST,
  FHOST,
  THOST,
  ADMINEHOST,
  HOST_PIC,
  STOREHOST,
  SYNHOST,
  UPLUSHOST,
  PDHOST,
  UPLUSSYN,
  HOST_PIC_ALOSS,
}
