const vipInvite = [ {
  path: '/vipInvite',
  name: 'vipInvite',
  component: () => import('@/viewsToB/vipInvite/index.vue'),
  meta: {
    title: '邀请入会',
    showHeader: false,
  }
}]

export default vipInvite
