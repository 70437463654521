const shopData = [
  {
    path: '/shopData',
    name: 'shopData',
    component: () => import('@/viewsToB/shopData/shopIndex/index.vue'),
    meta: {
      title: '店铺管理',
      keepAlive: false,
      showTabbar: false,
    }
  },
  {
    path: '/shopList',
    name: 'shopList',
    component: () => import('@/viewsToB/shopData/shopList/index.vue'),
    meta: {
      title: '门店列表',
      keepAlive: true,
      showTabbar: false,
      showHeader: false,
    }
  },
  {
    path: '/shopDetails',
    name: 'shopDetails',
    component: () => import('@/viewsToB/shopData/shopDetails/index.vue'),
    meta: {
      title: '门店详情',
      keepAlive: true,
      showTabbar: false,
    }
  },
  {
    path: '/shopSearch',
    name: 'shopSearch',
    component: () => import('@/viewsToB/shopData/shopSearch/index.vue'),
    meta: {
      title: '查询',
      keepAlive: true,
      showTabbar: false,
    }
  },
  {
    path: '/shopSearchDetails',
    name: 'shopSearchDetails',
    component: () => import('@/viewsToB/shopData/shopSearchDetails/index.vue'),
    meta: {
      title: '查询',
      keepAlive: true,
      showTabbar: false,
    }
  },
]

export default shopData
