const home = [
  {
    path: '/',
    name: 'home',
    component: () => import(
      /* webpackChunkName: "home" */
      '@/views/home/newHome.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      showTabbar: true,
    }
  },
  {
    path: '/clientHome',
    name: 'clientHome',
    component: () => import(
      /* webpackChunkName: "home" */
      '@/views/home/client.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      showTabbar: false,
    }
  },
  {
    path: '/ecologicalHome',
    name: 'ecologicalHome',
    component: () => import(
      /* webpackChunkName: "ecologicalHome" */
      '@/views/ecologicalHome/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
    }
  },
  {
    path: '/preview',
    name: 'homepreview',
    component: () => import(
      /* webpackChunkName: "home" */
      '@/views/home/preview.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      showTabbar: true,
    }
  },
  {
    path: '/vipstore',
    name: 'vipstore',
    component: () => import(
      /* webpackChunkName: "home" */
      '@/views/home/vipStore.vue'),
    meta: {
      keepAlive: false,
      showHeader: false,
      needLogin: true,
      title: '会员店铺'
    }
  },
  {
    path: '/banner/:id',
    name: 'bannerDetail',
    component: () => import(
      /* webpackChunkName: "bannerDetail" */
      '@/views/banner/bannerDetail.vue'),
    meta: {
      title: '海尔智家',
      keepAlive: true,
      showHeader: false,
      showTabbar: false,
    }
  },
  {
    /* 更改传参方式为query的动态活动页 */
    path: '/bannerCustom',
    name: 'bannerCustom',
    component: () => import(
      /* webpackChunkName: "bannerCustom" */
      '@/views/banner/bannerCustom.vue'),
    meta: {
      title: '海尔智家',
      keepAlive: true,
      showHeader: false,
      showTabbar: false,
    }
  },
  {
    path: '/bannerTheme/:id',
    name: 'bannerTheme',
    component: () => import(
      /* webpackChunkName: "bannerTheme" */
      '@/views/banner/bannerTheme.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      showTabbar: false,
    }
  },
  {
    /* 更改传参方式为query的主题活动页 */
    path: '/bannerSubject',
    name: 'bannerSubject',
    component: () => import(
      /* webpackChunkName: "bannerSubject" */
      '@/views/banner/bannerSubject.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      showTabbar: false,
    }
  },
  {
    path: '/couponDetail/:id/:from',
    name: 'couponDetail',
    component: () => import('@/views/banner/couponDetail.vue'),
    meta: {
      title: '优惠券详情',
      keepAlive: false,
      showTabbar: false,
    }
  },
  {
    /* 更改传参方式为query的优惠券详情页 */
    path: '/couponInfo',
    name: 'couponInfo',
    component: () => import('@/views/banner/couponInfo.vue'),
    meta: {
      title: '优惠券详情',
      keepAlive: false,
      showTabbar: false,
    }
  },
  {
    path: '/goodsSearch',
    name: 'goodsSearch',
    component: () => import(
      /* webpackChunkName: "goodsSearch" */
      '@/views/searchs/index.vue'),
    meta: {
      title: '搜索',
      isUseCache: true, // 是否使用缓存
      keepAlive: true,
      showHeader: false,
      showTabbar: false,
    }
  },
  {
    path: '/categorySearch',
    name: 'categorySearch',
    component: () => import('@/views/searchs/index.vue'),
    meta: {
      title: '搜索',
      isUseCache: true, // 是否使用缓存
      keepAlive: true,
      showHeader: false,
      showTabbar: false,
    }
  },
  {
    path: '/hotProdArea',
    name: 'hotProdArea',
    component: () => import('@/views/searchs/prodHotArea.vue'),
    meta: {
      title: '爆品专区',
      showTabbar: false,
    }
  },
  {
    path: '/rankList',
    name: 'rankList',
    component: () => import('@/views/rankList/index.vue'),
    meta: {
      title: '智家榜单',
      keepAlive: true,
      showTabbar: false,
      showHeader: false,
    }
  },
  {
    path: '/rankCategoryList',
    name: 'rankCategoryList',
    component: () => import('@/views/rankList/categoryRank/index.vue'),
    meta: {
      title: '智家榜单',
      keepAlive: true,
      showTabbar: false,
      showHeader: false,
    }
  },
  {
    path: '/partnerRank',
    name: 'partnerRank',
    component: () => import('@/views/partnerRank/index.vue'),
    meta: {
      title: '合伙人排行榜',
      keepAlive: true,
      showTabbar: false,
      showHeader: true,
    }
  },
  {
    path: '/suitChoice',
    name: 'suitChoice',
    component: () => import('@/views/suitChoice/index.vue'),
    meta: {
      showHeader: false,
      title: '精选套购'
    }
  }
]

export default home
