const games = [{
  path: '/games/luckywheel',
  name: 'luckywheel',
  component: () => import('@/views/games/LuckyWheel.vue'),
  meta: {
    keepAlive: false,
    title: '幸运大转盘',
    showTabbar: false,
    showHeader: false,
    awaitlogin: true
  }
},
{
  path: '/games/luckywheell',
  name: 'luckywheell',
  component: () => import('@/views/games/LuckyWheel.vue'),
  meta: {
    keepAlive: false,
    title: '幸运大转盘',
    showTabbar: false,
    showHeader: false,
    awaitlogin: true
  }
},
{
  path: '/games/giftRecord',
  name: 'giftRecord',
  component: () => import('@/views/games/GiftRecord.vue'),
  meta: {
    keepAlive: false,
    title: '我的中奖记录',
    showTabbar: false,
    showHeader: true,
  }
},
{
  path: '/games/verifyCode',
  name: 'VerifyCode',
  component: () => import('@/views/games/VerifyCode.vue'),
  meta: {
    keepAlive: false,
    title: '核销码',
    showTabbar: false,
    showHeader: true,
    needLogin: true,
  }
},
{
  path: '/games/giftAddress',
  name: 'giftAddress',
  component: () => import('@/views/games/GiftAddress.vue'),
  meta: {
    keepAlive: false,
    title: '我的领奖信息',
    showTabbar: false,
    showHeader: true,
  }
},
{
  path: '/games/storeLuckywheel',
  name: 'storeLuckywheel',
  component: () => import('@/views/games/StoreLuckyWheel.vue'),
  meta: {
    keepAlive: false,
    title: '幸运大转盘',
    showTabbar: false,
    showHeader: false,
  }
},
{
  path: '/games/storeLuckywheell',
  name: 'storeLuckywheell',
  component: () => import('@/views/games/StoreLuckyWheel.vue'),
  meta: {
    keepAlive: false,
    title: '幸运大转盘',
    showTabbar: false,
    showHeader: false,
  }
},
{
  path: '/games/slotmac',
  name: 'slotmac',
  component: () => import('@/views/games/SlotMachine.vue'),
  meta: {
    keepAlive: false,
    title: '好运连连看',
    showTabbar: false,
    showHeader: false,
    awaitlogin: true
  }
},
{
  path: '/games/slotmac/record',
  name: 'slotmacRecord',
  component: () => import('@/views/games/SlotMachineRecord.vue'),
  meta: {
    keepAlive: false,
    title: '我的奖品',
    showTabbar: false,
    showHeader: true,
    needLogin: true,
  }
}]

export default games
