const activity = [
  {
    path: '/renewedArea',
    name: 'renewedArea',
    component: () => import('@/views/activity/renewedArea.vue'),
    meta: {
      showHeader: false,
      title: '换新专区'
    }
  }
]

export default activity