import axios from 'axios'
import Qs from 'qs'
// import Cookies from 'js-cookie'
import URL from '@/http/url'
import store from '../store'
import Loading from '../components/loading/index.js'
import utils from '../utils/utils'
import AxiosRepeatAbandon from 'axios-repeat-abandon'
import _config from '../config'

AxiosRepeatAbandon(axios, {
  time: 800,
  openSwitch: false
})

// import { upGet, upPost, upRequest } from './upHttp'
// import UplusApi from '@uplus/uplus-api'
// const upInstance = new UplusApi();
// const TokenAuthorization = 'Bearer' + localStorage.getItem('sessionValue')
// axios.defaults.baseURL = process.env.VUE_APP_ENV === 'development' ? '' : _config.HOST
axios.defaults.baseURL = _config.HOST

/**
 * U+环境重写request、get、post方法
 */
// if (utils.isHaierApp()) {
// if (navigator.userAgent.indexOf('App/Uplus') > -1) {
// upInstance.initDeviceReady().then(() => {
// axios.request = upRequest
// axios.get = upGet
// axios.post = upPost
//   })
// }
export const platform = function() {
  if (utils.isSyBird()) {
    return utils.isIos() ? 'haier-app-sybird-ios' : 'haier-app-sybird-android'
  } else if (utils.isIMaker()) {
    return utils.isIos() ? 'haier-app-ichuangke-ios' : 'haier-app-ichuangke-android'
  } else if (utils.isInternalChannel()) {
    return utils.isIos() ? 'haier-app-ihaier2-ng-ios' : 'haier-app-ihaier2-ng-android'
  } else if (utils.isIhaierApp()) {
    return utils.isIos() ? 'haier-app-ihaier-ios' : 'haier-app-ihaier-android'
  } else if (utils.isUplus()) {
    return utils.isIos() ? 'haier-app-zhijia-ios' : 'haier-app-zhijia-android'
  } else if (utils.isSgRn()) {
    return utils.isIos() ? 'sc-app-zhanggui-ios' : 'sc-app-zhanggui-ios'
  } else if (utils.isShopKeeper()) {
    return utils.isIos() ? 'sc-app-eguang-ios' : 'sc-app-eguang-android'
  } else if (utils.isScMpAli()) {
    return 'sc-mp-ali'
  } else if (utils.isScMpWechat()) {
    return 'sc-mp-wx'
  } else if (utils.isScMpPeWechat()) {
    return 'sc-mp-wx-private'
  } else if(utils.isZhiJiaMP()){
    return 'sc-mp-wx-zjapp'
  } else if (utils.isScMpByte()) {
    return 'sc-mp-byte'
  } else if (utils.isSyBirdMP()) {
    return utils.isSyBirdWeChatMP() ? 'sybird-mp-wx' : 'sybird-mp-baidu'
  } else if (utils.isStoreCenter()) {
    return 'sc-app-eguang-android'
  } else {
    return 'sc-h5-m'
  }
  // else if (utils.isSYNDP()) {
  //   return 'haier-sybird-dp'
  // } else if (utils.isSyBirdH5()) {
  //   return 'haier-sybird-h5'
  // }

}
// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // 去除开发环境中的baseURL
    if (utils.isRunLocalServer()) {
      config.baseURL = ''
    }

    // 前端生成游客token的session随机数
    if (!sessionStorage.getItem('mg')) {
      sessionStorage.setItem('mg', utils.randomString())
    }
    config.headers.common['mg'] = sessionStorage.getItem('mg')
    config.headers.common['Platform'] = platform()
    if (utils.isShopKeeper() || utils.isScMpPeWechat() || utils.isStoreCenter()) {
      const currentRole = utils.strJSONParse(sessionStorage.getItem('currentRole'), null)
      if (currentRole?.userIdentity) config.headers.common['identity'] = currentRole.userIdentity
    }
    if(utils.isScMpPeWechat() && sessionStorage.getItem('sg_home_open_scene') === 'PPHP'){
      console.log('wxIdentity====>',utils.parseQueryValue('wxIdentity'))
      config.headers.common['identity'] = utils.parseQueryValue('wxIdentity')
    }
    const token = store.getters.accessToken
    if (token) {
      config.headers.common['ak'] = token
    }
    // if (utils.isHaierApp()) {
    //   config.headers.common['ak'] = store.getters.accessToken
    // }
    // U+环境，使用httpdns服务
    // if (utils.isHaierApp()) {
    //   upInstance.upHttpModule.domainTransformToIP({
    //     url: config.baseURL,
    //   }).then((result) => {
    //     console.log('http-dns 解析domain', config.baseURL);
    //     console.log('http-dns 解析结果', result);
    //     if (result.retCode === '000000' && result.retData) {
    //       const rHost = result.retData.IP.match(/\[(\S*)\//)[1]
    //       const rIp = result.retData.IP.match(/\/(\S*)\]/)[1]
    //       config.baseURL = config.baseURL.replace(rHost, rIp)
    //       config.headers.Host = rHost
    //     }
    //     return config
    //   }).catch((err) => {
    //     console.log('http-dns 解析失败', err);
    //     return config
    //   })
    // } else {
    //   return config
    // }
    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  }
)
// Add a response interceptor
axios.interceptors.response.use(
  async function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (!response.data.success) {
      if (+response.data.errorCode === 40001) {
        const refreshToken = localStorage.getItem('refreshToken')
        if (refreshToken) { // 只有H5才有refreshToken
          let { url, method, params, baseURL, data } = response.config
          let result = await axios.request({
            url,
            method,
            params,
            headers: {
              rt: refreshToken
            },
            baseURL,
            data: utils.strJSONParse(data, {}),
            noLogin: response.config.noLogin,
          })
          console.log(result.headers, '==========result==========',result.headers.ak, result.headers.rk)
          if(result.headers.ak || result.headers.rt){
            store.commit('setAcsToken', result.headers.ak || '')
            store.commit('setRefreshToken', result.headers.rt || '')
            localStorage.setItem('accessToken', result.headers.ak || '')
            // localStorage.setItem('refreshToken', result.headers.rt || '')
            utils.setLocalStorage('refreshToken', result.headers.rt || '')
          }
          return result
        } else {
          if (response.config.url !== URL.GET_USERINFO) { // 防止access_token无效时，接口自身导致拉起登录的问题
            if (!utils.isHaierApp() && !response.config.noLogin) {
              if(utils.isInternalChannel()) {
                response.data.message = '登录状态失效。'
              }
              await store.dispatch('logout', null)
              store.dispatch('doLogin') 
            } else if (utils.isHaierApp() && !store.uplusLogininitFlag && !response.config.noLogin) { // 控制只拉起一次登录
              store.uplusLogininitFlag = true
              await store.dispatch('logout', null)
              store.dispatch('doLogin')
            }
          }
        }
      } else if (+response.data.errorCode === 40002) { // refresh
        if (response.config.url !== URL.GET_USERINFO) { // 防止access_token无效时，接口自身导致拉起登录的问题
          if (!utils.isHaierApp() && !response.config.noLogin) {
            if(utils.isInternalChannel()) {
              response.data.message = '登录状态失效。'
            }
            await store.dispatch('logout', null)
            store.dispatch('doLogin')
          } else if (utils.isHaierApp() && !store.uplusLogininitFlag && !response.config.noLogin) { // 控制只拉起一次登录
            store.uplusLogininitFlag = true
            await store.dispatch('logout', null)
            store.dispatch('doLogin')
            // sdk重新取下access_token
          }
        }
      }
    }

    // if (!utils.isHaierApp()) { // app内每次免登故去除
    // if (!response.data.success && (response.data.errorCode === -100 || response.data.errorCode === '-100' || response.data.errorCode === '未登录') && !response.config.noLogin) {
    //   // token过期或未登录
    //   if (!utils.isHaierApp()) {
    //     store.dispatch('doLogin')
    //   } else {
    //     // Toast('token过期或未登录')
    //   }
    //   // store.dispatch('doLogin').then(()=>{
    //   // location.reload()
    //   // })
    // }
    // }
    return response
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log('error')

    return Promise.reject(error)
  }
)
export default axios

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {Object} config [请求配置]
 */
export function get(url, params, config = {}, loading = false) {
  return new Promise((resolve, reject) => {
    if (loading) {
      Loading.open()
      // Toast.loading({
      //   message: '',
      //   forbidClick: true,
      //   duration: 0,
      // })
    }
    axios
      .get(url, {
        params: params,
        paramsSerializer: function(params) {
          return Qs.stringify(params, { arrayFormat: 'repeat' })
        },
        ...config,
      })
      .then(res => {
        if (loading) {
          Loading.close()
          // Toast.clear()
        }
        resolve(res.data)
      })
      .catch(err => {
        if (loading) {
          Loading.close()
          // Toast.clear()
        }
        // Toast('请求失败')
        reject(err)
      })
  })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {Object} config [请求配置]
 */
export function post(url, params, config, loading) {
  return new Promise((resolve, reject) => {
    if (loading) {
      Loading.open()
      // Toast.loading({
      //   message: '',
      //   forbidClick: true,
      //   duration: 0,
      // })
    }
    axios
      .post(url, params, config)
      .then(res => {
        if (loading) {
          Loading.close()
          // Toast.clear()
        }
        resolve(res.data)
      })
      .catch(err => {
        if (loading) {
          Loading.close()
          // Toast.clear()
        }
        // Toast('请求失败')
        reject(err.data)
      })
  })
}
