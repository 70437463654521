
export default [
  {
    path: '/exclusivepricelist',
    name: 'exclusivePriceList',
    component: () => import('@/viewsToB/exclusivePrice/list.vue'),
    meta: {
      title: '专属价格',
      needLogin: true,
      awaitLogin: true,
    }
  },
  {
    path: '/exclusivepricedetail/:id',
    name: 'exclusivePriceDetail',
    component: () => import('@/viewsToB/exclusivePrice/detail.vue'),
    meta: {
      title: '专属价格详情',
      needLogin: true,
      awaitLogin: true,
    }
  },
  {
    path: '/exclusivePlan',
    name: 'exclusivePlan',
    component: () => import('@/viewsToB/exclusivePlan/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '我的方案',
    }
  },
  {
    path: '/exclusivePlanDetail/:quotationSeq',
    name: 'exclusivePlanDetail',
    component: () => import('@/viewsToB/exclusivePlan/list.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '方案报价单',
    }
  },
  {
    path: '/exclusiveEntry',
    name: 'exclusiveEntry',
    component: () => import('@/viewsToB/exclusiveEntry/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '专属报价',
    }
  },
]
