const transfer = [{
  path: '/qrTransfer',
  name: 'qrTransfer',
  component: () => import('@/views/transfer/qrTransfer.vue'),
  meta: {
    keepAlive: true,
    title: '二维码解析',
    needLogin: false,
    showTabbar: false,
    showHeader: false,
  }
}]

export default transfer
