/**
 *  TimeUtil
 */
function toStrFormat(a: number | string): number | string {
  a = a < 10 ? ('0' + a) : a;
  return a;
}

const TimeUtil = {
  /**
   *  format 格式化日期时间
   *  @param timestamp integer 欲被格式化的时间戳
   *  @param withTime boolean 是否需要包含日期
   *  @return string ex: 2019-01-09 02:04:56
   */
  format: function(timestamp: string | number, withTime = true) {
    const date: Date = Number.isInteger(timestamp) ? new Date(timestamp) : new Date(null);
    const time = [
      this.formatDate(date),
    ];
    if (withTime) {
      time.push(this.formatTime(date));
      return time.join(' ');
    }
    return time[0];
  },

  /**
   * 判断是否为今天
   * @param str 日期字符串 如：2019-11-11
   */
  isToday: function(str: string): boolean {
    const d: Date = new Date(str.replace(/-/g, '/'));
    const todaysDate: Date = new Date();
    if (d.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
      return true;
    } else {
      return false;
    }
  },

  /**
   *  formatDate 格式化日期
   *  @param date Date
   *  @return string ex: 2019-01-09
   */
  formatDate: function(date: Date, joinFlag = '-'): string {
    return [
      date.getFullYear(),
      String(date.getMonth() + 1).padStart(2, '0'),
      String(date.getDate()).padStart(2, '0'),
    ].join(joinFlag);
  },

  /**
   *  formatDate 格式化日期
   *  @param date Date
   *  @return string ex: 2019-01-09 17:00
   */
  formatFullDate: function(date: Date): string {
    const time: string = [
      String(date.getHours()).padStart(2, '0'),
      String(date.getMinutes()).padStart(2, '0'),
    ].join(':');
    return `${this.formatDate(date)} ${time}`;
  },
  /**
   *  formatDate 格式化日期
   *  @param date Date
   *  @return string ex: 2019-01-09 17:00:22
   */
  formatAllFullDate: function(date: Date): string {
    const time: string = [
      String(date.getHours()).padStart(2, '0'),
      String(date.getMinutes()).padStart(2, '0'),
      String(date.getSeconds()).padStart(2, '0'),
    ].join(':');
    return `${this.formatDate(date)} ${time}`;
  },

  /**
   *  formatTime 格式化时间
   *  @param date Date
   */
  formatTime: function(date: Date): string {
    return [
      String(date.getHours()).padStart(2, '0'),
      String(date.getMinutes()).padStart(2, '0'),
      String(date.getSeconds()).padStart(2, '0'),
    ].join(':');
  },

  /**
   *  getEndTime 格式化时间
   *  @param timestamp integer 被格式化的时间戳
   *  @param isShowDay 是否显示天数
   *   @return string ex: 3天 02:04:56
   */
  getEndTime: function(timestamp: number, isShowDay?: boolean): (string | number)[] {
    const timeList: (string | number)[] = [];
    timestamp = Math.floor(timestamp / 1000);
    const endTt: string | number = toStrFormat(Math.floor(timestamp / (24 * 60 * 60)));
    const endTh: string | number = toStrFormat(Math.floor((timestamp - Number(endTt) * 86400) / 3600));
    const endTm: string | number = toStrFormat(Math.floor((timestamp - Number(endTt) * 86400 - Number(endTh) * 3600) / 60));
    const endTs: string | number = toStrFormat(timestamp - Number(endTt) * 86400 - Number(endTh) * 3600 - Number(endTm) * 60);
    if (isShowDay) {
      timeList.push(endTt);
    }
    timeList.push(endTh);
    timeList.push(endTm);
    timeList.push(endTs);
    return timeList;
  },
  /**
   *  getEndTime 格式化时间戳
   *  @param timestamp integer 被格式化的时间戳
   *   @return integer 传入时间戳的时分秒设置为23:59:59，然后返回格式化后的时间戳
   */
  formatTimestamp: function(timestamp: number) : number {
    let result = new Date().getTime()
    if(timestamp) {
      result = new Date(this.formatDate(new Date(timestamp)).replace(/-/g, '/') + ' 23:59:59').getTime()
    }
    return result
  }
};

export default TimeUtil;
