const home = [
  {
    path: '/partners',
    component: () => import('@/views/partners/partners.vue'),
    meta: {
      showHeader: false,
      showTabbar: false,
    }
  }, {
    path: '/jobs',
    component: () => import('@/views/partners/component/jobs.vue'),
    meta: {
      showHeader: false
    }
  },
  {
    path: '/applyPartner',
    component: () => import('@/views/partners/apply.vue'),
    meta: {
      showTabbar: false,
      showHeader: false,
    }
  },
  {
    path: '/settleInPartner',
    component: () => import('@/views/partners/settleInPartner.vue'),
    meta: {
      showTabbar: false,
      showHeader: false,
      needLogin: true,
    }
  },
]

export default home
