import utils from '@/utils/utils'

// 对应的enum定义
export const DialogAction = {
  CLOSE: "DialogAction.close",
  SUBMIT: "DialogAction.submit",
  CANCEL: "DialogAction.cancel",
  NEVER_NOTIFY: "DialogAction.neverNotify",
  UNPROCESSED: "DialogAction.unprocessed"
};

export const PageType = {
  SEARCH: "M_SEARCH",
  COUPON: "M_COUPON_CENTER",
}
//csi场景
export const CSISC = {
  M_SEARCH: '搜索结果准确性评价',
  M_COUPON_CENTER: '领券中心满意度评价',
}
//页面名称
export const PageName = {
  M_SEARCH: '搜索页',
  M_COUPON_CENTER: '领券页'
}
//gio code
export const GioCode = {
  EXPOSURE: 'MB35041',
  CLICK: 'MB35042'
}

// 当前Tab是否展示弹窗
export const  canShowDialog = async function (tabName) {
  tabName = 'eshopTabUserEvaluate'
  try {
    // boolean
    const usei = await utils.getAppStorageBooleanValue('uplus_setting_evaluate_invite')
    console.log('usei:',usei)
    let status = Boolean(usei) === true;
    if (!status) {
      return false;
    }
    const name = await utils.getAppStorageStringValue('uplus_showTabName') || "";
    console.log('name:',name)
    if (!name) {
      return true;
    }
    // long value
    const time = parseInt(await utils.getAppStorageLongValue(tabName) || "0");
    const duration = 24 * 60 * 60 * 1000;
    const currentTime = new Date().getTime();

    console.log('tabName:',tabName)
    if (name === tabName) {
      return await _checkShowDialog(tabName, duration, currentTime, time);
    } else {
      if (currentTime - time >= duration * 14) {
        return await _checkShowDialog(tabName, duration * 14, currentTime, time);
      } else {
        return false;
      }
    }
  } catch (error) {

  }
}

async function _checkShowDialog(name, duration, currentTime, time) {
  const action = await utils.getAppStorageStringValue(`uplus_${name}_action`) || "";
  if (action === DialogAction.NEVER_NOTIFY) {
    duration *= 90;
  } else if (action === DialogAction.UNPROCESSED) {
    duration *= 30;
  } else {
    duration *= 60;
  }
  console.log('duration:',duration)
  return (currentTime - time) > duration;
}

// 设置某个Tab弹出弹窗的时间
export const setDialogTime = function (tabName) {
  tabName = 'eshopTabUserEvaluate'
  const timestamp = new Date().getTime();
  utils.setAppStorageLongValue(tabName, timestamp);
  utils.setAppStorageStringValue('uplus_showTabName', tabName);
}

// 弹窗的具体操作
export const setDialogAction = function (tabName, action) {
  tabName = 'eshopTabUserEvaluate'
  if (action.includes(DialogAction.NEVER_NOTIFY)) {
    utils.setAppStorageStringValue(`uplus_${tabName}_action`, DialogAction.NEVER_NOTIFY);
    return;
  }
  if (action.includes(DialogAction.UNPROCESSED)) {
    utils.setAppStorageStringValue(`uplus_${tabName}_action`, DialogAction.UNPROCESSED);
    return;
  }
  utils.setAppStorageStringValue(`uplus_${tabName}_action`, action[0]);
}
