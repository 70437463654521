const quickPlaceOrder = [
  {
    path: '/quickPlaceOrder',
    name: 'quickPlaceOrder',
    component: () => import('@/viewsToB/quickPlaceOrder/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '快下单',
      needLogin: true,
    }
  },
  {
    path: '/quickOrderShare',
    name: 'quickOrderShare',
    component: () => import('@/viewsToB/quickPlaceOrder/share.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '方案报价单',
    }
  },
  {
    path: '/quickOrderEntry',
    name: 'quickOrderEntry',
    component: () => import('@/viewsToB/quickPlaceOrder/detail.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '我的方案',
    }
  },
  {
    path: '/quickOrderSearch',
    name: 'quickOrderSearch',
    component: () => import('@/viewsToB/quickPlaceOrder/search.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '我的方案',
    }
  },
  {
    path: '/quickOrderSence',
    name: 'quickOrderSence',
    component: () => import('@/viewsToB/quickPlaceOrder/sence.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '',
    }
  },
  {
    path: '/quickOrderMyScheme',
    name: 'quickOrderMyScheme',
    component: () => import('@/viewsToB/quickPlaceOrder/myScheme.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '',
    }
  },
  {
    path: '/quickOrderSelectScheme',
    name: 'quickOrderSelectScheme',
    component: () => import('@/viewsToB/quickPlaceOrder/selectScheme.vue'),
    meta: {
      keepAlive: true,
      showHeader: false,
      title: '',
    }
  }
]

export default quickPlaceOrder