// 环境判断方法
export default {
  /**
   * 判断当前是否是生产环境 (决定接口走测试还是生产)
   */
  isProdEnv() {
    return process.env.VUE_APP_ENV === 'production'
  },
  /**
   * @description 判断当前是否预发环境
  */
  isPreEnv() {
    return process.env.VUE_APP_ENV === 'preview'
  },
  /**
   * @description 是否生产或者预发环境
  */
  isProdOrPreEnv() {
    return this.isProdEnv() || this.isPreEnv()
  },
  /**
   * 判断当前是否是在本地开发环境 （甄别是本地开发运行还是打包到服务器运行（生产、测试发布时 均为production））
   */
  isRunLocalServer() {
    return process.env.NODE_ENV === 'development'
  },
  /**
   * @returns 生产环境部署在线上时返回true
   */
  isProdDistribution() {
    return !this.isRunLocalServer() && this.isProdEnv()
  },
  /**
   * @returns 测试环境部署在线上时返回true
   */
  isDevDistribution() {
    return !this.isRunLocalServer() && !this.isProdEnv()
  },
}
