import utils from '@/utils/utils'
export default {
  namespaced: true,
  state: {
    isbackShowPop: false, // 是否返回弹出购物清单弹层
    isCreateFromMyList: false, // 是否从我的方案直接结算
    tmpSceneList: {}, //直接结算支付结果页获取改方案
    sceneCarNumber: 0, // 购物清单商品总数量
    sceneCarList: [], // 快下单购物清单商品列表
    sceneSourceType: 0, // 来源(0:非快下单;1:快下单;2:商机码)
    sceneSourceCode: '', // 商机码
    totalAmount: 0, // 购物清单总金额
    sceneCaseProductList: [], // 结算时保存商品列表部分信息为支付成功页取用
    currentMdInfo: {}, // 当前门店信息
    isSaveCase: false, // 购物清单商品是否保存过方案标识
    sceneCaseName: '', // 购物清单保存方案名
    sceneCode: '', // 购物清单sceneCode编码
  },
  getters: {
    getPayParams(state) {
      return {
        isCreateFromMyList: state.isCreateFromMyList,
        sceneSourceType: state.sceneSourceType,
        sceneSourceCode: state.sceneSourceCode,
        sceneCaseProductList: state.sceneCaseProductList,
        mdCode: state.currentMdInfo?.mdCode || '',
        isSaveCase: state.isSaveCase,
        sceneCaseName: state.sceneCaseName,
        visitcode: state.currentMdInfo?.storeId || '',
      }
    }
  },
  mutations: {
    setTmpSceneList(state, info){
      state.tmpSceneList = info
    },
    setIsbackShowPop(state, flag){
      state.isbackShowPop = flag
    },
    setIsCreateFromMyList(state, flag){
      state.isCreateFromMyList = flag
    },
    setSceneCarNumber(state, number) {
      state.sceneCarNumber = number
    },
    setSceneCarList(state, list) {
      state.sceneCarList = list
    },
    setSceneSourceType(state, type) {
      state.sceneSourceType = type
    },
    setAmount(state, value) {
      state.totalAmount = value
    },
    setSourceCode(state, businessCode) {
      state.sceneSourceCode = businessCode
    },
    setSceneCaseProductList(state, list) {
      state.sceneCaseProductList = list
    },
    setCurrentMdInfo(state, obj) {
      state.currentMdInfo = obj
    },
    setIsSaveCase(state, bool) {
      state.isSaveCase = bool
    },
    setSceneCaseName(state, str) {
      state.sceneCaseName = str
    },
    setSceneCode(state, str) {
      state.sceneCode = str
    }
  },
  actions: {
    /**
     * @description 购物清单总商品数量更新
     */
    changeSceneCarNumber({ commit, state }, number) {
      let newNumber = state.sceneCarNumber
      newNumber += number
      commit('setSceneCarNumber',newNumber)
    },
    /**
     * @description 快下单商品更新
     */
    changeSceneLightCarList({ commit, dispatch, state }, params = { cardList: [], isAdd: true}) {
      let newList = state.sceneCarList
      if (newList.length != 0) {
        let _list = []
        params.cardList.forEach(prod => {
          let dealTag = false; // 处理标识
          newList.forEach((item,index) => {
            if (item.productId == prod.productId) {
              dealTag = true
              // 更新利润来源中心
              item.profitCenterCode = prod.profitCenterCode
              if (params.isAdd) {
                item.sceneCarNumber += 1
              } else {
                item.sceneCarNumber -= 1
                if (item.sceneCarNumber == 0) {
                  newList.splice(index,1)
                }
              }
            }
          })
          if (!dealTag) {
            _list.push(prod)
          }
        })
        if (_list.length) {
          newList.push(..._list)
        }
      } else {
        newList.push(...params.cardList)
      }
      commit('setSceneCarList',newList)
      dispatch('upDataAmount')
    },
    /**
     * @description 购物清单场景清空
     */
    clearSceneShop({ commit }) {
      commit('setSceneCarList',[])
      commit('setSceneCarNumber',0)
    },
    /**
     * @description 来源：0:非快下单;1:快下单;2:商机码
     */
    changeSource({ commit },type) {
      commit('setSceneSourceType',type)
    },
    /**
     * @description 商机码设置
     */
    changeSourceCode({ commit },businessCode) {
      commit('setSourceCode',businessCode)
    },
    /**  
     * 改价后更新优惠券系信息
     */
    upCoupon({state}, arr){
      arr.forEach(nItem => {
        state.sceneCarList.forEach(item => {
          if(item.sku === nItem.sku){
            item.marketingTagList = nItem.marketingTagList || []
          }
        })
      })
    },
    /**
     * @description 更新购物清单总金额
     */
    upDataAmount({ commit, state }) {
      let value = 0
      if (state.sceneCarList.length) {
        state.sceneCarList.forEach(item => {
          value = utils.accAdd(value,utils.accMul(item.finalPrice,item.sceneCarNumber))
        })
      }
      commit('setAmount',value)
    },
    /**
     * @description 更新结算时商品列表部分信息
     */
    upDataSceneCaseProductList({ commit, state }) {
      let _list = []
      _list = state.sceneCarList.map(item => {
        return {
          sku: item.sku,
          addTime: item.addTime,
          profitCenterCode: item.profitCenterCode,
          num: item.sceneCarNumber,
        }
      })
      commit('setSceneCaseProductList', _list)
    },
    /**
     * @description 更新改价后购物清单商品价格和改价id
     */
    upDataChangePrice({ state }, changePriceList) {
      state.sceneCarList.forEach(item => {
        changePriceList.forEach(prod => {
          if (item.sku == prod.sku) {
            item.finalPrice = prod.changePrice
            if (prod?.exclusiveId	) {
              item.exclusiveId = prod.exclusiveId
            }
          }
        })
      })
    },
  },
}
