const magicSquare = [
  {
    path: '/magicCube',
    name: 'magicCube',
    component: () => import('@/views/magicCube/index.vue'),
    meta: {
      keepAlive: true,
      showHeader: true,
      title: '智家魔方',
      hasdownload: true,
    }
  },
  // {
  //   path: '/download',
  //   name: 'download',
  //   component: () => import('@/views/download/index.vue'),
  //   meta: {
  //     keepAlive: false,
  //     showHeader: false,
  //     title: '掌柜下载',
  //     hasdownload: false,
  //   }
  // }
]

export default magicSquare
